import {
    FETCH_TEHNICS_BY_ID_DATA_START,
    FETCH_TEHNICS_BY_ID_DATA_SUCCESS,
    CLEAR_TECHNIC_BY_ID_DATA,
    FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS
} from '../../actionTypes'

const initialState = {
    tehnics_by_id_data: null,
    current_location: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TEHNICS_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_TEHNICS_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                tehnics_by_id_data: action.data.tehnics_by_id_data
            }
        case FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS: {
            return {
                ...state,
                current_location: action.data.current_location
            }
        }
        case CLEAR_TECHNIC_BY_ID_DATA:
            return {
                tehnics_by_id_data: null,
                current_location: null
            }
        default: return state
    }
}