import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { fetchMyDeals } from 'store/actions/deals';
import { allOffersSwitch } from 'data/adminLists';
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery';
import Switch from 'components/shared/switch/Switch';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import OfferCollaps from 'components/shared/offerCollapse/OfferCollapse';
import Pagination from 'components/shared/pagination/Pagination';
import NoDataBlock from 'components/ui/noDataBlock/NoDataBlock';

import tractor from 'assets/images/messageIcons/tractor.svg';

function AllOffers({ user_data_deals, pages, fetchMyDeals }) {
    const [activeSwitch, setActiveSwitch] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyDeals(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                if (obj.is_viewed) {
                    setActiveSwitch(true)
                } else {
                    setActiveSwitch(false)
                }
            }
        } else {
            query.page = 1
            query.search = getQueryStringFromObject(JSON.stringify({ "is_viewed": false }))
            setSearchParams(query)
            setActiveSwitch(false)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const onSwitch = (role) => {
        const query = Object.fromEntries([...searchParams]);
        if (query.search) {
            const obj = JSON.parse(decodeURIComponent(((query.search))))
            obj.is_viewed = role
            query.search = getQueryStringFromObject(JSON.stringify(obj))
        } else {
            query.search = getQueryStringFromObject(JSON.stringify({ "is_viewed": role }))
        }
        setSearchParams(query)
        setActiveSwitch(role)
    }

    return (
        <div className='allUsersBlock'>
            <TextHeading text={'Предложения о сотрудничестве'} />
            <Switch
                items={allOffersSwitch}
                activeItem={activeSwitch}
                onSwitch={onSwitch}
                className="switch miniSwitch"
                style={{ marginTop: 24, marginBottom: 16 }}
            />
            {user_data_deals.length > 0
                ? user_data_deals.map(el => (
                    <OfferCollaps
                        key={`deal_${el.id}`}
                        deal={el}
                    />
                ))
                : <NoDataBlock
                    img={tractor}
                    title={`У Вас нет ${activeSwitch ? 'просмотренных' : 'новых'} предложений о сотрудничестве`}
                    buttonTitle={[]}
                    style={{ position: 'relative' }}
                />
            }
            { }
            {user_data_deals.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data_deals: state.userDeals.deals,
        pages: state.userDeals.pages,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyDeals: (page, size, params) => dispatch(fetchMyDeals(page, size, params))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllOffers);