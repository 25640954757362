import closeGrey from 'assets/images/icons/close_grey.svg';

import './style.css';

function MessageGroupCard({ name, new_messages, onClickFolder, onCloseFolder, active }) {

    return (
        <div
            className={`messageGroupCard  ${active ? 'active' : ''}`}
            onClick={onClickFolder}
        >
            <p className='activeText p14_500'>{name}</p>
            {new_messages > 0 && <div className='messageGroupCard_notification p12_400 whiteButtonText'>{new_messages}</div>}
            {active && <div className='messageGroupCard_closeIcon' onClick={onCloseFolder}><img src={closeGrey} alt="closeGrey" /></div>}
        </div>
    );
}

export default MessageGroupCard;