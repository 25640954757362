import {
    FETCH_MARKET_ALL_TECHNICS_START,
    FETCH_MARKET_ALL_TECHNICS_SUCCESS,
} from '../../actionTypes'

const initialState = {
    technics: [],
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MARKET_ALL_TECHNICS_START:
            return {
                ...state, loading: true
            }
        case FETCH_MARKET_ALL_TECHNICS_SUCCESS:
            return {
                technics: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                loading: false
            }
        default: return state
    }
}