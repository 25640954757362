import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dealConflictsTable } from 'data/adminTableHeader';
import { dealConflictsTableWidth } from 'data/adminTableWidth';
import { createFio } from 'hooks/createFio';
import { formatDateTime } from 'hooks/formatDate';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

function Conflicts({contract_by_id_data_conflicts }) {
    const navigate = useNavigate()

    const createTableData = () => {
        return contract_by_id_data_conflicts.map(el => (
            [
                {
                    type: 'text',
                    content: formatDateTime(el.created_at)
                },
                {
                    type: 'active_text',
                    content: el.contract_id
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/users/${el.owner.id}`)}>{createFio(el.owner)}</p>
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/users/${el.executor.id}`)}>{createFio(el.executor)}</p>
                },
                {
                    type: 'text',
                    content: el.reason
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <SimpleButton
                            title={'Инфо'}
                            style={{
                                padding: '7px 16px',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                color: '#01963A',
                                fontWeight: 500,
                                fontSize: '14px',
                                backgroundColor: '#FFFFFF'
                            }}
                            onClick={() => navigate(`/user/my-conflicts/${el.id}`)}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        <Table
            header={dealConflictsTable}
            width={dealConflictsTableWidth}
            data={createTableData()}
        />
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data_conflicts: state.adminContractById.contract_by_id_data.conflicts
    }
}

export default connect(mapStateToProps, null)(Conflicts);