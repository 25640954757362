import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from 'hooks/formatDate';
import { createFio } from 'hooks/createFio';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import commentAltEdit from 'assets/images/icons/comment-alt-edit.svg';

function MainInfo({ conflict_by_id_data, user_data }) {
    const navigate = useNavigate()
    
    return (
        <>
            <InfoBlock
                style={{
                    marginBottom: 24
                }}
            >
                <p className='p12_400 mainText p_mb8'>Дата и время отклонения со второй стороны</p>
                <p className='p14_400 mainText p_mb16'>{formatDateTime(conflict_by_id_data.created_at)}</p>
                <p className='p12_400 mainText p_mb8'>ID сделки</p>
                <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/user/my-contracts/${conflict_by_id_data.contract_id}`)}>{conflict_by_id_data.id}</p>
                <p className='p12_400 mainText p_mb8'>ФИО заказчика</p>
                <p className='p14_400 mainText p_mb16 activeText'>{createFio(conflict_by_id_data.owner)}</p>
                <p className='p12_400 mainText p_mb8'>Причина</p>
                <p className='p14_400 mainText p_mb16'>{conflict_by_id_data.reason}</p>
                <p className='p12_400 mainText p_mb8'>Комментарий</p>
                <p className='p14_400 mainText p_mb16'>{conflict_by_id_data.description}</p>
                <p className='p12_400 mainText p_mb8'>Медиафайлы спора</p>
                <div className='imagesBlock'>
                    {conflict_by_id_data.Images.length === 0
                        ? '-'
                        : conflict_by_id_data.Images.map((el, key) => (
                            <img key={key} src={el.media.link} alt={'image'} />
                        ))
                    }
                </div>
                <p className='p12_400 mainText p_mb8'>ФИО исполнителя</p>
                <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/public/user/${conflict_by_id_data.executor.id}`)}>{createFio(conflict_by_id_data.executor)}</p>
            </InfoBlock>
            <div className='twoButton'>
                <div>
                    <SimpleButton
                        title={'Перейти к контракту'}
                        style={{
                            color: '#01963A',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500
                        }}
                        onClick={() => navigate(`/user/my-contracts/${conflict_by_id_data.contract_id}`)}
                    />
                </div>
                <div className='twoButton_item'>
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={commentAltEdit} alt="commentAltEdit" style={{ marginRight: 8 }} />Перейти в чат</p>}
                        style={{
                            color: '#01963A',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500
                        }}
                        onClick={() => navigate(`/user/messages?reciever_id=${user_data.id === conflict_by_id_data.executor.id ? conflict_by_id_data.owner.id :  conflict_by_id_data.executor.id}`)}
                    />
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        conflict_by_id_data: state.conflictById.conflict_by_id_data,
        user_data: state.user.user_data
    }
}

export default connect(mapStateToProps, null)(MainInfo);