import { connect } from 'react-redux'
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchCreateContract } from 'store/actions/contracts';
import { fetchUploadContractDocs } from 'store/actions/contractById';
import { fetchMakeContract } from 'store/actions/deals';
import { formatDate } from 'hooks/formatDate';
import { successNotification } from 'hooks/notifications';
import CreateContractForm from 'components/components/forms/modal/createContract/CreateContract';

function CreateContract({ data, setVisible, fetchCreateContract, fetchUploadContractDocs, fetchMakeContract }) {

    const reject = (values, docs, range) => {
        const obj = {
            amount: values.amount,
            date_from: formatDate(range[0].startDate),
            date_to: formatDate(range[0].endDate)
        }
        if(data.type === "application") {
            fetchCreateContract(data.item.bid_id, data.item.id, obj)
            .then(async (res) => {
                if (res) {
                    for (let i = 0; i < docs.length; i++) {
                        let doc = new FormData();
                        let filedata = docs[i].originFileObj
                        delete filedata.uid
                        doc.append('file', filedata);
                        doc.append('name', docs[i].name);
                        await fetchUploadContractDocs(res, doc)
                    }
                    successNotification('Контракт создан', 'Контракт успешно создан')
                }
            })
        } else {
            fetchMakeContract(data.item.id, obj)
            .then(async (res) => {
                if (res) {
                    for (let i = 0; i < docs.length; i++) {
                        let doc = new FormData();
                        let filedata = docs[i].originFileObj
                        delete filedata.uid
                        doc.append('file', filedata);
                        doc.append('name', docs[i].name);
                        await fetchUploadContractDocs(res, doc)
                    }
                    successNotification('Контракт создан', 'Контракт успешно создан')
                }
            })
        }
        setVisible()
    }

    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center', width: '50vw' }}>Начать сотрудничество</p>
            <CreateContractForm
                reject={(values, docs, range) => reject(values, docs, range)}
                defData={data.item}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchCreateContract: (bid_id, reply_id, data) => dispatch(fetchCreateContract(bid_id, reply_id, data)),
        fetchUploadContractDocs: (contract_id, data) => dispatch(fetchUploadContractDocs(contract_id, data)),
        fetchMakeContract: (deal_id, data) => dispatch(fetchMakeContract(deal_id, data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateContract);