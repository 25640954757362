import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { fetchDeleteUser } from 'store/actions/admin/user';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { userVerificationTable } from 'data/adminTableHeader';
import { userVerificationTableWidth } from 'data/adminTableWidth';
import { verificationStatusesStyle } from 'data/lists';
import { formatDate } from 'hooks/formatDate';
import { warningNotification } from 'hooks/notifications';
import Table from 'components/ui/table/Table';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import deleteIcon from 'assets/images/icons/delete.svg';

function Verification({ user_by_id_data_verifications, fetchDeleteUser, setVisible, setVisibleDelete }) {
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams()

    const createTableData = () => {
        return user_by_id_data_verifications.map(el => (
            [
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div>
                },
                {
                    type: 'text',
                    content: el.comment ? el.comment : '-'
                }
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={userVerificationTable}
                width={userVerificationTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Проверить'}
                style={{
                    marginTop: 20,
                    background: user_by_id_data_verifications.length > 0 && user_by_id_data_verifications[0].status === 'ON_CONSIDER' ? '#43A047' : '#CCCCCC',
                    color: user_by_id_data_verifications.length > 0 && user_by_id_data_verifications[0].status === 'ON_CONSIDER' ? '#FFFFFF' : '#999999',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => {
                    if (user_by_id_data_verifications.length > 0) {
                        if (user_by_id_data_verifications[0].status === 'ON_CONSIDER') {
                            setVisible({
                                id,
                                verification_id: user_by_id_data_verifications[0].id,
                                new_data: user_by_id_data_verifications[0].new_data
                            })
                        }
                    }
                }
                }
            />
            <SimpleButton
                title={<div className='buttonWithIcon'><img src={deleteIcon} alt="deleteIcon" />Удалить пользователя</div>}
                style={{
                    marginTop: 20,
                    background: '#FFF',
                    border: '1px solid #D32F2F',
                    color: '#D32F2F',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => warningNotification(api, () => {
                    setVisibleDelete({user_id: id})
                    api.destroy()
                }, 'удаление пользователя.')}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data_verifications: state.userById.user_by_id_data.verifications
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisibleDelete: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'deleteProfileOnUser', data }),
        fetchDeleteUser: (user_id) => dispatch(fetchDeleteUser(user_id)),
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'mainVerificationUser', data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Verification);