import { api_contracts } from 'api/contracts'
import {
    FETCH_USER_DATA_CONTRACTS_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchMyContracts(page, size, params) {
    return async dispatch => {
        try {
            const contracts_data = await api_contracts.GetMyContracts(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_CONTRACTS_SUCCESS, {
                data: contracts_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchCreateContract(bid_id, reply_id, data) {
    return async dispatch => {
        try {
            const contracts_data = await api_contracts.PostCreateContract(bid_id, reply_id, data)
            return contracts_data.data.data.id
        } catch (e) {
            return false
        }
    }
}