import { useRef, useState } from 'react'
import { Button, Form, Upload } from 'antd';
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom';
import { fetchChatRoomById, fetchCreateRoom, fetchSendMessage, fetchUserChats } from 'store/actions/chats/chats';
import { createQuery } from 'hooks/createQuery';

import plus from 'assets/images/chat/plus.svg';
import message from 'assets/images/chat/message.svg';

import './style.css'

const props = {
  name: 'file',
  action: '',
  accept: ".png, .jpg, .doc, .docx, .pdf, .word",
  listType: "text",
  multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const AddMessage = ({ mode, fetchSendMessage, fetchCreateRoom, fetchChatRoomById, fetchUserChats, sendMessage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const fileInputRef = useRef();
  const [body, setBody] = useState('')
  const [fields, setFields] = useState([
    {
      name: 'files',
      value: null
    },
  ]);

  const onFinish = ({ files }) => {
    const query = Object.fromEntries([...searchParams]);
    if (mode !== 'createRoom') {
      addMessageFunc(files, query.room_id)
    } else {
      if ((body !== '' && body[0] !== ' ') || files?.fileList.length > 0) {
        fetchCreateRoom(query.reciever_id, { tag: 'BID' })
          .then(async (res) => {
            if (res) {
              await addMessageFunc(files, res)
              await fetchChatRoomById(res)
              await fetchUserChats(
                1,
                50,
                createQuery(Object.fromEntries([...searchParams]).search)
              )
              delete query.reciever_id
              query.room_id = res
              setSearchParams(query)
            }
          })
      }
    }
  };

  const addMessageFunc = async (files, room_id) => {
    let count = 0
    let data = new FormData();
    if (body !== '' && body[0] !== ' ') {
      count++
      if (files) {
        if (files.fileList.length > 0) {
          let filedata = files.fileList[0].originFileObj
          delete filedata.uid
          data.append('file', filedata);
          data.append('filename', files.fileList[0].name);
        }
      }
      data.append('body', body);
    } else {
      if (files) {
        if (files.fileList.length > 0) {
          count++
          let filedata = files.fileList[0].originFileObj
          delete filedata.uid
          data.append('file', filedata);
          data.append('filename', files.fileList[0].name);
        }
      }
    }
    if (count > 0) {
      fetchSendMessage(room_id, data)
        .then(async res => {
          if (res) {
            setBody('')
            await sendMessage({ new_message: res })
            if (files?.fileList.length > 1) {
              for (let i = 1; i < files.fileList.length; i++) {
                let data = new FormData()
                let filedata = files.fileList[i].originFileObj
                delete filedata.uid
                data.append('file', filedata);
                data.append('filename', files.fileList[i].name);
                await fetchSendMessage(room_id, data)
                  .then(res => sendMessage({ new_message: res }))
              }
            }
            form.resetFields();
          }
        })
    }
  }
  return (
    <Form
      layout="vertical"
      requiredMark={true}
      onFinish={onFinish}
      autoComplete="off"
      className="addMessageForm"
      fields={fields}
      form={form}
      onFieldsChange={(_, allFields) => {
        setFields(allFields)
      }}
    >
      <div className='addMessageBlock'>
        <img src={plus} alt='plus' onClick={() => fileInputRef.current.click()} className='addMessageBlock_plusIcon' />
        <TextareaAutosize placeholder='Начните печатать' className='addMessageBlock_input' value={body} onChange={e => setBody(e.target.value)} />
        <Form.Item>
          <button htmlType="submit" className='addMessageBlock_submit'><img src={message} alt='message' className='addMessageBlock_messageIcon' /></button>
        </Form.Item>
      </div>
      <Form.Item
        name="files"
      >
        <Upload
          {...props}
          className='addMessageUpload'
          customRequest={dummyRequest}
        >
          <Button ref={fileInputRef} hidden={true}>Click to Upload</Button>
        </Upload>
      </Form.Item>
    </Form>
  )
}

function mapDispatvhToProps(dispatch) {
  return {
    fetchSendMessage: (room_id, data) => dispatch(fetchSendMessage(room_id, data)),
    fetchCreateRoom: (reciever_id, data) => dispatch(fetchCreateRoom(reciever_id, data)),
    fetchChatRoomById: (room_id) => dispatch(fetchChatRoomById(room_id)),
    fetchUserChats: (page, size, params) => dispatch(fetchUserChats(page, size, params)),
  }
}

export default connect(null, mapDispatvhToProps)(AddMessage)