import { connect } from 'react-redux'

function UniqueByPsmAdmin({ }) {
    return (
        <div style={{ width: 380 }}>
            <p className='mainText p24_600 p_mb24'>Техника уже зарегистрирована</p>
            <p className='mainText p12_400 p_mb24'>Указанная серия и номер ПСМ уже зарегистрирована на нашей платформе и вы не можете ее зарегистрировать повторно. </p>
        </div>
    );
}

export default connect(null, null)(UniqueByPsmAdmin);