import { useRef } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { fetchDeleteSquareDoc, fetchSquareByID, fetchUploadSquareDocs } from 'store/actions/square';
import { handleChangeFiles } from 'hooks/handleChangeFiles';
import { warningNotification } from 'hooks/notifications';
import { formatDate } from 'hooks/formatDate';
import { fieldDocsTable } from 'data/tableHeader';
import { fieldDocsTableWidth } from 'data/tableWidth';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';
import downloading from 'assets/images/icons/downloading.svg';

function Docs({ square_by_id_data_docs, fetchDeleteSquareDoc, fetchSquareByID, fetchUploadSquareDocs }) {
    const fileInputRef = useRef();
    const [api, contextHolder] = notification.useNotification();

    const { id } = useParams()

    const createTableData = () => {
        return square_by_id_data_docs.map(el => (
            [
                {
                    type: 'text',
                    content: 'Документ'
                },
                {
                    type: 'active_text',
                    content: el.name
                },
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                // {
                //     type: 'text',
                //     content: 'В РАБОТЕ'
                // },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <a href={el.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                fetchDeleteSquareDoc(id, el.id)
                                api.destroy()
                            }, 'удаление документа.')}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={fieldDocsTable}
                width={fieldDocsTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Добавить документ'}
                style={{
                    marginTop: 20,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => fileInputRef.current.click()}
            />
            <input
                onChange={(e) => handleChangeFiles(e, async (data) => {
                    await fetchUploadSquareDocs(id, data)
                    await fetchSquareByID(id)
                    fileInputRef.current.value = null
                }
                )}
                multiple={false}
                ref={fileInputRef}
                type='file' accept='.png, .jpg, .doc, .docx, .pdf, .word'
                hidden />
        </>
    );
}

function mapStateToProps(state) {
    return {
        square_by_id_data_docs: state.squareById.square_by_id_data.documents
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDeleteSquareDoc: (id, doc_id) => dispatch(fetchDeleteSquareDoc(id, doc_id)),
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
        fetchUploadSquareDocs: (id, data) => dispatch(fetchUploadSquareDocs(id, data)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Docs);