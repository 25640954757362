import {
    FETCH_CONTRACT_BY_ID_DATA_START,
    FETCH_CONTRACT_BY_ID_DATA_SUCCESS,
    FETCH_CONTRACT_BY_ID_TECHNIC_MOVEMENT_DATA_SUCCESS,
    FETCH_CONTRACT_BY_ID_TECHNIC_STATISCICS_DATA_SUCCESS,
    FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS,
    FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS,
    CLEAR_CONTRACT_BY_ID_DATA_STATISTICS,
    CLEAR_CONTRACT_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    contract_by_id_data: null,
    technic_movement: [],
    technic_statistics: [],
    reportDateStart: null,
    reportDateEnd: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTRACT_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_CONTRACT_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                contract_by_id_data: action.data.contract_by_id_data,
                loading: false
            }
        case FETCH_CONTRACT_BY_ID_TECHNIC_MOVEMENT_DATA_SUCCESS:
            return {
                ...state,
                technic_movement: [action.data.movement, ...state.technic_movement]
            }
        case FETCH_CONTRACT_BY_ID_TECHNIC_STATISCICS_DATA_SUCCESS:
            return {
                ...state,
                technic_statistics: [action.data.statistics, ...state.technic_statistics]
            }
        case FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS:
            return {
                ...state,
                reportDateStart: action.reportDateStart
            }
        case FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS:
            return {
                ...state,
                reportDateEnd: action.reportDateEnd
            }
        case CLEAR_CONTRACT_BY_ID_DATA_STATISTICS:
            return {
                ...state,
                technic_movement: [],
                technic_statistics: [],
            }
        case CLEAR_CONTRACT_BY_ID_DATA:
            return {
                contract_by_id_data: null,
                technic_movement: [],
                technic_statistics: [],
                reportDateStart: null,
                reportDateEnd: null,
                loading: false
            }
        default: return state
    }
}