import './style.css';

function CompanyCard({ title, inn, address, onClick, active }) {
    return (
        <div
            className={`subtext companyCard  ${active ? 'active' : ''}`}
            onClick={onClick}
        >
            <p>{title}</p>
            <p><span>ИНН:</span>{inn}</p>
            <p>{address}</p>
        </div>
    );
}

export default CompanyCard;