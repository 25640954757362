import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { FullscreenControl, GeolocationControl, Map, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import ImageGallery from 'react-image-gallery';
import { MARKET_CLEAR_TECHNIC_BY_ID_DATA, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchTechnicsByID, fetchTechnicsByIdConfidentialData } from 'store/actions/market/technics';
import { fetchLikeTechnics } from 'store/actions/techics';
import { carStatuses, verificationStatusesStyle } from 'data/lists';
import { marketBidsTechnicTable, profileDocsTable } from 'data/tableHeader';
import { marketBidsTechnicTableWidth, profileDocsTableWidth } from 'data/tableWidth';
import { createBusyPeriods } from 'hooks/createBusyPeriods';
import { formatDate } from 'hooks/formatDate';
import { createFio } from '../../../../hooks/createFio';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import TechnicCard from 'components/shared/cards/technicCard/TechnicCard';
import CustomDatePicker from 'components/shared/datePicker/DatePicker';
import Table from 'components/ui/table/Table';

import calendar from 'assets/images/icons/calendar.svg';
import unavailable from 'assets/images/icons/unavailable.svg';
import heart from 'assets/images/icons/heart.svg';
import heartWhite from 'assets/images/icons/heart-white.svg';
import chat from 'assets/images/icons/chat.svg';
import telegram from 'assets/images/icons/telegram.svg';
import pin from 'assets/images/pins/combine.svg';
import downloading from 'assets/images/icons/downloading.svg';

import '../../style.css';
import '../../media.css';

function MarketTechnicById({
    setVisible,
    access_token,
    user_data,
    confidential,
    is_liked,
    technic_by_id_data,
    another_technics,
    bids,
    fetchTechnicsByID,
    MARKET_CLEAR_TECHNIC_BY_ID_DATA,
    fetchLikeTechnics,
    fetchTechnicsByIdConfidentialData
}) {
    const [width, setWidth] = useState(window.innerWidth);
    const { id } = useParams()
    const [activeLike, setActiveLike] = useState(is_liked)
    const [datePicker, setDatePicker] = useState(null)
    const navigate = useNavigate()

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    useEffect(() => {
        setDatePicker(null)
        fetchTechnicsByID(id)
    }, [id])

    useEffect(() => {
        if (technic_by_id_data) {
            setActiveLike(is_liked)
            setDatePicker(<CustomDatePicker
                ranges={createBusyPeriods(technic_by_id_data?.busy_periods)}
                mode="marketDatePicker"
                edit={false}
                monthe={width < 1520 ? 1 : 2}
            />);
        }
    }, [technic_by_id_data, width])

    useEffect(() => {
        return () => {
            MARKET_CLEAR_TECHNIC_BY_ID_DATA()
        }
    }, [])

    const createTableData = () => {
        if (confidential) {
            return confidential.documents.map(el => (
                [
                    {
                        type: 'text',
                        content: 'Документ'
                    },
                    {
                        type: 'active_text',
                        content: el.name
                    },
                    {
                        type: 'text',
                        content: formatDate(el.created_at)
                    },
                    {
                        type: 'actions',
                        content: <div className='tableIconsBlock'>
                            <a href={el.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                        </div>
                    },
                ]
            ))
        } else {
            return [[
                {
                    type: 'text',
                    content: '**********************'
                },
                {
                    type: 'active_text',
                    content: '**********************'
                },
                {
                    type: 'text',
                    content: 'XX.XX.XXXX'
                },
            ]]
        }
    }

    const createBidsTableData = () => {
        return bids.map(el => (
            [
                {
                    type: 'text',
                    content: 'с ' + el.date_from + ' по ' + el.date_to
                },
                {
                    type: 'active_text',
                    content: el.description
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: el.payment_desc
                },
            ]
        ))
    }

    const addFavorites = () => {
        if (access_token) {
            fetchLikeTechnics(id)
            setActiveLike(!activeLike)
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы добавить технику в избранное сначала авторизуйтесь в системе' })
        }
    }

    const onComplainsts = () => {
        if (access_token) {
            setVisible('fileComplaint', {
                accused_id: technic_by_id_data.owner.id
            })
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы пожаловаться на пользователя сначала авторизуйтесь в системе' })
        }
    }

    const onReply = () => {
        if (access_token) {
            navigate(`/market/technics/${id}/create-reply`)
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы предложить сделку сначала авторизуйтесь в системе' })
        }
    }

    const onConfidential = () => {
        if (access_token) {
            if (user_data.status === "VERIFIED") {
                fetchTechnicsByIdConfidentialData(id)
            } else {
                setVisible('noVerification')
            }
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы получить данные сначала авторизуйтесь в системе' })
        }
    }

    const onStartChat = () => {
        if (access_token) {
            navigate(`/user/messages?reciever_id=${technic_by_id_data.owner.id}`)
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы начать чат с пользователем сначала авторизуйтесь в системе' })
        }
    }

    return (
        technic_by_id_data &&
        <div className='marketDetalPage'>
            <div className='marketDetalPage__left'>
                {technic_by_id_data.photo.length > 0 &&
                    <InfoBlock
                        style={{ marginBottom: 16, padding: 20 }}
                    >
                        <ImageGallery
                            items={technic_by_id_data.photo.map(el => ({
                                original: el.media.link,
                                thumbnail: el.media.link,
                            }))}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            showBullets={true}
                        />
                    </InfoBlock>
                }
                <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                    <p className='p24_600 mainText p_mb24'>Информация о технике</p>
                    <InfoBlock
                        style={{ marginBottom: 16, padding: 20 }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Тип работ</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.work_type.name}</p>
                            <p className='p12_400 mainText p_mb8'>Модель</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.model}</p>
                            <p className='p12_400 mainText p_mb8'>Мощность (л.с)</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.power}</p>
                            <p className='p12_400 mainText p_mb8'>Производитель</p>
                            <p className='p14_400 mainText'>{technic_by_id_data.manufacturer}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Собственник</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.is_owner ? 'Да' : 'Нет'}</p>
                            <p className='p12_400 mainText p_mb8'>Тип техники</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.technic_type.name}</p>
                            <p className='p12_400 mainText p_mb8'>Год выпуска</p>
                            <p className='p14_400 mainText'>{technic_by_id_data.release_date}</p>
                        </div>
                    </InfoBlock>
                    <InfoBlock
                        style={{ marginBottom: 16, padding: 20 }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Номер ПСМ</p>
                            <p className='p14_400 mainText p_mb16'>{confidential ? confidential.PSM_number : '**********************'}</p>
                            <p className='p12_400 mainText p_mb8'>Гос. номер</p>
                            <p className='p14_400 mainText p_mb16'>{confidential ? confidential.gos_num : '**********************'}</p>
                            {!confidential &&
                                <SimpleButton
                                    title={'Запросить данные'}
                                    style={{
                                        // width: '100%',
                                        color: '#FFFFFF',
                                        border: '1px solid #01963A',
                                        borderRadius: '100px',
                                        backgroundColor: '#01963A',
                                        fontWeight: 500,
                                    }}
                                    onClick={onConfidential}
                                />
                            }
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Серия ПСМ</p>
                            <p className='p14_400 mainText p_mb16'>{confidential ? confidential.PSM_serial : '**********************'}</p>
                            <p className='p12_400 mainText p_mb8'>Дата регистрации ПСМ</p>
                            <p className='p14_400 mainText'>{confidential ? confidential.PSM_date : '**********************'}</p>
                        </div>
                    </InfoBlock>
                    <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                        <p className='p12_400 mainText p_mb8'>Дополнительное оборудование</p>
                        <p className='p14_400 mainText p_mb16'>{technic_by_id_data.extra_devices || 'Нет'}</p>
                        <p className='p12_400 mainText p_mb8'>Описание техники</p>
                        <p className='p14_400 mainText'>{technic_by_id_data.extra_devices_desc || 'Нет'}</p>
                    </InfoBlock>
                </InfoBlock>
                <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                    <p className='p24_600 mainText p_mb24'>Календарь загруженности техники</p>
                    {datePicker}
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={telegram} alt="unavailable" style={{ marginRight: 8 }} />Предложить сделку</p>}
                        style={{
                            // width: '100%',
                            color: '#FFFFFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            marginTop: 16,
                        }}
                        onClick={onReply}
                    />
                </InfoBlock>
                <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                    <p className='p24_600 mainText p_mb24'>Документы по технике</p>
                    <Table
                        header={profileDocsTable}
                        width={profileDocsTableWidth}
                        data={createTableData()}
                    />
                    {!confidential &&
                        <SimpleButton
                            title={'Запросить данные'}
                            style={{
                                // width: '100%',
                                color: '#FFFFFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500,
                                marginTop: 15
                            }}
                            onClick={onConfidential}
                        />
                    }
                </InfoBlock>
                <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                    <p className='p24_600 mainText p_mb24'>Компания</p>
                    <div className='twoRow'>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Название компании</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.owner.company ? technic_by_id_data.owner.company.name : '-'}</p>
                            <p className='p12_400 mainText p_mb8'>ИНН</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.owner.company ? technic_by_id_data.owner.company.inn : '-'}</p>
                            <p className='p12_400 mainText p_mb8'>ОГРН</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.owner.company ? technic_by_id_data.owner.company.ogrn : '-'}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Телефон для связи</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.owner.company ? technic_by_id_data.owner.company.phone : '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Коммерческая почта</p>
                            <p className='p14_400 mainText p_mb16'>{technic_by_id_data.owner.company ? technic_by_id_data.owner.company.email : '-'}</p>
                        </div>
                    </div>
                </InfoBlock>
                {((technic_by_id_data.location_latitude && technic_by_id_data.location_longitude) || technic_by_id_data.default_address) &&
                    <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                        <p className='p24_600 mainText p_mb24'>Местоположение</p>
                        <div className='locationBlock_map'>
                            <Map
                                defaultState={{
                                    center: (technic_by_id_data.location_latitude && technic_by_id_data.location_longitude) ? [technic_by_id_data.location_latitude, technic_by_id_data.location_longitude] : [technic_by_id_data.default_address.latitude, technic_by_id_data.default_address.longitude],
                                    zoom: 10,
                                    type: 'yandex#hybrid'
                                }}
                                width='100%'
                                height='100%'
                            >
                                <FullscreenControl />
                                <GeolocationControl options={{ float: "left" }} />
                                <TypeSelector options={{ float: 'right' }} />
                                <Placemark
                                    geometry={(technic_by_id_data.location_latitude && technic_by_id_data.location_longitude) ? [technic_by_id_data.location_latitude, technic_by_id_data.location_longitude] : [technic_by_id_data.default_address.latitude, technic_by_id_data.default_address.longitude]}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: pin,
                                        iconImageSize: [70, 70],
                                        // iconImageOffset: [-5, -38]
                                    }}
                                />
                            </Map>
                        </div>
                    </InfoBlock>
                }
                <InfoBlock style={{ padding: 20 }}>
                    <p className='p24_600 mainText p_mb24'>Заявки</p>
                    <Table
                        header={marketBidsTechnicTable}
                        width={marketBidsTechnicTableWidth}
                        data={createBidsTableData()}
                    />
                </InfoBlock>
                <div className='otherInfo2'>
                    <p className='p24_600 mainText p_mb16'>Другая техника</p>
                    {another_technics && another_technics.map(el => {
                        if (el.id !== id) {
                            return <TechnicCard key={`technicCard_${el.id}`} item={el} />
                        }
                    })}
                </div>
            </div>
            <div className='marketDetalPage__right'>
                <InfoBlock style={{ marginBottom: 30, padding: 20 }}>
                    <p className='mainText p24_600 p_mb8'>{createFio(technic_by_id_data.owner)}</p>
                    <div className='flexBlock'>
                        <img src={calendar} alt="calendar" />
                        <p className='mainText p14_400'>На сайте с {formatDate(technic_by_id_data.owner.created_at)}</p>
                    </div>
                    <div className='flexBlock'>
                        <p className='mainText p14_400'>Статус</p>
                        <div
                            style={{
                                padding: '4px 8px',
                                display: 'inline-block',
                                backgroundColor: carStatuses.filter(el => el.status === technic_by_id_data.is_busy)[0].bg,
                                borderRadius: 24,
                                marginLeft: 16
                            }}
                            className='whiteButtonText p12_400'>
                            {carStatuses.filter(el => el.status === technic_by_id_data.is_busy)[0].title}
                        </div>
                    </div>
                    <div className='flexBlock'>
                        <p className='mainText p14_400'>Верификация</p>
                        {technic_by_id_data.owner.status && <div className='statusBlock p12_400' style={{ backgroundColor: verificationStatusesStyle[technic_by_id_data.owner.status].bg }}>{verificationStatusesStyle[technic_by_id_data.owner.status].title}</div>}
                    </div>
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={unavailable} alt="unavailable" style={{ marginRight: 8 }} />Пожаловаться</p>}
                        style={{
                            width: '100%',
                            color: '#D32F2F',
                            border: '1px solid #D32F2F',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={onComplainsts}
                    />
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={activeLike ? heartWhite : heart} alt="unavailable" style={{ marginRight: 8 }} />{activeLike ? 'Удалить из избранного' : 'Добавить в избранное'}</p>}
                        style={{
                            width: '100%',
                            color: activeLike ? '#FFFFFF' : '#01963A',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: activeLike ? '#01963A' : '#FFFFFF',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={addFavorites}
                    />
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={chat} alt="unavailable" style={{ marginRight: 8 }} />Начать чат</p>}
                        style={{
                            width: '100%',
                            color: '#FFFFFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={onStartChat}
                    />
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={telegram} alt="unavailable" style={{ marginRight: 8 }} />Предложить сделку</p>}
                        style={{
                            width: '100%',
                            color: '#FFFFFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={onReply}
                    />
                </InfoBlock>
                <div className='otherInfo'>
                    <p className='p24_600 mainText p_mb16'>Другая техника</p>
                    {another_technics && another_technics.map(el => {
                        if (el.id !== id) {
                            return <TechnicCard key={`technicCard_${el.id}`} item={el} />
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        access_token: state.userEnv.access_token,
        user_data: state.user.user_data,
        technic_by_id_data: state.marketTechnicById.technic_by_id_data,
        another_technics: state.marketTechnicById.another_technics,
        confidential: state.marketTechnicById.confidential,
        is_liked: state.marketTechnicById.is_liked,
        bids: state.marketTechnicById.bids,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchTechnicsByID: (id) => dispatch(fetchTechnicsByID(id)),
        MARKET_CLEAR_TECHNIC_BY_ID_DATA: () => dispatch({ type: MARKET_CLEAR_TECHNIC_BY_ID_DATA }),
        fetchLikeTechnics: (id) => dispatch(fetchLikeTechnics(id)),
        fetchTechnicsByIdConfidentialData: (id) => dispatch(fetchTechnicsByIdConfidentialData(id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MarketTechnicById);