import { api_conflicts } from 'api/admin/conflicts'
import {
    FETCH_ALL_CONFLICTS_START,
    FETCH_ALL_CONFLICTS_SUCCESS,
    ADMIN_FETCH_CONFLICT_BY_ID_DATA_START,
    ADMIN_FETCH_CONFLICT_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllConflicts(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_CONFLICTS_START))
        try {
            const conflicts_data = await api_conflicts.GetAllConflicts(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_CONFLICTS_SUCCESS, {
                data: conflicts_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchConflictById(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_CONFLICT_BY_ID_DATA_START))
        try {
            const conflict_data = await api_conflicts.GetConflictById(id)
            dispatch(fetchSuccess(ADMIN_FETCH_CONFLICT_BY_ID_DATA_SUCCESS, {
                conflict_by_id_data: conflict_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchUploadConflictImage(id, data) {
    return async dispatch => {
        try {
            await api_conflicts.UploadConflictImageById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteConflictImage(id, img_id) {
    return async dispatch => {
        try {
            await api_conflicts.DeleteConflictImageById(id, img_id)
        } catch (e) {
        }
    }
}

export function fetchUploadConflictDocs(id, data) {
    return async dispatch => {
        try {
            await api_conflicts.UploadConflictDocumentById(id, data)
            dispatch(fetchConflictById(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteConflictDocs(id, doc_id) {
    return async dispatch => {
        try {
            await api_conflicts.DeleteConflictDocumentById(id, doc_id)
            dispatch(fetchConflictById(id))
        } catch (e) {
        }
    }
}