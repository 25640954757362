import { api_login } from 'api/login'
import { api_user } from 'api/user'
import {
    FETCH_USER_DATA_START,
    FETCH_USER_DATA_SUCCESS,
    FETCH_REFRESH_TOKEN_SUCCESS,
    EXIT
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'
import { errorNotification, warningNotificationWithoutConfirmation } from 'hooks/notifications';
import { initToken } from 'api/apiEnv';

export function fetchActiveUser(data, activation_code, loginData) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.ActivateUser(data, activation_code)
            return true
        } catch (e) {
            return false
        }
    }
}

export function fetchReactiveUser(data) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.ReactivateUser(data)
            return true
        } catch (e) {
            return false
        }
    }
}

export function fetchDeleteUser(user_id, comment) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.DeleteUser(user_id, comment)
        } catch (e) {
        }
    }
}

export function fetchMyData(activeCodeLink) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            const user_data = await api_user.GetMyData()
            dispatch(fetchSuccess(FETCH_USER_DATA_SUCCESS, {
                user_data: user_data.data.data
            }))
        } catch (e) {
            if (e.response.data.detail === 'Could not validate credentials') {
                try {
                    const new_token = await api_login.RefreshToken({
                        refresh_token: localStorage.getItem('agroproject-refresh-token')
                    })
                    dispatch(fetchSuccess(FETCH_REFRESH_TOKEN_SUCCESS, {
                        access_token: new_token.data.data.access_token
                    }))
                    initToken()
                } catch (e) {
                    if (e.response.data.detail === 'Refresh token invalid') {
                        dispatch(fetchSuccess(EXIT))
                    }
                }
            }
            if (e.response.data.detail === 'Inactive user') {
                dispatch(fetchSuccess(EXIT))
                activeCodeLink()
            }
        }
    }
}

export function fetchUpdateMyData(data) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.UpdateUserMe(data)
            dispatch(fetchMyData())
        } catch (e) {
            if (e.response.data.detail === "Model User has verification with status 'on consider'") {
                errorNotification('Ошибка!', 'Ваш аккаунт еще не верифицирован')
            }
        }
    }
}

export function fetchUpdateMyDataRegistration(data, token) {
    return async dispatch => {
        try {
            const user = await api_user.UpdateUserMeRegistartion(data, token)
            if (user !== 'There is already a user with same email' && user !== 'There is already a user with same phone' && user !== `Users with email (${data.email}) already exists.` && user !== `Users with phone (${data.phone}) already exists.`) {
                return true
            } else {
                return false
            }
        } catch (e) {
            if (e.response.data.detail === "Model User has verification with status 'on consider'") {
                errorNotification('Ошибка!', 'Ваш аккаунт еще не верифицирован')
            }
        }
    }
}


export function fetchUpdateMyPhoto(data) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.UpdateMeImg(data)
        } catch (e) {
        }
    }
}

export function fetchUpdateMyDocsSignUp(data, token) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            const user_data = await api_user.SecondRegisterUploadDocs(data, token)
        } catch (e) {
        }
    }
}

export function fetchUploadDocs(data) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.UploadDocs(data)
            dispatch(fetchMyData())
        } catch (e) {
        }
    }
}

export function fetchDeleteMyDocs(doc_id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.DeleteMyDocs(doc_id)
            dispatch(fetchMyData())
        } catch (e) {
        }
    }
}

export function fetchSensitiveCode(email, phone) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.SensitiveCode(email, phone)
            return true
        } catch (e) {
        }
    }
}

export function fetchSensitive(code, email, phone) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.Sensitive(code, email, phone)
            dispatch(fetchMyData())
            return true
        } catch (e) {
            if (e.response.data.detail === 'ActivationCode id not found.') {
                warningNotificationWithoutConfirmation('Неправильный код подтверждения')
            }
        }
    }
}

export function fetchDeleteMe(comment) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_START))
        try {
            await api_user.DeleteMe(comment)
            dispatch(fetchSuccess(EXIT))
        } catch (e) {
        }
    }
}