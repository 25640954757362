import { connect } from 'react-redux'
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchToggleCanceledApplicationById } from 'store/actions/applicationById';
import { createReplyBySquareTable } from 'data/tableHeader';
import { createReplyBySquareTableWidth } from 'data/tableWidth';
import { warningNotification } from 'hooks/notifications';
import SeasonCollapseItem from 'components/shared/seasonCollapse/SeasonCollapseItem';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import Table from 'components/ui/table/Table';

import './style.css';

function MainInfo({ application_by_id_data, fetchToggleCanceledApplicationById }) {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    const createTableData = () => {
        return application_by_id_data.technics.map(el => (
            [
                {
                    type: 'text',
                    content: el.id
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.gos_num
                },
            ]
        ))
    }
    
    return (
        <>
            {contextHolder}
            <InfoBlock
                className="infoBlock twoRow"
                style={{ marginBottom: 24 }}
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>ID сделки</p>
                    <p className='p14_400 mainText p_mb16 activeText'>{application_by_id_data.id}</p>
                    <p className='p12_400 mainText p_mb8'>Тип работы</p>
                    <p className='p14_400 mainText p_mb16'>{application_by_id_data.bid_type === "TECHNICIAN" ? '-' : (application_by_id_data.work_type ? application_by_id_data.work_type.name : '-')}</p>
                    <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                    <p className='p14_400 mainText p_mb16'>{application_by_id_data.bid_type === "TECHNICIAN" ? '-' : (application_by_id_data.crop_type ?  application_by_id_data.crop_type.name : '-')}</p>
                    <p className='p12_400 mainText p_mb8'>Сумма контракта, ₽</p>
                    <p className='p14_400 mainText'>{application_by_id_data.amount}₽</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Дата начала контракта</p>
                    <p className='p14_400 mainText p_mb16'>{application_by_id_data.date_from}</p>
                    <p className='p12_400 mainText p_mb8'>Дата завершения контракта</p>
                    <p className='p14_400 mainText'>{application_by_id_data.date_to}</p>
                    {/* <p className='p12_400 mainText p_mb8'>Адрес выполнения контракта</p>
                    <p className='p14_400 mainText'>Московская обл., г. Москва</p> */}
                </div>
            </InfoBlock>
            <InfoBlock style={{ marginBottom: 24 }}>
                <p className='p_mb16'>{application_by_id_data.bid_type === "TECHNICIAN" ? 'Техника в заявке' : 'Участки в заявке'}</p>
                {application_by_id_data.bid_type === "TECHNICIAN"
                    ?
                    <Table
                        header={createReplyBySquareTable}
                        width={createReplyBySquareTableWidth}
                        data={createTableData()}
                    />
                    :
                    <div className='scrollContent' style={{ display: 'inline-block' }}>
                        {application_by_id_data.plots.map(el => (
                            <SeasonCollapseItem
                                key={`seasonCollapsItem_${el.id}`}
                                item={el}
                                page={'market'}
                            />
                        ))}
                    </div>
                }
            </InfoBlock>
            {application_by_id_data.is_canceled
                ?
                <SimpleButton
                    title={'Заявка закрыта'}
                    style={{
                        color: '#D32F2F',
                        border: '1px solid #D32F2F',
                        borderRadius: '100px',
                        backgroundColor: '#FFF',
                        fontWeight: 500,
                        cursor: 'auto'
                    }}
                />
                :
                <div className='twoButton'>
                    <div>
                        <SimpleButton
                            title={'Просмотреть на бирже'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={() => navigate(`/market/${application_by_id_data.bid_type === "TECHNICIAN" ? 'technics' : 'fields'}/${application_by_id_data.bid_type === "TECHNICIAN" ? application_by_id_data.technics[0].id : application_by_id_data.square_id}`)}
                        />
                    </div>
                    <div className='twoButton_item'>
                        <SimpleButton
                            title={'Отменить объявление'}
                            style={{
                                color: '#D32F2F',
                                border: '1px solid #D32F2F',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500
                            }}
                            onClick={() => warningNotification(api, async () => {
                                fetchToggleCanceledApplicationById(application_by_id_data.id)
                                api.destroy()
                            }, 'отмену заявки.')}
                        />
                    </div>
                </div>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        application_by_id_data: state.applicationById.application_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchToggleCanceledApplicationById: (id) => dispatch(fetchToggleCanceledApplicationById(id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainInfo);