import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Form, Input, Radio, Select, Upload } from 'antd';
import { fetchUpdateTechnicsByID } from 'store/actions/technicsById';
import { fetchCreateAddress, fetchUpdateAddress } from 'store/actions/address';
import { fetchDeleteTechnicDocs, fetchDeleteTechnicImage, fetchUploadTechnicDocs, fetchUploadTechnicImage } from 'store/actions/techics';
import { yearList } from 'hooks/listOfYear';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { formatDate } from 'hooks/formatDate';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';
import CustomCalendar from 'components/shared/calendar/Calendar';

import uploadIcon from 'assets/images/icons/upload.svg';

const { Option } = Select;

const currentYear = (new Date()).getFullYear();

const exceptThisSymbols = [" "]

const propsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    multiple: true,
};

const propsUploadImages = {
    action: '',
    accept: ".png, .jpg",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function EditTechnic({ 
    form, 
    coords, 
    tehnics_by_id_data, 
    technic_types, work_types, 
    fetchUpdateTechnicsByID, 
    fetchUpdateAddress, 
    fetchCreateAddress, 
    fetchUploadTechnicDocs,
    fetchDeleteTechnicDocs,
    fetchUploadTechnicImage,
    fetchDeleteTechnicImage
}) {
    const { id } = useParams()
    const [PSMDate, setPSMDate] = useState(tehnics_by_id_data.PSM_date ? new Date(tehnics_by_id_data.PSM_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : new Date())
    const [filePhotoList, setFilePhotoList] = useState(JSON.parse(JSON.stringify(tehnics_by_id_data.Images)))
    const [fields, setFields] = useState([
        {
            name: 'is_owner',
            value: tehnics_by_id_data.is_owner,
        },
        {
            name: 'gos_num',
            value: tehnics_by_id_data.gos_num,
        },
        {
            name: 'technic_type_id',
            value: tehnics_by_id_data.technic_type_id
        },
        {
            name: 'manufacturer',
            value: tehnics_by_id_data.manufacturer,
        },
        {
            name: 'model',
            value: tehnics_by_id_data.model
        },
        {
            name: 'release_date',
            value: tehnics_by_id_data.release_date,
        },
        {
            name: 'power',
            value: tehnics_by_id_data.power,
        },
        {
            name: 'PSM_serial',
            value: tehnics_by_id_data.PSM_serial,
        },
        {
            name: 'PSM_number',
            value: tehnics_by_id_data.PSM_number,
        },
        {
            name: 'description',
            value: tehnics_by_id_data.description,
        },
        {
            name: 'extra_devices',
            value: tehnics_by_id_data.extra_devices,
        },
        {
            name: 'extra_devices_desc',
            value: tehnics_by_id_data.extra_devices_desc,
        },
        {
            name: 'work_type_id',
            value: tehnics_by_id_data.work_type_id,
        },
        {
            name: 'period_of_employment',
            value: false,
        },
        {
            name: 'filePhotoList',
            value: JSON.parse(JSON.stringify(tehnics_by_id_data.Images))
        },
        {
            name: 'files',
            value: JSON.parse(JSON.stringify(tehnics_by_id_data.documents))
        }
    ]);
    const [value, setValue] = useState(tehnics_by_id_data.is_owner)
    const [fileList, setFileList] = useState(JSON.parse(JSON.stringify(tehnics_by_id_data.documents)))

    const onFinish = async (values) => {
        values.PSM_date = formatDate(PSMDate)
        let newDocs = []
        let deleteDocs = []
        if (values.files) {
            newDocs = values.files.filter(el => !el.media)
            deleteDocs = values.files.filter(el => el.media)
            deleteDocs = tehnics_by_id_data.documents.filter(({ id: id1 }) => !deleteDocs.some(({ id: id2 }) => id2 === id1))
            delete values.files
        }
        const newImages = values.filePhotoList.filter(el => el.name)
        let deleteImages = values.filePhotoList.filter(el => el.media)
        deleteImages = tehnics_by_id_data.Images.filter(({ media: { id: id1 } }) => !deleteImages.some(({ media: { id: id2 } }) => id2 === id1))
        
        if (coords) {
            const data = {
                latitude: coords[0],
                longitude: coords[1]
            }
            if (tehnics_by_id_data.default_address_id) {
                await fetchUpdateAddress(data, tehnics_by_id_data.default_address.id)
            } else {
                await fetchCreateAddress(data)
                    .then(res => {
                        values.default_address_id = res
                    })
            }
        }
        for (let i = 0; i < newImages.length; i++) {
            let data = new FormData();
            let filedata = newImages[i].originFileObj
            delete filedata.uid
            data.append('image_file', filedata);
            data.append('title', newImages[i].name);
            await fetchUploadTechnicImage(id, data)
        }
        for (let i = 0; i < newDocs.length; i++) {
            let data = new FormData();
            let filedata = newDocs[i].originFileObj
            delete filedata.uid
            data.append('file', filedata);
            data.append('name', newDocs[i].name);
            await fetchUploadTechnicDocs(id, data)
        }
        for (let i = 0; i < deleteDocs.length; i++) {
            await fetchDeleteTechnicDocs(id, deleteDocs[i].id)
        }
        for (let i = 0; i < deleteImages.length; i++) {
            await fetchDeleteTechnicImage(id, deleteImages[i].id)
        }

        await fetchUpdateTechnicsByID(values, tehnics_by_id_data.id)
        setFileList([])
    };

    const normFile = (e, type) => {
        if (type === 'files') {
            setFileList(e.fileList)
        } else {
            setFilePhotoList(e.fileList)
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const fieldsArray = [...fields]
        if (typeof fieldsArray[1].name === 'object') {
            fieldsArray.filter(el => el.name[0] === type)[0].value.splice(index, 1)
        } else {
            fieldsArray.filter(el => el.name === type)[0].value.splice(index, 1)
        }
        if (type === 'files') {
            const files = [...fileList];
            files.splice(index, 1);
            setFileList(files);
        } else {
            const files = [...filePhotoList];
            files.splice(index, 1);
            setFilePhotoList(files);
        }
        setFields(fieldsArray)
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            className="twoColumnForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            form={form}
            onFinish={onFinish}
        >
            <div className='twoColumnForm_column'>
                <Form.Item
                    label={'Собственник'}
                    name="is_owner"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group onChange={(e) => setValue(e.target.value)}>
                        <Radio value={true}>Да</Radio>
                        <Radio value={false}>Нет</Radio>
                    </Radio.Group>
                </Form.Item>
                {!value &&
                    <div style={{ marginTop: 24 }}>
                        <p className='p14_500 p_mb12'>Загрузите набор документов подтверждающий право пользования</p>
                        <div className={`${fileList.length === 0 ? '' : 'squareUploadBlock'}`}>
                            <div className='squareUploadBlock'>
                                {fileList.map((el, index) => (
                                    <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index, 'files')} />
                                ))}
                            </div>
                            <Form.Item
                                name="files"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => normFile(e, 'files')}
                                className={`${fileList.length === 0 ? '' : 'squareUpload'}`}
                            >
                                {fileList.length === 0
                                    ?
                                    <Upload.Dragger
                                        name="files"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsUpload}
                                    >
                                        <div style={{ width: 190, margin: '100px auto' }}>
                                            <p className="subtext upload-text">Перетащите сюда, чтобы загрузить документы подтверждающие право пользования</p>
                                            <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                        </div>
                                    </Upload.Dragger>
                                    :
                                    <Upload.Dragger
                                        name="files"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsUpload}
                                        fileList={fileList}
                                    >
                                        <img src={uploadIcon} alt="uploadIcon" />
                                    </Upload.Dragger>
                                }
                            </Form.Item>
                        </div>
                    </div>
                }
                <Form.Item
                    label={'Гос. номер'}
                    name="gos_num"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите гос. номер' />
                </Form.Item>
                <Form.Item
                    label={'Тип техники'}
                    name="technic_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип">
                        {technic_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Производитель'}
                    name="manufacturer"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите производителя' />
                </Form.Item>
                <Form.Item
                    label={'Модель'}
                    name="model"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите модель' />
                </Form.Item>
                <Form.Item
                    label={'Год выпуска'}
                    name="release_date"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите год">
                        {yearList(currentYear, currentYear - 50, -1).map(el => (
                            <Option value={el} key={`release_date_${el}`}>{el}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Тип работ'}
                    name="work_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип работ">
                        {work_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите фото (необязательно)</p>
                    <div className={`${filePhotoList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {filePhotoList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'filePhotoList')} />
                            ))}
                        </div>
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'filePhotoList')}
                            className={`${filePhotoList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {filePhotoList.length === 0
                                ?
                                <Upload.Dragger
                                    name="filePhotoList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUploadImages}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить фото</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="filePhotoList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUploadImages}
                                    fileList={filePhotoList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </div>
            <div className='twoColumnForm_column'>
                <Form.Item
                    label={'Мощность (л.с)'}
                    name="power"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите мощность (л.с)' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
                <Form.Item
                    label={'Серия ПСМ'}
                    name="PSM_serial"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите серию ПСМ' onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </Form.Item>
                <Form.Item
                    label={'Номер ПСМ'}
                    name="PSM_number"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите номер ПСМ' onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </Form.Item>
                <p>Дата выдачи ПСМ <span className='errorColor'>*</span></p>
                <div className='calendarBlock'>
                    <CustomCalendar date={PSMDate} onChange={(item) => { setPSMDate(item) }} />
                </div>
                <Form.Item
                    label={'Описание техники'}
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input.TextArea placeholder='Введите описание техники' />
                </Form.Item>
                <Form.Item
                    label={'Дополнительное оборудование'}
                    name="extra_devices"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Перечислите через запятую доп. оборудование на технике' />
                </Form.Item>
                <Form.Item
                    label={'Описание доп. оборудования'}
                    name="extra_devices_desc"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите описание дополнительного оборудования' />
                </Form.Item>
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        tehnics_by_id_data: state.tehnicById.tehnics_by_id_data,
        technic_types: state.technicTypes.technic_types,
        work_types: state.workTypes.work_types
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateTechnicsByID: (data, id) => dispatch(fetchUpdateTechnicsByID(data, id)),
        fetchUpdateAddress: (data, address_id) => dispatch(fetchUpdateAddress(data, address_id)),
        fetchCreateAddress: (data) => dispatch(fetchCreateAddress(data)),
        fetchUploadTechnicDocs: (id, data) => dispatch(fetchUploadTechnicDocs(id, data)),
        fetchUploadTechnicImage: (id, data) => dispatch(fetchUploadTechnicImage(id, data)),
        fetchDeleteTechnicImage: (id, img_id) => dispatch(fetchDeleteTechnicImage(id, img_id)),
        fetchDeleteTechnicDocs: (id, doc_id) => dispatch(fetchDeleteTechnicDocs(id, doc_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditTechnic);