import { connect } from 'react-redux'
import { adminComplainstBreadcrumb } from 'data/brebcrumb';
import { useEffect } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchComplaintById, fetchDeleteComplaint } from 'store/actions/admin/complaints';
import { ADMIN_CLEAR_COMPLAINT_BY_ID_DATA } from 'store/actionTypes';
import { warningNotification } from 'hooks/notifications';
import { createFio } from 'hooks/createFio';
import { formatDateTime } from 'hooks/formatDate';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import deleteIcon from 'assets/images/icons/delete.svg';

function ComplainstByIdAdmin({ complaint_by_id_data, fetchComplaintById, fetchDeleteComplaint, ADMIN_CLEAR_COMPLAINT_BY_ID_DATA }) {
    const { id } = useParams()
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    useEffect(() => {
        fetchComplaintById(id)
    }, [])

    useEffect(() => {
        return () => {
            ADMIN_CLEAR_COMPLAINT_BY_ID_DATA()
        }
    }, [])

    return (
        complaint_by_id_data &&
        <div className='allUsersBlock'>
            {contextHolder}
            <Breadcrumb list={adminComplainstBreadcrumb} activeLink={complaint_by_id_data.id} />
            <TextHeading text={`Жалоба ${complaint_by_id_data.id}`} />
            <InfoBlock style={{ marginTop: 24 }}>
                <p className='p12_400 mainText p_mb8'>Дата и время</p>
                <p className='p14_400 mainText p_mb16'>{formatDateTime(complaint_by_id_data.created_at)}</p>
                <p className='p12_400 mainText p_mb8'>ФИО отправителя</p>
                <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/admin/users/${complaint_by_id_data.owner.id}`)}>{createFio(complaint_by_id_data.owner)}</p>
                <p className='p12_400 mainText p_mb8'>ФИО обвиняемого</p>
                <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/admin/users/${complaint_by_id_data.accused.id}`)}>{createFio(complaint_by_id_data.accused)}</p>
                <p className='p12_400 mainText p_mb8'>E-mail пользователя</p>
                <p className='p14_400 mainText p_mb16'>{complaint_by_id_data.owner.email || '-'}</p>
                <p className='p12_400 mainText p_mb8'>Причина жалобы</p>
                <p className='p14_400 mainText p_mb16'>{complaint_by_id_data.reason || '-'}</p>
                <p className='p12_400 mainText p_mb8'>Комментарий</p>
                <p className='p14_400 mainText'>{complaint_by_id_data.comment || '-'}</p>
            </InfoBlock>
            <SimpleButton
                title={<div className='buttonWithIcon'><img src={deleteIcon} alt="deleteIcon" />Удалить запрос</div>}
                style={{
                    marginTop: 20,
                    background: '#FFF',
                    border: '1px solid #D32F2F',
                    color: '#D32F2F',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => warningNotification(api, async () => {
                    await fetchDeleteComplaint(id)
                    navigate('/admin/complaints')
                }, 'удаление жалобы.')}
            />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        complaint_by_id_data: state.adminComplaintById.complaint_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchComplaintById: (id) => dispatch(fetchComplaintById(id)),
        fetchDeleteComplaint: (id) => dispatch(fetchDeleteComplaint(id)),
        ADMIN_CLEAR_COMPLAINT_BY_ID_DATA: () => dispatch({ type: ADMIN_CLEAR_COMPLAINT_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ComplainstByIdAdmin);