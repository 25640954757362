import { useNavigate } from 'react-router-dom';
import { createFio } from 'hooks/createFio';

import technicAvatar from 'assets/images/testImages/technicAvatar.png';

import './style.css';

function TechnicCard({ item }) {
    const navigate = useNavigate()

    return (
        item &&
        <div
            className='technicCard'
            onClick={() => navigate(`/market/technics/${item.id}`)}
        >
            <img src={item.photo.length > 0 ? item.photo[0].media.link : technicAvatar} alt="technicAvatar" />
            <div className='technicCard_description'>
                <p className='p14_600'>{item.manufacturer} <span className='p14_400'>({item.model})</span></p>
                <p className='p14_400'>{createFio(item.owner)}</p>
                <p className='p14_400'>{item.owner.company ? item.owner.company.name : ''}</p>
                <div className='technicCard_description__status' style={{backgroundColor: item.is_busy ? '#D32F2F' : '#43A047'}}>{item.is_busy ? 'Занята' :'Свободна'}</div>
            </div>
        </div>
    );
}

export default TechnicCard;