import { api_technics } from 'api/admin/technics'
import { successNotification } from 'hooks/notifications';
import {
    FETCH_ALL_TECHNICS_START,
    FETCH_ALL_TECHNICS_SUCCESS,
    ADMIN_FETCH_TECHNIC_BY_ID_DATA_START,
    ADMIN_FETCH_TECHNIC_BY_ID_DATA_SUCCESS,
    ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllTechnics(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_TECHNICS_START))
        try {
            const users_data = await api_technics.GetAllTechnics(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_TECHNICS_SUCCESS, {
                data: users_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchTechnicsByID(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_TECHNIC_BY_ID_DATA_START))
        try {
            const tehnic_data = await api_technics.GetTechnicsById(id)
            dispatch(fetchSuccess(ADMIN_FETCH_TECHNIC_BY_ID_DATA_SUCCESS, {
                tehnics_by_id_data: tehnic_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchUpdateTechnicsByID(data, id) {
    return async dispatch => {
        // dispatch(fetchStart(ADMIN_FETCH_TECHNIC_BY_ID_DATA_START))
        try {
            await api_technics.UpdateTechic(data, id)
            dispatch(fetchTechnicsByID(id))
        } catch (e) {
        }
    }
}

export function fetchUploadTechnicImage(id, data) {
    return async dispatch => {
        try {
            await api_technics.UploadTechicImageById(id, data)
        } catch (e) {

        }
    }
}

export function fetchDeleteTechnicImage(id, img_id) {
    return async dispatch => {
        try {
            await api_technics.DeleteTechicImageById(id, img_id)
        } catch (e) {

        }
    }
}

export function fetchUploadTechnicDocs(id, data) {
    return async dispatch => {
        try {
            await api_technics.UploadTechicDocumentById(id, data)
        } catch (e) {

        }
    }
}

export function fetchDeleteTechnicDocs(id, doc_id) {
    return async dispatch => {
        try {
            await api_technics.DeleteTechicDocumentById(id, doc_id)
            dispatch(fetchTechnicsByID(id))
        } catch (e) {

        }
    }
}

export function fetchDeleteTechnic(id) {
    return async dispatch => {
        try {
            await api_technics.DeleteTechnicById(id)
        } catch (e) {

        }
    }
}

export function fetchCreateTechnic(data, user_id) {
    return async dispatch => {
        try {
            const technic = await api_technics.PostCreateTechic(data, user_id)
            successNotification('Техника создана', 'Техника успешно создана!')
            return technic.data.data.id
        } catch (e) {

        }
    }
}

export function fetchGetUniqueByPsm(psm_serial, psm_number) {
    return async dispatch => {
        try {
            const unique = await api_technics.GetUniqueByPsm(psm_serial, psm_number)
            return unique.data.Status
        } catch (e) {

        }
    }
}

export function fetchCurrentLocationTechnicsByID(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_TECHNIC_BY_ID_DATA_START))
        try {
            const current_location = await api_technics.PostCurrentLocationTechic(id)
            dispatch(fetchSuccess(ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS, {
                current_location: current_location.data.data
            }))
        } catch (e) {
            dispatch(fetchSuccess(ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS, {
                current_location: null
            }))
        }
    }
}

export function fetchConfirmTechnicVerificstion(technic_id, verification_id) {
    return async dispatch => {
        try {
            await api_technics.ConfirmTechnicVerificstion(technic_id, verification_id)
            dispatch(fetchTechnicsByID(technic_id))
        } catch (e) {
        }
    }
}

export function fetchRejectTechnicVerificstion(technic_id, verification_id) {
    return async dispatch => {
        try {
            await api_technics.RejectTechnicVerificstion(technic_id, verification_id)
            dispatch(fetchTechnicsByID(technic_id))
        } catch (e) {
        }
    }
}