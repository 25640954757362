import NoAuth from "components/components/modalChildrens/wornings/NoAuth";
import AddPlotToTheSeason from "components/components/modalChildrens/addPlotToTheSeason/AddPlotToTheSeason";
import CleanModal from "components/components/modalChildrens/cleanModal/CleanModal";
import CloseDeal from "components/components/modalChildrens/closeDeal/CloseDeal";
import ConfirmProfileChanges from "components/components/modalChildrens/confirmProfileChanges/ConfirmProfileChanges";
import CreateSeason from "components/components/modalChildrens/createSeason/CreateSeason";
import DeleteProfile from "components/components/modalChildrens/deleteProfile/DeleteProfile";
import FileComplaint from "components/components/modalChildrens/fileComplaint/FileComplaint";
import UniqueByPsm from "components/components/modalChildrens/uniqueByPsm/UniqueByPsm";
import UniqueByPsmAdmin from "components/components/modalChildrens/uniqueByPsm/UniqueByPsmAdmin";
import MainVerificationSquare from "components/components/modalChildrens/verificationSquare/mainVerificationSquare/MainVerificationSquare";
import RejectVerificationSquare from "components/components/modalChildrens/verificationSquare/rejectVerificationSquare/RejectVerificationSquare";
import MainVerificationTechnic from "components/components/modalChildrens/verificationTechnic/mainVerificationTechnic/MainVerificationTechnic";
import RejectVerificationTechnic from "components/components/modalChildrens/verificationTechnic/rejectVerificationTechnic/RejectVerificationTechnic";
import MainVerificationUser from "components/components/modalChildrens/verificationUser/mainVerificationUser/MainVerificationUser";
import RejectVerificationUser from "components/components/modalChildrens/verificationUser/rejectVerificationUser/RejectVerificationUser";
import DetailInfo from "components/components/modalChildrens/detailInfo/DetailInfo";
import NoVerification from "components/components/modalChildrens/wornings/NoVerification";
import BidInfo from "components/components/modalChildrens/bidInfo/BidInfo";
import PlotsInfo from "components/components/modalChildrens/plotsInfo/PlotsInfo";
import CreateConflict from "components/components/modalChildrens/createConflict/CreateConflict";
import CreateContract from "components/components/modalChildrens/createContract/CreateContract";

export const modalChildrens = {
    cleanModal: <CleanModal />,
    deleteProfileOnUser: <DeleteProfile />,
    mainVerificationUser: <MainVerificationUser />,
    rejectVerificationUser: <RejectVerificationUser />,
    mainVerificationTechnic: <MainVerificationTechnic />,
    rejectVerificationTechnic: <RejectVerificationTechnic />,
    mainVerificationSquare: <MainVerificationSquare />,
    rejectVerificationSquare: <RejectVerificationSquare />,
    uniqueByPsm: <UniqueByPsm />,
    uniqueByPsmAdmin: <UniqueByPsmAdmin />,
    confirmProfileChanges: <ConfirmProfileChanges />,
    createSeason: <CreateSeason />,
    addPlotToTheSeason: <AddPlotToTheSeason />,
    closeDeal: <CloseDeal />,
    fileComplaint: <FileComplaint />,
    noAuth: <NoAuth />,
    noVerification: <NoVerification />,
    detailInfo: <DetailInfo />,
    bidInfo: <BidInfo />,
    plotsInfo: <PlotsInfo />,
    createConflict: <CreateConflict />,
    createContract: <CreateContract />
}