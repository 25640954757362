import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { fetchPlotByID } from "store/actions/plotById";
import { CLEAR_PLOTS_BY_ID_DATA } from "store/actionTypes";
import { marketPlotBreadcrumb } from "data/brebcrumb";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";

import pin from 'assets/images/pins/field.svg';

function MarketPlotById({ plotById, fetchPlotByID, CLEAR_PLOTS_BY_ID_DATA }) {
    const [pinCoords, setCoords] = useState(null)
    const { plot_id, square_id } = useParams()

    useEffect(() => {
        fetchPlotByID(plot_id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_PLOTS_BY_ID_DATA()
        }
    }, [])

    useEffect(() => {
        if (plotById) {
            setCoords([plotById.address.latitude, plotById.address.longitude])
        }
    }, [plotById])

    const defaultState = {
        center: pinCoords,
        zoom: 10,
        type: 'yandex#hybrid'
    };

    return (
        plotById &&
        <div className='marketDetalPageWithoutFlex'>
            <Breadcrumb list={marketPlotBreadcrumb(square_id)} activeLink={`Участок`} />
            <div className='createCarBlock_header'>
                <TextHeading text={'Участок'} />
            </div>
            <InfoBlock
                style={{ marginTop: 24 }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Номер</p>
                    <p className='p14_400 mainText p_mb16'>{plotById.number}</p>
                    <p className='p12_400 mainText p_mb8'>Фактический посев, Га</p>
                    <p className='p14_400 mainText'>{plotById.fact_sowing}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Порядковый номер</p>
                    <p className='p14_400 mainText p_mb16'>{plotById.serial}</p>
                    <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                    <p className='p14_400 mainText'>{plotById.crop_type.name}</p>
                </div>
            </InfoBlock>
            <div className='locationBlock_map'>
                <Map
                    defaultState={defaultState}
                    width='100%'
                    height='100%'
                >
                    <FullscreenControl />
                    <SearchControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    <TypeSelector options={{ float: 'right' }} />
                    {pinCoords &&
                        <Placemark
                            geometry={pinCoords}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: pin,
                                iconImageSize: [70, 70],
                                // iconImageOffset: [-5, -38]
                            }}
                        />
                    }
                </Map>
            </div>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        plotById: state.plotById.plots_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchPlotByID: (id) => dispatch(fetchPlotByID(id)),
        CLEAR_PLOTS_BY_ID_DATA: () => dispatch({ type: CLEAR_PLOTS_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MarketPlotById);