import { Link } from "react-router-dom";
import { adminLoginList } from "data/routings";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import EmailLogin from "components/components/forms/authentication/login/emailLogin/EmailLogin";

function LoginAdmin() {
    return (
        <AuthenticationBlock>
            <TextHeading text={'Вход'} style={{ textAlign: 'center' }} />
            <EmailLogin currentRole={['admin', 'manager']}/>
            <div className="autehenticationLinksBlock">
                {adminLoginList.map((item, key) => (
                    <Link to={item.to} className="activeText autehenticationLink" key={`loginLink_${key}`}>{item.title}</Link>
                ))}
            </div>
        </AuthenticationBlock>
    );
}

export default LoginAdmin;