import { useState } from "react";
import { Link } from "react-router-dom";
import { passwordRecoverySwitch } from "data/lists";
import { passwordRecoveryList } from "data/routings";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import GreyDot from "components/ui/greyDot/GreyDot";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Switch from "components/shared/switch/Switch";
import EmailPasswordRecovery from "components/components/forms/authentication/passwordRecovery/emailPasswordRecovery/EmailPasswordRecovery";
import PhonePasswordRecovery from "components/components/forms/authentication/passwordRecovery/phonePasswordRecovery/phonePasswordRecovery";

import '../style.css';

function PasswordRecovery() {
    const [activeSwitch, setActiveSwitch] = useState('email')

    return (
        <AuthenticationBlock>
            <TextHeading text={'Забыли пароль?'} style={{ textAlign: 'center' }} />
            <Switch
                items={passwordRecoverySwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
            />
            {activeSwitch === 'email' && <EmailPasswordRecovery />}
            {activeSwitch === 'phone' && <PhonePasswordRecovery />}
            <div className="autehenticationLinksBlock">
                {passwordRecoveryList.map((item, key) => {
                    if (key === passwordRecoveryList.length - 1) {
                        return <Link to={item.to} className="activeText autehenticationLink" key={`loginLink_${key}`}>{item.title}</Link>
                    } else {
                        return (
                            <>
                                <Link to={item.to} className="activeText autehenticationLink" key={`loginLink_${key}`}>{item.title}</Link>
                                <GreyDot />
                            </>
                        )
                    }
                })}
            </div>
        </AuthenticationBlock>
    );
}

export default PasswordRecovery;