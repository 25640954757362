import AdminLayout from "components/layout/admin/AdminLayout";

import AllUsers from "views/admin/pages/users/allUsers/AllUsers";
import AdminUserProfile from "views/admin/pages/users/userProfile/AdminUserProfile";
import AllTechnicsAdmin from "views/admin/pages/technics/allTechnics/AllTechnicsAdmin";
import TechnicByIdAdmin from "views/admin/pages/technics/technicById/TechnicByIdAdmin";
import CreateTechnicAdmin from "views/admin/pages/technics/createTechnic/CreateTechnicAdmin";
import AllFieldsAdmin from "views/admin/pages/fields/allFields/AllFieldsAdmin";
import FieldByIdAdmin from "views/admin/pages/fields/fieldById/FieldByIdAdmin";
import PlotById from "views/admin/pages/fields/plot/PlotById";
import AllContractsAdmin from "views/admin/pages/contracts/allContracts/AllContractsAdmin";
import ContractByIdAdmin from "views/admin/pages/contracts/contractById/ContractByIdAdmin";
import AllConflictsAdmin from "views/admin/pages/conflicts/allConflicts/AllConflictsAdmin";
import ConflictByIdAdmin from "views/admin/pages/conflicts/conflictById/ConflictByIdAdmin";
import AllDeletionRequestsAdmin from "views/admin/pages/deletionRequests/AllDeletionRequests";
import AllComplainstAdmin from "views/admin/pages/complaints/allComplaints/AllComplainstAdmin";
import ComplainstByIdAdmin from "views/admin/pages/complaints/complainstById/ComplainstByIdAdmin";
import CreateUserAdmin from "views/admin/pages/users/createUser/CreateUserAdmin";

export const adminRoutings = [
    {
        path: '/admin/',
        element: <AdminLayout />,
        navigate: '/admin/users',
        children: [
            {
                path: 'users',
                index: false,
                element: <AllUsers />
            },
            {
                path: 'users/:id',
                index: false,
                element: <AdminUserProfile />
            },
            {
                path: 'users/:user_id/cars/:id',
                index: false,
                element: <TechnicByIdAdmin />
            },
            {
                path: 'users/create',
                index: false,
                element: <CreateUserAdmin />
            },
            {
                path: 'cars',
                index: false,
                element: <AllTechnicsAdmin />
            },
            {
                path: 'cars/:id',
                index: false,
                element: <TechnicByIdAdmin />
            },
            {
                path: 'cars/create',
                index: false,
                element: <CreateTechnicAdmin />
            },
            {
                path: 'fields',
                index: false,
                element: <AllFieldsAdmin />
            },
            {
                path: 'fields/:id',
                index: false,
                element: <FieldByIdAdmin />
            },
            {
                path: 'fields/field/:square_id/:plot_id',
                index: false,
                element: <PlotById mode={'byId'} />
            },
            {
                path: 'fields/field/:square_id/plot/create',
                index: false,
                element: <PlotById mode={'create'} />
            },
            {
                path: 'contracts',
                index: false,
                element: <AllContractsAdmin />
            },
            {
                path: 'contracts/:id',
                index: false,
                element: <ContractByIdAdmin />
            },
            {
                path: 'conflicts',
                index: false,
                element: <AllConflictsAdmin />
            },
            {
                path: 'conflicts/:id',
                index: false,
                element: <ConflictByIdAdmin />
            },
            {
                path: 'deletion-requests',
                index: false,
                element: <AllDeletionRequestsAdmin />
            },
            {
                path: 'complaints',
                index: false,
                element: <AllComplainstAdmin />
            },
            {
                path: 'complaints/:id',
                index: false,
                element: <ComplainstByIdAdmin />
            }
        ]
    }
]

const userPath = [
    {
        path: 'users',
        index: false,
        element: <AllUsers />
    },
    {
        path: 'users/:id',
        index: false,
        element: <AdminUserProfile />
    }
]