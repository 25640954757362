import {
    MARKET_FETCH_TECHNIC_BY_ID_DATA_START,
    MARKET_FETCH_TECHNIC_BY_ID_DATA_SUCCESS,
    MARKET_FETCH_ANOTHER_TECHNICS,
    MARKET_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS,
    MARKET_FETCH_TECHNIC_CONFIDATIAL_DATA_BY_ID_SUCCESS,
    MARKET_FETCH_BIDS_BY_TECHNICS,
    MARKET_CLEAR_TECHNIC_BY_ID_DATA
} from '../../actionTypes'

const initialState = {
    technic_by_id_data: null,
    current_location: null,
    confidential: null,
    another_technics: [],
    is_liked: false,
    bids: []
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case MARKET_FETCH_TECHNIC_BY_ID_DATA_START:
            return {
                ...state,
            }
        case MARKET_FETCH_TECHNIC_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                technic_by_id_data: action.data.tehnics_by_id_data,
                is_liked: action.data.is_liked
            }
        case MARKET_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS: {
            return {
                ...state,
                current_location: action.data.current_location
            }
        }
        case MARKET_FETCH_ANOTHER_TECHNICS:
            return {
                ...state,
                another_technics: action.data.data.items || [],
            }
        case MARKET_FETCH_TECHNIC_CONFIDATIAL_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                confidential: action.data.tehnics_by_id_data,
            }
        case MARKET_FETCH_BIDS_BY_TECHNICS: {
            return {
                ...state,
                bids: action.data.data.items || []
            }
        }
        case MARKET_CLEAR_TECHNIC_BY_ID_DATA: {
            return {
                technic_by_id_data: null,
                current_location: null,
                confidential: null,
                is_liked: false,
                bids: []
            }
        }
        default: return state
    }
}