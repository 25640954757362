import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import SpecifiedData from "components/components/forms/authentication/signup/secondStageSignup/specifiedData/SpecifiedData";

import '../../style.css';

function SpecifiedDataSignup() {
    return (
        <AuthenticationBlock>
            <TextHeading text={'Укажите ваши данные'} style={{ textAlign: 'center' }} />
            <SpecifiedData />
        </AuthenticationBlock>
    );
}

export default SpecifiedDataSignup;