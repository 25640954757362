import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { fetchDeleteTechnic } from 'store/actions/admin/technics';
import { fetchUserByID } from 'store/actions/admin/user';
import { warningNotification } from 'hooks/notifications';
import { verificationStatusesStyle } from 'data/lists';
import { userCarsTable } from 'data/adminTableHeader';
import { userCarsTableWidth } from 'data/adminTableWidth';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';

function Technics({ user_by_id_data_technics, fetchDeleteTechnic, fetchUserByID }) {
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams()
    const navigate = useNavigate()

    const createTableData = () => {
        return user_by_id_data_technics.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/users/${id}/cars/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchDeleteTechnic(el.id)
                                fetchUserByID(id)
                                api.destroy()
                            }, 'удаление техники.')}
                        />
                    </div>
                }
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={userCarsTable}
                width={userCarsTableWidth}
                data={createTableData()}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data_technics: state.userById.user_by_id_data.technics
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDeleteTechnic: (id) => dispatch(fetchDeleteTechnic(id)),
        fetchUserByID: (id) => dispatch(fetchUserByID(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Technics);