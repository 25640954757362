import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { responseSquaresTable, responseTchnicsCarsTable } from 'data/tableHeader';
import { responseSquaresTableWidth, responseTchnicsCarsTableWidth } from 'data/tableWidth';
import { confirmNotification } from 'hooks/notifications';
import { createFio } from 'hooks/createFio';
import { formatDateTime, formatDate } from 'hooks/formatDate';
import SimpleButton from '../buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

import angle_down from 'assets/images/icons/angle-down.svg';
import chat from 'assets/images/icons/chat.svg';
import check from 'assets/images/icons/check.svg';

import './style.css';
import './media.css';

function ResponseCollaps({ item, setVisible, page, application_status }) {
    const [api, contextHolder] = notification.useNotification();
    const [active, setActive] = useState(false)
    const navigate = useNavigate()

    const createTableData = () => {
        if (item.reply_type === 'TECHNICIAN') {
            return item.technics.map(el => (
                [
                    {
                        type: 'active_text',
                        content: <p onClick={() => setVisible('detailInfo', { type: 'technic', data: el })}>{el.id}</p>
                    },
                    {
                        type: 'text',
                        content: el.technic_type.name
                    },
                    {
                        type: 'text',
                        content: el.model
                    },
                    {
                        type: 'text',
                        content: el.gos_num
                    },
                    {
                        type: 'text',
                        content: el.PSM_serial
                    },
                    {
                        type: 'text',
                        content: el.PSM_number
                    },
                ]
            ))
        } else {
            return item.plots.map(el => (
                [
                    {
                        type: 'active_text',
                        content: <p onClick={() => setVisible('detailInfo', { type: 'square', data: el })}>{el.number}</p>
                    },
                    {
                        type: 'text',
                        content: el.crop_type.name
                    },
                    {
                        type: 'text',
                        content: el.fact_sowing
                    },
                ]
            ))
        }
    }

    return (
        <>
            {contextHolder}
            <div className={active ? 'responseCollapseItemBlock active' : 'responseCollapseItemBlock'} >
                <div className='responseCollapseItemBlock_header' onClick={() => setActive(!active)}>
                    <div className='responseCollapseItemBlock_header__info'>
                        <p className='mainText p14_500 p_mr16'>{createFio(item.owner)}</p>
                        <p className='mainText p14_400'>{formatDateTime(item.created_at)}</p>
                    </div>
                    <img src={angle_down} alt='angle_down' />
                </div>
                <div className='responseCollapseItemBlock_data'>
                    <p className='p12_400 mainText p_mb8'>ФИО пользователя</p>
                    <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/public/user/${item.owner.id}`)}>{createFio(item.owner)}</p>
                    <p className='p12_400 mainText p_mb8'>Дата отклика</p>
                    <p className='p14_400 mainText p_mb16'>{formatDate(item.created_at)}</p>
                    <p className='p12_400 mainText p_mb8'>Комментарий</p>
                    <p className='p14_400 mainText p_mb16'>{item.description}</p>
                    <p className='p12_400 mainText p_mb8'>Дата проведения</p>
                    <p className='p14_400 mainText p_mb16'>{'с ' + item.date_from + ' по ' + item.date_to}</p>
                    {item.reply_type === 'FARMER' &&
                        <>
                            <p className='p12_400 mainText p_mb8'>Тип работ</p>
                            <p className='p14_400 mainText p_mb16'>{item.work_type.name}</p>
                        </>
                    }
                    <p className='p12_400 mainText p_mb8'>Предлагаемая цена, ₽</p>
                    <p className='p14_400 mainText p_mb16'>{item.amount}₽</p>
                    <Table
                        header={item.reply_type === 'FARMER' ? responseSquaresTable : responseTchnicsCarsTable}
                        width={item.reply_type === 'FARMER' ? responseSquaresTableWidth : responseTchnicsCarsTableWidth}
                        data={createTableData()}
                    />
                    {page !== 'publicUser' &&
                        <div className='twoButton' style={{ marginTop: 16 }}>
                            {!application_status &&
                                <div>
                                    <SimpleButton
                                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={check} alt="check" style={{ marginRight: 8 }} />Начать сотрудничество</p>}
                                        style={{
                                            color: '#FFF',
                                            border: '1px solid #01963A',
                                            borderRadius: '100px',
                                            backgroundColor: '#01963A',
                                            fontWeight: 500
                                        }}
                                        onClick={() => confirmNotification(api,
                                            () => {
                                                setVisible('createContract', {
                                                    item,
                                                    type: 'application'
                                                })
                                                api.destroy()
                                            },
                                            'начало сотрудничества')}
                                    />
                                </div>
                            }
                            <div className={!application_status ? 'twoButton_item' : ''}>
                                <SimpleButton
                                    title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={chat} alt="chat" style={{ marginRight: 8 }} />Начать чат</p>}
                                    style={{
                                        color: '#FFF',
                                        border: '1px solid #01963A',
                                        borderRadius: '100px',
                                        backgroundColor: '#01963A',
                                        fontWeight: 500
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    }
}

export default connect(null, mapDispatvhToProps)(ResponseCollaps);