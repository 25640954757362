import { api_technic_types } from 'api/technicTypes';
import {
    FETCH_TECHNIC_TYPES_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchTechnicTypes() {
    return async dispatch => {
        try {
            const technic_types = await api_technic_types.GetTechnicTypes()
            dispatch(fetchSuccess(FETCH_TECHNIC_TYPES_SUCCESS, {
                technic_types: technic_types.data.data.items
            }))
        } catch (e) {
        }
    }
}