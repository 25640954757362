import { api_public } from 'api/public/user'
import {
    FETCH_PUBLIC_USER_DATA_START,
    FETCH_PUBLIC_USER_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchPublicUserById(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_PUBLIC_USER_DATA_START))
        try {
            const public_user_by_id = await api_public.GetPublicUserById(id)
            dispatch(fetchSuccess(FETCH_PUBLIC_USER_DATA_SUCCESS, {
                public_user_by_id: public_user_by_id.data.data
            }))
        } catch (e) {
        }
    }
}