import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { applicationBreadcrumb } from 'data/brebcrumb';
import { useLocation, useParams } from 'react-router-dom';
import { fetchApplicationById } from 'store/actions/applicationById';
import { CLEAR_APPLICATION_BY_ID_DATA } from 'store/actionTypes';
import { applicationByIdSwitch } from 'data/lists';
import MainInfo from './mainInfo/MainInfo';
import Responses from './responses/Responses';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import Switch from 'components/shared/switch/Switch';

function ApplicationById({ fetchApplicationById, application_by_id_data, CLEAR_APPLICATION_BY_ID_DATA }) {
    const location = useLocation()
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState(location.state ? (location.state.step || 'mainInfo') : 'mainInfo')

    useEffect(() => {
        fetchApplicationById(id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_APPLICATION_BY_ID_DATA()
        }
    }, [])

    return (
        application_by_id_data &&
        <div className='allUsersBlock'>
            <Breadcrumb list={applicationBreadcrumb} activeLink={application_by_id_data.id} />
            <Switch
                items={applicationByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo />}
            {activeSwitch === 'resnonses' && <Responses />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        application_by_id_data: state.applicationById.application_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchApplicationById: (id) => dispatch(fetchApplicationById(id)),
        CLEAR_APPLICATION_BY_ID_DATA: () => dispatch({ type: CLEAR_APPLICATION_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ApplicationById);