import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { useState } from 'react';
import { SAVE_CREATE_TECHNIC_DATA } from 'store/actionTypes';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

function AdditionalEquipment({ form, nextStep, create_technic, SAVE_CREATE_TECHNIC_DATA }) {
    const [fields, setFields] = useState([
        {
            name: 'extra_devices',
            value: create_technic.extra_devices,
        },
        {
            name: 'extra_devices_desc',
            value: create_technic.extra_devices_desc,
        },
    ]);

    const onFinish = ({extra_devices, extra_devices_desc}) => {
        let obj = {...create_technic}
        obj.extra_devices = extra_devices
        obj.extra_devices_desc = extra_devices_desc
        SAVE_CREATE_TECHNIC_DATA(obj)
        nextStep()
    };

    return (
        <InfoBlock>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label={'Дополнительное оборудование'}
                    name="extra_devices"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Перечислите через запятую доп. оборудование на технике' />
                </Form.Item>
                <Form.Item
                    label={'Описание доп. оборудования'}
                    name="extra_devices_desc"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите описание дополнительного оборудования' />
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AdditionalEquipment);