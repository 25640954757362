export const allUsersTable = [
    {
        title: 'Имя',
    },
    {
        title: 'Статус',
        sort: true,
        sort_param: 'status'
    },
    {
        title: 'Название компании',
    },
    {
        title: 'Электронная почта',
    },
    {
        title: 'Номер телефона',
    },
    {
        title: 'ИНН',
    },
    {
        title: 'Тип',
    },
    {
        title: '',
    },
]

export const allTechnicsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Статус',
        sort: true,
        sort_param: 'status'
    },
    {
        title: 'ФИО хозяина',
    },
    {
        title: 'Компания',
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Тип работ',
    },
    // {
    //     title: 'Занятость',
    //     sort: true
    // },
    {
        title: '',
    },
]

export const allFieldsTable = [
    {
        title: 'ФИО фермера',
    },
    {
        title: 'Статус',
        sort: true,
        sort_param: 'status'
    },
    {
        title: 'Компания',
    },
    // {
    //     title: 'Адрес поля',
    // },
    {
        title: 'Тип поля',
    },
    {
        title: 'Размер поля,  Га',
    },
    {
        title: 'Номер поля',
    },
    {
        title: 'Посев',
    },
    {
        title: '',
    },
]

export const userCarsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Статус',
        // sort: true,
        // sort_param: 'status'
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Тип работ',
    },
    {
        title: 'Занятость',
        // sort: true
    },
    {
        title: '',
    },
]

export const userContractsTable = [
    {
        title: 'ID',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Тип работы',
    },
    {
        title: 'Поле',
    },
    {
        title: 'Техника',
    },
    {
        title: 'Сумма, ₽',
    },
    {
        title: 'Период действия контракта',
    },
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]

export const userFieldsTable = [
    {
        title: 'Номер поля',
    },
    {
        title: 'Статус',
        // sort: true,
        // sort_param: 'status'
    },
    {
        title: 'Адрес поля',
    },
    {
        title: 'Тип поля',
    },
    {
        title: 'Размер поля,  Га',
    },
    {
        title: 'Посев',
    },
    {
        title: 'Культура',
    },
    {
        title: '',
    },
]

export const userVerificationTable = [
    {
        title: 'Дата подачи заявки',
    },
    {
        title: 'Статус',
    },
    {
        title: 'Комментарий',
    },
]

export const userVerificationModalTable = [
    {
        title: 'Название',
    },
    {
        title: 'Файл',
    },
    {
        title: 'Дата загрузки',
    },
    {
        title: 'Пользователь',
    },
]

export const allDealsTable = [
    {
        title: 'ID',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Тип работы',
    },
    {
        title: 'Поле',
    },
    {
        title: 'Техника',
    },
    {
        title: 'Сумма, ₽',
    },
    {
        title: 'Период действия контракта',
    },
    {
        title: 'Статус',
    },
]

export const dealTechnicsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Статус',
        // sort: true,
        // sort_param: 'status'
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Тип работ',
    },
    {
        title: 'Занятость',
        // sort: true
    },
]

export const dealSquaresTable = [
    {
        title: 'ФИО фермера',
    },
    {
        title: 'Статус',
        sort: true,
        sort_param: 'status'
    },
    {
        title: 'Компания',
    },
    {
        title: 'Адрес поля',
    },
    {
        title: 'Тип поля',
    },
    {
        title: 'Размер поля,  Га',
    },
    {
        title: 'Номер поля',
    },
    {
        title: 'Посев',
    },
]

export const dealConflictsTable = [
    {
        title: 'Дата и время',
    },
    {
        title: 'ID сделки',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Причина',
    },
    {
        title: '',
    },
]

export const dealReviewsTable = [
    {
        title: 'ФИО пользователя',
    },
    {
        title: 'Текст отзыва',
    },
    {
        title: 'Оценка',
    },
]

export const allConflictsTable = [
    {
        title: 'Дата и время',
    },
    {
        title: 'ID сделки',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Причина',
    },
    {
        title: '',
    },
]

export const allDeletionRequestsTable = [
    {
        title: 'ФИО',
    },
    {
        title: 'Компания',
    },
    {
        title: 'Комментарий',
    }
]

export const allComplainstTable = [
    {
        title: 'Дата и время',
    },
    {
        title: 'ФИО отправителя',
    },
    {
        title: 'ФИО обвиняемого',
    },
    {
        title: 'E-mail пользователя',
    },
    {
        title: 'Причина',
    },
    {
        title: 'Комментарий',
    },
    {
        title: '',
    },
]

export const contractCarsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Статус',
        // sort: true,
        // sort_param: 'status'
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Тип работ',
    },
    {
        title: 'Занятость',
        // sort: true
    }
]

export const contractFieldsTable = [
    {
        title: 'ID',
    },
    {
        title: 'ФИО владельца',
    },
    {
        title: 'Статус',
    },
    {
        title: 'Компания',
    },
    {
        title: 'Тип поля',
    },
    {
        title: 'Размер поля,  Га',
    },
    {
        title: 'Номер поля',
    },
    {
        title: 'Посев',
    },
    {
        title: 'Участки',
    }
]

export const contractConflictsTable = [
    {
        title: 'Дата и время',
    },
    {
        title: 'ID сделки',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Причина',
    },
    {
        title: '',
    },
]

export const contractReviewsTable = [
    {
        title: 'ФИО пользователя',
    },
    {
        title: 'Статус выполнения',
    },
    {
        title: 'Комментарий',
    },
    {
        title: '',
    },
]

export const publicUserReviewsTable = [
    {
        title: 'ФИО пользователя',
    },
    {
        title: 'Текст отзыва',
    },
    {
        title: 'Оценка',
    },
]
