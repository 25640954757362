import { useEffect } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import CreateReplyBySquare from 'components/components/forms/market/createReplyBySquare/CreateReplyBySquare';

import '../../style.css'

function SquareByIdCreateReply({ access_token }) {
    const [form] = Form.useForm();
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    
    useEffect(() => {
        if (!access_token) {
            navigate(`/market/fields/${id}`)
        }
    }, [])

    return (
        <div className='createReply'>
            <div className='createReply_header'>
                <TextHeading text={'Предложить сделку'} />
                <div className='createReply_header__buttons'>
                    <SimpleButton
                        title={'Отмена'}
                        className="whiteButton"
                        onClick={() => navigate(location.state ? location.state.back : `/market/fields/${id}`)}
                    />
                    <SimpleButton
                        title={'Отправить'}
                        className="greenButton"
                        onClick={() => form.submit()}
                    />
                </div>
            </div>
            <InfoBlock >
                <CreateReplyBySquare form={form} />
            </InfoBlock>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        access_token: state.userEnv.access_token,
    }
}

export default connect(mapStateToProps, null)(SquareByIdCreateReply);