import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Upload, Radio } from 'antd';
import { SAVE_CREATE_TECHNIC_DATA, SAVE_CREATE_TECHNIC_MAIN_FILES } from 'store/actionTypes';
import { warningNotificationWithoutConfirmation } from 'hooks/notifications';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';

import uploadIcon from 'assets/images/icons/upload.svg';

import './style.css';

const propsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function MainInfo({ form, nextStep, main_info_files, main_info_filesPSM, create_technic, SAVE_CREATE_TECHNIC_DATA, SAVE_CREATE_TECHNIC_MAIN_FILES }) {
    const [value, setValue] = useState(create_technic.is_owner)
    const [fileList, setFileList] = useState(main_info_files)
    const [filePSMList, setFilePSMList] = useState(main_info_filesPSM)
    const [fields, setFields] = useState([
        {
            name: 'is_owner',
            value: create_technic.is_owner,
        },
        {
            name: 'files',
            value: main_info_files
        },
        {
            name: 'filesPSM',
            value: main_info_filesPSM
        }
    ]);

    const onFinish = ({ is_owner, files, filesPSM }) => {
        let obj = { ...create_technic }
        obj.is_owner = is_owner
        if (is_owner) {
            nextStep()
            SAVE_CREATE_TECHNIC_MAIN_FILES({
                main_info_files: [],
                main_info_filesPSM: filesPSM
            })
        } else {
            if (files.length === 0) {
                warningNotificationWithoutConfirmation(
                    <p>
                        <p>Загрузите документ,</p>
                        <p>подтверждающий право на</p>
                        <p>использование техники.</p>
                    </p>
                )
            } else {
                nextStep()
                SAVE_CREATE_TECHNIC_MAIN_FILES({
                    main_info_files: files,
                    main_info_filesPSM: filesPSM
                })
            }
        }
        SAVE_CREATE_TECHNIC_DATA(obj)
    };

    const normFile = (e, type) => {
        if (type === 'files') {
            setFileList(e.fileList)
        } else {
            setFilePSMList(e.fileList)
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const fieldsArray = [...fields]
        if (typeof fieldsArray[1].name === 'object') {
            fieldsArray.filter(el => el.name[0] === type)[0].value.splice(index, 1)
        } else {
            fieldsArray.filter(el => el.name === type)[0].value.splice(index, 1)
        }
        if (type === 'files') {
            const files = [...fileList];
            files.splice(index, 1);
            setFileList(files);
        } else {
            const files = [...filePSMList];
            files.splice(index, 1);
            setFilePSMList(files);
        }
        setFields(fieldsArray)
    }

    return (
        <Form
            layout="vertical"
            requiredMark={true}
            initialValues={{
                'is_owner': true,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
        >
            <InfoBlock>
                <Form.Item
                    label={'Собственник'}
                    name="is_owner"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group onChange={(e) => setValue(e.target.value)}>
                        <Radio value={true}>Да</Radio>
                        <Radio value={false}>Нет</Radio>
                    </Radio.Group>
                </Form.Item>
            </InfoBlock>
            {!value &&
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите набор документов подтверждающий право пользования<span className='errorColor'>*</span></p>
                    <div className={`${fileList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {fileList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index, 'files')} />
                            ))}
                        </div>
                        <Form.Item
                            name="files"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => normFile(e, 'files')}
                            className={`${fileList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {fileList.length === 0
                                ?
                                <Upload.Dragger
                                    name="files"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUpload}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить документы подтверждающие право пользования</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="files"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUpload}
                                    fileList={fileList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            }
            <div style={{ marginTop: 24 }}>
                <p className='p14_500 p_mb12'>Загрузите набор документов (документ ПСМ, свидетельство ПСМ)</p>
                <div className={`${filePSMList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {filePSMList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index, 'filesPSM')} />
                        ))}
                    </div>
                    <Form.Item
                        name="filesPSM"
                        valuePropName="filePSMList"
                        getValueFromEvent={(e) => normFile(e, 'filesPSM')}
                        className={`${filePSMList.length === 0 ? '' : 'squareUpload'}`}
                    >
                        {filePSMList.length === 0
                            ?
                            <Upload.Dragger
                                name="filesPSM"
                                action="/upload.do"
                                itemRender={null}
                                customRequest={dummyRequest}
                                {...propsUpload}
                            >
                                <div style={{ width: 190, margin: '100px auto' }}>
                                    <p className="subtext upload-text">Перетащите сюда, чтобы загрузить документ ПСМ и свидетельство ПСМ</p>
                                    <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                </div>
                            </Upload.Dragger>
                            :
                            <Upload.Dragger
                                name="filesPSM"
                                action="/upload.do"
                                itemRender={null}
                                customRequest={dummyRequest}
                                {...propsUpload}
                                fileList={filePSMList}
                            >
                                <img src={uploadIcon} alt="uploadIcon" />
                            </Upload.Dragger>
                        }
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
        main_info_files: state.createTechic.main_info_files,
        main_info_filesPSM: state.createTechic.main_info_filesPSM,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
        SAVE_CREATE_TECHNIC_MAIN_FILES: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_MAIN_FILES, data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainInfo);