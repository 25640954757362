import { api_work_types } from 'api/workTypes'
import {
    FETCH_WORK_TYPES_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchWorkTypes() {
    return async dispatch => {
        try {
            const work_types = await api_work_types.GetWorkTypes()
            dispatch(fetchSuccess(FETCH_WORK_TYPES_SUCCESS, {
                work_types: work_types.data.data.items
            }))
        } catch (e) {
        }
    }
}