import { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchActiveUser, fetchReactiveUser } from 'store/actions/user';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { errorNotification, successNotification } from 'hooks/notifications';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function EnterCodeForm({ buttonTtile, type, activeMode, fetchActiveUser, fetchReactiveUser, onViewTimer }) {
    const [errorStatus, setErrorStatus] = useState({})
    const [step, setStep] = useState('first')
    const navigate = useNavigate()

    const onFinish = (values) => {
        if (type === 'recovery') {
            navigate('/enter-new-password')
        } else if (type === 'sigup') {
            let obj = {}
            if (values.phone === undefined) {
                obj.email = values.email
            } else {
                obj.phone = '7' + values.phone
            }
            if (step === 'first') {
                fetchReactiveUser(obj)
                    .then(res => {
                        if (res) {
                            setStep('second')
                            onViewTimer(obj)
                        } else {
                            errorNotification('Такого пользователя нет')
                        }
                    })
            } else {
                fetchActiveUser(obj, values.code)
                    .then(res => {
                        if (!res) {
                            setErrorStatus({
                                validateStatus: "error",
                                help: "Неверный код"
                            })
                        } else {
                            successNotification('Аккаунт активирован', 'Аккаунт успешно активирован! Пройдите повторную авторизацию')
                            navigate('/')
                        }
                    })
            }
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            onFieldsChange={() => {
                setErrorStatus({})
            }}
        >
            {step === 'first'
                ?
                <>
                    <Form.Item
                        label={activeMode === 'email' ? 'Ваша почта' : 'Ваш номер телефона'}
                        name={activeMode === 'email' ? 'email' : 'phone'}
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                        {...errorStatus}
                    >
                        {activeMode === 'email'
                            ? <Input placeholder='Введите почту' />
                            : <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" onKeyDown={numberInputFormat} />
                        }
                    </Form.Item>
                    <Form.Item>
                        <FormButton
                            title={type === 'recovery' ? buttonTtile : (step === 'first' ? 'Отправить код подтверждения' : 'Подтвердить')}
                            style={{ width: '100%' }}
                            htmlType="submit"
                        />
                    </Form.Item>
                </>
                :
                <>
                    <Form.Item
                        label={activeMode === 'email' ? 'Ваша почта' : 'Ваш номер телефона'}
                        name={activeMode === 'email' ? 'email' : 'phone'}
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        {activeMode === 'email'
                            ? <Input placeholder='Введите почту' disabled={true} />
                            : <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" onKeyDown={numberInputFormat} disabled={true} />
                        }
                    </Form.Item>
                    <Form.Item
                        label={'Код'}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                        {...errorStatus}
                    >
                        <Input placeholder='Введите код' type="number" onKeyDown={numberInputFormat} />
                    </Form.Item>
                    <Form.Item>
                        <FormButton
                            title={type === 'recovery' ? buttonTtile : (step === 'first' ? 'Отправить код подтверждения' : 'Подтвердить')}
                            style={{ width: '100%' }}
                            htmlType="submit"
                        />
                    </Form.Item>
                </>
            }
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchActiveUser: (data, activation_code, loginData) => dispatch(fetchActiveUser(data, activation_code, loginData)),
        fetchReactiveUser: (data) => dispatch(fetchReactiveUser(data))
    }
}

export default connect(null, mapDispatvhToProps)(EnterCodeForm);