import { connect } from 'react-redux'
import { CLOSE_MODAL } from 'store/actionTypes';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function UniqueByPsm({ setVisible }) {
    return (
        <div style={{ width: 380 }}>
            <p className='mainText p24_600 p_mb24'>Техника уже зарегистрирована</p>
            <p className='mainText p12_400 p_mb24'>Указанная серия и номер ПСМ уже зарегистрирована на нашей платформе и вы не можете ее зарегистрировать повторно. </p>
            <p className='mainText p12_400 p_mb24'>Если вы являетесь собственником техники и кто-то зарегистрировал вашу технику без согласия, то напишите нашему администратору и мы рассмотрим вопрос в индивидуальном порядке.</p>
            <SimpleButton
                title={'Написать в тех. поддержку'}
                style={{
                    color: '#FFF',
                    border: '1px solid #01963A',
                    borderRadius: '100px',
                    backgroundColor: '#01963A',
                    fontWeight: 500,
                    width: '100%',
                }}
            />
            <SimpleButton
                title={'Отменить'}
                style={{
                    color: '#01963A',
                    backgroundColor: '#FFF',
                    fontWeight: 500,
                    width: '100%',
                    marginTop: '16px'
                }}
                onClick={() => setVisible()}
            />
        </div>
    );
}


function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(null, mapDispatvhToProps)(UniqueByPsm);