import { useState } from "react";
import { Link } from "react-router-dom";
import { loginSwitch } from "data/lists";
import { loginList } from "data/routings";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import GreyDot from "components/ui/greyDot/GreyDot";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Switch from "components/shared/switch/Switch";
import EmailLogin from "components/components/forms/authentication/login/emailLogin/EmailLogin";
import InnLogin from "components/components/forms/authentication/login/innLogin/InnLogin";
import PhoneLogin from "components/components/forms/authentication/login/phoneLogin/PhoneLogin";

import '../style.css';

function Login() {
    const [activeSwitch, setActiveSwitch] = useState('email')

    return (
        <AuthenticationBlock>
            <TextHeading text={'Вход'} style={{ textAlign: 'center' }} />
            <Switch
                items={loginSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
            />
            {activeSwitch === 'email' && <EmailLogin currentRole={['user', 'manager']} />}
            {activeSwitch === 'phone' && <PhoneLogin currentRole={['user', 'manager']} />}
            {activeSwitch === 'inn' && <InnLogin currentRole={['user', 'manager']} />}
            <div className="autehenticationLinksBlock">
                {loginList.map((item, key) => {
                    if (key === loginList.length - 1) {
                        return <Link to={item.to} className="activeText autehenticationLink" key={`loginLink_${key}`}>{item.title}</Link>
                    } else {
                        return (
                            <>
                                <Link to={item.to} className="activeText autehenticationLink" key={`loginLink_${key}`}>{item.title}</Link>
                                <GreyDot />
                            </>
                        )
                    }
                })}
            </div>
        </AuthenticationBlock>
    );
}

export default Login;