import { api_sowing_types } from 'api/sowingTypes';
import {
    FETCH_SOWING_TYPES_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchSowingTypes() {
    return async dispatch => {
        try {
            const sowing_types = await api_sowing_types.GetSowingTypes()
            dispatch(fetchSuccess(FETCH_SOWING_TYPES_SUCCESS, {
                sowing_types: sowing_types.data.data.items
            }))
        } catch (e) {
        }
    }
}