import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import PlotCard from 'components/shared/cards/plotCard/PlotCard';

import pin from 'assets/images/pins/field.svg'
import whitePin from 'assets/images/pins/whiteField.svg'
import field from 'assets/images/messageIcons/field.svg';

import './style.css';
import './media.css';

function Plots({ plots, coords }) {
    const navigate = useNavigate()
    const [map, setMap] = useState(null)
    const [activePin, setActivePin] = useState(null)

    useEffect(() => {
        setMap(
            <Map
                defaultState={defaultState}
                width='100%'
                height='100%'
            >
                <FullscreenControl />
                <SearchControl options={{ float: "right" }} />
                <GeolocationControl options={{ float: "left" }} />
                <TypeSelector options={{ float: 'right' }} />
                {plots.map(el => (
                    <Placemark
                        key={`pin_${el.index}`}
                        geometry={el.address}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: (el.index === activePin ? whitePin : pin),
                            iconImageSize: [70, 70],
                            // iconImageOffset: [-5, -38]
                        }}
                        onClick={() => setActivePin(el.index)}
                    />
                ))}
            </Map>
        )
    }, [plots, activePin])

    const defaultState = {
        center: coords || [55.751574, 37.573856],
        zoom: 10,
        type: 'yandex#hybrid'
    };

    return (
        <div className='regionBlock'>
            <div className='regionBlock_list'>
                <SimpleButton
                    title={'Добавить участок'}
                    style={{
                        background: '#01963A',
                        borderRadius: '100px',
                        padding: '11px 16px',
                        width: '100%'
                    }}
                    onClick={() => navigate(`/user/my-fields/create-field/region/create`, { state: { step: 2 } })}
                />
                {plots.length > 0
                    ? plots.map((el, key) => (
                        <PlotCard
                            key={`plotItem${key}`}
                            style={{ marginTop: 8 }}
                            item={el}
                            active={el.index === activePin ? true : false}
                            onActive={() => setActivePin(el.index)}
                        />
                    ))
                    :
                    <div className='regionBlock_list__noData'>
                        <img src={field} alt='field' />
                        <p className='mainText p24_600 p_mb24'>Нет участков</p>
                        <p className='mainText p14_400 p_mb24'>Добавьте новые участки, чтобы они отобразились на поле</p>
                    </div>
                }
            </div>
            <div className='regionBlock_map'>
                <div className='locationBlock_map'>
                    {map}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        plots: state.createSquare.plots,
        coords: state.createSquare.coords,
    }
}

export default connect(mapStateToProps, null)(Plots);