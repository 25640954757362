import {
    FETCH_WORK_TYPES_SUCCESS
} from '../actionTypes'

const initialState = {
    work_types: [],
    loading: true
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_WORK_TYPES_SUCCESS:
            return {
                ...state,
                work_types: action.data.work_types,
                loading: false                
            }
        default: return state
    }
}