import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { Form, Input, Select } from 'antd';
import { fetchCreateUser } from 'store/actions/admin/user';
import { rolesNames } from 'data/adminLists';
import { checkPassword } from 'hooks/checkPassword';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { checkFioLength } from 'hooks/checkFioLength';
import FormButton from 'components/shared/buttons/formButton/FormButton'

const { Option } = Select;

function CreateUser({ roles, fetchCreateUser }) {
    const navigate = useNavigate()
    const [errorStatusFirst, setErrorStatusFirst] = useState({})
    const [errorStatusSecond, setErrorStatusSecond] = useState({})
    const [fields, setFields] = useState([
        {
            name: 'email',
            value: null
        },
        {
            name: 'phone',
            value: true,
        },
        {
            name: 'fio',
            value: null,
        },
        {
            name: 'password',
            value: null
        },
        {
            name: 'role_id',
            value: null,
        },
    ]);

    const onFinish = async ({ role_id, fio, email, phone, password, replay_password }) => {
        if (checkPassword(password)) {
            if (replay_password === password) {
                const fioArray = checkFioLength(fio)
                if (fioArray.length === 3) {
                    const obj = {
                        email,
                        phone: '7' + phone,
                        first_name: fioArray[0],
                        last_name: fioArray[1],
                        third_name: fioArray[2],
                        password,
                        role_id
                    }
                    fetchCreateUser(obj)
                        .then((res) => { if (res) navigate(`/admin/users`) })
                }
            } else {
                setErrorStatusFirst({
                    validateStatus: "error"
                })
                setErrorStatusSecond({
                    validateStatus: "error",
                    help: "Пароли не совпадают"
                })
            }
        } else {
            setErrorStatusFirst({
                validateStatus: "error",
                help: "Некорректный пароль. Пароль должен состоять минимум из 8 символов и содержать буквы верхнего и нижнего регистра, цифры и специальные символы."
            })
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
                setErrorStatusFirst({})
                setErrorStatusSecond({})
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label={'Роль пользователя'}
                name="role_id"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Select placeholder="Выберите роль пользователя">
                    {roles.map(el => (
                        <Option value={el.id} key={el.id}>{rolesNames[el.name]}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label={'ФИО'}
                name="fio"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите ФИО' />
            </Form.Item>
            <Form.Item
                label={'Email'}
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите почту' />
            </Form.Item>
            <Form.Item
                label={'Номер телефона'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" onKeyDown={numberInputFormat} />
            </Form.Item>
            <Form.Item
                label={'Пароль'}
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusFirst}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item
                label={'Повторите пароль'}
                name="replay_password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusSecond}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Сохранить'}
                    style={{ float: 'right' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        roles: state.roles.roles
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateUser: (data) => dispatch(fetchCreateUser(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateUser);