import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Upload } from 'antd';
import { fetchUpdateMyData, fetchUpdateMyPhoto } from 'store/actions/user';
import { checkFioLength } from 'hooks/checkFioLength';

import pen_white from 'assets/images/icons/pen_white.svg';

const propsUpload = {
    action: '',
    accept: ".png, .jpg",
    listType: "picture",
    maxCount: 1,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }

    return e?.fileList;
};

function EditMainInfo({ onEdit, user_data, form, fetchUpdateMyData, fetchUpdateMyPhoto }) {
    const [fileList, setFileList] = useState(null)
    const [fields, setFields] = useState([
        {
            name: 'fio',
            value: user_data.first_name + ' ' + user_data.last_name + ' ' + user_data.third_name,
        }
    ]);

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = async ({ photo, fio }) => {
        if (fields.length === 2) {
            const fioArray = checkFioLength(fio)
            if (fioArray.length > 2) {
                const data = {
                    first_name: fioArray[0],
                    last_name: fioArray[1],
                    third_name: fioArray[2],
                }
                if (photo !== undefined) {
                    let data = new FormData();
                    let filedata = photo[0].originFileObj
                    delete filedata.uid
                    data.append('image_file', filedata);
                    await fetchUpdateMyPhoto(data)
                }
                fetchUpdateMyData(data)
                onEdit()
            }
        } else {
            onEdit()
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            form={form}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
        >
            <Form.Item
                name="photo"
                getValueFromEvent={normFile}
            >
                <Upload
                    {...propsUpload}
                    fileList={fileList}
                    onChange={handleChange}
                    customRequest={dummyRequest}
                    className="editUpload"
                    itemRender={null}
                >
                    <div className='profileUpload'>
                        <img src={pen_white} alt="pen_white" />
                    </div>
                </Upload>
            </Form.Item>
            <Form.Item
                label={'ФИО'}
                name="fio"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите ФИО' />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateMyData: (data) => dispatch(fetchUpdateMyData(data)),
        fetchUpdateMyPhoto: (data) => dispatch(fetchUpdateMyPhoto(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditMainInfo);