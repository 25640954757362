import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux'
import { fetchNotificationById } from 'store/actions/notifications';
import { CLEAR_NOTIFICATION_BY_ID_DATA } from 'store/actionTypes';
import { notificationBreadcrumb } from "data/brebcrumb";
import { formatDateTime } from 'hooks/formatDate';
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import InfoBlock from "components/ui/infoBlock/InfoBlock";

function NotificationById({ notification_by_id_data, fetchNotificationById, CLEAR_NOTIFICATION_BY_ID_DATA }) {
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        fetchNotificationById(id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_NOTIFICATION_BY_ID_DATA()
        }
    }, [])

    const searchLink = (str) => {
        const array = str.split(' ')
        let link = ''
        let newStr = []
        array.forEach(el => {
            if (el.search("http://frontend.oxu-development.ru") !== -1 || el.search("https://asm-harvest.ru") !== -1) {
                el.split('/').forEach((el, key) => {
                    if (key > 2) {
                        link += '/' + el
                    }
                })
                newStr.push(
                    <span
                        className='activeText'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(link)}
                    >
                        {el}
                    </span>
                )
            } else {
                newStr.push(el + ' ')
            }
        })
        return newStr
    }

    return (
        notification_by_id_data &&
        <div className='allUsersBlock'>
            <Breadcrumb list={notificationBreadcrumb} activeLink={`${notification_by_id_data.title} (${formatDateTime(notification_by_id_data.created_at)})`} />
            <TextHeading text={`${notification_by_id_data.title} (${formatDateTime(notification_by_id_data.created_at)})`} />
            <InfoBlock style={{ marginTop: 24 }}>
                <p className="p14_400 mainText">{searchLink(notification_by_id_data.body)}</p>
            </InfoBlock>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        notification_by_id_data: state.notificationById.notification_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchNotificationById: (id) => dispatch(fetchNotificationById(id)),
        CLEAR_NOTIFICATION_BY_ID_DATA: () => dispatch({ type: CLEAR_NOTIFICATION_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(NotificationById);