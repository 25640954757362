import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchMyContracts } from 'store/actions/contracts';
import { allContractsFilter, allContractsSwitch } from 'data/adminLists';
import { allContractsTable, allContractsWithoutStatusTable } from 'data/tableHeader'
import { contractsStatusesStyle } from 'data/lists';
import { allContractsTableWidth, allContractsWithoutStatusTableWidth } from 'data/tableWidth';
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery';
import { createFio } from 'hooks/createFio';
import Filter from 'components/shared/filter/Filter';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Switch from 'components/shared/switch/Switch';

function AllContracts({ contracts, pages, work_types, fetchMyContracts }) {
    const [api, contextHolder] = notification.useNotification();
    const [activeSwitch, setActiveSwitch] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyContracts(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                if (obj.is_archived) {
                    setActiveSwitch(obj.is_archived[0])
                } else {
                    setActiveSwitch(false)
                }
            }
        } else {
            query.page = 1
            query.search = getQueryStringFromObject(JSON.stringify({ "is_archived": [false] }))
            setSearchParams(query)
            setActiveSwitch(false)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return contracts.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-contracts/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: createFio(el.owner)
                },
                {
                    type: 'text',
                    content: createFio(el.executor)
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/market/fields/${el.square_id}`)}>{el.square.number}</p>
                },
                {
                    type: 'active_text',
                    content: <p>{el.technics.length > 0 ? (el.technics[0].technic_type.name + (el.technics.length > 1 ? ` + ${el.technics.length - 1}` : '')) : '-'}</p>
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: 'с ' + el.valid_from + ' по ' + el.valid_to
                },
                activeSwitch &&
                {
                    type: 'actions',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: contractsStatusesStyle[el.status].bg }}>{contractsStatusesStyle[el.status].title}</div>
                },
            ]
        ))
    }

    const onSwitch = (is_active) => {
        const query = Object.fromEntries([...searchParams]);
        if (is_active) {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.is_archived = [true]
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "is_archived": [true] }))
            }
        } else {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                delete obj.is_archived
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            }
        }
        setSearchParams(query)
        setActiveSwitch(is_active)
    }
    
    return (
        <div className='allUsersBlock'>
            {contextHolder}
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                    <TextHeading text={'Контракты'} />
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                    <Filter
                        className={'filter_marginRight'}
                        filterList={allContractsFilter(work_types)}
                        blockFilterList={['is_archived']}
                    />
                </div>
            </div>
            <Switch
                items={allContractsSwitch}
                activeItem={activeSwitch}
                onSwitch={onSwitch}
                className="switch miniSwitch"
                style={{ marginTop: 24, marginBottom: 16 }}
            />
            <Table
                header={activeSwitch ? allContractsTable : allContractsWithoutStatusTable}
                width={activeSwitch ? allContractsTableWidth : allContractsWithoutStatusTableWidth}
                data={createTableData(work_types)}
            />
            {contracts.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        contracts: state.userContracts.contracts,
        pages: state.userContracts.pages,
        work_types: state.workTypes.work_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyContracts: (page, size, params) => dispatch(fetchMyContracts(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllContracts);