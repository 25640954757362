import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { fetchPasswordRecovery } from 'store/actions/login';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function EmailPasswordRecovery({fetchPasswordRecovery}) {

    const onFinish = ({email}) => {
        fetchPasswordRecovery(email)
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Электронная почта'}
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите почту' />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Отправить ссылку'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchPasswordRecovery: (email) => dispatch(fetchPasswordRecovery(email))
    }
}

export default connect(null, mapDispatvhToProps)(EmailPasswordRecovery);