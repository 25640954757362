export const stepsCreateCar = [
    {
        title: 'Основная информация'
    },
    {
        title: 'Модель техники'
    },
    {
        title: 'Описание техники'
    },
    {
        title: 'Доп. оборудование'
    },
    {
        title: 'Прочее'
    },
    {
        title: 'Установка GPS оборудования'
    }
]

export const stepsQuickCreateField = [
    {
        title: 'Основная информация'
    },
    {
        title: 'Местоположение'
    },
]

export const stepsFullCreateField = [
    {
        title: 'Основная информация'
    },
    {
        title: 'Местоположение'
    },
    {
        title: 'Участки'
    },
    {
        title: 'Сезоны'
    },
    {
        title: 'Документы'
    },
]

export const stepsCreateApplication = [
    {
        title: 'Тип заявки'
    },
    {
        title: 'Выбор'
    },
    {
        title: 'Даты'
    },
    {
        title: 'Описание заявки'
    }
]