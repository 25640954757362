import { ApiUrl, instance } from './apiEnv';

export const api_contracts = {

    async GetMyContracts(page, size, params) {
        return await instance.get(`${ApiUrl}contracts/my?page=${page}&size=${size}&${params}`)
    },

    async GetContractById(id) {
        return await instance.get(`${ApiUrl}contracts/${id}`)
    },

    async PostCreateContract(bid_id, reply_id, data) {
        return await instance.post(`${ApiUrl}contracts/sign?bid_id=${bid_id}&reply_id=${reply_id}`, data)
    },

    async PostCompleteContract(contract_id) {
        return await instance.post(`${ApiUrl}contracts/${contract_id}/complete`)
    },

    async PostTerminateContract(contract_id) {
        return await instance.post(`${ApiUrl}contracts/${contract_id}/terminate`)
    },

    async PostRepeatContract(contract_id, data) {
        return await instance.post(`${ApiUrl}contracts/${contract_id}/repeat`, data)
    },

    async UploadContractDocumentById(id, data) {
        return await instance.put(`${ApiUrl}contracts/${id}/document`, data)
    },

    async DeleteContractDocumentById(id, doc_id) {
        return await instance.delete(`${ApiUrl}contracts/${id}/document/${doc_id}`)
    },

}