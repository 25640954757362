import { connect } from 'react-redux'
import { Form, Upload, Radio, Input, Select } from 'antd';
import { useState } from 'react';
import { SAVE_CREATE_SQUARE_DATA, SAVE_CREATE_SQUARE_MAIN_FILES } from 'store/actionTypes';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { checkFioLength } from 'hooks/checkFioLength';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';

import uploadIcon from 'assets/images/icons/upload.svg';

import './style.css';

const { Option } = Select;

const propsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    multiple: true,
};

const propsUploadImages = {
    action: '',
    accept: ".png, .jpg",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function MainInfo({
    form,
    nextStep,
    create_square_quick,
    square_types,
    crop_types,
    sowing_types,
    main_info_files_photo,
    main_info_files_isOwner,
    SAVE_CREATE_SQUARE_DATA,
    SAVE_CREATE_SQUARE_MAIN_FILES
}) {
    const [value, setValue] = useState(create_square_quick.is_owner)
    const [filePhotoList, setFilePhotoList] = useState(main_info_files_photo)
    const [fileOwnerList, setFileOwnerList] = useState(main_info_files_isOwner)
    const [fields, setFields] = useState([
        {
            name: 'number',
            value: create_square_quick.number,
        },
        {
            name: 'farmer',
            value: create_square_quick.farmer
        },
        {
            name: 'is_owner',
            value: create_square_quick.is_owner
        },
        {
            name: 'cadastral',
            value: create_square_quick.cadastral
        },
        {
            name: 'description',
            value: create_square_quick.description
        },
        {
            name: 'size',
            value: create_square_quick.size
        },
        {
            name: 'square_type_id',
            value: create_square_quick.square_type_id
        },
        {
            name: 'crop_type_id',
            value: create_square_quick.crop_type_id
        },
        {
            name: 'sowing_type_id',
            value: create_square_quick.sowing_type_id
        },
        {
            name: 'filePhotoList',
            value: main_info_files_photo
        },
        {
            name: 'fileOwnerList',
            value: main_info_files_isOwner
        }
    ]);
    const [errorStatus, setErrorStatus] = useState({})

    const onFinish = ({ number, farmer, is_owner, cadastral, description, size, square_type_id, crop_type_id, sowing_type_id }) => {
        if (checkFioLength(farmer).length === 3) {
            let obj = { ...create_square_quick }
            obj.number = number
            obj.is_owner = is_owner
            obj.farmer = farmer
            obj.cadastral = cadastral
            obj.description = description
            obj.size = size
            obj.square_type_id = square_type_id
            obj.crop_type_id = crop_type_id
            obj.sowing_type_id = sowing_type_id
            if (is_owner) {
                nextStep()
                SAVE_CREATE_SQUARE_MAIN_FILES({
                    main_info_files_photo: filePhotoList,
                    main_info_files_isOwner: []
                })
            } else {
                nextStep()
                SAVE_CREATE_SQUARE_MAIN_FILES({
                    main_info_files_photo: filePhotoList,
                    main_info_files_isOwner: fileOwnerList
                })
            }
            SAVE_CREATE_SQUARE_DATA(obj)
        } else {
            setErrorStatus({
                validateStatus: "error",
                help: "Некорректные данные"
            })
        }
    };

    const normFile = (e, type) => {
        if (type === 'fileOwnerList') {
            setFileOwnerList(e.fileList)
        } else {
            setFilePhotoList(e.fileList)
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const fieldsArray = [...fields]
        if (typeof fieldsArray[1].name === 'object') {
            fieldsArray.filter(el => el.name[0] === type)[0].value.splice(index, 1)
        } else {
            fieldsArray.filter(el => el.name === type)[0].value.splice(index, 1)
        }
        if (type === 'fileOwnerList') {
            const files = [...fileOwnerList];
            files.splice(index, 1);
            setFileOwnerList(files);
        } else {
            const files = [...filePhotoList];
            files.splice(index, 1);
            setFilePhotoList(files);
        }
        setFields(fieldsArray)
    }

    return (
        <Form
            layout="vertical"
            requiredMark={true}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
        >
            <InfoBlock>
                <div className="twoColumnForm">
                    <div className='twoColumnForm_column'>
                        <Form.Item
                            label={'Номер поля'}
                            name="number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Input placeholder='Введите номер поля' type="number" onKeyDown={numberInputFormat} />
                        </Form.Item>
                        <Form.Item
                            label={'Кадастровый номер'}
                            name="cadastral"
                            rules={[
                                {
                                    required: false,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Input placeholder='Введите кадастровый номер' />
                        </Form.Item>
                        <Form.Item
                            label={'ФИО фермера'}
                            name="farmer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                            {...errorStatus}
                        >
                            <Input placeholder='Введите ФИО фермера' />
                        </Form.Item>
                        <Form.Item
                            label={'Владеет полем'}
                            name="is_owner"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Radio.Group onChange={(e) => setValue(e.target.value)}>
                                <Radio value={true}>Да</Radio>
                                <Radio value={false}>Нет</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='twoColumnForm_column'>
                        <Form.Item
                            label={'Тип поля'}
                            name="square_type_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите тип поля">
                                {square_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={'Культура'}
                            name="crop_type_id"
                            rules={[
                                {
                                    required: false,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите тип культуры">
                                {crop_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={'Фактический посев'}
                            name="sowing_type_id"
                            rules={[
                                {
                                    required: false,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите фактический посев">
                                {sowing_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <Form.Item
                    label={'Описание'}
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input.TextArea placeholder='Введите описание' />
                </Form.Item>
                <Form.Item
                    label={'Размер поля (Га)'}
                    name="size"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите размер поля' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
                {!value &&
                    <div style={{ marginTop: 24 }}>
                        <p className='p14_500 p_mb12'>Загрузите документ, подтверждающий право пользования полем (необязательно)</p>
                        <div className={`${fileOwnerList.length === 0 ? '' : 'squareUploadBlock'}`}>
                            <div className='squareUploadBlock'>
                                {fileOwnerList.map((el, index) => (
                                    <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index, 'fileOwnerList')} />
                                ))}
                            </div>
                            <Form.Item
                                name="fileOwnerList"
                                valuePropName="fileOwnerList"
                                getValueFromEvent={(e) => normFile(e, 'fileOwnerList')}
                                className={`${fileOwnerList.length === 0 ? '' : 'squareUpload'}`}
                            >
                                {fileOwnerList.length === 0
                                    ?
                                    <Upload.Dragger
                                        name="fileOwnerList"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsUpload}
                                    >
                                        <div style={{ width: 190, margin: '100px auto' }}>
                                            <p className="subtext upload-text">Перетащите сюда, чтобы загрузить документы подтверждающие право пользования</p>
                                            <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                        </div>
                                    </Upload.Dragger>
                                    :
                                    <Upload.Dragger
                                        name="fileOwnerList"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsUpload}
                                        fileList={fileOwnerList}
                                    >
                                        <img src={uploadIcon} alt="uploadIcon" />
                                    </Upload.Dragger>
                                }
                            </Form.Item>
                        </div>
                    </div>
                }
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите фото (необязательно)</p>
                    <div className={`${filePhotoList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {filePhotoList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index, 'filePhotoList')} />
                            ))}
                        </div>
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'filePhotoList')}
                            className={`${filePhotoList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {filePhotoList.length === 0
                                ?
                                <Upload.Dragger
                                    name="filePhotoList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUploadImages}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить фото</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="filePhotoList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUploadImages}
                                    fileList={filePhotoList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </InfoBlock>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        create_square_quick: state.createSquare.create_square_quick,
        main_info_files_photo: state.createSquare.main_info_files_photo,
        main_info_files_isOwner: state.createSquare.main_info_files_isOwner,
        square_types: state.squareTypes.square_types,
        crop_types: state.cropTypes.crop_types,
        sowing_types: state.sowingTypes.sowing_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_SQUARE_DATA: (data) => dispatch({ type: SAVE_CREATE_SQUARE_DATA, create_square_quick: data }),
        SAVE_CREATE_SQUARE_MAIN_FILES: (data) => dispatch({ type: SAVE_CREATE_SQUARE_MAIN_FILES, data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainInfo);