import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { SAVE_SQUARE_COORDS } from 'store/actionTypes';

import pin from 'assets/images/pins/field.svg'

function Address({ coords, SAVE_SQUARE_COORDS }) {
    const [map, setMap] = useState(null)

    const defaultState = {
        center: coords || [55.751574, 37.573856],
        zoom: 10,
        type: 'yandex#hybrid'
    };

    useEffect(() => {
        setMap(
            <Map
                defaultState={defaultState}
                width='100%'
                height='100%'
                onClick={(e) => SAVE_SQUARE_COORDS(e.get('coords'))}
            >
                <FullscreenControl />
                <SearchControl options={{ float: "right" }} />
                <GeolocationControl options={{ float: "left" }} />
                <TypeSelector options={{ float: 'right' }} />
                {coords &&
                    <Placemark
                        geometry={coords}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: pin,
                            iconImageSize: [70, 70],
                            // iconImageOffset: [-5, -38]
                        }}
                    />
                }
            </Map>
        )
    }, [coords])

    return (
        <div className='locationBlock_map'>
            {map}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        coords: state.createSquare.coords,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_SQUARE_COORDS: (coords) => dispatch({ type: SAVE_SQUARE_COORDS, coords })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Address);