import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { fetchAllContracts } from 'store/actions/admin/contracts';
import { allDealsTable } from 'data/adminTableHeader';
import { allDealsTableWidth } from 'data/adminTableWidth';
import { allContractsFilter, allDealsFilter } from 'data/adminLists';
import { contractsStatusesStyle } from 'data/lists';
import { createQuery } from 'hooks/createQuery';
import { createFio } from 'hooks/createFio';
import Filter from 'components/shared/filter/Filter';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';

function AllContractsAdmin({ contracts, pages, work_types, fetchAllContracts }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllContracts(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return contracts.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/contracts/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: createFio(el.owner)
                },
                {
                    type: 'text',
                    content: createFio(el.executor)
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/fields/${el.square_id}`)}>{el.square.number}</p>
                },
                {
                    type: 'text',
                    content: <p>{el.technics.length > 0 ? (el.technics[0].technic_type.name + (el.technics.length > 1 ? ` + ${el.technics.length - 1}` : '')) : '-'}</p>
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: 'с ' + el.valid_from + ' по ' + el.valid_to
                },
                {
                    type: 'actions',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: contractsStatusesStyle[el.status].bg }}>{contractsStatusesStyle[el.status].title}</div>
                },
            ]
        ))
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                    <TextHeading text={'Контракты'} />
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                    <Filter
                        className={'filter_marginRight'}
                        filterList={allContractsFilter(work_types)}
                    />
                </div>
            </div>
            <Table
                header={allDealsTable}
                width={allDealsTableWidth}
                data={createTableData()}
            />
            {contracts.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        contracts: state.allContracts.contracts,
        pages: state.allContracts.pages,
        work_types: state.workTypes.work_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllContracts: (page, size, params) => dispatch(fetchAllContracts(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllContractsAdmin);