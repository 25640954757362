import { useEffect } from 'react';
import { connect } from 'react-redux'
import { fetchAllUser } from 'store/actions/admin/user';
import { adminCarBreadcrumb } from 'data/brebcrumb';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import CreateTechnic from 'components/components/forms/admin/technics/createTechnic/CreateTechnic';

function CreateTechnicAdmin({ fetchAllUser }) {

    useEffect(() => {
        fetchAllUser(1, 100, null)
    }, [])

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={adminCarBreadcrumb} activeLink={'Создание техники'} />
            <InfoBlock>
                <CreateTechnic />
            </InfoBlock>
        </div>
    );
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllUser: (page, size, params) => dispatch(fetchAllUser(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateTechnicAdmin);