import { ApiUrl, instance } from '../apiEnv';

export const api_complaints = {

    async PostCreateComplaint(data) {
        return await instance.post(`${ApiUrl}complaints`, data)
    },

    async GetAllComplaints(page, size, params) {
        return await instance.get(`${ApiUrl}complaints?page=${page}&size=${size}&${params}`)
    },

    async GetComplaintById(complaint_id) {
        return await instance.get(`${ApiUrl}complaints/${complaint_id}`)
    },

    async UpdateComplaintById(complaint_id, data) {
        return await instance.put(`${ApiUrl}complaints/${complaint_id}`, data)
    },

    async DeleteComplaintById(complaint_id) {
        return await instance.delete(`${ApiUrl}complaints/${complaint_id}`)
    },
}