import { ApiUrl, instance } from '../apiEnv';

export const api_chats = {

    async GetMyChats(page, size, params) {
        return await instance.get(`${ApiUrl}chats/my?page=${page}&size=${size}&${params}`)
    },

    async GetMyFolders(page, size, params) {
        return await instance.get(`${ApiUrl}chats/folders/my?page=${page}&size=${size}&${params}`)
    },

    async GetRoomById(room_id) {
        return await instance.get(`${ApiUrl}chats/${room_id}`)
    },

    async PostSendMessageToRoom(room_id, data) {
        return await instance.post(`${ApiUrl}chats/${room_id}/send_message`, data)
    },

    async PostCreateRoom(reciever_id, data) {
        return await instance.post(`${ApiUrl}chats?reciever_id=${reciever_id}`, data)
    },
}