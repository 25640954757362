import { ApiUrl, instance } from './apiEnv';

export const api_applications = {

    async GetMyApplications(page, size, params) {
        return await instance.get(`${ApiUrl}bids/my?page=${page}&size=${size}&${params}`)
    },

    async GetApplicationById(id) {
        return await instance.get(`${ApiUrl}bids/${id}`)
    },

    async PostCreateApplications(data) {
        return await instance.post(`${ApiUrl}bids`, data)
    },

    async PutAddTechnicsToApplications(bid_id, data) {
        return await instance.put(`${ApiUrl}bids/${bid_id}/add_technics`, data)
    },

    async PutAddPlotsToApplications(bid_id, data) {
        return await instance.put(`${ApiUrl}bids/${bid_id}/add_plots`, data)
    },
    
    async PutToggleConceledOfApplications(bid_id) {
        return await instance.put(`${ApiUrl}bids/${bid_id}/toggle_canceled`)
    },

}