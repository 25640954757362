export const createQuery = (search, order_by, order) => {
    let query = ''
    if (search) {
        query = 'search=' + search
    }
    if (order_by) {
        query += `${query && '&'}order_by=` + order_by
    }
    if (order && order !== 'null') {
        query += `${query && '&'}order=` + order
    }
    return query
}

export const getQueryStringFromObject = (filter) => {
    let str = new URLSearchParams(filter).toString()
    return str.slice(0, -1);
}