import { useState } from 'react';
import { connect } from 'react-redux'
import { applicationBreadcrumb } from 'data/brebcrumb';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CLEAR_CREATE_APPLICATION_DATA, CLEAR_SQUARE_BY_ID_DATA } from 'store/actionTypes';
import { stepsCreateApplication } from 'data/steps';
import ApplicationDescription from 'components/components/forms/application/createApplication/ApplicationDescription';
import ApplicationType from 'components/components/forms/application/createApplication/ApplicationType';
import ApplicationDate from 'components/components/forms/application/createApplication/ApplicationDate';
import ApplicationTechnicData from 'components/components/forms/application/createApplication/ApplicationTechnicData';
import ApplicationSquareData from 'components/components/forms/application/createApplication/ApplicationSquareData';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Steps from 'components/ui/steps/Steps';

function CreateApplication({ create_application, CLEAR_CREATE_APPLICATION_DATA, CLEAR_SQUARE_BY_ID_DATA }) {
    const [activeStep, setActiveStep] = useState(0)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const nextStep = () => {
        if (activeStep !== 3) {
            setActiveStep(activeStep + 1)
        } else if (activeStep === 3) {
            navigate(`/user/my-applications`)
            CLEAR_CREATE_APPLICATION_DATA()
            CLEAR_SQUARE_BY_ID_DATA()
        }
    }

    const onClickSaveForm = () => {
        form.submit()
    }

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={applicationBreadcrumb} activeLink={'Создание заявки'} />
            <div className='createCarBlock_header'>
                <TextHeading text={'Создать заявку'} />
                {activeStep === 0
                    ?
                    <SimpleButton
                        title={'Далее'}
                        style={{
                            background: '#01963A',
                            borderRadius: '100px'
                        }}
                        onClick={onClickSaveForm}
                    />
                    :
                    <div className='userProfile_header__actions'>
                        {activeStep !== 4 &&
                            <SimpleButton
                                title={'Назад'}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500,
                                    marginRight: 16
                                }}
                                onClick={() => setActiveStep(activeStep - 1)}
                            />
                        }
                        <SimpleButton
                            title={activeStep === 3 ? 'Завершить' : 'Далее'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={onClickSaveForm}
                            loading={create_application.loading}
                        />
                    </div>
                }
            </div>
            <div className='createCarBlock'>
                <Steps stepsList={stepsCreateApplication} activeStep={activeStep} />
                <div style={{ marginTop: 24 }} className='createCarBlock_forms'>
                    {activeStep === 0 && <ApplicationType nextStep={nextStep} form={form} />}
                    {activeStep === 1 && (create_application.bid_type === 'TECHNICIAN' ? <ApplicationTechnicData nextStep={nextStep} form={form} /> : <ApplicationSquareData nextStep={nextStep} form={form} />)}
                    {activeStep === 2 && <ApplicationDate nextStep={nextStep} form={form} />}
                    {activeStep === 3 && <ApplicationDescription nextStep={nextStep} form={form} />}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        create_application: state.createApplication,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        CLEAR_CREATE_APPLICATION_DATA: () => dispatch({ type: CLEAR_CREATE_APPLICATION_DATA }),
        CLEAR_SQUARE_BY_ID_DATA: () => dispatch({type: CLEAR_SQUARE_BY_ID_DATA})
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateApplication);