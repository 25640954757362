import { api_login } from 'api/login'
import { FETCH_LOGIN_START, FETCH_LOGIN_SUCCESS, SAVE_DATA_FOR_ACTIVE, FETCH_LOGIN_ERROR } from '../actionTypes'
import { fetchError, fetchStart, fetchSuccess } from './fetchStatuses'
import { errorNotification, successNotification, warningNotificationWithoutConfirmation } from 'hooks/notifications';
import { initToken } from 'api/apiEnv';

export function fetchLogin(data, currentRole) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_LOGIN_START))
        try {
            const token = await api_login.Login(data, () => dispatch(fetchError(FETCH_LOGIN_ERROR)))
            if (token !== 'Email or Password incorrect' && token !== 'Error: Network Error' && token !== 'User is inactive') {
                if (currentRole.indexOf(token.data.user.role.name) !== -1) {
                    dispatch(fetchSuccess(FETCH_LOGIN_SUCCESS, {
                        access_token: token.data.access_token,
                        refresh_token: token.data.refresh_token,
                        role: currentRole[0] === 'user' ? 'user' : token.data.user.role.name
                    }))
                    initToken()
                } else {
                    warningNotificationWithoutConfirmation('Для входа необходимо воспользоваться другим окном авторизации!')
                }
            }
            if (token === 'User is inactive' || token === 'Inactive user') {
                dispatch({
                    type: SAVE_DATA_FOR_ACTIVE,
                    data
                })
                return 'inactive'
            }
        } catch (e) {
            dispatch(fetchError(FETCH_LOGIN_ERROR, e))
        }
    }
}

export function fetchPasswordRecovery(email) {
    return async dispatch => {
        try {
            await api_login.PasswordRecovery(email)
            successNotification('Письмо отправлено', 'Письмо успешно отправленно на указанную почту. Проверьте папку Спам!')
        } catch (e) {
            if (e.response.status === 404) errorNotification('Пользователь не найден', 'Пользователь с таким email не найден.')
        }
    }
}

export function fetchPasswordRecoveryPhone(phone, nav) {
    return async dispatch => {
        try {
            await api_login.PasswordRecovery(phone)
            successNotification('SMS отправлено', 'SMS успешно отправленно на указанный номер.')
            nav()
        } catch (e) {
            if (e.response.status === 404) errorNotification('Пользователь не найден', 'Пользователь с таким номером телефона не найден.')
        }
    }
}

export function fetchPasswordReset(data) {
    return async dispatch => {
        try {
            await api_login.PasswordReset(data)
            successNotification('Пароль успешно изменен', 'Пароль успешно изменен. Теперь вы может им пользоваться')
            return 'success'
        } catch (e) {

        }
    }
}

