import { connect } from 'react-redux'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchContractById } from 'store/actions/admin/contracts';
import { ADMIN_CLEAR_CONTRACT_BY_ID_DATA } from 'store/actionTypes';
import { adminDealBreadcrumb } from 'data/brebcrumb';

import { dealByIdSwitch } from 'data/adminLists';
import MainInfo from './mainInfo/MainInfo';
import Participants from './participants/Participants';
import Technics from './technics/Technics';
import Squares from './squares/Squares';
import Conflicts from './conflict/Conflicts';
import Docs from './docs/Docs';
import Reviews from './reviews/Reviews';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import Switch from 'components/shared/switch/Switch';

function ContractByIdAdmin({ contract_by_id_data, fetchContractById, ADMIN_CLEAR_CONTRACT_BY_ID_DATA }) {
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')

    useEffect(() => {
        fetchContractById(id)
    }, [])

    useEffect(() => {
        return () => {
            ADMIN_CLEAR_CONTRACT_BY_ID_DATA()
        }
    }, [])

    return (
        contract_by_id_data &&
        <div className='allUsersBlock'>
            <Breadcrumb list={adminDealBreadcrumb} activeLink={contract_by_id_data.id} />
            <Switch
                items={dealByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo />}
            {activeSwitch === 'participants' && <Participants />}
            {activeSwitch === 'technics' && <Technics />}
            {activeSwitch === 'squares' && <Squares />}
            {activeSwitch === 'conflicts' && <Conflicts />}
            {activeSwitch === 'docs' && <Docs />}
            {activeSwitch === 'reviews' && <Reviews />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.adminContractById.contract_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchContractById: (id) => dispatch(fetchContractById(id)),
        ADMIN_CLEAR_CONTRACT_BY_ID_DATA: () => dispatch({ type: ADMIN_CLEAR_CONTRACT_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ContractByIdAdmin);