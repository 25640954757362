import {
    FETCH_USER_DATA_REPLIES_START,
    FETCH_USER_DATA_REPLIES_SUCCESS,
} from '../../actionTypes'

const initialState = {
    replies: null,
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_DATA_REPLIES_START:
            return {
                ...state, loading: true
            }
        case FETCH_USER_DATA_REPLIES_SUCCESS:
            return {
                replies: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                loading: false
            }
        default: return state
    }
}