import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { contractCarsTable } from 'data/adminTableHeader';
import { contractCarsTableWidth } from 'data/adminTableWidth';
import { verificationStatusesStyle } from 'data/lists';
import Table from 'components/ui/table/Table';

function Technics({ contract_by_id_data_technics }) {
    const navigate = useNavigate()

    const createTableData = () => {
        return contract_by_id_data_technics.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => { if (el.status !== "BLOCKED") navigate(`/market/technics/${el.id}`) }}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: <div className='technicCard_description__status' style={{ backgroundColor: el.is_busy ? '#D32F2F' : '#43A047', width: 'fit-content' }}>{el.is_busy ? 'Занята' : 'Свободна'}</div>
                },
            ]
        ))
    }

    return (
        <Table
            header={contractCarsTable}
            width={contractCarsTableWidth}
            data={createTableData()}
        />
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data_technics: state.contractById.contract_by_id_data.technics
    }
}

export default connect(mapStateToProps, null)(Technics);