import {
    FETCH_PLOTS_BY_ID_DATA_START,
    FETCH_PLOTS_BY_ID_DATA_SUCCESS,
    CLEAR_PLOTS_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    plots_by_id_data: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PLOTS_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_PLOTS_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                plots_by_id_data: action.data.plots_by_id_data
            }
        case CLEAR_PLOTS_BY_ID_DATA:
            return {
                ...state,
                plots_by_id_data: null
            }
        default: return state
    }
}