import { api_contracts } from 'api/admin/contracts'
import {
    FETCH_ALL_CONTRACTS_START,
    FETCH_ALL_CONTRACTS_SUCCESS,
    ADMIN_FETCH_CONTRACT_BY_ID_DATA_START,
    ADMIN_FETCH_CONTRACT_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllContracts(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_CONTRACTS_START))
        try {
            const contracts_data = await api_contracts.GetAllContracts(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_CONTRACTS_SUCCESS, {
                data: contracts_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchContractById(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_CONTRACT_BY_ID_DATA_START))
        try {
            const contract_data = await api_contracts.GetContractById(id)
            dispatch(fetchSuccess(ADMIN_FETCH_CONTRACT_BY_ID_DATA_SUCCESS, {
                contract_by_id_data: contract_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchTerminateContract(contract_id) {
    return async dispatch => {
        try {
            await api_contracts.PostTerminateContract(contract_id)
            dispatch(fetchContractById(contract_id))
            return true
        } catch (e) {
            return false
        }
    }
}

export function fetchUploadContractDocs(id, data) {
    return async dispatch => {
        try {
            await api_contracts.UploadContractDocumentById(id, data)
            dispatch(fetchContractById(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteContractDocs(id, doc_id) {
    return async dispatch => {
        try {
            await api_contracts.DeleteContractDocumentById(id, doc_id)
            dispatch(fetchContractById(id))
        } catch (e) {
        }
    }
}