import { connect } from 'react-redux'
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchRejectSquareVerificstion } from 'store/actions/admin/squares';
import RejectVerivication from "components/components/forms/modal/rejectVerification/RejectVerivication";

function RejectVerificationSquare({ data, fetchRejectSquareVerificstion, setVisible, setBack }) {

    const reject = () => {
        fetchRejectSquareVerificstion(data.id, data.verification_id)
        setVisible()
    }

    return (
        <>
            <p className="errorColor p14_500">Отклонить в верификации</p>
            <RejectVerivication
                style={{ marginTop: 16 }}
                reject={() => reject()}
                setBack={(data) => setBack(data)}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        setBack: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'mainVerificationSquare', data }),
        fetchRejectSquareVerificstion: (square_id, verification_id) => dispatch(fetchRejectSquareVerificstion(square_id, verification_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(RejectVerificationSquare);