import {
    SAVE_CREATE_SQUARE_DATA,
    SAVE_CREATE_SQUARE_PLOTS,
    SAVE_CREATE_SQUARE_MAIN_FILES,
    SAVE_CREATE_SQUARE_DOCS,
    CLEAR_CREATE_SQUARE_DATA,
    DELETE_CREATE_SQUARE_PLOT,
    SAVE_CREATE_SQUARE_SEASONS,
    DELETE_CREATE_SQUARE_SEASONS,
    SAVE_CREATE_SQUARE_EDIT_SEASONS,
    SAVE_CREATE_SQUARE_OWNER_FILES,
    SAVE_SQUARE_COORDS
} from '../actionTypes'

const clearCreatQuickSquare = {
    size: null,
    number: null,
    cadastral: null,
    description: null,
    is_owner: true,
    square_type_id: null
}

const initialState = {
    create_square_quick: {
        size: null,
        number: null,
        cadastral: null,
        description: null,
        is_owner: true,
        square_type_id: null
    },
    plots: [],
    seasons: [],
    main_info_files_photo: [],
    main_info_files_isOwner: [],
    docs: [],
    coords: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CREATE_SQUARE_DATA:
            return {
                ...state,
                create_square_quick: action.create_square_quick
            }
        case SAVE_CREATE_SQUARE_PLOTS:
            let array = [...state.plots]
            array.push(action.plot)
            return {
                ...state,
                plots: array
            }
        case DELETE_CREATE_SQUARE_PLOT:
            let array2 = [...state.plots]
            array2.splice(action.index, 1)
            return {
                ...state,
                plots: array2
            }
        case SAVE_CREATE_SQUARE_SEASONS:
            let seasonsCopy = [...state.seasons]
            seasonsCopy.push(action.seasons)
            return {
                ...state,
                seasons: seasonsCopy
            }
        case DELETE_CREATE_SQUARE_SEASONS:
            let seasonsCopy2 = [...state.seasons]
            seasonsCopy2.splice(action.index, 1)
            return {
                ...state,
                seasons: seasonsCopy2
            }
        case SAVE_CREATE_SQUARE_EDIT_SEASONS:
            return {
                ...state,
                seasons: action.editSeasons
            }
        case SAVE_CREATE_SQUARE_MAIN_FILES:
            return {
                ...state,
                main_info_files_photo: action.data.main_info_files_photo,
                main_info_files_isOwner: action.data.main_info_files_isOwner,
            }
        case SAVE_CREATE_SQUARE_OWNER_FILES:
            return {
                ...state,
                main_info_files_isOwner: action.files,
            }
        case SAVE_CREATE_SQUARE_DOCS:
            return {
                ...state,
                docs: action.docs
            }
        case SAVE_SQUARE_COORDS:
            return {
                ...state,
                coords: action.coords,
            }
        case CLEAR_CREATE_SQUARE_DATA:
            return {
                ...state,
                create_square_quick: clearCreatQuickSquare,
                plots: [],
                seasons: [],
                main_info_files_photo: [],
                main_info_files_isOwner: [],
                docs: [],
                coords: null
            }
        default: return state
    }
}