import {
    MARKET_FETCH_SQUARES_BY_ID_DATA_START,
    MARKET_FETCH_SQUARES_BY_ID_DATA_SUCCESS,
    MARKET_FETCH_ANOTHER_SQUARES,
    MARKET_FETCH_BIDS_BY_SQUARES,
    MARKET_CLEAR_SQUARES_BY_ID_DATA
} from '../../actionTypes'

const initialState = {
    square_by_id_data: null,
    another_squares: [],
    bids: []
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case MARKET_FETCH_SQUARES_BY_ID_DATA_START:
            return {
                ...state,
            }
        case MARKET_FETCH_SQUARES_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                square_by_id_data: action.data.square_by_id_data,
            }
        case MARKET_FETCH_ANOTHER_SQUARES:
            return {
                ...state,
                another_squares: action.data.data.items || [],
            }
        case MARKET_FETCH_BIDS_BY_SQUARES: {
            return {
                ...state,
                bids: action.data.data.items || []
            }
        }
        case MARKET_CLEAR_SQUARES_BY_ID_DATA: {
            return {
                square_by_id_data: null,
                another_squares: [],
                bids: []
            }
        }
        default: return state
    }
}