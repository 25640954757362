import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Select, Input } from 'antd';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { formatDate } from 'hooks/formatDate';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import CustomDatePicker from '../../../../shared/datePicker/DatePicker';

const { Option } = Select;

function RepeatContractForm({
    form,
    reject,
    work_types,
}) {
    const [range, setRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const onFinish = async (values) => {
        const data = {
            valid_from: formatDate(range[0].startDate),
            valid_to: formatDate(range[0].endDate),
            ...values
        }
        reject(data)
    };

    return (
        <InfoBlock style={{ marginTop: 26 }}>
            <Form
                layout="vertical"
                requiredMark={false}
                autoComplete="off"
                className="modalForm"
                onFinish={onFinish}
                form={form}
            >
                <p className='mainText p14_500'>Дата начала контракта</p>
                <div className='calendarBlock'>
                    <CustomDatePicker
                        ranges={range}
                        mode="userCreateReply"
                        saveRange={state => setRange(state)}
                        edit={false}
                    />
                </div>
                <Form.Item
                    label={'Стоимость работы, ₽'}
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите стоимость работ' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
                <Form.Item
                    label={'Тип работ'}
                    name="work_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип работ">
                        {work_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        work_types: state.workTypes.work_types,
    }
}

export default connect(mapStateToProps, null)(RepeatContractForm);