import { connect } from 'react-redux'
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchRejectUserVerificstion } from 'store/actions/admin/user';
import RejectVerivication from "components/components/forms/modal/rejectVerification/RejectVerivication";

function RejectVerificationUser({data, setBack, fetchRejectUserVerificstion, setVisible}) {

    const reject = () => {
        fetchRejectUserVerificstion(data.id, data.verification_id)
        setVisible()
    }

    return (
        <>
            <p className="errorColor p14_500">Отклонить в верификации</p>
            <RejectVerivication 
                style={{marginTop: 16}}
                reject={() => reject()}
                setBack={(data) => setBack(data)}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL}),
        setBack: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'mainVerificationUser', data }),
        fetchRejectUserVerificstion: (user_id, verification_id) => dispatch(fetchRejectUserVerificstion(user_id, verification_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(RejectVerificationUser);