import { yearList } from "hooks/listOfYear"

/*list items in switch login */
export const loginSwitch = [
    {
        item: 'email',
        title: 'Почта'
    },
    {
        item: 'phone',
        title: 'Телефон'
    },
    {
        item: 'inn',
        title: 'ИНН'
    },
]

/*list items in switch login */
export const signupSwitch = [
    {
        item: 'email',
        title: 'Почта'
    },
    {
        item: 'phone',
        title: 'Телефон'
    },
]

/*list items in switch password recovery */
export const passwordRecoverySwitch = [
    {
        item: 'email',
        title: 'Почта'
    },
    // {
    //     item: 'phone',
    //     title: 'Телефон'
    // }
]

/*list items in switch login */
export const signupSecondStageSwitch = [
    {
        item: 'fiz',
        title: 'Физлицо'
    },
    {
        item: 'ur',
        title: 'Юрлицо'
    },
]

/*list items in switch profile */
export const profileSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'company',
        title: 'Компания'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'verification',
        title: 'Верификация'
    },
]

export const carsSwitch = [
    {
        item: 'public',
        title: 'Опубликованная'
    },
    {
        item: 'consideration',
        title: 'На рассмотрении'
    }
]

export const carByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'employmentSchedule',
        title: 'График занятости'
    },
    {
        item: 'verification',
        title: 'Верификация'
    },
    {
        item: 'current_location',
        title: 'Отслеживание'
    },
]

export const publicUserSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'technics',
        title: 'Техника'
    },
    {
        item: 'squares',
        title: 'Поля'
    },
    {
        item: 'replies',
        title: 'Отклики'
    },
    {
        item: 'reviews',
        title: 'Отзывы'
    },
]

export const carStatuses = [
    {
        status: false,
        title: 'Свободна',
        bg: '#01963A'
    },
    {
        status: true,
        title: 'Занята',
        bg: '#D32F2F'
    }
]

export const carViews = [
    {
        status: false,
        title: 'Показывать',
        bg: '#43A047'
    },
    {
        status: true,
        title: 'Скрыть',
        bg: '#D32F2F'
    },
]


export const verificationStatusesStyle = {
    VERIFIED: {
        title: 'Верифицирован',
        bg: '#43A047'
    },
    BLOCKED: {
        title: 'Отклонено',
        bg: '#D32F2F'
    },
    ON_CONSIDER: {
        title: 'На проверке',
        bg: '#FFC107'
    },
    NOT_RELEVANT: {
        title: 'Не актуально',
        bg: '#3949AB'
    }
}

export const dealStatusStyles = {
    VERIFIED: {
        title: 'Завершена',
        bg: '#43A047'
    },
    BLOCKED: {
        title: 'Активна',
        bg: '#FFC107'
    }
}

export const applicationStatusStyles = {
    true: {
        title: 'Завершена',
        bg: '#43A047'
    },
    false: {
        title: 'Активна',
        bg: '#FFC107'
    }
}

export const reviewsStatusStyles = {
    true: {
        title: 'Завершена',
        bg: '#43A047'
    },
    false: {
        title: 'Расторгнута',
        bg: '#FFC107'
    }
}

export const contractsStatusesStyle = {
    COMPLITED: {
        title: 'Успешный',
        bg: '#43A047'
    },
    TERMINATED: {
        title: 'Расторгнутый',
        bg: '#D32F2F'
    },
    UNCONCLUDED: {
        title: 'Незаключённый',
        bg: '#FFC107'
    },
    IN_DICPUTE: {
        title: 'В споре',
        bg: '#FFC107'
    }
}

export const fieldByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'plotsAndSeasons',
        title: 'Участки'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'verification',
        title: 'Верификация'
    }
]

export const fieldByIdPlotsAndSeasonsSwitch = [
    {
        item: 'plots',
        title: 'Все участки'
    },
    {
        item: 'seasons',
        title: 'Распределение по сезонам'
    },
]

export const fieldByIdPlotsAndSeasonsAndBidsSwitch = [
    {
        item: 'plots',
        title: 'Все участки'
    },
    {
        item: 'seasons',
        title: 'Распределение по сезонам'
    },
    {
        item: 'bids',
        title: 'Заявки'
    },
]


export const contractByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'participants',
        title: 'Участники'
    },
    {
        item: 'technics',
        title: 'Техника'
    },
    {
        item: 'squares',
        title: 'Поля'
    },
    {
        item: 'conflicts',
        title: 'Споры'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'reports',
        title: 'Отчёты'
    },
]

export const closeContractByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'participants',
        title: 'Участники'
    },
    {
        item: 'technics',
        title: 'Техника'
    },
    {
        item: 'squares',
        title: 'Поля'
    },
    {
        item: 'conflicts',
        title: 'Споры'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'reports',
        title: 'Отчёты'
    },
    {
        item: 'reviews',
        title: 'Отзывы'
    },
]

export const seasonCollapse = [
    {
        title: 'Номер',
        width: 100,
        item: 'number',
        type: 'string'
    },
    {
        title: 'Порядковый номер',
        width: 200,
        item: 'serial',
        type: 'string'
    },
    {
        title: 'Фактический посев, Га',
        width: 200,
        item: 'fact_sowing',
        type: 'string'
    },
    {
        title: 'Выращиваемая культура',
        width: 300,
        item: 'crop_type_name',
        type: 'select'
    },
]

export const applicationByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'resnonses',
        title: 'Отклики'
    },
]

export const notificationsSwitch = [
    {
        item: false,
        title: 'Новые'
    },
    {
        item: true,
        title: 'Просмотренные'
    }
]

export const chatsSwitch = [
    {
        item: 'all',
        title: 'Все'
    },
    {
        item: 'REPLY',
        title: 'Отклики'
    },
    {
        item: 'DEAL',
        title: 'Предложения'
    },
    {
        item: 'CONTRACT',
        title: 'Контракты'
    }
]

export const marketTechnicsFilter = (technic_types, work_types, technic_manufacturer, technic_model) => {
    return [
        {
            type: 'select',
            title: 'Тип техники',
            param: 'technic_type_id',
            childrens: technic_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        {
            type: 'inputWithCheckbox',
            title: 'Производитель техники',
            placeholder: 'Введите производителя',
            param: 'manufacturer',
            childrens: technic_manufacturer.map(el => ({
                title: el,
                param: el
            })),
        },
        {
            type: 'inputWithCheckbox',
            title: 'Модель техники',
            placeholder: 'Введите модель',
            param: 'model',
            childrens: technic_model.map(el => ({
                title: el,
                param: el
            })),
        },
        {
            type: 'select',
            title: 'Тип работ',
            param: 'work_type_id',
            childrens: work_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        {
            type: 'select',
            title: 'Статус',
            param: 'is_busy',
            childrens: [
                {
                    title: 'Свободна',
                    param: false
                },
                {
                    title: 'Занята',
                    param: true
                }
            ]
        },
        {
            type: 'from_to',
            title: 'Мощность в л.с',
            childrens: [
                {
                    type: 'inputNumber',
                    placeholder: 'От',
                    param: 'power_from'
                },
                {
                    type: 'inputNumber',
                    placeholder: 'До',
                    param: 'power_to'
                }
            ],
        },
        {
            type: 'from_to',
            title: 'Год выпуска',
            childrens: [
                {
                    type: 'inputNumber',
                    placeholder: 'От',
                    param: 'release_date_from'
                },
                {
                    type: 'inputNumber',
                    placeholder: 'До',
                    param: 'release_date_to'
                }
            ],
        },
    ]
}

export const marketSquaresFilterViews = [
    {
        status: 'bids_squares',
        title: 'Нуждается в работе',
        bg: '#43A047'
    },
    {
        status: 'all_squares',
        title: 'Все поля',
        bg: '#FFC107'
    },
]

export const marketSquaresFilter = (technic_types, work_types, square_types, crop_types, sowing_types) => {
    return [
        // {
        //     type: 'select',
        //     title: 'Тип техники',
        //     param: 'technic_type_id',
        //     childrens: technic_types.map(el => ({
        //         title: el.name,
        //         param: el.id
        //     }))
        // },
        {
            type: 'select',
            title: 'Культура',
            param: 'crop_type_id',
            childrens: crop_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        // {
        //     type: 'select',
        //     title: 'Тип работ',
        //     param: 'work_type_id',
        //     childrens: work_types.map(el => ({
        //         title: el.name,
        //         param: el.id
        //     }))
        // },
        {
            type: 'select',
            title: 'Тип поля',
            param: 'square_type_id',
            childrens: square_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        {
            type: 'select',
            title: 'Фактический посев',
            param: 'sowing_type_id',
            childrens: sowing_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
    ]
}
