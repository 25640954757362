import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Radio, Select, Upload } from 'antd';
import { SAVE_CREATE_TECHNIC_DATA, SAVE_CREATE_TECHNIC_OTHER_FILES } from 'store/actionTypes';
import { fetchCreateTechnic, fetchUploadTechnicDocs, fetchUploadTechnicImage } from 'store/actions/techics';
import { fetchCreateAddress } from 'store/actions/address';
import { fetchCreateBusyPeriod } from 'store/actions/busyPeriod';
import { formatDate } from 'hooks/formatDate';
import { fetchMyData } from 'store/actions/user';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';
import CustomDatePicker from '../../../../shared/datePicker/DatePicker';

import uploadIcon from 'assets/images/icons/upload.svg';

const { Option } = Select;

const propsUpload = {
    action: '',
    accept: ".png, .jpg",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function OtherInfo({
    form,
    nextStep,
    work_types,
    create_technic,
    other_info_files,
    main_info_files,
    main_info_filesPSM,
    coords,
    busyPeriods,
    SAVE_CREATE_TECHNIC_DATA,
    SAVE_CREATE_TECHNIC_OTHER_FILES,
    fetchCreateTechnic,
    fetchMyData,
    fetchUploadTechnicDocs,
    fetchCreateAddress,
    fetchCreateBusyPeriod,
    fetchUploadTechnicImage
}) {
    const [value, setValue] = useState(false)
    const [fileList, setFileList] = useState(other_info_files)
    const [fields, setFields] = useState([
        {
            name: 'work_type_id',
            value: create_technic.work_type_id,
        },
        {
            name: 'period_of_employment',
            value: false,
        },
        {
            name: 'files',
            value: other_info_files
        }
    ]);

    const onFinish = async ({ work_type_id, files }) => {
        const allFiles = [...main_info_files, ...main_info_filesPSM]
        let obj = { ...create_technic }
        obj.work_type_id = work_type_id
        SAVE_CREATE_TECHNIC_DATA(obj)
        SAVE_CREATE_TECHNIC_OTHER_FILES(files)
        if (coords) {
            const data = {
                latitude: coords[0],
                longitude: coords[1]
            }
            await fetchCreateAddress(data)
                .then(res => {
                    obj.default_address_id = res
                })
        }
        await fetchCreateTechnic(obj)
            .then(async (res) => {
                allFiles.forEach(el => {
                    let data = new FormData();
                    let filedata = el.originFileObj
                    delete filedata.uid
                    data.append('file', filedata);
                    data.append('name', el.name);
                    fetchUploadTechnicDocs(res, data)
                })
                for (let i = 0; i < files.length; i++) {
                    let data = new FormData();
                    let filedata = files[i].originFileObj
                    delete filedata.uid
                    data.append('image_file', filedata);
                    data.append('title', files[i].name);
                    fetchUploadTechnicImage(res, data)
                }
                if (value) {
                    for (let i = 0; i < busyPeriods.length; i++) {
                        const obj = {
                            date_from: formatDate(busyPeriods[i].startDate),
                            date_to: formatDate(busyPeriods[i].endDate),
                            technic_id: res
                        }
                        await fetchCreateBusyPeriod(obj)
                    }
                }
            })
        fetchMyData()
        nextStep()
    };

    const normFile = (e) => {
        setFileList(e.fileList)
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index) => {
        const fieldsArray = [...fields]
        const files = [...fileList];
        files.splice(index, 1);
        fieldsArray[2].value.splice(index, 1)
        setFileList(files);
        setFields(fieldsArray)
    }

    return (
        <InfoBlock>
            <Form
                layout="vertical"
                requiredMark={false}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
                onValuesChange={({ period_of_employment }) => { setValue(period_of_employment) }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label={'Тип работ'}
                    name="work_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип работ">
                        {work_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Указать период занятости'}
                    name="period_of_employment"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={false}>Нет</Radio>
                        <Radio value={true}>Да</Radio>
                    </Radio.Group>
                </Form.Item>
                {value &&
                    <div className='calendarBlock'>
                        <CustomDatePicker
                            ranges={busyPeriods}
                            mode="userCreateTechnic"
                        />
                    </div>
                }
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите фото (png, jpg)</p>
                    <div className={`${fileList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {fileList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index)} />
                            ))}
                        </div>
                        <Form.Item
                            name="files"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            className={`${fileList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {fileList.length === 0
                                ?
                                <Upload.Dragger
                                    name="files"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUpload}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить фото</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="files"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUpload}
                                    fileList={fileList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
        work_types: state.workTypes.work_types,
        other_info_files: state.createTechic.other_info_files,
        main_info_files: state.createTechic.main_info_files,
        main_info_filesPSM: state.createTechic.main_info_filesPSM,
        busyPeriods: state.createTechic.busyPeriods,
        coords: state.createTechic.coords
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
        SAVE_CREATE_TECHNIC_OTHER_FILES: (other_info_files) => dispatch({ type: SAVE_CREATE_TECHNIC_OTHER_FILES, other_info_files }),
        fetchCreateTechnic: (data) => dispatch(fetchCreateTechnic(data)),
        fetchUploadTechnicDocs: (id, data) => dispatch(fetchUploadTechnicDocs(id, data)),
        fetchMyData: () => dispatch(fetchMyData()),
        fetchCreateAddress: (data) => dispatch(fetchCreateAddress(data)),
        fetchCreateBusyPeriod: (data) => dispatch(fetchCreateBusyPeriod(data)),
        fetchUploadTechnicImage: (id, data) => dispatch(fetchUploadTechnicImage(id, data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(OtherInfo);