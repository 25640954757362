import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector, Clusterer, useYMaps } from '@pbe/react-yandex-maps';
import { fetchAllTechnics } from 'store/actions/market/technics';
import { fetchTechnicManufacturer, fetchTechnicModel } from 'store/actions/techics';
import { marketTechnicsFilter } from 'data/lists';
import { createQuery } from 'hooks/createQuery';
import TechnicCard from 'components/shared/cards/technicCard/TechnicCard';
import SecondTypeFilter from 'components/shared/secondTypeFilter/SecondTypeFilter';

import pin from 'assets/images/pins/combine.svg'
import whitePin from 'assets/images/pins/whiteCombine.svg'
import greyClose from 'assets/images/icons/close_grey.svg';

import '../../style.css'

const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 10,
    type: 'yandex#hybrid'
};

const coords = [[55.751574, 37.573856], [55.751574, 37.583856], [55.751574, 37.593856], [55.751574, 37.593956]]

function MarketAllTechics({ allTechnics, technic_types, work_types, technic_manufacturer, technic_model, fetchAllTechnics, fetchTechnicManufacturer, fetchTechnicModel }) {
    const [activePin, setActivePin] = useState([])
    const [ymaps, setYmaps] = useState(null);
    const [placemarks, setPlacemarks] = useState(null)
    const [lastPin, setLastPin] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (ymaps) {
            setPlacemarks(allTechnics.map((el, key) => (
                <Placemark
                    key={`pin_${key}`}
                    geometry={(el.location_latitude && el.location_longitude) ? [el.location_latitude, el.location_longitude] : [el.default_address.latitude, el.default_address.longitude]}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: whitePin,
                        iconImageSize: [70, 70],
                        iconContent: el
                    }}
                />
            )))
        }
    }, [ymaps, allTechnics])

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllTechnics(query.page, 100, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
            if (query.params) {
                fetchTechnicManufacturer('manufacturer', JSON.parse(decodeURIComponent(query.params)).manufacturer || '')
                fetchTechnicModel('model', JSON.parse(decodeURIComponent(query.params)).model || '')
            }
            setActivePin([])
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order,
        Object.fromEntries([...searchParams]).params
    ])

    const test = () => {
        if (ymaps) {
            const Layout = ymaps.templateLayoutFactory.createClass(
                `<div 
                style=" background: #F6F8F9; border-radius: 20px; color: #42A33A; font-size: 16px; font-weight: 700;  height: 40px; display: flex; flex-direction: row; justify-content: center; 
                align-items: center; margin-top: -40px"
                >
                {{ properties.geoObjects.length }}
                </div>`
            );

            return Layout;
        }
        return null;
    }

    const onClickCluster = (e) => {
        if (lastPin) {
            if (lastPin.type === 'cluster') {
                lastPin.target.options.set('clusterIcons', [
                    {
                        href: whitePin,
                        size: [70, 70],
                        offset: [-20, -20]
                    }
                ]);
            } else {
                lastPin.target.options.set('iconImageHref', whitePin);
            }
        }

        const target = e.get('target')
        if (target.getGeoObjects) {
            target.options.set('clusterIcons', [
                {
                    href: pin,
                    size: [70, 70],
                    offset: [-20, -20]
                }
            ]);
            setActivePin([...target.getGeoObjects()])
            setLastPin({
                type: 'cluster',
                target
            })
        } else {
            target.options.set('iconImageHref', pin);
            setActivePin([target])
            setLastPin({
                type: 'placemark',
                target
            })
        }
    }

    return (
        <>
            <div className='MarketAllTechics__block'>
                <div>
                    <div className='MarketFilterBlock'>
                        {marketTechnicsFilter(technic_types, work_types, technic_manufacturer, technic_model).map((el, key) => (
                            <SecondTypeFilter settings={el} key={`filter${key}`} />
                        ))}
                    </div>
                </div>
                <div className='MarketAllTechics__block__map'>
                    {activePin.length > 0 &&
                        <div className='MarketAllTechics__technics'>
                            <div className='MarketAllTechics__technics__header'>
                                <p>Количество: {activePin.length}</p>
                                <img src={greyClose} alt="greyClose" onClick={() => setActivePin([])} />
                            </div>
                            {activePin.map((el, key) => (
                                <TechnicCard
                                    key={`technicCard_${key}`}
                                    item={el.options['_options'].iconContent}
                                />
                            ))}
                        </div>
                    }
                    {coords.length > 0 &&
                        <Map
                            defaultState={defaultState}
                            onLoad={ymaps => setYmaps(ymaps)}
                            width='100%'
                            height='100%'
                            modules={[
                                'templateLayoutFactory',
                                'geoObject.addon.balloon',
                                'clusterer.addon.balloon',
                            ]}
                            options={{
                                autoFitToViewport: 'always'
                            }}
                        >
                            <FullscreenControl />
                            <SearchControl options={{ float: "right" }} />
                            <GeolocationControl options={{ float: "left" }} />
                            <TypeSelector options={{ float: "right" }} />
                            <Clusterer
                                options={{
                                    groupByCoordinates: false,
                                    clusterIcons: [
                                        {
                                            href: whitePin,
                                            size: [70, 70],
                                            offset: [-20, -20]
                                        }
                                    ],
                                    clusterIconContentLayout: test(),
                                    clusterDisableClickZoom: true,
                                    clusterHideIconOnBalloonOpen: false,
                                    hasBalloon: false,
                                }}
                                onClick={(e) => onClickCluster(e)}
                            >
                                {placemarks}
                            </Clusterer>
                        </Map>
                    }
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        allTechnics: state.marketAllTechnics.technics,
        technic_types: state.technicTypes.technic_types,
        work_types: state.workTypes.work_types,
        technic_manufacturer: state.technicParams.technic_manufacturer,
        technic_model: state.technicParams.technic_models,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllTechnics: (page, size, params) => dispatch(fetchAllTechnics(page, size, params)),
        fetchTechnicManufacturer: (param, value) => dispatch(fetchTechnicManufacturer(param, value)),
        fetchTechnicModel: (param, value) => dispatch(fetchTechnicModel(param, value))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MarketAllTechics);