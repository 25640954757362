import { connect } from 'react-redux'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { fetchDeleteSquare, fetchSquareByID } from 'store/actions/square';
import { CLEAR_SQUARE_BY_ID_DATA } from 'store/actionTypes';
import { fieldByIdSwitch } from 'data/lists';
import { fieldBreadcrumb } from 'data/brebcrumb';
import { warningNotification } from 'hooks/notifications';
import PlotsAndSeasons from './plotsAndSeasons/PlotsAndSeasons';
import MainInfo from './mainInfo/MainInfo';
import Docs from './docs/Docs';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import Switch from 'components/shared/switch/Switch';
import Verification from './varification/Verification';

import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';

function FieldById({ fetchSquareByID, fetchDeleteSquare, square_by_id_data, CLEAR_SQUARE_BY_ID_DATA }) {
    const location = useLocation()
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState(location.state ? (location.state.step || 'mainInfo') : 'mainInfo')
    const [editMainInfo, setEditMainInfo] = useState(location.state ? location.state.edit : false)
    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    useEffect(() => {
        fetchSquareByID(id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_SQUARE_BY_ID_DATA()
        }
    }, [])

    return (
        square_by_id_data &&
        <div className='allUsersBlock'>
            {contextHolder}
            <Breadcrumb list={fieldBreadcrumb} activeLink={`Поле ${square_by_id_data.number}`} />
            <div className='userProfile_header'>
                <TextHeading text={`Поле ${square_by_id_data.number}`} />
                {activeSwitch === 'mainInfo'
                    ?
                    editMainInfo
                        ?
                        <div className='userProfile_header__actions'>
                            <SimpleButton
                                title={'Отмена'}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500,
                                    marginRight: 16
                                }}
                                onClick={() => setEditMainInfo(false)}
                            />
                            <SimpleButton
                                title={'Сохранить'}
                                style={{
                                    color: '#FFF',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#01963A',
                                    fontWeight: 500
                                }}
                                onClick={() => {
                                    form.submit()
                                }}
                            />
                        </div>
                        :
                        <div className='userProfile_header__actions'>
                            <div className='userProfile_header__actions__edit' onClick={() => setEditMainInfo(true)}>
                                <img src={edit_green_pen} alt="edit_green_pen" />
                                <p className='activeText p14_500'>Редактировать</p>
                            </div>
                            <SimpleButton
                                title={'Удалить'}
                                style={{
                                    color: '#D32F2F',
                                    border: '1px solid #D32F2F',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500
                                }}
                                onClick={() => warningNotification(api, async () => {
                                    await fetchDeleteSquare(id)
                                    navigate(`/user/my-fields/`)
                                    api.destroy()
                                }, 'удаление поля.')}
                            />
                        </div>
                    :
                    <SimpleButton
                        title={'Удалить'}
                        style={{
                            color: '#D32F2F',
                            border: '1px solid #D32F2F',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500
                        }}
                        onClick={() => warningNotification(api, async () => {
                            await fetchDeleteSquare(id)
                            navigate(`/user/my-fields/`)
                            api.destroy()
                        }, 'удаление поля.')}
                    />
                }
            </div>
            <Switch
                items={fieldByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo form={form} editMainInfo={editMainInfo} setEditMainInfo={() => setEditMainInfo(false)} />}
            {activeSwitch === 'plotsAndSeasons' && <PlotsAndSeasons />}
            {activeSwitch === 'docs' && <Docs />}
            {activeSwitch === 'verification' && <Verification />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        square_by_id_data: state.squareById.square_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
        fetchDeleteSquare: (id) => dispatch(fetchDeleteSquare(id)),
        CLEAR_SQUARE_BY_ID_DATA: () => dispatch({ type: CLEAR_SQUARE_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(FieldById);