import { ApiUrl, instance } from './apiEnv';

export const api_replies = {

    async GetMyReplies(page, size, params) {
        return await instance.get(`${ApiUrl}replies/my?page=${page}&size=${size}&${params}`)
    },

    async GetRepliesById(id) {
        return await instance.get(`${ApiUrl}replies/${id}`)
    },
    
    async PostCreateReplies(data) {
        return await instance.post(`${ApiUrl}replies`, data)
    },

    async PutAddTechnicsToReplies(replay_id, data) {
        return await instance.put(`${ApiUrl}replies/${replay_id}/add_technics`, data)
    },

    async PutAddPlotsToReplies(replay_id, data) {
        return await instance.put(`${ApiUrl}replies/${replay_id}/add_plots`, data)
    },
    
}