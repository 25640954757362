import {
    FETCH_USER_DATA_SQUARES_SUCCESS,
} from '../../actionTypes'

const initialState = {
    squares: null,
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        // case FETCH_ALL_SQUARES_START:
        //     return {
        //         ...state, loading: true
        //     }
        case FETCH_USER_DATA_SQUARES_SUCCESS:
            return {
                squares: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                loading: false
            }
        default: return state
    }
}