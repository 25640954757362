import { useEffect } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { DELETE_CREATE_SQUARE_SEASONS, SAVE_CREATE_SQUARE_EDIT_SEASONS, SAVE_CREATE_SQUARE_SEASONS, SET_VISIBLE_MODAL } from 'store/actionTypes';
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import SeasonCollapse from 'components/shared/seasonCollapse/SeasonCollapse';

import './style.css';

function Seasons({ seasons, plots, SAVE_CREATE_SQUARE_SEASONS, setVisible, DELETE_CREATE_SQUARE_SEASONS, SAVE_CREATE_SQUARE_EDIT_SEASONS }) {
    const navigate = useNavigate()

    useEffect(() => {
        if (seasons.length === 0) {
            SAVE_CREATE_SQUARE_SEASONS({
                plots,
                title: 'По-умолчанию (доступы во всех сезонах)',
                being_deleted: false,
                year: 0
            })
        }
    }, [])

    const addPlotOnSeasons = (seasonIndex, addedData) => {
        let array = JSON.parse(JSON.stringify(seasons))
        array[seasonIndex].plots = [...array[seasonIndex].plots, ...addedData]
        SAVE_CREATE_SQUARE_EDIT_SEASONS(array)
    }

    const editPlotOnSeason = (seasonIndex, seasonPlotIndex, crop_type_id, crop_type_name) => {
        let array = JSON.parse(JSON.stringify(seasons))
        let obj = array[seasonIndex].plots[seasonPlotIndex]
        obj.crop_type_id = crop_type_id
        obj.crop_type_name = crop_type_name
        SAVE_CREATE_SQUARE_EDIT_SEASONS(array)
    }

    const deletePlotOnSeason = (seasonIndex, seasonPlotIndex) => {
        let array = JSON.parse(JSON.stringify(seasons))
        let arrayPlots = array[seasonIndex].plots
        arrayPlots.splice(seasonPlotIndex, 1)
        SAVE_CREATE_SQUARE_EDIT_SEASONS(array)
    }

    return (
        <>
            <div className='seasonCollapseBlock'>
                {seasons.map((el, key) => (
                    <SeasonCollapse
                        key={`season_${key}`}
                        item={el}
                        index={key}
                        seasons={seasons}
                        deleteSeason={DELETE_CREATE_SQUARE_SEASONS}
                        deletePlotOnSeason={deletePlotOnSeason}
                        editPlotOnSeason={editPlotOnSeason}
                        addPlotOnSeasons={addPlotOnSeasons}
                        linkOnCreatPlot={() => navigate(`/user/my-fields/create-field/region/create`, { state: { step: 3 } })}
                    />
                ))}
            </div>
            <SimpleButton
                title={'Добавить сезон'}
                style={{
                    marginTop: 20,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => setVisible({
                    seasons,
                    plots,
                    page: 'createSquare'
                })}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        seasons: state.createSquare.seasons,
        plots: state.createSquare.plots,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_SQUARE_SEASONS: (seasons) => dispatch({ type: SAVE_CREATE_SQUARE_SEASONS, seasons }),
        SAVE_CREATE_SQUARE_EDIT_SEASONS: (editSeasons) => dispatch({ type: SAVE_CREATE_SQUARE_EDIT_SEASONS, editSeasons }),
        DELETE_CREATE_SQUARE_SEASONS: (index) => dispatch({ type: DELETE_CREATE_SQUARE_SEASONS, index }),
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'createSeason', data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Seasons);