import { Link } from 'react-router-dom';
import { footerList } from 'data/routings';

import './style.css';
import 'components/layout/media.css';

function Footer() {
    return (
        <div className="footer">
            {footerList.map((item, key) => (
                <Link to={item.to} key={`footer_link${key}`} className="footer_link">{item.title}</Link>
            ))}
        </div>
    );
}

export default Footer;