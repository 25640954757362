import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import { fetchRepeatContract } from 'store/actions/contractById';
import { repeatContractBreadcrumb } from 'data/brebcrumb';
import { successNotification } from 'hooks/notifications';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import RepeatContractForm from 'components/components/forms/contract/repeatContract/RepeatContractForm';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function RepeatContract({ fetchRepeatContract }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const [form] = Form.useForm();

    const reject = async (values) => {
        fetchRepeatContract(id, values)
        .then(res => {
            if(res){
                successNotification('Контракт повторен!', 'Контракт успешно повторен')
                navigate(`/user/my-contracts/${res}`)
            }
        })
    }

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={repeatContractBreadcrumb(id)} activeLink={'Повторить заявку'} />
            <div className='userProfile_header'>
                <TextHeading text={'Повторить контракт'} />
                <div className='userProfile_header__actions'>
                    <SimpleButton
                        title={'Отмена'}
                        style={{
                            color: '#01963A',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500,
                            marginRight: 16
                        }}
                        onClick={() => navigate(`/user/my-contracts/${id}`)}
                    />
                    <SimpleButton
                        title={'Сохранить'}
                        style={{
                            color: '#FFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500
                        }}
                        onClick={() => form.submit()}
                    />
                </div>
            </div>
            <RepeatContractForm form={form} reject={(values) => reject(values)} />
        </div>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchRepeatContract: (contract_id, data) => dispatch(fetchRepeatContract(contract_id, data))
    }
}

export default connect(null, mapDispatvhToProps)(RepeatContract);