import NoAuthLayout from "components/layout/noAuth/NoAuthLayout";

import EnterCode from "views/user/authentication/enterCode/EnterCode";
import Login from "views/user/authentication/login/Login";
import LoginAdmin from "views/admin/autentification/login/Login";
import NewPassword from "views/user/authentication/newPassword/NewPassword";
import PasswordRecovery from "views/user/authentication/passwordRecovery/PasswordRecovery";
import RulesPage from "views/user/rulesPage/RulesPage";
import FirstStageSignup from "views/user/authentication/signup/firstStage/FirstStageSignup";
import EnterCodeSignUp from "views/user/authentication/signup/firstStage/EnterCode";
import SecondStageSignup from "views/user/authentication/signup/secondStage/SecondStageSignup";
import SpecifiedDataSignup from "views/user/authentication/signup/secondStage/SpecifiedDataSignup";
import SpecifiedDataLegalPersonSignup from "views/user/authentication/signup/secondStage/SpecifiedDataLegalPersonSignup";
import SignupGreate from "views/user/authentication/signup/greate/SignupGreate";
import MarketAllTechics from "views/market/technics/allTechics/AllTechics";
import MarketTechnicById from "views/market/technics/techicById/MarketTechnicById";
import MarketAllSquares from "views/market/squares/allSquareas/MarketAllSquares";
import MarketSquareById from "views/market/squares/squareById/MarketSquareById";
import MarketPlotById from "views/market/squares/plotById/MarketPlotById";
import TechnicByIdCreateReply from "views/market/technics/technicByIdCreateReply/TechnicByIdCreateReply";
import UserById from "views/market/users/userById/UserById";
import MainPage from "views/public/MainPage";

export const noAuthRoutings = [
    {
        path: '/',
        element: <NoAuthLayout />,
        navigate: '/',
        children: [
            {
                path: '',
                index: true,
                element: <MainPage />
            },
            {
                path: 'login',
                index: false,
                element: <Login />
            },
            {
                path: 'signup/first-stage',
                index: false,
                element: <FirstStageSignup />
            },
            {
                path: 'signup/first-stage/enter-code',
                index: false,
                element: <EnterCodeSignUp />
            },
            {
                path: 'signup/second-stage',
                index: false,
                element: <SecondStageSignup />
            },
            {
                path: 'signup/second-stage/specified-data',
                index: false,
                element: <SpecifiedDataSignup />
            },
            {
                path: 'signup/second-stage/specified-data-legal-person',
                index: false,
                element: <SpecifiedDataLegalPersonSignup />
            },
            {
                path: 'signup/complite',
                index: false,
                element: <SignupGreate />
            },
            {
                path: 'password-recovery',
                index: false,
                element: <PasswordRecovery />
            },
            {
                path: 'enter-code',
                index: false,
                element: <EnterCode />
            },
            {
                path: 'reset_password',
                index: false,
                element: <NewPassword />
            },
            {
                path: 'rules/:page',
                index: false,
                element: <RulesPage />
            },
            {
                path: 'admin/login',
                index: false,
                element: <LoginAdmin />
            },
        ]
    },
    {
        path: '/market/',
        element: <NoAuthLayout />,
        children: [
            {
                path: 'technics',
                index: false,
                element: <div className="userLayout_contentBlock" style={{width: '100%'}}><MarketAllTechics /></div>
            },
            {
                path: 'technics/:id',
                index: false,
                element: <MarketTechnicById />
            },
            {
                path: 'technics/:id/create-reply',
                index: false,
                element: <TechnicByIdCreateReply />
            },
            {
                path: 'fields',
                index: false,
                element: <div className="userLayout_contentBlock" style={{width: '100%'}}><MarketAllSquares /></div>
            },
            {
                path: 'fields/:id',
                index: false,
                element: <MarketSquareById />
            },
            {
                path: 'fields/:square_id/plots/:plot_id',
                index: false,
                element: <MarketPlotById />
            }
        ]
    },
    {
        path: '/public/',
        element: <NoAuthLayout />,
        children: [
            {
                path: 'user/:id',
                index: false,
                element: <UserById />
            },
        ]
    },
]
