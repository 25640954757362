import CloseDealModal from 'components/components/forms/modal/closeDeal/CloseDealModal';
import { connect } from 'react-redux'
import { CLOSE_MODAL } from 'store/actionTypes';

function CloseDeal({setVisible, setBack}) {

    const reject = () => {
        setVisible()
    }

    return (
        <>
            <p className="mainText p24_600 p_mb24" style={{width: 300, textAlign: 'center'}}>Подтвердите закрытие</p>
            <CloseDealModal />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL}),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CloseDeal);