import { api_deals } from 'api/deals'
import {
    FETCH_USER_DATA_DEALS_SUCCESS,
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchMyDeals(page, size, params) {
    return async dispatch => {
        try {
            const deals_data = await api_deals.GetMyDeals(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_DEALS_SUCCESS, {
                data: deals_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchDealById(id) {
    return async dispatch => {
        try {
            const deal_data = await api_deals.GetDealsById(id)
            return deal_data.data.data
        } catch (e) {
            return false
        }
    }
}

export function fetchUpdateDealById(deal_id, data) {
    return async dispatch => {
        try {
            await api_deals.PutUpdateDeal(deal_id, data)
        } catch (e) {
        }
    }
}

export function fetchCreateDeals(data) {
    return async dispatch => {
        try {
            const deal = await api_deals.PostCreateDeals(data)
            return deal.data.data.id
        } catch (e) {
            return false
        }
    }
}

export function fetchAddTechnicToDeals(deal_id, data) {
    return async dispatch => {
        try {
            await api_deals.PutAddTechnicsToDeals(deal_id, data)
        } catch (e) {
        }
    }
}

export function fetchAddPlotsDeals(deal_id, data) {
    return async dispatch => {
        try {
            await api_deals.PutAddPlotsToDeals(deal_id, data)
        } catch (e) {
        }
    }
}

export function fetchMakeContract(deal_id, data) {
    return async dispatch => {
        try {
            const contracts_data = await api_deals.PostMakeContract(deal_id, data)
            return contracts_data.data.data.id
        } catch (e) {
            return false
        }
    }
}