import { Link, NavLink } from 'react-router-dom';
import { headerList } from 'data/routings';
import HeaderLogo from 'assets/images/headerLogo.svg';

import 'components/layout/style.css';
import 'components/layout/media.css';

function Header() {
    return (
        <div className="header">
            <Link to={'/'}><img src={HeaderLogo} alt="HeaderLogo" /></Link>
            {headerList.map((item, key) => (
                <NavLink to={item.to} key={`headerLink_${key}`} className={({ isActive }) => isActive ? 'header_link active-header-link' : 'header_link'}>{item.title}</NavLink>
            ))}
        </div>
    );
}

export default Header;
