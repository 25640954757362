import { NavLink } from 'react-router-dom';

import './style.css';
import './media.css';

function FullPageMenu({ list }) {
    return (
        <div className='fullPageMenu'>
            {list.map((item, key) => (
                <NavLink
                    key={`rulesLink_${key}`}
                    to={item.to}
                    className={({ isActive }) => isActive ? 'rules-link mainText active-link' : 'rules-link mainText'}
                >
                    {item.title}
                </NavLink>
            ))}
        </div>
    );
}

export default FullPageMenu;