import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { CLEAR_PUBLIC_USER_DATA } from 'store/actionTypes';
import { fetchPublicUserById } from 'store/actions/public/publicUser';
import { publicUserSwitch } from 'data/lists';
import { formatDate } from 'hooks/formatDate';
import MainInfo from './mainInfo/MainInfo';
import Switch from 'components/shared/switch/Switch';
import Technics from './technics/Technics';
import Squares from './squares/Squares';
import Replies from './replies/Replies';
import Reviews from './reviews/Reviews';

import calendar from 'assets/images/icons/calendar.svg';

function UserById({ public_user_by_id, fetchPublicUserById, CLEAR_PUBLIC_USER_DATA}) {
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')
    const { id } = useParams()

    useEffect(() => {
        fetchPublicUserById(id)
    }, [])


    useEffect(() => {
        return () => {
            CLEAR_PUBLIC_USER_DATA()
        }
    }, [])

    return (
        public_user_by_id &&
        <div className='marketDetalPageWithoutFlex' style={{flex: 1}}>
            <div className='userProfile_header'>
                <div className='userProfile_header__info'>
                    {public_user_by_id.image
                        ?
                        <div className='userProfile_header__info__avatar__photo' style={{ backgroundImage: `url(${public_user_by_id.image.media.link})` }} />
                        :
                        <div className='userProfile_header__info__avatar p14_500'>{public_user_by_id.first_name[0].toUpperCase() + public_user_by_id.last_name[0].toUpperCase()}</div>
                    }
                    <p className='p24_600 mainText' style={{ textAlign: 'center' }}>{public_user_by_id.first_name + ' ' + public_user_by_id.last_name + ' ' + public_user_by_id.third_name}</p>
                </div>
            </div>
            <div className='flexBlock'>
                <img src={calendar} alt="calendar" style={{ marginRight: 12 }} />
                <p className='mainText p14_400'>На сайте с {formatDate(public_user_by_id.created_at)}</p>
            </div>
            <Switch
                items={publicUserSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo />}
            {activeSwitch === 'technics' && <Technics />}
            {activeSwitch === 'squares' && <Squares />}
            {activeSwitch === 'replies' && <Replies />}
            {activeSwitch === 'reviews' && <Reviews />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        public_user_by_id: state.publicUser.public_user_by_id,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchPublicUserById: (id) => dispatch(fetchPublicUserById(id)),
        CLEAR_PUBLIC_USER_DATA: () => dispatch({ type: CLEAR_PUBLIC_USER_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(UserById);