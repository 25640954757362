import './style.css';
import './media.css';

function InfoBlock({ children, ...props }) {
    return (
        <div
            className='infoBlock'
            {...props}
        >
            {children}
        </div>
    );
}

export default InfoBlock;