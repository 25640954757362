import { api_complaints } from 'api/complains'
import { successNotification } from 'hooks/notifications'

export function fetchCreateComplaint(data) {
    return async dispatch => {
        try {
            await api_complaints.PostCreateComplaint(data)
            successNotification('Жалоба создана', 'Ваша жалаба успешно создана!')
        } catch (e) {
        }
    }
}