import { api_square_types } from 'api/squareTypes';
import {
    FETCH_SQUARE_TYPES_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchSquareTypes() {
    return async dispatch => {
        try {
            const square_types = await api_square_types.GetSquareTypes()
            dispatch(fetchSuccess(FETCH_SQUARE_TYPES_SUCCESS, {
                square_types: square_types.data.data.items
            }))
        } catch (e) {
        }
    }
}