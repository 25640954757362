import { useRef } from "react";
import { connect } from 'react-redux';
import { notification } from "antd";
import { SAVE_CREATE_SQUARE_DOCS, SAVE_CREATE_SQUARE_OWNER_FILES } from "store/actionTypes";
import { createFieldDocsTable } from "data/tableHeader";
import { createFieldDocsTableWidth } from "data/tableWidth";
import { warningNotification } from "hooks/notifications";
import { formatDate } from "hooks/formatDate";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Table from "components/ui/table/Table";

import deleteIcon from 'assets/images/icons/delete.svg';

function Docs({ docs, main_info_files_isOwner, SAVE_CREATE_SQUARE_DOCS, SAVE_CREATE_SQUARE_OWNER_FILES }) {
    const fileInputRef = useRef();
    const [api, contextHolder] = notification.useNotification();

    const createTableData = () => {
        const array = []
        docs.map((el, key) => {
            array.push([
                {
                    type: 'text',
                    content: 'Документ'
                },
                {
                    type: 'active_text',
                    content: el.name
                },
                {
                    type: 'text',
                    content: formatDate(el.lastModifiedDate)
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                deleteFiles(key)
                                api.destroy()
                            }, 'удаление документа.')}
                        />
                    </div>
                },
            ])
        })
        main_info_files_isOwner.map((el, key) => {
            array.push([
                {
                    type: 'text',
                    content: 'Документ'
                },
                {
                    type: 'active_text',
                    content: el.name
                },
                {
                    type: 'text',
                    content: formatDate(el.lastModifiedDate)
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                deleteOwnerFiles(key)
                                api.destroy()
                            }, 'удаление документа.')}
                        />
                    </div>
                },
            ])
        })
        return array
    }

    const deleteFiles = (index) => {
        let array = [...docs]
        array.splice(index, 1)
        SAVE_CREATE_SQUARE_DOCS(array)
    }

    const deleteOwnerFiles = (index) => {
        let array = [...main_info_files_isOwner]
        array.splice(index, 1)
        SAVE_CREATE_SQUARE_OWNER_FILES(array)
    }

    const handleChangeFiles = (event) => {
        SAVE_CREATE_SQUARE_DOCS([...docs, event.target.files[0]]);
        fileInputRef.current.value = null
    }

    return (
        <>
            {contextHolder}
            <Table
                header={createFieldDocsTable}
                width={createFieldDocsTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Добавить документ'}
                style={{
                    marginTop: 20,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => fileInputRef.current.click()}
            />
            <input onChange={(e) => handleChangeFiles(e)} multiple={false} ref={fileInputRef} type='file' accept='.png, .jpg, .doc, .docx, .pdf, .word' hidden />
        </>
    );
}

function mapStateToProps(state) {
    return {
        docs: state.createSquare.docs,
        main_info_files_isOwner: state.createSquare.main_info_files_isOwner,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_SQUARE_DOCS: (docs) => dispatch({ type: SAVE_CREATE_SQUARE_DOCS, docs }),
        SAVE_CREATE_SQUARE_OWNER_FILES: (files) => dispatch({type: SAVE_CREATE_SQUARE_OWNER_FILES, files})
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Docs);