import { errorNotification } from 'hooks/notifications';
import { ApiUrl, instance } from '../apiEnv';

export const api_user_admin = {

    async CreateUser(data) {
        return await instance.post(`${ApiUrl}users`, data)
            .then(res => {
                return res.data
            })
            .catch(error => {
                if (error.response) {
                    errorNotification('Пользователь с таким email/номером телефона уже существует', null)
                    return error.response.data.detail
                } else {
                    return 'Error: Network Error'
                }
            })
    },

    async UploadUserDocs(user_id, data) {
        return await instance.put(`${ApiUrl}users/${user_id}/document`, data)
    },

    async DeleteUserDocs(user_id, doc_id) {
        return await instance.delete(`${ApiUrl}users/${user_id}/document/${doc_id}`)
    },

    async ConfirmUserVerificstion(user_id, verification_id) {
        return await instance.put(`${ApiUrl}users/${user_id}/confirm/${verification_id}`)
    },

    async RejectUserVerificstion(user_id, verification_id) {
        return await instance.put(`${ApiUrl}users/${user_id}/reject/${verification_id}`)
    },

    async UpdateUser(data, user_id) {
        return await instance.put(`${ApiUrl}users/${user_id}`, data)
    },

    async UpdateUserImg(data, user_id) {
        return await instance.put(`${ApiUrl}users/${user_id}/image`, data)
    },

    async UpdateUserRole(user_id, role_name) {
        return await instance.put(`${ApiUrl}users/${user_id}/update_role?role_name=${role_name}`)
    },

}