import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Form, Radio, Checkbox } from 'antd';
import { SAVE_CREATE_APPLICATION_DATA } from 'store/actionTypes';
import { fetchMySquares, fetchSquareByID } from 'store/actions/square';
import { createReplyByTechnicTable } from 'data/tableHeader';
import { createReplyByTechnicTableWidth } from 'data/tableWidth';
import { fieldByIdPlotsAndSeasonsSwitch } from 'data/lists';
import { errorNotification } from 'hooks/notifications';
import NoDataBlock from 'components/ui/noDataBlock/NoDataBlock';
import Table from 'components/ui/table/Table';
import Pagination from 'components/shared/pagination/Pagination';
import Switch from 'components/shared/switch/Switch';
import SeasonCollapse from 'components/shared/seasonCollapse/SeasonCollapse';
import SeasonCollapseItem from 'components/shared/seasonCollapse/SeasonCollapseItem';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import field from 'assets/images/messageIcons/field.svg';

function ApplicationSquareData({ form, nextStep, create_application, fetchMySquares, user_data_squares, pages, fetchSquareByID, square_by_id_data, SAVE_CREATE_APPLICATION_DATA }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [activeSwitch, setActiveSwitch] = useState('plots')
    const [fields, setFields] = useState([
        {
            name: 'square',
            value: create_application.square_data,
        },
        {
            name: 'plots',
            value: create_application.plots,
        },
    ]);

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMySquares(query.page, 50, '')
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
    ])


    const createTableData = () => {
        return user_data_squares.map(el => (
            [
                {
                    type: 'text',
                    content: el.number
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
                {
                    type: 'text',
                    content: el.square_type.name
                },
                {
                    type: 'text',
                    content: el.size
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <Radio value={el.id} />
                    </div>
                },
            ]
        ))
    }

    const onFinish = ({ square, plots }) => {
        if (plots) {
            if (plots.length > 0) {
                let obj = { ...create_application }
                obj.square_data = square
                obj.plots = plots
                SAVE_CREATE_APPLICATION_DATA(obj)
                nextStep()
            } else {
                errorNotification('Вы не выбрали участки')
            }
        } else {
            errorNotification('Вы не выбрали участки')
        }
    };

    const onChangeSquare = (e) => {
        const fieldsCopy = JSON.parse(JSON.stringify(fields))
        fieldsCopy[0].value = e
        fieldsCopy[1].value = []
        setFields(fieldsCopy)
        fetchSquareByID(e)
    }

    return (
        user_data_squares &&
        (user_data_squares.length > 0
            ?
            <Form
                layout="vertical"
                requiredMark={false}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name="square"
                    label="Выберите поле"
                >
                    <Radio.Group
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            onChangeSquare(e.target.value)
                        }}
                    >
                        <Table
                            header={createReplyByTechnicTable}
                            width={createReplyByTechnicTableWidth}
                            data={createTableData()}
                        />
                        {pages > 1 && <Pagination pages={pages} />}
                    </Radio.Group>
                </Form.Item>
                {
                    square_by_id_data && (
                        square_by_id_data.plots.length > 0
                            ?
                            <Form.Item
                                name="plots"
                                label="Выберите участок"
                            >
                                <Checkbox.Group style={{ width: '100%', display: 'block' }}>
                                    <Switch
                                        items={fieldByIdPlotsAndSeasonsSwitch}
                                        activeItem={activeSwitch}
                                        onSwitch={setActiveSwitch}
                                        style={{
                                            marginBottom: 24
                                        }}
                                        className="switch miniSwitch"
                                    />
                                    <div className='scrollContent'>
                                        {activeSwitch === 'plots'
                                            ? square_by_id_data.plots.map(el => (
                                                <SeasonCollapseItem
                                                    key={`seasonCollapsItem_${el.id}`}
                                                    item={el}
                                                    page={'check'}
                                                />
                                            ))
                                            : square_by_id_data.seasons.map(el => (
                                                <SeasonCollapse
                                                    key={`season_${el.id}`}
                                                    item={el}
                                                    index={el.id}
                                                    page={'check'}
                                                />
                                            ))
                                        }
                                    </div>
                                </Checkbox.Group>
                            </Form.Item>
                            :
                            <InfoBlock style={{ textAlign: 'center' }}>
                                <img src={field} alt="field" />
                                <p className='mainText p24_600 p_mb24'>Нет участков</p>
                                <p className='mainText p14_400 p_mb24'>На данный момент у нас нет информации о ваших участков по выбранному полю, чтобы внести ее - нажмите на кнопку ниже.</p>
                                <SimpleButton
                                    title={'Добавить участки'}
                                    className="simpleButton greenButton"
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                    onClick={() => navigate(`/user/my-fields/${square_by_id_data.id}`)}
                                />
                            </InfoBlock>
                    )
                }
            </Form >
            :
            <NoDataBlock
                img={field}
                title={'Нет информации о полях'}
                description={'На данный момент у нас нет информации о ваших полях, чтобы внести ее - нажмите на кнопку ниже.'}
                buttonTitle={['Добавить поле']}
                onClick={[() => navigate(`/user/my-fields/full-create`)]}
                style={{ position: 'relative' }}
            />
        )
    );
}

function mapStateToProps(state) {
    return {
        create_application: state.createApplication,
        user_data_squares: state.userSquares.squares,
        square_by_id_data: state.squareById.square_by_id_data,
        pages: state.userSquares.pages,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_APPLICATION_DATA: (data) => dispatch({ type: SAVE_CREATE_APPLICATION_DATA, create_application: data }),
        fetchMySquares: (page, size, params) => dispatch(fetchMySquares(page, size, params)),
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ApplicationSquareData);