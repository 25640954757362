import {
    FETCH_SQUARE_BY_ID_DATA_START,
    FETCH_SQUARE_BY_ID_DATA_SUCCESS,
    CLEAR_SQUARE_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    square_by_id_data: null,
    square_seasons: []
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SQUARE_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_SQUARE_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                square_by_id_data: action.data.square_by_id_data,
                square_seasons: action.data.seasons
            }
        case CLEAR_SQUARE_BY_ID_DATA:
            return {
                square_by_id_data: null,
                square_seasons: []
            }
        default: return state
    }
}