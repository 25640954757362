import {
    SIGNUP_FIRST_STAGE_SAVE_DATA,
    SIGNUP_SECOND_STAGE_SAVE_COMPANY_DATA
} from '../actionTypes'

const initialState = {
    token: null,
    user_data: null,
    select_company_data: null,
    password: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case SIGNUP_FIRST_STAGE_SAVE_DATA:
            return {
                ...state,
                token: action.data.token,
                user_data: action.data.user_data,
                password: action.data.password
            }
        case SIGNUP_SECOND_STAGE_SAVE_COMPANY_DATA:
            return {
                ...state,
                select_company_data: action.data.select_company_data
            }
        default: return state
    }
}