import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { FullscreenControl, GeolocationControl, Map, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { CLOSE_MODAL } from 'store/actionTypes';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import pin from 'assets/images/pins/field.svg';
import whitePin from 'assets/images/pins/whiteField.svg'

function PlotsInfo({ data, setVisible }) {
    const [activePin, setActivePin] = useState(null)
    const [plotsMap, setPlotsMap] = useState(null)

    useEffect(() => {
        if (data) {
            setPlotsMap(
                <Map
                    defaultState={{
                        center: [data.plots[0].address.latitude, data.plots[0].address.longitude],
                        zoom: 18,
                        type: 'yandex#hybrid'
                    }}
                    width='100%'
                    height='100%'
                >
                    <FullscreenControl />
                    <GeolocationControl options={{ float: "left" }} />
                    <TypeSelector options={{ float: 'right' }} />
                    {data.plots.map((el) => (
                        <Placemark
                            geometry={[el.address.latitude, el.address.longitude]}
                            key={`pin_${el.id}`}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: (el.id === activePin ? whitePin : pin),
                                iconImageSize: [70, 70],
                                // iconImageOffset: [-5, -38]
                            }}
                            onClick={() => setActivePin(el.id)}
                        />
                    ))}
                </Map>
            )
        }
    }, [data.plots, activePin])

    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center', width: '50vw' }}>Участки</p>
            {data.plots.map(el => (
                <InfoBlock
                    style={{
                        marginBottom: 20,
                        marginTop: 20,
                        borderWidth: el.id === activePin ? 2 : 1,
                        borderColor: el.id === activePin ? '#b9b9b9' : '#E4E4E4',
                        cursor: 'pointer'
                    }}
                    className="infoBlock twoRow"
                    onClick={() => setActivePin(el.id)}
                >
                    <div style={{ width: '100%' }}>
                        <p className='p12_400 mainText p_mb8'>Номер</p>
                        <p className='p14_400 mainText p_mb16'>{el.number}</p>
                        <p className='p12_400 mainText p_mb8'>Фактический посев, Га</p>
                        <p className='p14_400 mainText'>{el.fact_sowing}</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <p className='p12_400 mainText p_mb8'>Порядковый номер</p>
                        <p className='p14_400 mainText p_mb16'>{el.serial}</p>
                        <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                        <p className='p14_400 mainText'>{el.crop_type.name}</p>
                    </div>
                </InfoBlock>
            ))}
            <div className='locationBlock_map' style={{ height: '300px', width: '100%' }}>
                {plotsMap}
            </div>
            <p className='activeText p14_500 p_mt24' style={{ textAlign: 'center', cursor: 'pointer' }} onClick={setVisible}>Отменить</p>
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(PlotsInfo);