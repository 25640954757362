export const userBreadcrumb = [
    {
        title: 'Пользователи',
        to: -1
    }
]

export const adminUserCarsBreadcrumb = (user_id) => ([
    {
        title: 'Пользователь',
        to: `/admin/users/${user_id}`
    },
    {
        title: 'Техника',
        to: `/admin/users/${user_id}`
    },
])


export const adminCarBreadcrumb = [
    {
        title: 'Вся техника',
        to: -1
    }
]

export const carBreadcrumb = [
    {
        title: 'Моя техника',
        to: -1
    }
]

export const carFromReplyBreadcrumb = [
    {
        title: 'Предложение',
        to: -1
    }
]

export const carFromCreateApplicationBreadcrumb = [
    {
        title: 'Создание заявки',
        to: -1
    }
]

export const fieldBreadcrumb = [
    {
        title: 'Мои поля',
        to: -1
    }
]

export const contractBreadcrumb = [
    {
        title: 'Мои контракты',
        to: -1
    }
]

export const repeatContractBreadcrumb = (id) => ([
    {
        title: 'Мои контракты',
        to: '/user/my-contracts'
    },
    {
        title: 'Контракт',
        to: `/user/my-contracts/${id}`
    }
])

export const terminationContractBreadcrumb = (id) => ([
    {
        title: 'Мои контракты',
        to: '/user/my-contracts'
    },
    {
        title: 'Контракт',
        to: `/user/my-contracts/${id}`
    }
])

export const adminFieldBreadcrumb = [
    {
        title: 'Поля',
        to: -1
    }
]

export const createFieldBreadcrumb = [
    {
        title: 'Мои поля',
        to: -1
    }
]

export const applicationBreadcrumb = [
    {
        title: 'Заявки',
        to: '/user/my-applications'
    }
]

export const notificationBreadcrumb = [
    {
        title: 'Уведомления',
        to: -1
    }
]

export const conflictBreadcrumb = [
    {
        title: 'Мои споры',
        to: -1
    }
]

export const adminDealBreadcrumb = [
    {
        title: 'Контракты',
        to: -1
    }
]

export const adminConflictBreadcrumb = [
    {
        title: 'Споры',
        to: -1
    }
]

export const adminComplainstBreadcrumb = [
    {
        title: 'Жалобы',
        to: -1
    }
]

export const marketPlotBreadcrumb = (square_id) => ([
    {
        title: 'Поле',
        to: `/market/fields/${square_id}`
    },
])