import { ApiUrl, instance } from './apiEnv';

export const api_seasons = {
    async PostCreateSeasons(data) {
        return await instance.post(`${ApiUrl}seasons`, data)
    },

    async PutAddPlotOnSeasons(id, data) {
        return await instance.put(`${ApiUrl}seasons/${id}/add_plot`, data)
    },

    async PutUpdateCropType(id, data) {
        return await instance.put(`${ApiUrl}seasons/${id}/update_plot_crop`, data)
    },

    async DeletePlotOnSeasons(id, plot_id) {
        return await instance.delete(`${ApiUrl}seasons/${id}/remove_plot?plot_id=${plot_id}`)
    },

    async PostRelatedCropType(data) {
        return await instance.post(`${ApiUrl}seasons/related_crop_type`, data)
    },

    async DeleteSeasons(id) {
        return await instance.delete(`${ApiUrl}seasons/${id}`)
    },
}