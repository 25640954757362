import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { CLOSE_MODAL } from 'store/actionTypes';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function CloseDealModal({ setVisible, data, reject, ...props }) {

    const onFinish = ({ reason_deletion }) => {
        reject()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            {...props}
        >
            <Form.Item
                label={'Причина закрытия:'}
                name="reason_deletion"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input.TextArea placeholder='Введите причина закрытия сделки' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <SimpleButton
                    title={'Отправить'}
                    className="p14_500"
                    htmltype="submit"
                    style={{
                        color: 'white',
                        padding: '11px 16px',
                        borderRadius: '100px',
                        backgroundColor: '#D32F2F',
                        width: '100%'
                    }}
                />
            </Form.Item>
            <SimpleButton
                title={'Отмена'}
                className="activeText p14_500"
                style={{
                    // border: '1px solid #01963A',
                    padding: '11px 16px',
                    borderRadius: '100px',
                    backgroundColor: 'white',
                    width: '100%'
                }}
                onClick={() => setVisible()}
            />
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL}),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CloseDealModal);