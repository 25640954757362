import { connect } from 'react-redux'
import { adminCarBreadcrumb, adminUserCarsBreadcrumb } from 'data/brebcrumb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { fetchCurrentLocationTechnicsByID, fetchDeleteTechnic, fetchTechnicsByID } from 'store/actions/admin/technics';
import { ADMIN_CLEAR_TECHNIC_BY_ID_DATA } from 'store/actionTypes';
import { carByIdSwitch } from 'data/adminLists';
import { warningNotification } from 'hooks/notifications';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import Switch from 'components/shared/switch/Switch';
import MainInfo from './mainInfo/MainInfo';
import Docs from './docs/Docs';
import Verification from './verification/Verification';
import EmploymentSchedule from './employmentSchedule/EmploymentSchedule';
import Contracts from './contracts/Contracts';
import CurrentLocation from './currentLocation/CurrentLocation';

import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';

import './style.css';

function TechnicByIdAdmin({ fetchTechnicsByID, tehnics_by_id_data, fetchCurrentLocationTechnicsByID, CLEAR_TECHNIC_BY_ID_DATA, fetchDeleteTechnic }) {
    const { id, user_id } = useParams()
    const location = useLocation()
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')
    const [editMainIfo, setEditMainInfo] = useState(location.state ? location.state.edit : false)
    const [editEmployment, setEditEmployment] = useState(false)
    const [form] = Form.useForm()
    const [employmentForm] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    useEffect(() => {
        fetchTechnicsByID(id)
        fetchCurrentLocationTechnicsByID(id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_TECHNIC_BY_ID_DATA()
        }
    }, [])

    const headerButtons = () => {
        return (
            <div className='userProfile_header__actions'>
                <SimpleButton
                    title={'Отмена'}
                    style={{
                        color: '#01963A',
                        border: '1px solid #01963A',
                        borderRadius: '100px',
                        backgroundColor: '#FFF',
                        fontWeight: 500,
                        marginRight: 16
                    }}
                    onClick={() => {
                        if (activeSwitch === 'mainInfo') setEditMainInfo(false)
                        if (activeSwitch === 'offers') setEditEmployment(false)
                    }}
                />
                <SimpleButton
                    title={'Сохранить'}
                    style={{
                        color: '#FFF',
                        border: '1px solid #01963A',
                        borderRadius: '100px',
                        backgroundColor: '#01963A',
                        fontWeight: 500
                    }}
                    onClick={() => {
                        if (activeSwitch === 'mainInfo') {
                            form.submit()
                            setEditMainInfo(false)
                        } else {
                            employmentForm.submit()
                            setEditEmployment(false)
                        }
                    }}
                />
            </div>
        )
    }

    return (
        tehnics_by_id_data &&
        <div className='allUsersBlock'>
            {contextHolder}
            <Breadcrumb list={user_id ? adminUserCarsBreadcrumb(user_id) : adminCarBreadcrumb} activeLink={tehnics_by_id_data.gos_num} />
            <div className='userProfile_header'>
                <TextHeading text={tehnics_by_id_data.gos_num} />
                {(activeSwitch === 'mainInfo' || activeSwitch === 'offers')
                    ? (
                        activeSwitch === 'mainInfo'
                            ? editMainIfo
                                ?
                                headerButtons()
                                :
                                <div className='userProfile_header__actions'>
                                    <div
                                        className='userProfile_header__actions__edit'
                                        onClick={() => {
                                            if (activeSwitch === 'mainInfo') setEditMainInfo(true)
                                            if (activeSwitch === 'offers') setEditEmployment(true)
                                        }}
                                    >
                                        <img src={edit_green_pen} alt="edit_green_pen" />
                                        <p className='activeText p14_500'>Редактировать</p>
                                    </div>
                                    <SimpleButton
                                        title={'Удалить'}
                                        style={{
                                            color: '#D32F2F',
                                            border: '1px solid #D32F2F',
                                            borderRadius: '100px',
                                            backgroundColor: '#FFF',
                                            fontWeight: 500
                                        }}
                                        onClick={() => warningNotification(api, async () => {
                                            await fetchDeleteTechnic(id)
                                            navigate(`/admin/cars/`)
                                            api.destroy()
                                        }, 'удаление техники.')}
                                    />
                                </div>
                            : editEmployment
                                ?
                                headerButtons()
                                :
                                <div className='userProfile_header__actions'>
                                    <div
                                        className='userProfile_header__actions__edit'
                                        onClick={() => {
                                            if (activeSwitch === 'mainInfo') setEditMainInfo(true)
                                            if (activeSwitch === 'offers') setEditEmployment(true)
                                        }}
                                    >
                                        <img src={edit_green_pen} alt="edit_green_pen" />
                                        <p className='activeText p14_500'>Редактировать</p>
                                    </div>
                                    <SimpleButton
                                        title={'Удалить'}
                                        style={{
                                            color: '#D32F2F',
                                            border: '1px solid #D32F2F',
                                            borderRadius: '100px',
                                            backgroundColor: '#FFF',
                                            fontWeight: 500
                                        }}
                                        onClick={() => warningNotification(api, async () => {
                                            await fetchDeleteTechnic(id)
                                            navigate(`/admin/cars/`)
                                            api.destroy()
                                        }, 'удаление техники.')}
                                    />
                                </div>
                    )

                    :
                    <SimpleButton
                        title={'Удалить'}
                        style={{
                            color: '#D32F2F',
                            border: '1px solid #D32F2F',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500
                        }}
                        onClick={() => warningNotification(api, async () => {
                            await fetchDeleteTechnic(id)
                            navigate(`/user/my-cars/`)
                            api.destroy()
                        }, 'удаление техники.')}
                    />
                }
            </div>
            <Switch
                items={carByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo form={form} editMainIfo={editMainIfo} />}
            {activeSwitch === 'docs' && <Docs />}
            {activeSwitch === 'contracts' && <Contracts />}
            {activeSwitch === 'offers' && <EmploymentSchedule form={employmentForm} edit={editEmployment} />}
            {activeSwitch === 'verification' && <Verification />}
            {activeSwitch === 'current_location' && <CurrentLocation />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        tehnics_by_id_data: state.adminTehnicById.technic_by_id_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchTechnicsByID: (id) => dispatch(fetchTechnicsByID(id)),
        fetchCurrentLocationTechnicsByID: (id) => dispatch(fetchCurrentLocationTechnicsByID(id)),
        fetchDeleteTechnic: (id) => dispatch(fetchDeleteTechnic(id)),
        CLEAR_TECHNIC_BY_ID_DATA: () => dispatch({ type: ADMIN_CLEAR_TECHNIC_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(TechnicByIdAdmin);