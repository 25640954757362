import axios from 'axios';
import { ApiUrl, instance } from './apiEnv';
import { errorNotification } from 'hooks/notifications';

export const api_user = {

    async Register(data) {
        return await axios.post(`${ApiUrl}users/register`, data, {
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(res => {
                return res.data
            })
            .catch(error => {
                if (error.response) {
                    errorNotification('Пользователь с таким email/номером телефона уже существует', null)
                    return error.response.data.detail
                } else {
                    return 'Error: Network Error'
                }
            })
    },

    async ActivateUser(data, activation_code) {
        return await axios.put(`${ApiUrl}users/activate?activation_code=${activation_code}`, data)
    },

    async ReactivateUser(data) {
        return await axios.post(`${ApiUrl}users/reactivate`, data)
    },

    async SecondRegister(data, token) {
        return await axios.post(`${ApiUrl}users/register-second`, data, {
            headers: { Authorization: `Bearer ${token}` }
        })
    },

    async SecondRegisterUploadDocs(data, token) {
        return await axios.put(`${ApiUrl}users/me/document`, data, {
            headers: { Authorization: `Bearer ${token}` }
        })
    },

    async GetMyData() {
        return await instance.get(`${ApiUrl}users/me`)
    },

    async GetMyLikes(page, size, params) {
        return await instance.get(`${ApiUrl}users/me/liked_technics?page=${page}&size=${size}&${params}`)
    },

    async UpdateUserMe(data) {
        return await instance.put(`${ApiUrl}users/me`, data)
    },

    async UpdateUserMeRegistartion(data, token) {
        return await axios.put(`${ApiUrl}users/me`, data, {
            headers: { Authorization: `Bearer ${token}` }
        })
    },

    async DeleteUser(user_id, comment) {
        return await instance.delete(`${ApiUrl}users/${user_id}?comment=${comment}`)
    },

    async GetAllUsers(page, size, params) {
        return await instance.get(`${ApiUrl}users/list?page=${page}&size=${size}&${params}`)
    },

    async GetUserById(id) {
        return await instance.get(`${ApiUrl}users/${id}`)
    },

    async UpdateMeImg(data) {
        return await instance.put(`${ApiUrl}users/me/image`, data)
    },

    async UploadDocs(data) {
        return await instance.put(`${ApiUrl}users/me/document`, data)
    },

    async DeleteMyDocs(doc_id) {
        return await instance.delete(`${ApiUrl}users/me/document/${doc_id}`)
    },

    async SensitiveCode(email, phone) {
        return await instance.put(`${ApiUrl}users/me/sensitive/code?${email ? `email=${email}` : ''}${phone ? `phone=${phone}` : ''}`)
    },

    async Sensitive(code, email, phone) {
        return await instance.put(`${ApiUrl}users/me/sensitive?code=${code}&${email ? `email=${email}` : ''}${phone ? `phone=${phone}` : ''}`)
    },

    async DeleteMe(comment) {
        return await instance.delete(`${ApiUrl}users/me/delete?comment=${comment}`)
    },
    
}