import { connect } from 'react-redux'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { fetchUpdateBusyPeriod, fetchCreateBusyPeriod, fetchDeleteBusyPeriod } from 'store/actions/busyPeriod';
import { formatDate } from 'hooks/formatDate';
import { createBusyPeriods } from 'hooks/createBusyPeriods';
import CustomDatePicker from 'components/shared/datePicker/DatePicker';

import './style.css';

function EmploymentSchedule({ form, edit, busy_periods, fetchCreateBusyPeriod, fetchUpdateBusyPeriod, fetchDeleteBusyPeriod }) {
    const [busyPeriods, setBusyPeriods] = useState([])
    const [datePicker, setDatePicker] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        setDatePicker(
            <CustomDatePicker
                ranges={createBusyPeriods(busy_periods)}
                mode="userEditTechnic"
                edit={edit}
                saveRange={(items) => {
                    setBusyPeriods(items)
                }}
            />
        )
        setBusyPeriods(createBusyPeriods(busy_periods))
    }, [edit])

    const onFinish = async () => {
        for (let i = 0; i < busy_periods.length; i++) {
            await fetchDeleteBusyPeriod(busy_periods[i].id)
        }
        for (let i = 0; i < busyPeriods.length; i++) {
            const data = {
                date_from: formatDate(busyPeriods[i].startDate),
                date_to: formatDate(busyPeriods[i].endDate),
                technic_id: id
            }
            await fetchCreateBusyPeriod(data)
        }
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <div className='employmentScheduleBlock'>
                {datePicker}
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        busy_periods: state.tehnicById.tehnics_by_id_data.busy_periods
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateBusyPeriod: (data) => dispatch(fetchCreateBusyPeriod(data)),
        fetchUpdateBusyPeriod: (busyPeriod_id, data) => dispatch(fetchUpdateBusyPeriod(busyPeriod_id, data)),
        fetchDeleteBusyPeriod: (busyPeriod_id) => dispatch(fetchDeleteBusyPeriod(busyPeriod_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EmploymentSchedule);