import { connect } from 'react-redux'
import EditMainInfo from 'components/components/forms/admin/profile/editMainInfo/EditMainInfo';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

function MainInfo({ edit, onEdit, user_by_id_data, form }) {
    return (
        !edit
            ? <div>
                <InfoBlock style={{ marginBottom: 16 }}>
                    <p className='p12_400 mainText p_mb8'>ФИО</p>
                    <p className='p14_400 mainText p_mb16'>{user_by_id_data.first_name + ' ' + user_by_id_data.last_name + ' ' + user_by_id_data.third_name}</p>
                    <p className='p12_400 mainText p_mb8'>Электронная почта</p>
                    <p className='p14_400 mainText p_mb16'>{user_by_id_data.email ? user_by_id_data.email : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Номер телефона</p>
                    <p className='p14_400 mainText'>{user_by_id_data.phone ? '+' + user_by_id_data.phone : '-'}</p>
                </InfoBlock>
                <InfoBlock>
                    <p className='p12_400 mainText p_mb8'>Тип лица</p>
                    <p className='p14_400 mainText'>{user_by_id_data.jur_type === 'PHYSICAL' ? 'Физическое' : 'Юридическое'}</p>
                </InfoBlock>
            </div>
            : <InfoBlock>
                <EditMainInfo form={form} onEdit={onEdit} />
            </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data: state.userById.user_by_id_data
    }
}

export default connect(mapStateToProps, null)(MainInfo);