import { connect } from 'react-redux'
import { Form, Radio } from 'antd';
import { useState } from 'react';
import { SAVE_CREATE_APPLICATION_DATA } from 'store/actionTypes';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

function ApplicationType({ form, nextStep, create_application, SAVE_CREATE_APPLICATION_DATA }) {
    const [fields, setFields] = useState([
        {
            name: 'bid_type',
            value: create_application.bid_type,
        },
    ]);

    const onFinish = ({ bid_type }) => {
        let obj = { ...create_application }
        obj.bid_type = bid_type
        SAVE_CREATE_APPLICATION_DATA(obj)
        nextStep()
    };
    
    return (
        <Form
            layout="vertical"
            requiredMark={true}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
        >
            <InfoBlock>
                <Form.Item
                    label={'Я'}
                    name="bid_type"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={'TECHNICIAN'}>Техник</Radio>
                        <Radio value={'FARMER'}>Фермер</Radio>
                    </Radio.Group>
                </Form.Item>
            </InfoBlock>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        create_application: state.createApplication,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_APPLICATION_DATA: (data) => dispatch({ type: SAVE_CREATE_APPLICATION_DATA, create_application: data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ApplicationType);