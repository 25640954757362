import { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchSecondSignUp } from 'store/actions/signup';
import { fetchUpdateMyDataRegistration } from 'store/actions/user';
import { checkFioLength } from 'hooks/checkFioLength';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function SpecifiedData({ user_data, token, fetchSecondSignUp, fetchUpdateMyDataRegistration }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [errorStatus, setErrorStatus] = useState({})
    const [errorStatusPhone, setErrorStatusPhone] = useState({})
    const [fields, setFields] = useState([
        {
            name: 'phone',
            value: user_data.phone ? user_data.phone.substring(1) : null,
        },
        {
            name: 'email',
            value: user_data.email ? user_data.email : null,
        }
    ]);
    
    const onFinish = ({ fio, email, phone }) => {
        if (checkFioLength(fio).length === 3) {
            const fioArray = checkFioLength(fio)
            const data = {
                email,
                phone: '7' + phone,
                first_name: fioArray[0],
                last_name: fioArray[1],
                third_name: fioArray[2],
            }
            if (location.state) {
                fetchUpdateMyDataRegistration(data, token)
                    .then(res => {
                        if (res === true) { navigate('/signup/complite') }
                        else {
                            setErrorStatusPhone({
                                validateStatus: "error",
                                help: "Данный номер уже зарегистрирован"
                            })
                        }
                    })
            } else {
                fetchSecondSignUp(data, token)
                    .then(res => {
                        if (res === true) { navigate('/signup/complite') }
                        else {
                            setErrorStatusPhone({
                                validateStatus: "error",
                                help: "Данный номер уже зарегистрирован"
                            })
                        }
                    })
            }
        } else {
            setErrorStatus({
                validateStatus: "error",
                help: "Некорректные данные"
            })
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
                setErrorStatus({})
                setErrorStatusPhone({})
            }}
        >
            <Form.Item
                label={'ФИО'}
                name="fio"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatus}
            >
                <Input placeholder='Введите ФИО' />
            </Form.Item>
            <Form.Item
                label={'Email'}
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите почту' disabled={user_data.email ? true : false} />
            </Form.Item>
            <Form.Item
                label={'Номер телефона'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusPhone}
            >
                <Input placeholder='(000) 000-0000' prefix={'+7'} disabled={user_data.phone ? true : false} type="number" />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Далее'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.signup.user_data,
        token: state.signup.token,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchSecondSignUp: (data, token) => dispatch(fetchSecondSignUp(data, token)),
        fetchUpdateMyDataRegistration: (data, token) => dispatch(fetchUpdateMyDataRegistration(data, token))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SpecifiedData);