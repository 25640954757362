import { useState } from 'react';
import { connect } from 'react-redux'
import { Select } from 'antd';
import { CLOSE_MODAL, SAVE_CREATE_SQUARE_SEASONS } from 'store/actionTypes';
import { yearList } from 'hooks/listOfYear';
import { warningNotificationWithoutConfirmation } from 'hooks/notifications';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton'

const { Option } = Select;

const currentYear = (new Date()).getFullYear();

function CreateSeason({ setVisible, data, SAVE_CREATE_SQUARE_SEASONS }) {
    const [newDate, setNewDate] = useState(null)

    const createSeason = () => {
        if (data.seasons.findIndex(el => el.year === newDate) === -1) {
            if (data.page === 'squareById') {
                data.createSeason(newDate)
                setVisible()
            } else if (data.page == 'createSquare') {
                SAVE_CREATE_SQUARE_SEASONS({
                    plots: [...data.plots],
                    year: newDate,
                    title: `Сезон ${newDate}`,
                    being_deleted: true
                })
                setVisible()
            }
        } else {
            warningNotificationWithoutConfirmation('Такой сезон уже создан')
        }
    }
  
    return (
        <div style={{ width: 380 }}>
            <p className='mainText p24_600 p_mb24' style={{ textAlign: 'center' }}>Добавить сезон</p>
            <div>
                <p className='p14_400' style={{ textAlign: 'center' }}>Год начала сезона</p>
                <div>
                    <Select
                        placeholder="Выберите год"
                        style={{ display: 'block', marginTop: 10 }}
                        onChange={(value) => setNewDate(value)}
                    >
                        {yearList(currentYear, currentYear + 50, +1).map(el => (
                            <Option value={el} key={`release_date_${el}`}>{el}</Option>
                        ))}
                    </Select>
                </div>
            </div>
            <SimpleButton
                title={'Создать'}
                style={{
                    marginTop: 20,
                    marginBottom: 11,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px',
                    width: '100%'
                }}
                onClick={createSeason}
            />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        SAVE_CREATE_SQUARE_SEASONS: (seasons) => dispatch({ type: SAVE_CREATE_SQUARE_SEASONS, seasons }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateSeason);