import { api_market } from 'api/market'
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery'
import {
    FETCH_MARKET_ALL_SQUARES_START,
    FETCH_MARKET_ALL_SQUARES_SUCCESS,
    MARKET_FETCH_SQUARES_BY_ID_DATA_START,
    MARKET_FETCH_SQUARES_BY_ID_DATA_SUCCESS,
    MARKET_FETCH_ANOTHER_SQUARES,
    MARKET_FETCH_BIDS_BY_SQUARES
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllSquares(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_MARKET_ALL_SQUARES_START))
        try {
            const technic_data = await api_market.GetAllSquares(page, size, params)
            dispatch(fetchSuccess(FETCH_MARKET_ALL_SQUARES_SUCCESS, {
                data: technic_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchAnotherSquares(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(MARKET_FETCH_SQUARES_BY_ID_DATA_START))
        try {
            const technic_data = await api_market.GetAllSquares(page, size, params)
            dispatch(fetchSuccess(MARKET_FETCH_ANOTHER_SQUARES, {
                data: technic_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchSquaresByID(id) {
    return async dispatch => {
        dispatch(fetchStart(MARKET_FETCH_SQUARES_BY_ID_DATA_START))
        try {
            const square_data = await api_market.GetSquaresById(id)
            await dispatch(fetchSuccess(MARKET_FETCH_SQUARES_BY_ID_DATA_SUCCESS, {
                square_by_id_data: square_data.data.data
            }))
            await dispatch(fetchAnotherSquares(1, 10, createQuery(getQueryStringFromObject(JSON.stringify({ 'square_type_id': [`${square_data.data.data.square_type.id}`] })))))
            dispatch(fetchBidsBySquare(id))
        } catch (e) {
        }
    }
}

export function fetchBidsBySquare(id) {
    return async dispatch => {
        try {
            const bids_by_square_data = await api_market.GetBidsBySquare(id)
            await dispatch(fetchSuccess(MARKET_FETCH_BIDS_BY_SQUARES, {
                data: bids_by_square_data.data.data
            }))
        } catch (e) {
        }
    }
}