import Advantages from './modules/advantages/Advantages';
import AdvantagesMini from './modules/advantagesMini/AdvantagesMini';
import Description from './modules/description/Description';
import FindOrder from './modules/findOrder/FindOrder';
import Problems from './modules/problems/Problems';
import SignUp from './modules/signUp/SignUp';

import './style.css';
import './media.css';

const MainPage = () => {
    return (
        <div className='mainPage' style={{ flex: 1 }}>
            <Description />
            <div className='mainPage_content'>
                <FindOrder />
                <Advantages />
                <SignUp />
                <Problems />
                <AdvantagesMini />
                <SignUp />
            </div>
        </div>
    )
}

export default MainPage