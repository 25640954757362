import { useState } from 'react';
import { connect } from 'react-redux'
import { Form } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { profileSwitch } from 'data/lists';
import Switch from 'components/shared/switch/Switch';
import Company from './company/Company';
import Docs from './docs/Docs';
import Verification from './verification/Verification';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import MainInfo from './mainInfo/MainInfo';

import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';

import './style.css';
import './media.css';

function UserProfile({ user_data, setVisible }) {
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')
    const [form] = Form.useForm();
    const [edit, setEdit] = useState({
        mainInfo: false,
        company: false,
        docs: false,
        verification: false
    })

    const onEdit = () => {
        let copyOfObject = { ...edit }
        copyOfObject[activeSwitch] = !copyOfObject[activeSwitch]
        setEdit(copyOfObject)
    }

    return (
        <div className='userProfile'>
            <div className='userProfile_header'>
                <div className='userProfile_header__info'>
                    {user_data.image
                        ?
                        <div className='userProfile_header__info__avatar__photo' style={{ backgroundImage: `url(${user_data.image.media.link})` }} />
                        :
                        <div className='userProfile_header__info__avatar p14_500'>{user_data.first_name[0].toUpperCase() + user_data.last_name[0].toUpperCase()}</div>
                    }
                    <p className='p24_600 mainText' style={{ textAlign: 'center' }}>{user_data.first_name + ' ' + user_data.last_name + ' ' + user_data.third_name}</p>
                </div>
                {edit[activeSwitch]
                    ? <div className='userProfile_header__actions'>
                        <SimpleButton
                            title={'Отмена'}
                            style={{
                                color: '#01963A',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500,
                                marginRight: 16
                            }}
                            onClick={() => onEdit()}
                        />
                        <SimpleButton
                            title={'Сохранить'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={() => form.submit()}
                        />
                    </div>
                    : <div className='userProfile_header__actions'>
                        <div className='userProfile_header__actions__edit' onClick={() => onEdit()}>
                            <img src={edit_green_pen} alt="edit_green_pen" />
                            <p className='activeText p14_500'>Редактировать</p>
                        </div>
                        <SimpleButton
                            title={'Удалить'}
                            style={{
                                color: '#D32F2F',
                                border: '1px solid #D32F2F',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500
                            }}
                            onClick={() => setVisible()}
                        />
                    </div>
                }
            </div>
            <Switch
                items={profileSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo edit={edit[activeSwitch]} onEdit={() => onEdit()} form={form} />}
            {activeSwitch === 'company' && <Company edit={edit[activeSwitch]} onEdit={() => onEdit()} form={form} />}
            {activeSwitch === 'docs' && <Docs />}
            {activeSwitch === 'verification' && <Verification />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'deleteProfileOnUser' }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(UserProfile);