import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchDeleteTechnic } from 'store/actions/admin/technics';
import { verificationStatusesStyle } from 'data/lists';
import { profileVerificationTable } from 'data/tableHeader';
import { profileVerificationTableWidth } from 'data/tableWidth';
import { formatDate } from 'hooks/formatDate';
import { warningNotification } from 'hooks/notifications';
import Table from 'components/ui/table/Table';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import deleteIcon from 'assets/images/icons/delete.svg';

function Verification({ tehnics_by_id_data_verifications, fetchDeleteTechnic, setVisible }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();

    const createTableData = () => {
        return tehnics_by_id_data_verifications.map(el => (
            [
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div>
                },
                {
                    type: 'text',
                    content: el.comment ? el.comment : '-'
                }
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={profileVerificationTable}
                width={profileVerificationTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Проверить'}
                style={{
                    marginTop: 20,
                    background: tehnics_by_id_data_verifications.length > 0 && tehnics_by_id_data_verifications[0].status === 'ON_CONSIDER' ? '#43A047' : '#CCCCCC',
                    color: tehnics_by_id_data_verifications.length > 0 && tehnics_by_id_data_verifications[0].status === 'ON_CONSIDER' ? '#FFFFFF' : '#999999',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => {
                    if (tehnics_by_id_data_verifications.length > 0) {
                        if (tehnics_by_id_data_verifications[0].status === 'ON_CONSIDER') {
                            setVisible({
                                id,
                                verification_id: tehnics_by_id_data_verifications[0].id,
                                new_data: tehnics_by_id_data_verifications[0].new_data
                            })
                        }
                    }
                }
                }
            />
            <SimpleButton
                title={<p className='buttonWithImage'><img src={deleteIcon} />Удалить технику</p>}
                style={{
                    color: '#D32F2F',
                    border: '1px solid #D32F2F',
                    borderRadius: '100px',
                    backgroundColor: '#FFF',
                    marginTop: 16,
                    fontWeight: 500
                }}
                onClick={() => warningNotification(api, () => {
                    fetchDeleteTechnic(id)
                    api.destroy()
                    navigate('/admin/cars')
                }, 'удаление техники.')}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        tehnics_by_id_data_verifications: state.adminTehnicById.technic_by_id_data.verifications,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDeleteTechnic: (id) => dispatch(fetchDeleteTechnic(id)),
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'mainVerificationTechnic', data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Verification);