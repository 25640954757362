import { useState } from 'react';
import { formatDate } from 'hooks/formatDate';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import CustomCalendar from '../calendar/Calendar';

import angle_down from 'assets/images/icons/angle-down.svg';

import './style.css';

function SelectDate({ placeholder, selectDate, onChangeData, ...props }) {
    const [active, setActive] = useState(false)

    return (
        <div className={active ? 'selectDate active' : 'selectDate'}>
            <div className='selectDateMainBlock' onClick={() => setActive(!active)}>
                <p className='activeText p14_400'>{selectDate ? formatDate(selectDate) : placeholder}</p>
                <img src={angle_down} alt='angle_down' />
            </div>
            <div className='selectDateCalendarBlock'>
                <InfoBlock>
                    <CustomCalendar
                        date={selectDate || null}
                        onChange={(item) => { onChangeData(formatDate(item)) }}
                        {...props}
                    />
                </InfoBlock>
            </div>
        </div>
    );
}

export default SelectDate;