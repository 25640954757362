import { api_plots } from 'api/plots'

export function fetchCreatePlot(data) {
    return async dispatch => {
        try {
            const plot = await api_plots.PostCreatePlots(data)
            return plot.data.data.id
        } catch (e) {
        }
    }
}