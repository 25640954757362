import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { headerList } from 'data/routings';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import BurgerMenu from 'components/shared/burgerMenu/BurgerMenu';

import HeaderLogo from 'assets/images/headerLogo.svg';
import burgerMenu from 'assets/images/landing/icons/burger-menu.svg';

import './style.css';
import './media.css';

const Description = () => {
  const [activeBurger, setActiveBurger] = useState(false)
  const navigate = useNavigate()

  return (
    <div
      className='descriptionBlock'
    >
      <div className='descriptionBlock_menu'>
        <div className='descriptionBlock_menu_links'>
          <Link to={'/'}><img src={HeaderLogo} alt="HeaderLogo" /></Link>
          {headerList.map((item, key) => (
            <NavLink to={item.to} key={`headerLink_${key}`} className={({ isActive }) => isActive ? 'header_link active-header-link' : 'header_link'}>{item.title}</NavLink>
          ))}
        </div>
        <div className='twoButton'>
          <div>
            <SimpleButton
              title={'Войти'}
              className='greenButton'
              onClick={() => navigate(`/login`)}
            />
          </div>
          <div className='twoButton_item'>
            <SimpleButton
              title={'Зарегистрироваться'}
              className='greenButton'
              onClick={() => navigate(`/signup/first-stage`)}
            />
          </div>
        </div>
        <img src={burgerMenu} alt="burgerMenu" className='descriptionBlock_menu_links__burger' onClick={() => setActiveBurger(!activeBurger)} />
        <BurgerMenu active={activeBurger} setActive={() => setActiveBurger(!activeBurger)} />
      </div>
      <div className='descriptionBlock_text'>
        <p className='p36_700 p_mb16 descriptionBlock_title'>Маркетплейс <br /> в сельскохозяйственной сфере</p>
        <p className='p16_400 descriptionBlock_subText'>Позволит владельцам техники найти быстро и главное рядом заказ, а владельцу земель - быстро найти исполнителя</p>
        <SimpleButton
          title={'Попробовать'}
          className="greenButton"
          style={{
            marginTop: 31
          }}
          onClick={() => navigate(`/market/fields`)}
        />
      </div>
    </div>
  )
}

export default Description