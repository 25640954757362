import { api_seasons } from 'api/seasons'

export function fetchCreateSeason(data) {
    return async dispatch => {
        try {
            const plot = await api_seasons.PostCreateSeasons(data)
            return plot.data.data.id
        } catch (e) {
        }
    }
}

export function fetchAddPlotOnSeason(id, data) {
    return async dispatch => {
        try {
            await api_seasons.PutAddPlotOnSeasons(id, data)
        } catch (e) {
        }
    }
}

export function fetchUpdatePlotOnSeason(id, data) {
    return async dispatch => {
        try {
            await api_seasons.PutUpdateCropType(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeletePlotOnSeason(id, plot_id) {
    return async dispatch => {
        try {
            await api_seasons.DeletePlotOnSeasons(id, plot_id)
        } catch (e) {
        }
    }
}

export function fetchReloadCropType(data) {
    return async dispatch => {
        try {
            const crop_type = await api_seasons.PostRelatedCropType(data)
            return crop_type.data.data
        } catch (e) {
        }
    }
}

export function fetchDeleteSeason(id) {
    return async dispatch => {
        try {
            await api_seasons.DeleteSeasons(id)
        } catch (e) {
        }
    }
}