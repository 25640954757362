import {
    FETCH_CHECK_CHAT_BY_USER_ID,
    CLEAR_CHECK_CHAT_BY_USER_ID
} from '../../actionTypes'

const initialState = {
    checkChats: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHECK_CHAT_BY_USER_ID:
            return {
                checkChats: action.data.data.items,
            }
        case CLEAR_CHECK_CHAT_BY_USER_ID:
            return {
                checkChats: null,
            }
        default: return state
    }
}