import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { fetchCreateBusyPeriod, fetchDeleteBusyPeriod } from 'store/actions/admin/busyPeriod';
import { formatDate } from 'hooks/formatDate';
import CustomDatePicker from 'components/shared/datePicker/DatePicker';

function EmploymentSchedule({ form, edit, busy_periods, fetchCreateBusyPeriod, fetchDeleteBusyPeriod }) {
    const [busyPeriods, setBusyPeriods] = useState([])
    const [datePicker, setDatePicker] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        const array = []
        busy_periods.forEach((el, key) => {
            array.push({
                startDate: new Date(el.date_from),
                endDate: new Date(el.date_to),
                key: `selection${key + 1}`
            })
        })
        setDatePicker(
            <CustomDatePicker
                ranges={array}
                mode="userEditTechnic"
                edit={edit}
                saveRange={(items) => setBusyPeriods(items)}
            />
        )
        setBusyPeriods(array)
    }, [edit])

    const onFinish = async () => {
        for (let i = 0; i < busy_periods.length; i++) {
            await fetchDeleteBusyPeriod(busy_periods[i].id)
        }
        for (let i = 0; i < busyPeriods.length; i++) {
            const data = {
                date_from: formatDate(busyPeriods[i].startDate),
                date_to: formatDate(busyPeriods[i].endDate),
                technic_id: id
            }
            await fetchCreateBusyPeriod(data)
        }
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <div className='employmentScheduleBlock'>
                {/* {datePicker} */}
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        busy_periods: state.adminTehnicById.technic_by_id_data.busy_periods
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateBusyPeriod: (data) => dispatch(fetchCreateBusyPeriod(data)),
        fetchDeleteBusyPeriod: (busyPeriod_id) => dispatch(fetchDeleteBusyPeriod(busyPeriod_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EmploymentSchedule);