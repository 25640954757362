import {
    FETCH_DADATA_START,
    FETCH_DADATA_SUCCESS,
} from '../actionTypes'

const initialState = {
    search_company: []
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_DADATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_DADATA_SUCCESS:
            return {
                ...state,
                search_company: action.data.search_company
            }
        default: return state
    }
}