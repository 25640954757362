import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { useSearchParams } from "react-router-dom";
import { fetchMyNotifications, fetchNotificationSetReaded } from "store/actions/notifications";
import { notificationsSwitch } from "data/lists";
import { createQuery, getQueryStringFromObject } from "hooks/createQuery";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Switch from "components/shared/switch/Switch";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import NotificationCard from "components/shared/cards/notificationCard/NotificationCard";

import check from 'assets/images/icons/check.svg';

function Notifications({ notifications, fetchMyNotifications, fetchNotificationSetReaded }) {
    const [activeSwitch, setActiveSwitch] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.search) {
            fetchMyNotifications(
                1,
                50,
                createQuery(Object.fromEntries([...searchParams]).search)
            )
            const obj = JSON.parse(decodeURIComponent(((query.search))))
            if (obj.is_readed) {
                setActiveSwitch(obj.is_readed)
            } else {
                setActiveSwitch(false)
            }
        } else {
            query.search  = getQueryStringFromObject(JSON.stringify({ "is_readed": false }))
            setSearchParams(query)
            setActiveSwitch(false)
        }
    }, [
        Object.fromEntries([...searchParams]).search,
    ])

    const onSwitch = (param) => {
        const query = Object.fromEntries([...searchParams]);
        if (query.search) {
            const obj = JSON.parse(decodeURIComponent(((query.search))))
            obj.is_readed = param
            query.search = getQueryStringFromObject(JSON.stringify(obj))
        } else {
            query.search = getQueryStringFromObject(JSON.stringify({ "is_readed": param }))
        }
        setSearchParams(query)
        setActiveSwitch(param)
    }

    const onSetReaded = (id) => {
        fetchNotificationSetReaded(
            [id],
            1,
            50,
            createQuery(Object.fromEntries([...searchParams]).search)
        )
    }

    return (
        <div className='allUsersBlock'>
            <TextHeading text={'Уведомления'} />
            <div className='allUsersBlock_header' style={{ marginTop: 24 }}>
                <div className='allUsersBlock_header__leftHalf' style={{marginBottom: 15}}>
                    <Switch
                        items={notificationsSwitch}
                        activeItem={activeSwitch}
                        onSwitch={onSwitch}
                        className="switch miniSwitch"
                    />
                </div>
                {(!activeSwitch && notifications.length > 0) &&
                    <div className='allUsersBlock_header__rightHalf'>
                        <SimpleButton
                            title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={check} alt="check" style={{ marginRight: 8 }} />Отметить все как “прочитано”</p>}
                            className="greenButton"
                            onClick={() => fetchNotificationSetReaded(
                                notifications.map(el => el.id),
                                1,
                                50,
                                createQuery(Object.fromEntries([...searchParams]).search)
                            )}
                        />
                    </div>
                }
            </div>
            {notifications.length > 0
                ? notifications.map(el => (
                    <NotificationCard
                        key={el.id}
                        {...el}
                        onSetReaded={onSetReaded}
                    />
                ))
                : <p className='p_mt24 p14_500' style={{ textAlign: 'center' }}>Нет уведомлений</p>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        notifications: state.userNotifications.notifications
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyNotifications: (page, size, params) => dispatch(fetchMyNotifications(page, size, params)),
        fetchNotificationSetReaded: (data, page, size, params) => dispatch(fetchNotificationSetReaded(data, page, size, params))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Notifications);