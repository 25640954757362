import {
    SAVE_CREATE_APPLICATION_DATA,
    SAVE_CREATE_APPLICATION_DATA_DATE,
    START_CREATE_APPLICATION_FETCH,
    STOP_CREATE_APPLICATION_FETCH,
    CLEAR_CREATE_APPLICATION_DATA,
} from '../actionTypes'

const initialState = {
    bid_type: 'TECHNICIAN',
    technic_data: [],
    square_data: null,
    plots: [],
    date: [{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }],
    description: {
        description: null,
        work_type_id: null,
        payment_desc: null,
        amount: null
    },
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CREATE_APPLICATION_DATA:
            return action.create_application
        case SAVE_CREATE_APPLICATION_DATA_DATE:
            return {
                ...state,
                date: action.date
            }
        case START_CREATE_APPLICATION_FETCH:
            return {
                ...state,
                loading: true
            }
        case STOP_CREATE_APPLICATION_FETCH:
            return {
                ...state,
                loading: false
            }
        case CLEAR_CREATE_APPLICATION_DATA:
            return {
                bid_type: 'TECHNICIAN',
                technic_data: [],
                square_data: null,
                plots: [],
                date: [{
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                }],
                description: {
                    description: null,
                    work_type_id: null,
                    prerequisites: null,
                    cost: null
                },
                loading: false
            }
        default: return state
    }
}