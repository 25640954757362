import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { fetchAddPlotOnSeason, fetchCreateSeason, fetchDeletePlotOnSeason, fetchDeleteSeason, fetchUpdatePlotOnSeason } from 'store/actions/seasons';
import { fetchSquareByID } from 'store/actions/square';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchDeletePlotById } from 'store/actions/plotById';
import { warningNotification } from 'hooks/notifications';
import { fieldByIdPlotsAndSeasonsSwitch } from 'data/lists';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import Switch from 'components/shared/switch/Switch';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import PlotCard from 'components/shared/cards/plotCard/PlotCard';
import SeasonCollapse from 'components/shared/seasonCollapse/SeasonCollapse';

import pin from 'assets/images/pins/field.svg'
import whitePin from 'assets/images/pins/whiteField.svg'
import field from 'assets/images/messageIcons/field.svg';

function PlotsAndSeasons({
    default_address,
    plots,
    seasons,
    fetchDeleteSeason,
    fetchSquareByID,
    fetchDeletePlotById,
    fetchDeletePlotOnSeason,
    fetchAddPlotOnSeason,
    fetchCreateSeason,
    fetchUpdatePlotOnSeason,
    setVisible
}) {
    const [activeSwitch, setActiveSwitch] = useState('plots')
    const [map, setMap] = useState(null)
    const [activePin, setActivePin] = useState(null)
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    const { id } = useParams()

    useEffect(() => {
        setMap(
            <Map
                defaultState={defaultState}
                width='100%'
                height='100%'
            >
                <FullscreenControl />
                <SearchControl options={{ float: "right" }} />
                <GeolocationControl options={{ float: "left" }} />
                <TypeSelector options={{ float: 'right' }} />
                {plots.map(el => (
                    <Placemark
                        key={`pin_${el.id}`}
                        geometry={[el.address.latitude, el.address.longitude]}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: (el.id === activePin ? whitePin : pin),
                            iconImageSize: [70, 70],
                            // iconImageOffset: [-5, -38]
                        }}
                        onClick={() => setActivePin(el.id)}
                    />
                ))}
            </Map>
        )
    }, [plots, activePin])

    const defaultState = {
        center: default_address ? [default_address.latitude, default_address.longitude] : [55.751574, 37.573856],
        zoom: 15,
        type: 'yandex#hybrid'
    };

    const addPlotOnSeasons = async (seasonIndex, addedData) => {
        for (let i = 0; i < addedData.length; i++) {
            const data = {
                crop_type_id: addedData[i].crop_type_id,
                plot_id: addedData[i].id
            }
            await fetchAddPlotOnSeason(seasonIndex, data)
        }
        if (addedData.length > 0) fetchSquareByID(id)
    }

    const createSeason = (newDate) => {
        const season = {
            year: newDate,
            square_id: id
        }
        fetchCreateSeason(season)
            .then(async (res) => {
                for (let i = 0; i < plots.length; i++) {
                    const addPlot = {
                        crop_type_id: plots[i].crop_type_id,
                        plot_id: plots[i].id
                    }
                    await fetchAddPlotOnSeason(res, addPlot)
                }
                fetchSquareByID(id)
            })
    }

    const editPlotOnSeason = (seasonIndex, seasonPlotIndex, value) => {
        fetchUpdatePlotOnSeason(seasonIndex, {
            crop_type_id: value,
            plot_id: seasonPlotIndex
        })
    }

    return (
        <>
            {contextHolder}
            <InfoBlock
                style={{ marginTop: 24 }}
            >
                <Switch
                    items={fieldByIdPlotsAndSeasonsSwitch}
                    activeItem={activeSwitch}
                    onSwitch={setActiveSwitch}
                    style={{
                        marginBottom: 24
                    }}
                    className="switch miniSwitch"
                />
                {activeSwitch === 'plots'
                    ?
                    <div className='regionBlock'>
                        <div className='regionBlock_list'>
                            <SimpleButton
                                title={'Добавить участок'}
                                style={{
                                    background: '#01963A',
                                    borderRadius: '100px',
                                    padding: '11px 16px',
                                    width: '100%'
                                }}
                                onClick={() => navigate(`/user/my-fields/field/${id}/plot/create`)}
                            />
                            {plots.length > 0
                                ? plots.map((el, key) => (
                                    <PlotCard
                                        key={`plotItem${key}`}
                                        style={{ marginTop: 8 }}
                                        item={el}
                                        active={el.id === activePin ? true : false}
                                        onActive={() => setActivePin(el.id)}
                                        page={'fieldByid'}
                                        deleteFunction={async (plot_id) => {
                                            warningNotification(api, async () => {
                                                await fetchDeletePlotById(plot_id)
                                                fetchSquareByID(id)
                                                api.destroy()
                                            }, 'удаление документа.')
                                        }}
                                    />
                                ))
                                :
                                <div className='regionBlock_list__noData'>
                                    <img src={field} alt='field' />
                                    <p className='mainText p24_600 p_mb24'>Нет участков</p>
                                    <p className='mainText p14_400 p_mb24'>Добавьте новые участки, чтобы они отобразились на поле</p>
                                </div>
                            }
                        </div>
                        <div className='regionBlock_map'>
                            <div className='locationBlock_map'>
                                {map}
                            </div>
                        </div>
                    </div>
                    : <>
                        {seasons.map(el => (
                            <SeasonCollapse
                                key={`season_${el.id}`}
                                item={el}
                                index={el.id}
                                current_plots={plots}
                                deleteSeason={async (index) => {
                                    await fetchDeleteSeason(index)
                                    fetchSquareByID(id)
                                }}
                                deletePlotOnSeason={async (seasonIndex, seasonPlotIndex) => {
                                    await fetchDeletePlotOnSeason(seasonIndex, seasonPlotIndex)
                                    fetchSquareByID(id)
                                }}
                                addPlotOnSeasons={addPlotOnSeasons}
                                linkOnCreatPlot={() => navigate(`/user/my-fields/field/${id}/plot/create`)}
                                editPlotOnSeason={editPlotOnSeason}
                            />
                        ))
                        }
                        <SimpleButton
                            title={'Добавить сезон'}
                            style={{
                                marginTop: 20,
                                background: '#01963A',
                                borderRadius: '100px',
                                padding: '11px 16px'
                            }}
                            onClick={() => setVisible({
                                seasons,
                                page: 'squareById',
                                createSeason: (newDate) => createSeason(newDate)
                            })}
                        />
                    </>
                }
            </InfoBlock>
        </>
    );
}

function mapStateToProps(state) {
    return {
        default_address: state.squareById.square_by_id_data.address,
        plots: state.squareById.square_by_id_data.plots,
        seasons: state.squareById.square_seasons
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDeleteSeason: (id) => dispatch(fetchDeleteSeason(id)),
        fetchDeletePlotById: (id) => dispatch(fetchDeletePlotById(id)),
        fetchAddPlotOnSeason: (id, data) => dispatch(fetchAddPlotOnSeason(id, data)),
        fetchDeletePlotOnSeason: (id, plot_id) => dispatch(fetchDeletePlotOnSeason(id, plot_id)),
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
        fetchCreateSeason: (data) => dispatch(fetchCreateSeason(data)),
        fetchUpdatePlotOnSeason: (id, data) => dispatch(fetchUpdatePlotOnSeason(id, data)),
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'createSeason', data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(PlotsAndSeasons);