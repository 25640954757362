import { connect } from 'react-redux'
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import EditCompany from 'components/components/forms/admin/profile/editCompany/EditCompany';

function Company({ edit, form, onEdit, user_data_company }) {
    
    return (
        !edit ?
            <div>
                <InfoBlock style={{ marginBottom: 16 }}>
                    <p className='p12_400 mainText p_mb8'>Название компании</p>
                    <p className='p14_400 mainText p_mb16 '>{user_data_company ? user_data_company.name ? user_data_company.name : '-' : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Адрес компании</p>
                    <p className='p14_400 mainText p_mb16'>{user_data_company ? user_data_company.address ? user_data_company.address : '-' : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>ИНН</p>
                    <p className='p14_400 mainText p_mb16'>{user_data_company ? user_data_company.inn ? user_data_company.inn : '-' : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>ОГРН</p>
                    <p className='p14_400 mainText p_mb16 '>{user_data_company ? user_data_company.ogrn ? user_data_company.ogrn : '-' : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Коммерческая почта</p>
                    <p className='p14_400 mainText p_mb16'>{user_data_company ? user_data_company.email ? user_data_company.email : '-' : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Телефон для связи</p>
                    <p className='p14_400 mainText'>{user_data_company ? user_data_company.phone ? '+' + user_data_company.phone : '-' : '-'}</p>
                </InfoBlock>
            </div>
            : <InfoBlock>
                <EditCompany form={form} onEdit={onEdit} />
            </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        user_data_company: state.userById.user_by_id_data.company
    }
}

export default connect(mapStateToProps, null)(Company);