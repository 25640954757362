import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { fetchUserByID } from 'store/actions/admin/user';
import { fetchDeleteSquare } from 'store/actions/admin/squares';
import { verificationStatusesStyle } from 'data/lists';
import { userFieldsTable } from 'data/adminTableHeader';
import { userFieldsTableWidth } from 'data/adminTableWidth';
import { warningNotification } from 'hooks/notifications';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';

function Fields({ user_by_id_data_squares, fetchUserByID, fetchDeleteSquare }) {
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams()
    const navigate = useNavigate()

    const createTableData = () => {
        return user_by_id_data_squares.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/fields/${el.id}`)}>{el.number}</p>
                },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
                {
                    type: 'text',
                    content: el.square_type.name
                },
                {
                    type: 'text',
                    content: el.size
                },
                {
                    type: 'text',
                    content: el.sowing_type ? el.sowing_type.name : '-'
                },
                {
                    type: 'text',
                    content: el.crop_type ? el.crop_type.name : '-'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchDeleteSquare(el.id)
                                fetchUserByID(id)
                                api.destroy()
                            }, 'удаление техники.')}
                        />
                    </div>
                }
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={userFieldsTable}
                width={userFieldsTableWidth}
                data={createTableData()}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data_squares: state.userById.user_by_id_data.squares
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDeleteSquare: (id) => dispatch(fetchDeleteSquare(id)),
        fetchUserByID: (id) => dispatch(fetchUserByID(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Fields);