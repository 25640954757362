import { api_user_admin } from 'api/admin/user'
import { api_user } from 'api/user'
import {
    FETCH_ALL_USERS_START,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_USER_BY_ID_DATA_START,
    FETCH_USER_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

import { errorNotification } from 'hooks/notifications';

export function fetchAllUser(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_USERS_START))
        try {
            const users_data = await api_user.GetAllUsers(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_USERS_SUCCESS, {
                data: users_data.data.data
            }))
        } catch (e) {
            if (e.response.data.detail === 'Could not validate credentials') {
                dispatch(fetchAllUser(page, size, params))
            }

        }
    }
}

export function fetchCreateUser(data) {
    return async dispatch => {
        try {
            const res = await api_user_admin.CreateUser(data)
            return res.data
        } catch (e) {

        }
    }
}

export function fetchUserByID(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_BY_ID_DATA_START))
        try {
            const user_data = await api_user.GetUserById(id)
            dispatch(fetchSuccess(FETCH_USER_BY_ID_DATA_SUCCESS, {
                user_by_id_data: user_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchDeleteUser(user_id) {
    return async dispatch => {
        try {
            await api_user.DeleteUser(user_id)
        } catch (e) {

        }
    }
}

export function fetchUploadUserDocs(user_id, data) {
    return async dispatch => {
        try {
            await api_user_admin.UploadUserDocs(user_id, data)
            dispatch(fetchUserByID(user_id))
        } catch (e) {

        }
    }
}

export function fetchDeleteUserDocs(user_id, doc_id) {
    return async dispatch => {
        try {
            await api_user_admin.DeleteUserDocs(user_id, doc_id)
            dispatch(fetchUserByID(user_id))
        } catch (e) {

        }
    }
}

export function fetchConfirmUserVerificstion(user_id, verification_id) {
    return async dispatch => {
        try {
            await api_user_admin.ConfirmUserVerificstion(user_id, verification_id)
            dispatch(fetchUserByID(user_id))
        } catch (e) {

        }
    }
}

export function fetchRejectUserVerificstion(user_id, verification_id) {
    return async dispatch => {
        try {
            await api_user_admin.RejectUserVerificstion(user_id, verification_id)
            dispatch(fetchUserByID(user_id))
        } catch (e) {

        }
    }
}

export function fetchUpdateUserData(data, user_id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_BY_ID_DATA_START))
        try {
            await api_user_admin.UpdateUser(data, user_id)
            dispatch(fetchUserByID(user_id))
        } catch (e) {
            if (e.response.status === 409) {
                errorNotification('Неверные данные!', 'Такой email/ИНН/номером телефона уже существует!')
            }

        }
    }
}

export function fetchUpdateUserPhoto(data, user_id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_BY_ID_DATA_START))
        try {
            await api_user_admin.UpdateUserImg(data, user_id)
        } catch (e) {

        }
    }
}

export function fetchUpdateUserRole(user_id, role_name) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_BY_ID_DATA_START))
        try {
            await api_user_admin.UpdateUserRole(user_id, role_name)
        } catch (e) {

        }
    }
}