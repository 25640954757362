import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Upload } from 'antd';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import uploadIcon from 'assets/images/icons/upload.svg';

const propsUploadImages = {
    action: '',
    accept: ".png, .jpg",
    listType: "text",
    multiple: true,
};

const propsDocsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function TerminationContractForm({ form, reject }) {
    const [filePhotoList, setFilePhotoList] = useState([])
    const [fileDocsList, setFileDocsList] = useState([])

    const onFinish = (values) => {
        reject(values, filePhotoList, fileDocsList)
    };

    const normFile = (e, type) => {
        if (type === 'fileDocsList') {
            setFileDocsList(e.fileList)
        } else {
            setFilePhotoList(e.fileList)
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        if (type === 'fileDocsList') {
            const files = [...fileDocsList];
            files.splice(index, 1);
            setFileDocsList(files);
        } else {
            const files = [...filePhotoList];
            files.splice(index, 1);
            setFilePhotoList(files);
        }
    }

    return (
        <InfoBlock style={{ marginTop: 16 }}>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="modalForm"
                form={form}
            >
                <Form.Item
                    label={'Причина'}
                    name="reason"
                    rules={[
                        {
                            required: true,
                            message: 'Опишите кратко причину спора',
                        },
                    ]}
                >
                    <Input placeholder='Опишите кратко причину спора' />
                </Form.Item>
                <Form.Item
                    label={'Комментарий'}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Опишите подробную информацию для рассмотрения' />
                </Form.Item>
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите фото (необязательно)</p>
                    <div className={`${filePhotoList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {filePhotoList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'filePhotoList')} />
                            ))}
                        </div>
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'filePhotoList')}
                            className={`${filePhotoList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {filePhotoList.length === 0
                                ?
                                <Upload.Dragger
                                    name="filePhotoList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUploadImages}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить фото</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="filePhotoList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUploadImages}
                                    fileList={filePhotoList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите документы (необязательно)</p>
                    <div className={`${fileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {fileDocsList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'fileDocsList')} />
                            ))}
                        </div>
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'fileDocsList')}
                            className={`${fileDocsList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {fileDocsList.length === 0
                                ?
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить фото</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                    fileList={fileDocsList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </InfoBlock>
    );
}

export default connect(null, null)(TerminationContractForm);