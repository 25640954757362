import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from 'react-redux'
import { fetchMyData } from "store/actions/user";
import { fetchWorkTypes } from "store/actions/workTypes";
import { fetchTechnicTypes } from "store/actions/technicTypes";
import { fetchSquareTypes } from "store/actions/squareTypes";
import { fetchCropTypes } from "store/actions/cropTypes";
import { fetchSowingTypes } from "store/actions/sowingTypes";
import { CLEARE_USER_DATA, EXIT, SIGNUP_FIRST_STAGE_SAVE_DATA } from "store/actionTypes";
import { fetchUserUnreadedMessages } from "store/actions/chats/chats";
import { fetchUserUnreadedNotifications } from "store/actions/notifications";
import { menuListUser, menuSubList } from "data/routings";
import { createQuery, getQueryStringFromObject } from "hooks/createQuery";
import Header from "./header/Header";
import DrawerMenu from "../../shared/drawerMenu/DrawerManu";

import './style.css';

function UserLayout({
    fetchMyData,
    fetchWorkTypes,
    fetchTechnicTypes,
    fetchSquareTypes,
    user_data,
    access_token,
    square_types_loading,
    crop_types_loading,
    sowing_types_loading,
    work_types_loading,
    technic_types_loading,
    fetchCropTypes,
    fetchSowingTypes,
    EXIT,
    SIGNUP_FIRST_STAGE_SAVE_DATA,
    CLEARE_USER_DATA,
    fetchUserUnreadedMessages,
    fetchUserUnreadedNotifications,
}) {
    const navigate = useNavigate()

    useEffect(() => {
        if (access_token) {
            fetchMyData(() => navigate(`/signup/first-stage/enter-code`))
            fetchWorkTypes()
            fetchTechnicTypes()
            fetchSquareTypes()
            fetchCropTypes()
            fetchSowingTypes()
            fetchUserUnreadedMessages(1, 50, null)
            fetchUserUnreadedNotifications(1, 100, createQuery(getQueryStringFromObject(JSON.stringify({ 'is_readed': false }))))
        }
    }, [access_token])

    useEffect(() => {
        if (user_data) {
            if (!user_data.first_name && !user_data.last_name) {
                CLEARE_USER_DATA()
                EXIT()
                SIGNUP_FIRST_STAGE_SAVE_DATA({
                    user_data,
                    token: access_token
                })
                navigate(`/signup/second-stage`, { state: { mdoe: 'updateData' } })
            }

        }
    }, [user_data])

    return (
        user_data &&
        (user_data.first_name && user_data.last_name) &&
        (!square_types_loading && !crop_types_loading && !sowing_types_loading && !work_types_loading && !technic_types_loading) &&
        <div className="userLayout">
            <Header />
            <div className="userLayout_contentBlock">
                <DrawerMenu menuList={menuListUser} menuSub={menuSubList} />
                <div className="outletLayout">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
        access_token: state.userEnv.access_token,
        square_types_loading: state.squareTypes.loading,
        crop_types_loading: state.cropTypes.loading,
        sowing_types_loading: state.sowingTypes.loading,
        work_types_loading: state.workTypes.loading,
        technic_types_loading: state.technicTypes.loading,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyData: (activeCodeLink) => dispatch(fetchMyData(activeCodeLink)),
        fetchWorkTypes: () => dispatch(fetchWorkTypes()),
        fetchTechnicTypes: () => dispatch(fetchTechnicTypes()),
        fetchSquareTypes: () => dispatch(fetchSquareTypes()),
        fetchCropTypes: () => dispatch(fetchCropTypes()),
        fetchSowingTypes: () => dispatch(fetchSowingTypes()),
        EXIT: () => dispatch({ type: EXIT }),
        SIGNUP_FIRST_STAGE_SAVE_DATA: (data) => dispatch({ type: SIGNUP_FIRST_STAGE_SAVE_DATA, data }),
        CLEARE_USER_DATA: () => dispatch({ type: CLEARE_USER_DATA }),
        fetchUserUnreadedMessages: (page, size, params) => dispatch(fetchUserUnreadedMessages(page, size, params)),
        fetchUserUnreadedNotifications: (page, size, params) => dispatch(fetchUserUnreadedNotifications(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(UserLayout);