import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import ImageGallery from 'react-image-gallery';
import { fieldByIdPlotsAndSeasonsAndBidsSwitch, verificationStatusesStyle } from 'data/lists';
import { MARKET_CLEAR_SQUARES_BY_ID_DATA, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchSquaresByID } from 'store/actions/market/squares';
import { createFio } from 'hooks/createFio';
import { formatDate } from 'hooks/formatDate';
import { marketBidsTable } from 'data/tableHeader';
import { marketBidsTableWidth } from 'data/tableWidth';
import PlotCard from 'components/shared/cards/plotCard/PlotCard';
import SeasonCollapse from 'components/shared/seasonCollapse/SeasonCollapse';
import Table from 'components/ui/table/Table';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import SquareCard from 'components/shared/cards/squareCard/SquareCard';
import Switch from 'components/shared/switch/Switch';

import calendar from 'assets/images/icons/calendar.svg';
import unavailable from 'assets/images/icons/unavailable.svg';
import chat from 'assets/images/icons/chat.svg';
import telegram from 'assets/images/icons/telegram.svg';
import pin from 'assets/images/pins/field.svg';
import whitePin from 'assets/images/pins/whiteField.svg'
import eye from 'assets/images/icons/eye-green.svg';

import '../../style.css';
import '../../media.css';

function MarketSquareById({ square_by_id_data, access_token, bids, user_data, fetchSquaresByID, another_squares, setVisible }) {
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState('plots')
    const [activePin, setActivePin] = useState(null)
    const [plotsMap, setPlotsMap] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        fetchSquaresByID(id)
    }, [id])

    useEffect(() => {
        if (square_by_id_data) {
            if (square_by_id_data.address || square_by_id_data.plots.length > 0) {
                setPlotsMap(
                    <Map
                        defaultState={{
                            center: square_by_id_data.address ? [square_by_id_data.address.latitude, square_by_id_data.address.longitude] : [square_by_id_data.plots[0].address.latitude, square_by_id_data.plots[0].address.longitude],
                            zoom: 10,
                            type: 'yandex#hybrid'
                        }}
                        width='100%'
                        height='100%'
                    >
                        <FullscreenControl />
                        <SearchControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                        <TypeSelector options={{ float: 'right' }} />
                        {square_by_id_data.plots.map(el => (
                            <Placemark
                                key={`pin_${el.id}`}
                                geometry={[el.address.latitude, el.address.longitude]}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: (el.id === activePin ? whitePin : pin),
                                    iconImageSize: [70, 70],
                                    // iconImageOffset: [-5, -38]
                                }}
                                onClick={() => setActivePin(el.id)}
                            />
                        ))}
                    </Map>
                )
            }
        }
    }, [square_by_id_data, activePin])

    const onComplainsts = () => {
        if (access_token) {
            setVisible('fileComplaint', {
                accused_id: square_by_id_data.owner.id
            })
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы пожаловаться на пользователя сначала авторизуйтесь в системе' })
        }
    }

    const onReply = () => {
        if (access_token) {
            if (user_data.status === "VERIFIED") {
                navigate(`/market/fields/${id}/create-reply`)
            } else {
                setVisible('noVerification', { worning_text: 'Чтобы откликнуться на заявку вам необходимо пройти верификацию. Нажмите на кнопку ниже для продолжения.' })
            }
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы предложить сделаку сначала авторизуйтесь в системе' })
        }

    }

    const onStartChat = () => {
        if (access_token) {
            navigate(`/user/messages?reciever_id=${square_by_id_data.owner.id}`)
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы начать чат с пользователем сначала авторизуйтесь в системе' })
        }
    }


    const createTableData = () => {
        return bids.map(el => (
            [
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: el.crop_type ? el.crop_type.name : '-'
                },
                {
                    type: 'text',
                    content: 'с ' + el.date_from + ' по ' + el.date_to
                },
                {
                    type: 'text',
                    content: el.description
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: el.payment_desc
                },
                {
                    type: 'actions',
                    content: <img src={eye} alt="eye" style={{ cursor: 'pointer' }} onClick={() => setVisible('bidInfo', { bid: el, map_center: square_by_id_data.address ? [square_by_id_data.address.latitude, square_by_id_data.address.longitude] : [square_by_id_data.plots[0].address.latitude, square_by_id_data.plots[0].address.longitude] })} />
                },
            ]
        ))
    }

    return (
        square_by_id_data &&
        <div className='marketDetalPage'>
            <div className='marketDetalPage__left'>
                {square_by_id_data.Images.length > 0 &&
                    <InfoBlock
                        style={{ marginBottom: 16, padding: 20 }}
                    >
                        <ImageGallery
                            items={square_by_id_data.Images.map(el => ({
                                original: el.media.link,
                                thumbnail: el.media.link,
                            }))}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            showBullets={true}
                        />
                    </InfoBlock>
                }
                <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                    <p className='p24_600 mainText p_mb24'>Информация о поле</p>
                    <InfoBlock
                        style={{ marginBottom: 16, padding: 20 }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Адрес</p>
                            <p className='p14_400 mainText p_mb16 activeText'>-</p>
                            <p className='p12_400 mainText p_mb8'>Регион</p>
                            <p className='p14_400 mainText p_mb16'>-</p>
                            <p className='p12_400 mainText p_mb8'>Тип поля</p>
                            <p className='p14_400 mainText'>{square_by_id_data.square_type.name || '-'}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Кадастровый номер</p>
                            <p className='p14_400 mainText p_mb16'>{square_by_id_data.cadastral || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Размер поля, Га</p>
                            <p className='p14_400 mainText'>{square_by_id_data.size || '-'}</p>
                        </div>
                    </InfoBlock>
                    <InfoBlock
                        style={{ marginBottom: 16, padding: 20 }}
                    >
                        <p className='p12_400 mainText p_mb8'>Описание</p>
                        <p className='p14_400 mainText'>{square_by_id_data.description || '-'}</p>
                    </InfoBlock>
                </InfoBlock>
                {(square_by_id_data.address && square_by_id_data.plots.length > 0) &&
                    <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                        <p className='p24_600 mainText p_mb24'>Местоположение</p>
                        <div className='locationBlock_map'>
                            <Map
                                defaultState={{
                                    center: square_by_id_data.address ? [square_by_id_data.address.latitude, square_by_id_data.address.longitude] : [square_by_id_data.plots[0].address.latitude, square_by_id_data.plots[0].address.longitude],
                                    zoom: 10,
                                    type: 'yandex#hybrid'
                                }}
                                width='100%'
                                height='100%'
                            >
                                <FullscreenControl />
                                <GeolocationControl options={{ float: "left" }} />
                                <TypeSelector options={{ float: 'right' }} />
                                <Placemark
                                    geometry={square_by_id_data.address ? [square_by_id_data.address.latitude, square_by_id_data.address.longitude] : [square_by_id_data.plots[0].address.latitude, square_by_id_data.plots[0].address.longitude]}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: pin,
                                        iconImageSize: [70, 70],
                                        // iconImageOffset: [-5, -38]
                                    }}
                                />
                            </Map>
                        </div>
                    </InfoBlock>
                }
                {square_by_id_data.plots.length > 0 &&
                    <InfoBlock style={{ marginBottom: 16, padding: 20 }}>
                        <p className='p24_600 mainText p_mb24'>Участки</p>
                        <Switch
                            items={fieldByIdPlotsAndSeasonsAndBidsSwitch}
                            activeItem={activeSwitch}
                            onSwitch={setActiveSwitch}
                            style={{
                                marginBottom: 24
                            }}
                            className="switch miniSwitch"
                        />
                        {activeSwitch === 'plots' &&
                            <div className='regionBlock'>
                                <div className='regionBlock_list'>
                                    {square_by_id_data.plots.map((el, key) => (
                                        <PlotCard
                                            key={`plotItem${key}`}
                                            style={{ marginTop: 8 }}
                                            item={el}
                                            active={el.id === activePin ? true : false}
                                            onActive={() => setActivePin(el.id)}
                                            page={'market'}
                                        />
                                    ))
                                    }
                                </div>
                                <div className='regionBlock_map'>
                                    <div className='locationBlock_map'>
                                        {plotsMap}
                                    </div>
                                </div>
                            </div>
                        }
                        {activeSwitch === 'seasons' &&
                            square_by_id_data.seasons.map(el => (
                                <SeasonCollapse
                                    key={`season_${el.id}`}
                                    item={el}
                                    index={el.id}
                                    page={'market'}
                                />
                            ))
                        }
                        {activeSwitch === 'bids' &&
                            <Table
                                header={marketBidsTable}
                                width={marketBidsTableWidth}
                                data={createTableData()}
                            />
                        }
                    </InfoBlock>
                }
                <div className='otherInfo2'>
                    <p className='p24_600 mainText p_mb16'>Другие поля</p>
                    {another_squares && another_squares.map(el => {
                        if (el.id !== id) {
                            return <SquareCard key={`squareCard_${el.id}`} item={el} />
                        }
                    })}
                </div>
            </div>
            <div className='marketDetalPage__right'>
                <InfoBlock style={{ marginBottom: 30, padding: 20 }}>
                    <p className='mainText p24_600 p_mb8'>{createFio(square_by_id_data.owner)}</p>
                    <div className='flexBlock'>
                        <img src={calendar} alt="calendar" />
                        <p className='mainText p14_400'>На сайте с {formatDate(square_by_id_data.owner.created_at)}</p>
                    </div>
                    {square_by_id_data.owner.status &&
                        <div className='flexBlock'>
                            <p className='mainText p14_400'>Верификация</p>
                            <div className='statusBlock p12_400' style={{ backgroundColor: verificationStatusesStyle[square_by_id_data.owner.status].bg }}>{verificationStatusesStyle[square_by_id_data.owner.status].title}</div>
                        </div>
                    }
                    <div className='flexBlock flex_start'>
                        <p className='mainText p14_400 p_mr16'>Компания</p>
                        <p className='mainText p14_400 activeText'>{square_by_id_data.owner.company ? square_by_id_data.owner.company.name : '-'}</p>
                    </div>
                    <div className='flexBlock flex_start'>
                        <p className='mainText p14_400 p_mr16'>Адрес</p>
                        <p className='mainText p14_400 activeText'>{square_by_id_data.owner.company ? square_by_id_data.owner.company.address : '-'}</p>
                    </div>
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={unavailable} alt="unavailable" style={{ marginRight: 8 }} />Пожаловаться</p>}
                        style={{
                            width: '100%',
                            color: '#D32F2F',
                            border: '1px solid #D32F2F',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={onComplainsts}
                    />
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={chat} alt="unavailable" style={{ marginRight: 8 }} />Начать чат</p>}
                        style={{
                            width: '100%',
                            color: '#FFFFFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={onStartChat}
                    />
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={telegram} alt="unavailable" style={{ marginRight: 8 }} />Предложить сделку</p>}
                        style={{
                            width: '100%',
                            color: '#FFFFFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            marginTop: 8
                        }}
                        onClick={onReply}
                    />
                </InfoBlock>
                <div className='otherInfo'>
                    <p className='p24_600 mainText p_mb16'>Другие поля</p>
                    {another_squares && another_squares.map(el => {
                        if (el.id !== id) {
                            return <SquareCard key={`squareCard_${el.id}`} item={el} />
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        access_token: state.userEnv.access_token,
        user_data: state.user.user_data,
        square_by_id_data: state.marketSquaresById.square_by_id_data,
        another_squares: state.marketSquaresById.another_squares,
        bids: state.marketSquaresById.bids,
        user_data: state.user.user_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchSquaresByID: (id) => dispatch(fetchSquaresByID(id)),
        MARKET_CLEAR_SQUARES_BY_ID_DATA: () => dispatch({ type: MARKET_CLEAR_SQUARES_BY_ID_DATA }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MarketSquareById);