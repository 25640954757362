import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchSensitive } from 'store/actions/user';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function ConfirmProfileChangesForm({ data, setVisible, fetchSensitive }) {
    const [fields, setFields] = useState([
        {
            name: 'code',
            value: null,
        },
    ]);

    const onFinish = ({ code }) => {
        fetchSensitive(code, data.email, data.phone)
            .then(res => {
                if (res) setVisible()
            })
    };

    return (
        <>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
            >
                <Form.Item
                    label={'Код подтверждения:'}
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите код подтверждения' />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 0 }}
                >
                    <SimpleButton
                        title={'Изменить данные'}
                        style={{
                            color: '#FFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            width: '100%',
                        }}
                        htmlType="submit"
                    // onClick={}
                    />
                </Form.Item>
            </Form>
            <SimpleButton
                title={'Отменить'}
                style={{
                    color: '#01963A',
                    backgroundColor: '#FFF',
                    fontWeight: 500,
                    width: '100%',
                    marginTop: '16px'
                }}
                onClick={() => setVisible()}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchSensitive: (code, email, phone) => dispatch(fetchSensitive(code, email, phone))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ConfirmProfileChangesForm);