import file from 'assets/images/icons/file.svg';
import close from 'assets/images/icons/close_grey.svg';

import './style.css';

function UploadCard({ title, onClick }) {
    return (
        <div className='uploadCard'>
            <img src={close} alt="close" className='closeIcon' onClick={onClick} />
            <img src={file} alt="file" className='fileIcon' />
            <p className='p14_400 subtext'>{title}</p>
        </div>
    );
}

export default UploadCard;