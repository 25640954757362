import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector, Clusterer } from '@pbe/react-yandex-maps';
import { useSearchParams } from 'react-router-dom';
import { fetchAllSquares } from 'store/actions/market/squares';
import { marketSquaresFilter, marketSquaresFilterViews } from 'data/lists';
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery';
import SquareCard from 'components/shared/cards/squareCard/SquareCard';
import SecondTypeFilter from 'components/shared/secondTypeFilter/SecondTypeFilter';
import Selector from 'components/shared/selector/Selector';

import pin from 'assets/images/pins/field.svg';
import whitePin from 'assets/images/pins/whiteField.svg';
import greyClose from 'assets/images/icons/close_grey.svg';

const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 10,
    type: 'yandex#hybrid'
};

const coords = [[55.751574, 37.573856], [55.751574, 37.583856], [55.751574, 37.593856]]

function MarketAllSquares({ allSquares, technic_types, work_types, square_types, crop_types, sowing_types, fetchAllSquares }) {
    const [activePin, setActivePin] = useState([])
    const [ymaps, setYmaps] = useState(null);
    const [placemarks, setPlacemarks] = useState(null)
    const [lastPin, setLastPin] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterView, setFilterView] = useState('all_squares')

    useEffect(() => {
        if (ymaps) {
            setPlacemarks(allSquares.map((el, key) => (
                <Placemark
                    key={`pin_${key}`}
                    geometry={[el.address.latitude, el.address.longitude]}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: whitePin,
                        iconImageSize: [70, 70],
                        iconContent: el
                    }}
                />
            )))
        }
    }, [ymaps, allSquares])

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllSquares(query.page, 100, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
        if(query.search) {
            if(JSON.parse(decodeURIComponent(query.search)).has_bids) {
                setFilterView('bids_squares')
            }
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const test = () => {
        if (ymaps) {
            const Layout = ymaps.templateLayoutFactory.createClass(
                `<div 
                style=" background: #F6F8F9; border-radius: 20px; color: #42A33A; font-size: 16px; font-weight: 700;  height: 40px; display: flex; flex-direction: row; justify-content: center; 
                align-items: center; margin-top: -40px"
                >
                {{ properties.geoObjects.length }}
                </div>`
            );

            return Layout;
        }
        return null;
    }

    const onClickCluster = (e) => {
        if (lastPin) {
            if (lastPin.type === 'cluster') {
                lastPin.target.options.set('clusterIcons', [
                    {
                        href: whitePin,
                        size: [70, 70],
                        offset: [-20, -20]
                    }
                ]);
            } else {
                lastPin.target.options.set('iconImageHref', whitePin);
            }
        }

        const target = e.get('target')
        if (target.getGeoObjects) {
            target.options.set('clusterIcons', [
                {
                    href: pin,
                    size: [70, 70],
                    offset: [-20, -20]
                }
            ]);
            setActivePin([...target.getGeoObjects()])
            setLastPin({
                type: 'cluster',
                target
            })
        } else {
            target.options.set('iconImageHref', pin);
            setActivePin([target])
            setLastPin({
                type: 'placemark',
                target
            })
        }
    }

    const onFilterView = (e) => {
        const query = Object.fromEntries([...searchParams]);
        if(query.search) {
            const obj = JSON.parse(decodeURIComponent(query.search))
            if (marketSquaresFilterViews.filter(el => el.title === e)[0].status === 'bids_squares') {
                obj.has_bids = [true]
            } else {
                if (obj.has_bids) {
                    delete obj.has_bids
                }
            }
            query.search = getQueryStringFromObject(JSON.stringify(obj))
        } else {
            const obj = {}
            if (marketSquaresFilterViews.filter(el => el.title === e)[0].status === 'bids_squares') {
                obj.has_bids = [true]
            } else {
                if (obj.has_bids) {
                    delete obj.has_bids
                }
            }
            query.search = getQueryStringFromObject(JSON.stringify(obj))
        }
        setSearchParams(query)
        setFilterView(marketSquaresFilterViews.filter(el => el.title === e)[0].status)
    }

    return (
        <div className='MarketAllTechics__block'>
            <div>
                <div className='MarketFilterBlock'>
                    <Selector
                        list={marketSquaresFilterViews}
                        activeItem={marketSquaresFilterViews.filter(el => el.status === filterView)[0].title}
                        setActiveSelect={onFilterView}
                        style={{
                            backgroundColor: marketSquaresFilterViews.filter(el => el.status === filterView)[0].bg,
                            marginRight: 7
                        }}
                    />
                    {marketSquaresFilter(technic_types, work_types, square_types, crop_types, sowing_types).map((el, key) => (
                        <SecondTypeFilter settings={el} key={`filter${key}`} />
                    ))}
                </div>
            </div>
            <div className='MarketAllTechics__block__map'>
                {activePin.length > 0 &&
                    <div className='MarketAllTechics__technics'>
                        <div className='MarketAllTechics__technics__header'>
                            <p>Количество: {activePin.length}</p>
                            <img src={greyClose} alt="greyClose" onClick={() => setActivePin([])} />
                        </div>
                        {activePin.map((el, key) => (
                            <SquareCard
                                key={`technicCard_${key}`}
                                item={el.options['_options'].iconContent}
                            />
                        ))}
                    </div>
                }
                {coords.length > 0 &&
                    <Map
                        defaultState={defaultState}
                        onLoad={ymaps => setYmaps(ymaps)}
                        width='100%'
                        height='100%'
                        modules={[
                            'templateLayoutFactory',
                            'geoObject.addon.balloon',
                            'clusterer.addon.balloon',
                        ]}
                        options={{
                            autoFitToViewport: 'always'
                        }}
                    >
                        <FullscreenControl />
                        <SearchControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                        <TypeSelector options={{ float: "right" }} />
                        <Clusterer
                            options={{
                                groupByCoordinates: false,
                                clusterIcons: [
                                    {
                                        href: whitePin,
                                        size: [70, 70],
                                        offset: [-20, -20]
                                    }
                                ],
                                clusterIconContentLayout: test(),
                                clusterDisableClickZoom: true,
                                clusterHideIconOnBalloonOpen: false,
                                hasBalloon: false,
                            }}
                            onClick={(e) => onClickCluster(e)}
                        >
                            {placemarks}
                        </Clusterer>
                    </Map>
                }
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        allSquares: state.marketAllSquares.squares,
        technic_types: state.technicTypes.technic_types,
        work_types: state.workTypes.work_types,
        square_types: state.squareTypes.square_types,
        crop_types: state.cropTypes.crop_types,
        sowing_types: state.sowingTypes.sowing_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllSquares: (page, size, params) => dispatch(fetchAllSquares(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MarketAllSquares);