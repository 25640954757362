import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function PrivatePerson({mode}) {
    const navigate = useNavigate()

    const onFinish = (values) => {
        navigate('/signup/second-stage/specified-data', { state: mode })
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item>
                <FormButton
                    title={'Далее'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

export default PrivatePerson;