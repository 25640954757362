import {
    FETCH_PUBLIC_USER_DATA_START,
    FETCH_PUBLIC_USER_DATA_SUCCESS,
    CLEAR_PUBLIC_USER_DATA,
} from '../../actionTypes'

const initialState = {
    public_user_by_id: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PUBLIC_USER_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_PUBLIC_USER_DATA_SUCCESS:
            return {
                ...state,
                public_user_by_id: action.data.public_user_by_id
            }
        case CLEAR_PUBLIC_USER_DATA:
            return {
                ...state,
                public_user_by_id: null
            }
        default: return state
    }
}