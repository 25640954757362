import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Upload } from 'antd';
import { fetchUpdateUserData, fetchUpdateUserPhoto } from 'store/actions/admin/user';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { checkFioLength } from 'hooks/checkFioLength';

import pen_white from 'assets/images/icons/pen_white.svg';

const propsUpload = {
    action: '',
    accept: ".png, .jpg",
    listType: "picture",
    maxCount: 1,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }

    return e?.fileList;
};

function EditMainInfo({ form, onEdit, user_data, fetchUpdateUserData, fetchUpdateUserPhoto }) {
    const [fileList, setFileList] = useState(null)
    const [fields, setFields] = useState([
        {
            name: 'fio',
            value: user_data.first_name + ' ' + user_data.last_name + ' ' + user_data.third_name,
        },
        {
            name: 'email',
            value: user_data.email,
        },
        {
            name: 'phone',
            value: user_data.phone.substring(1),
        }
    ]);

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = async () => {
        if (fields.length === 4) {
            const fioArray = checkFioLength(fields[1].value)
            if (fioArray.length === 3) {
                const data = {
                    email: fields[2].value,
                    phone: fields[3].value ? '7' + fields[3].value : fields[3].value,
                    first_name: fioArray[0],
                    last_name: fioArray[1],
                    third_name: fioArray[2],
                }
                if (fields[0].value !== undefined) {
                    let photo = new FormData();
                    let filedata = fields[0].value[0].originFileObj
                    delete filedata.uid
                    photo.append('image_file', filedata);
                    await fetchUpdateUserPhoto(photo, user_data.id)
                }
                fetchUpdateUserData(data, user_data.id)
                onEdit()
            }
        } else {
            onEdit()
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name="photo"
                getValueFromEvent={normFile}
            >
                <Upload
                    {...propsUpload}
                    fileList={fileList}
                    onChange={handleChange}
                    customRequest={dummyRequest}
                    className="editUpload"
                    itemRender={null}
                >
                    <div className='profileUpload'>
                        <img src={pen_white} alt="pen_white" />
                    </div>
                </Upload>
            </Form.Item>
            <Form.Item
                label={'ФИО'}
                name="fio"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите ФИО' />
            </Form.Item>
            <Form.Item
                label={'Электронная почта'}
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите почту' />
            </Form.Item>
            <Form.Item
                label={'Номер телефона'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" onKeyDown={numberInputFormat}/>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.userById.user_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateUserData: (data, user_id) => dispatch(fetchUpdateUserData(data, user_id)),
        fetchUpdateUserPhoto: (data, user_id) => dispatch(fetchUpdateUserPhoto(data, user_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditMainInfo);