import { ApiUrl, instance } from './apiEnv';

export const api_market = {

    async GetAllTechnics(page, size, params) {
        return await instance.get(`${ApiUrl}exhange/technics?page=${page}&size=${size}&${params}`)
    },

    async GetTechnicById(id) {
        return await instance.get(`${ApiUrl}exhange/technics/${id}`)
    },

    async GetTechnicByIdConfidential(id) {
        return await instance.get(`${ApiUrl}exhange/technics/${id}/confidential`)
    },

    async GetAllSquares(page, size, params) {
        return await instance.get(`${ApiUrl}exhange/squares?page=${page}&size=${size}&${params}`)
    },

    async GetSquaresById(id) {
        return await instance.get(`${ApiUrl}exhange/squares/${id}`)
    },

    async GetBidsBySquare(id) {
        return await instance.get(`${ApiUrl}squares/${id}/bids`)
    },

    async GetBidsByTechnics(id) {
        return await instance.get(`${ApiUrl}technics/${id}/bids`)
    },
    
}