import { api_market } from 'api/market'
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery'
import {
    FETCH_MARKET_ALL_TECHNICS_START,
    FETCH_MARKET_ALL_TECHNICS_SUCCESS,
    MARKET_FETCH_ANOTHER_TECHNICS,
    MARKET_FETCH_TECHNIC_BY_ID_DATA_START,
    MARKET_FETCH_TECHNIC_BY_ID_DATA_SUCCESS,
    MARKET_FETCH_TECHNIC_CONFIDATIAL_DATA_BY_ID_SUCCESS,
    MARKET_FETCH_BIDS_BY_TECHNICS
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllTechnics(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_MARKET_ALL_TECHNICS_START))
        try {
            const technic_data = await api_market.GetAllTechnics(page, size, params)
            dispatch(fetchSuccess(FETCH_MARKET_ALL_TECHNICS_SUCCESS, {
                data: technic_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchAnotherTechnics(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_MARKET_ALL_TECHNICS_START))
        try {
            const technic_data = await api_market.GetAllTechnics(page, size, params)
            dispatch(fetchSuccess(MARKET_FETCH_ANOTHER_TECHNICS, {
                data: technic_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchTechnicsByID(id) {
    return async dispatch => {
        dispatch(fetchStart(MARKET_FETCH_TECHNIC_BY_ID_DATA_START))
        try {
            const tehnic_data = await api_market.GetTechnicById(id)
            await dispatch(fetchSuccess(MARKET_FETCH_TECHNIC_BY_ID_DATA_SUCCESS, {
                tehnics_by_id_data: tehnic_data.data.data,
                is_liked: tehnic_data.data.meta.is_liked
            }))
            await dispatch(fetchAnotherTechnics(1, 10, createQuery(getQueryStringFromObject(JSON.stringify({ 'technic_type_id': [`${tehnic_data.data.data.technic_type.id}`] })))))
            dispatch(fetchBidsByTechnic(id))
        } catch (e) {

        }
    }
}

export function fetchTechnicsByIdConfidentialData(id) {
    return async dispatch => {
        try {
            const tehnic_data = await api_market.GetTechnicByIdConfidential(id)
            await dispatch(fetchSuccess(MARKET_FETCH_TECHNIC_CONFIDATIAL_DATA_BY_ID_SUCCESS, {
                tehnics_by_id_data: tehnic_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchBidsByTechnic(id) {
    return async dispatch => {
        try {
            const bids_by_technic_data = await api_market.GetBidsByTechnics(id)
            await dispatch(fetchSuccess(MARKET_FETCH_BIDS_BY_TECHNICS, {
                data: bids_by_technic_data.data.data
            }))
        } catch (e) {

        }
    }
}