import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchSearchCompany } from 'store/actions/services';
import { SIGNUP_SECOND_STAGE_SAVE_COMPANY_DATA } from 'store/actionTypes';
import FormButton from 'components/shared/buttons/formButton/FormButton';
import CompanyCard from 'components/shared/cards/companyCard/CompanyCard';

import './style.css'

function LegalPerson({ fetchSearchCompany, search_company, saveCompanyData, mode }) {
    const navigate = useNavigate()

    const onFinish = (values) => {
        fetchSearchCompany(values.company_search_query, 20)
    };

    const onClickCompany = (item) => {
        saveCompanyData(item)
        navigate('/signup/second-stage/specified-data-legal-person', { state: mode })
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'ИНН/ОГРН/Названию'}
                name="company_search_query"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите ИНН/ОГРН/Название' />
            </Form.Item>
            {search_company.length > 0 &&
                <div className='serchLegalPersonBlock'>
                    {search_company.map(item => (
                        <CompanyCard
                            key={item.inn}
                            title={item.name}
                            inn={item.inn}
                            address={item.address}
                            onClick={() => onClickCompany(item)}
                        />
                    ))}
                </div>
            }
            <Form.Item>
                <FormButton
                    title={'Далее'}
                    style={{ width: '100%' }}
                    htmltype="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        search_company: state.services.search_company,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchSearchCompany: (query, limit) => dispatch(fetchSearchCompany(query, limit)),
        saveCompanyData: (select_company_data) => dispatch({ type: SIGNUP_SECOND_STAGE_SAVE_COMPANY_DATA, data: { select_company_data } })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(LegalPerson);