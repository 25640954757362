import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RootReducer from 'store/rootReducer';
import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { YMaps } from '@pbe/react-yandex-maps';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(thunk))
)

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <YMaps
          query={{
            lang: "ru_RU",
            apikey: process.env.REACT_APP_YMAPS_KEY_API,
          }}
        >
          <App />
        </YMaps>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
