import {
    FETCH_USER_DATA_START,
    FETCH_USER_DATA_SUCCESS,
    CLEARE_USER_DATA,
} from '../../actionTypes'

const initialState = {
    user_data: null,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_USER_DATA_SUCCESS:
            return {
                ...state,
                user_data: action.data.user_data
            }
        case CLEARE_USER_DATA:
            return {
                user_data: null
            }
        default: return state
    }
}