import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { fetchConflictById } from 'store/actions/conflictById';
import { CLEAR_CONFLICT_BY_ID_DATA } from 'store/actionTypes';
import { conflictBreadcrumb } from "data/brebcrumb";
import { conflictByIdSwitch } from 'data/adminLists';
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Switch from 'components/shared/switch/Switch';
import MainInfo from './mainInfo/MainInfo';
import Docs from './docs/Docs';

function ConflictById({ conflict_by_id_data, fetchConflictById, CLEAR_CONFLICT_BY_ID_DATA }) {
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')

    useEffect(() => {
        fetchConflictById(id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_CONFLICT_BY_ID_DATA()
        }
    }, [])
    
    return (
        conflict_by_id_data &&
        <div className='allUsersBlock'>
            <Breadcrumb list={conflictBreadcrumb} activeLink={`Спор ${conflict_by_id_data.id}`} />
            <TextHeading text={`Спор (ID сделки ${conflict_by_id_data.contract_id})`} />
            <Switch
                items={conflictByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo />}
            {activeSwitch === 'docs' && <Docs />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        conflict_by_id_data: state.conflictById.conflict_by_id_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchConflictById: (id) => dispatch(fetchConflictById(id)),
        CLEAR_CONFLICT_BY_ID_DATA: () => dispatch({ type: CLEAR_CONFLICT_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ConflictById);