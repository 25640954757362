import {
    SAVE_CREATE_TECHNIC_DATA,
    SAVE_CREATE_TECHNIC_MAIN_FILES,
    SAVE_CREATE_TECHNIC_OTHER_FILES,
    CLEAR_CREATE_TECHNIC_DATA,
    SAVE_CREATE_TECHNIC_BUSY_PERIOD,
    SAVE_COORDS
} from '../actionTypes'

const clearCreatTechnic = {
    model: null,
    gos_num: null,
    manufacturer: null,
    release_date: null,
    power: null,
    PSM_serial: null,
    PSM_number: null,
    PSM_date: null,
    description: null,
    extra_devices: null,
    extra_devices_desc: null,
    is_owner: true,
    is_hidden: false,
    technic_type_id: null,
    work_type_id: null
}

const initialState = {
    create_technic: {
        model: null,
        gos_num: null,
        manufacturer: null,
        release_date: null,
        power: null,
        PSM_serial: null,
        PSM_number: null,
        PSM_date: null,
        description: null,
        extra_devices: null,
        extra_devices_desc: null,
        is_owner: true,
        is_hidden: false,
        technic_type_id: null,
        work_type_id: null
    },
    main_info_files: [],
    main_info_filesPSM: [],
    other_info_files: [],
    busyPeriods: [],
    coords: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CREATE_TECHNIC_DATA:
            return {
                ...state,
                create_technic: action.create_technic
            }
        case SAVE_CREATE_TECHNIC_MAIN_FILES:
            return {
                ...state,
                main_info_files: action.data.main_info_files,
                main_info_filesPSM: action.data.main_info_filesPSM,
            }
        case SAVE_CREATE_TECHNIC_OTHER_FILES:
            return {
                ...state,
                other_info_files: action.other_info_files,
            }
        case SAVE_COORDS:
            return {
                ...state,
                coords: action.coords,
            }
        case SAVE_CREATE_TECHNIC_BUSY_PERIOD:
            return {
                ...state,
                busyPeriods: action.busyPeriods,
            }
        case CLEAR_CREATE_TECHNIC_DATA:
            return {
                ...state,
                create_technic: clearCreatTechnic,
                main_info_files: [],
                main_info_filesPSM: [],
                other_info_files: [],
                busyPeriods: [],
                coords: null
            }
        default: return state
    }
}