import { connect } from 'react-redux'
import { Checkbox, Form, Input, Radio, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchMyTechnic } from 'store/actions/techics';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchBidsBySquare, fetchSquaresByID } from 'store/actions/market/squares';
import { fetchAddTechnicToReplies, fetchCreateReplies } from 'store/actions/replies';
import { fetchAddPlotsDeals, fetchAddTechnicToDeals, fetchCreateDeals } from 'store/actions/deals';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { errorNotification, successNotification } from 'hooks/notifications';
import { formatDate } from 'hooks/formatDate';
import { fieldByIdPlotsAndSeasonsSwitch } from 'data/lists';
import { carFromReplyBreadcrumb } from 'data/brebcrumb';
import { createReplyBySquareBidsTable, createReplyBySquareTable } from 'data/tableHeader';
import { createReplyBySquareBidsTableWidth, createReplyBySquareTableWidth } from 'data/tableWidth';
import Table from 'components/ui/table/Table';
import Pagination from 'components/shared/pagination/Pagination';
import CustomDatePicker from '../../../../shared/datePicker/DatePicker';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import Switch from 'components/shared/switch/Switch';
import SeasonCollapseItem from 'components/shared/seasonCollapse/SeasonCollapseItem';
import SeasonCollapse from 'components/shared/seasonCollapse/SeasonCollapse';

import eye from 'assets/images/icons/eye-green.svg';
import field from 'assets/images/messageIcons/field.svg';

const { Option } = Select;

function CreateReplyBySquare({
    form,
    user_data_technics,
    pages,
    bids,
    user_data,
    square_by_id_data,
    work_types,
    crop_types,
    fetchMyTechnic,
    setVisible,
    fetchBidsBySquare,
    fetchCreateReplies,
    fetchAddTechnicToReplies,
    fetchSquaresByID,
    fetchCreateDeals,
    fetchAddPlotsDeals,
    fetchAddTechnicToDeals,
}) {
    const [range, setRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])
    const [activeSwitch, setActiveSwitch] = useState('plots')
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyTechnic(query.page, 100, '')
            fetchBidsBySquare(id)
            fetchSquaresByID(id)
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
    ])


    const createTableData = () => {
        return user_data_technics.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-cars/${el.id}`, { state: { breadcrumb: carFromReplyBreadcrumb } })}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.gos_num
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        {el.status === "VERIFIED"
                            ?
                            <Checkbox value={el.id} />
                            :
                            <div
                                style={{
                                    background: '#01963A',
                                    borderRadius: '24px',
                                    padding: '4px 8px',
                                    width: 139

                                }}
                                className="whiteButtonText p12_400"
                            >
                                Пройти верификацию
                            </div>
                        }
                    </div >
                },
            ]
        ))
    }

    const createBidsTableData = () => {
        return bids.map(el => (
            [
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: el.crop_type ? el.crop_type.name : '-'
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: 'с ' + el.date_from + ' по ' + el.date_to
                },
                {
                    type: 'text',
                    content: el.description
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: el.payment_desc
                },
                {
                    type: 'actions',
                    content: <img src={eye} alt="eye" style={{ cursor: 'pointer' }} onClick={() => setVisible('bidInfo', { bid: el, map_center: [el.plots[0].address.latitude, el.plots[0].address.longitude] })} />
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <Radio value={el} />
                    </div >
                },
            ]
        ))
    }

    const onFinish = async (value) => {
        if (user_data.status === "VERIFIED") {
            if (bids.length > 0 && (!value.bids || value.bids.length === 0)) {
                errorNotification('Вы не выбрали заявку')
                return
            }
            if (bids.length === 0 && (!value.plots || value.plots.length === 0)) {
                errorNotification('Вы не выбрали участки')
                return
            }
            if (!value.technics || value.technics.length === 0) {
                errorNotification('Вы не выбрали технику')
                return
            }
            if (bids.length > 0) {
                const obj = {
                    reply_type: "TECHNICIAN",
                    date_from: formatDate(range[0].startDate),
                    date_to: formatDate(range[0].endDate),
                    description: value.description,
                    payment_desc: value.payment_desc,
                    amount: value.amount,
                    bid_id: value.bids.id,
                }
                await fetchCreateReplies(obj)
                    .then(async (res) => {
                        if (res) {
                            await fetchAddTechnicToReplies(res, value.technics)
                            navigate(`/market/fields/${id}`)
                            successNotification('Заявка отправлена', 'Заявка успешно отправлена')
                        } else {
                            errorNotification('Возникла ошибка')
                        }
                    })
            } else {
                const data = {
                    amount: value.amount,
                    valid_from: formatDate(range[0].startDate),
                    valid_to: formatDate(range[0].endDate),
                    description: value.description,
                    payment_desc: value.payment_desc,
                    is_viewed: false,
                    reply_type: "TECHNICIAN",
                    crop_type_id: value.crop_type_id,
                    work_type_id: value.work_type_id,
                    executor_id: square_by_id_data.owner.id,
                    square_id: id
                }
                await fetchCreateDeals(data)
                    .then(async (res) => {
                        if (res) {
                            await fetchAddPlotsDeals(res, value.plots)
                            await fetchAddTechnicToDeals(res, value.technics)
                            navigate(`/market/fields/${id}`)
                            successNotification('Предложение о сотрудничестве отправлено', 'Предложение о сотрудничестве успешно отправлено')
                        } else {
                            errorNotification('Возникла ошибка')
                        }
                    })
            }
        } else {
            setVisible('noVerification', { worning_text: 'Чтобы откликнуться на заявку или оставить предложение о сотрудничестве вам необходимо пройти верификацию. Нажмите на кнопку ниже для продолжения.' })
        }
    };
    
    return (
        user_data_technics &&
        <Form
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            className="modalForm"
            form={form}
            onFinish={onFinish}
        >
            {bids.length > 0
                ?
                <Form.Item
                    name="bids"
                    label="Выберите заявку по этому полю"
                >
                    <Radio.Group style={{ width: '100%', display: 'block' }}>
                        <Table
                            header={createReplyBySquareBidsTable}
                            width={createReplyBySquareBidsTableWidth}
                            data={createBidsTableData()}
                        />
                    </Radio.Group>
                </Form.Item>
                : square_by_id_data && (
                    square_by_id_data.plots.length > 0
                        ?
                        <Form.Item
                            name="plots"
                            label="Выберите участок"
                        >
                            <Checkbox.Group style={{ width: '100%', display: 'block' }}>
                                <Switch
                                    items={fieldByIdPlotsAndSeasonsSwitch}
                                    activeItem={activeSwitch}
                                    onSwitch={setActiveSwitch}
                                    style={{
                                        marginBottom: 24
                                    }}
                                    className="switch miniSwitch"
                                />
                                <div className='scrollContent'>
                                    {activeSwitch === 'plots'
                                        ? square_by_id_data.plots.map(el => (
                                            <SeasonCollapseItem
                                                key={`seasonCollapsItem_${el.id}`}
                                                item={el}
                                                page={'check'}
                                            />
                                        ))
                                        : square_by_id_data.seasons.map(el => (
                                            <SeasonCollapse
                                                key={`season_${el.id}`}
                                                item={el}
                                                index={el.id}
                                                page={'check'}
                                            />
                                        ))
                                    }
                                </div>
                            </Checkbox.Group>
                        </Form.Item>
                        :
                        <InfoBlock style={{ textAlign: 'center' }}>
                            <img src={field} alt="field" />
                            <p className='mainText p24_600 p_mb24'>Нет участков</p>
                            <p className='mainText p14_400 p_mb24'>На данный момент у нас нет информации об участках по этому полю</p>
                        </InfoBlock>
                )
            }
            <Form.Item
                name="technics"
                label="Выберите технику (допускаются только верифицированные)"
            >
                <Checkbox.Group style={{ width: '100%', display: 'block' }}>
                    <Table
                        header={createReplyBySquareTable}
                        width={createReplyBySquareTableWidth}
                        data={createTableData()}
                    />
                    {pages > 1 && <Pagination pages={pages} />}
                </Checkbox.Group>
            </Form.Item>
            <p>В какие даты свободна техника</p>
            <div className='calendarBlock'>
                <CustomDatePicker
                    ranges={range}
                    mode="userCreateReply"
                    saveRange={state => setRange(state)}
                    edit={false}
                />
            </div>
            <InfoBlock>
                {bids.length === 0 &&
                    <>
                        <Form.Item
                            label={'Тип работ'}
                            name="work_type_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите тип работ">
                                {work_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={'Обрабатываемая культуры '}
                            name="crop_type_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите тип выращиваемой культуры">
                                {crop_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                }
                <Form.Item
                    label={'Комментарий'}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите описание заявки' />
                </Form.Item>
                <Form.Item
                    label={'Ваши условия оплаты'}
                    name="payment_desc"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите предварительные условия оплаты' />
                </Form.Item>
                <Form.Item
                    label={'Предлагаемая цена, ₽'}
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите стоимость' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
            </InfoBlock>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data_technics: state.userTechnics.technics,
        square_by_id_data: state.marketSquaresById.square_by_id_data,
        user_data: state.user.user_data,
        pages: state.userTechnics.pages,
        bids: state.marketSquaresById.bids,
        work_types: state.workTypes.work_types,
        crop_types: state.cropTypes.crop_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyTechnic: (page, size, params) => dispatch(fetchMyTechnic(page, size, params)),
        fetchSquaresByID: (id) => dispatch(fetchSquaresByID(id)),
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchBidsBySquare: (id) => dispatch(fetchBidsBySquare(id)),
        fetchCreateReplies: (data) => dispatch(fetchCreateReplies(data)),
        fetchAddTechnicToReplies: (replay_id, data) => dispatch(fetchAddTechnicToReplies(replay_id, data)),
        fetchCreateDeals: (data) => dispatch(fetchCreateDeals(data)),
        fetchAddPlotsDeals: (deal_id, data) => dispatch(fetchAddPlotsDeals(deal_id, data)),
        fetchAddTechnicToDeals: (deal_id, data) => dispatch(fetchAddTechnicToDeals(deal_id, data)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateReplyBySquare);