import { connect } from 'react-redux'
import { notification } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchTerminateContract } from 'store/actions/admin/contracts';
import { contractsStatusesStyle } from 'data/lists';
import { successNotification, warningNotification } from 'hooks/notifications';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import red_cross from 'assets/images/icons/red_cross.svg';

function MainInfo({ contract_by_id_data, setVisible, fetchTerminateContract }) {
    const [api, contextHolder] = notification.useNotification();

    return (
        <>
            {contextHolder}
            <InfoBlock
                className="infoBlock twoRow"
                style={{ marginBottom: 24 }}
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>ID сделки</p>
                    <p className='p14_400 mainText p_mb16 activeText'>{contract_by_id_data.id}</p>
                    <p className='p12_400 mainText p_mb8'>Тип культур</p>
                    <p className='p14_400 mainText p_mb16'>{contract_by_id_data.crop_type ? contract_by_id_data.crop_type.name : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Сумма контракта, ₽</p>
                    <p className='p14_400 mainText'>{contract_by_id_data.amount + '₽' || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Дата начала контракта</p>
                    <p className='p14_400 mainText p_mb16'>{contract_by_id_data.valid_from}</p>
                    <p className='p12_400 mainText p_mb8'>Дата завершения контракта</p>
                    <p className='p14_400 mainText p_mb16'>{contract_by_id_data.valid_to}</p>
                    {/* <p className='p12_400 mainText p_mb8'>Адрес выполнения контракта</p>
                    <p className='p14_400 mainText p_mb16'>В РАБОТЕ</p> */}
                    <p className='p12_400 mainText p_mb8'>Статус</p>
                    <div className='statusOnTable p12_400' style={{ backgroundColor: contractsStatusesStyle[contract_by_id_data.status].bg }}>{contractsStatusesStyle[contract_by_id_data.status].title}</div>
                </div>
            </InfoBlock>
            {contract_by_id_data.status === 'COMPLITED' &&
                <SimpleButton
                    title={'Контракт завершен'}
                    style={{
                        color: '#FFF',
                        border: '1px solid #01963A',
                        borderRadius: '100px',
                        backgroundColor: '#01963A',
                        fontWeight: 500,
                        cursor: 'auto'
                    }}
                />
            }
            {contract_by_id_data.status === 'UNCONCLUDED' &&
                <SimpleButton
                    title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={red_cross} alt="red_cross" style={{ marginRight: 8 }} />Закрыть контракт</p>}
                    style={{
                        color: '#D32F2F',
                        border: '1px solid #D32F2F',
                        borderRadius: '100px',
                        backgroundColor: '#FFF',
                        fontWeight: 500
                    }}
                    onClick={() => warningNotification(api, () => {
                        // setVisible()
                        fetchTerminateContract(contract_by_id_data.id)
                            .then(res => {
                                if (res) {
                                    successNotification('Контракт расторгнут!', 'Контракт успешно расторгнут')
                                    api.destroy()
                                }
                            })
                    }, 'закрытие контракта.')}
                />
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.adminContractById.contract_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'closeDeal', data }),
        fetchTerminateContract: (contract_id) => dispatch(fetchTerminateContract(contract_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainInfo);