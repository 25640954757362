import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { DELETE_CREATE_SQUARE_PLOT } from 'store/actionTypes';

import eye from 'assets/images/icons/eye-green.svg';
import deleteIcon from 'assets/images/icons/delete.svg';

import './style.css';

function PlotCard({ item, DELETE_CREATE_SQUARE_PLOT, active, onActive, page, deleteFunction, ...props }) {
    const navigate = useNavigate()
    const { id } = useParams()

    const onClickEye = () => {
        if (page === 'fieldByid') {
            navigate(`/user/my-fields/field/${id}/${item.id}`)
        } else if (page === 'fieldByid_admin') {
            navigate(`/admin/fields/field/${id}/${item.id}`)
        } else if (page === 'market') {
            navigate(`/market/fields/${id}/plots/${item.id}`)
        } else {
            navigate(`/user/my-fields/create-field/region/${item.index}`, { state: { step: 2 } })
        }
    }

    const onClickDelete = () => {
        if (page === 'fieldByid' || page === 'fieldByid_admin') {
            deleteFunction(item.id)
        } else {
            DELETE_CREATE_SQUARE_PLOT(item.index)
        }
    }

    return (
        <div
            className={`${active ? 'regionCard active' : 'regionCard'}`}
            onClick={onActive}
            {...props}
        >
            <div>
                <p className='p14_400 p_mb4'>Номер</p>
                <p className='p14_400'>{item.number}</p>
            </div>
            <div>
                <img src={eye} alt="eye" onClick={onClickEye} />
                {page !== 'market' && <img src={deleteIcon} alt="deleteIcon" style={{ marginLeft: 8 }} onClick={onClickDelete} />}
            </div>
        </div>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        DELETE_CREATE_SQUARE_PLOT: (index) => dispatch({ type: DELETE_CREATE_SQUARE_PLOT, index: index }),
    }
}

export default connect(null, mapDispatvhToProps)(PlotCard);