import { useState, useEffect } from 'react';
import { Checkbox, Dropdown, Input } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { getQueryStringFromObject } from 'hooks/createQuery';
import { numberInputFormat } from 'hooks/numderInputFormat';

import angleDown from 'assets/images/icons/angle-down.svg';

import './style.css';

function SecondTypeFilter({ settings }) {
    const [activeFilter, setActiveFilter] = useState(false)
    const [items, setItems] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterParams, setFilterParams] = useState({})
    const [filterParamsSearch, setFilterParamsSearch] = useState({})

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.search) {
            setFilterParams(JSON.parse(decodeURIComponent(query.search.replace(/\+/g, '%20'))))
        } else {
            setFilterParams({})
        }
        if (query.params) {
            setFilterParamsSearch(JSON.parse(decodeURIComponent(query.params)))
        } else {
            setFilterParamsSearch({})
        }
    }, [
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).params
    ])

    useEffect(() => {
        const item = []
        if (settings.type === 'select') {
            item.push({
                label: <div className='secondTypeFilterParams'>
                    {settings.childrens.map((children, i) => (
                        <Checkbox
                            key={`children_${i}`}
                            checked={filterParams[settings.param] ? (filterParams[settings.param].indexOf(children.param) > -1 ? true : false) : false}
                            onChange={(e) => onChange(e.target.checked, settings.param, children.param)}
                        >
                            {children.title}
                        </Checkbox>
                    ))}
                </div>,
                key: 0,
            })
        } else if (settings.type === 'input') {
            item.push({
                label: <div className='secondTypeFilterParams'>
                    <Input
                        placeholder={settings.placeholder}
                        value={filterParams[settings.param] ? filterParams[settings.param][0] : ''}
                        onChange={(e) => onChangeInput(e.target.value, settings.param, settings.type)}
                    />
                </div>,
                key: 0,
            })
        } else if (settings.type === 'inputNumber') {
            item.push({
                label: <div className='secondTypeFilterParams'>
                    <Input
                        placeholder={settings.placeholder}
                        value={filterParams[settings.param] ? filterParams[settings.param][0] : ''}
                        type="number"
                        onKeyDown={numberInputFormat}
                        onChange={(e) => onChangeInput(e.target.value, settings.param, settings.type)}
                    />
                </div>,
                key: 0,
            })
        } else if (settings.type === 'from_to') {
            item.push({
                label: <div className='secondTypeFilterParams_row'>
                    {settings.childrens.map((children, i) => {
                        if (children.type === 'inputNumber') {
                            return <Input
                                placeholder={children.placeholder}
                                value={filterParams[children.param] ? filterParams[children.param][0] : ''}
                                type="number"
                                onKeyDown={numberInputFormat}
                                onChange={(e) => onChangeInput(e.target.value, children.param, 'input')}
                                key={`inputNumber_${i}`}
                                style={{ margin: '0 2px', width: 100 }}
                            />
                        }
                    })}
                </div>,
                key: 0,
            })
        } else if (settings.type === 'inputWithCheckbox') {
            item.push({
                label: <div className='secondTypeFilterParams'>
                    <Input
                        placeholder={settings.placeholder}
                        value={filterParamsSearch[settings.param] || ''}
                        onChange={(e) => onChangeFetchData(e.target.value, settings.param, settings.type)}
                    />
                    {settings.childrens.length > 0 &&
                        <div className='secondTypeFilterParams' style={{ marginTop: 4 }}>
                            {settings.childrens.map((children, i) => (
                                <Checkbox
                                    key={`children_${i}`}
                                    checked={filterParams[settings.param] ? (filterParams[settings.param].indexOf(children.param) > -1 ? true : false) : false}
                                    onChange={(e) => onChange(e.target.checked, settings.param, children.param)}
                                >
                                    {children.title}
                                </Checkbox>
                            ))}
                        </div>
                    }
                </div>,
                key: 0,
            })
        }
        setItems(item)
    }, [settings, filterParams])

    const onChange = (e, el, children) => {
        const query = Object.fromEntries([...searchParams]);
        const obj = JSON.parse(JSON.stringify(filterParams))
        if (e) {
            if (obj[el]) {
                obj[el].push(children)
            } else {
                obj[el] = [children]
            }
        } else {
            if (obj[el].length > 1) {
                const index = obj[el].indexOf(children)
                obj[el].splice(index, 1)
            } else {
                delete obj[el]
            }
        }
        query.search = getQueryStringFromObject(JSON.stringify(obj))
        setSearchParams(query)
        setFilterParams(obj)
    };

    const onChangeInput = (e, el, type) => {
        const query = Object.fromEntries([...searchParams]);
        const obj = JSON.parse(JSON.stringify(filterParams))
        let data
        if (type === 'inputNumber') {
            data = parseInt(e)
        } else if (type === 'input') {
            data = e
        }
        if (data) {
            obj[el] = [data]
        } else {
            if (obj[el].length > 1) {
                const index = obj[el].indexOf(data)
                obj[el].splice(index, 1)
            } else {
                delete obj[el]
            }
        }
        query.search = getQueryStringFromObject(JSON.stringify(obj))
        setSearchParams(query)
        setFilterParams(obj)
    };

    const onChangeFetchData = (e, el, type) => {
        const query = Object.fromEntries([...searchParams]);
        const obj = JSON.parse(JSON.stringify(filterParams))
        if (e) {
            obj[el] = e
        } else {
            delete obj[el]
        }
        query.params = getQueryStringFromObject(JSON.stringify(obj))
        setSearchParams(query)
        setFilterParams(obj)
    }

    const countLengthObj = () => {
        let count = 0
        for (let key in filterParams) {
            if (settings.type === 'from_to') {
                if (settings.childrens.map(el => el.param).indexOf(key) !== -1) count++
            } else {
                if (key === settings.param) count++
            }
        }
        return count
    }

    return (
        <Dropdown
            menu={{ items }}
            trigger={['click']}
            className='greenDropdown'
            onOpenChange={() => setActiveFilter(!activeFilter)}
            open={activeFilter}
            placement="bottomRight"
        >
            {settings.type === 'color_select'
                ?
                <div className='secondTypeFilterBlockColor'>
                    <p className='mainText p14_400'>{settings.title}</p>
                    <img src={angleDown} alt="angleDown" />
                    {countLengthObj() > 0 && <div className='activeDot' />}
                </div>
                :
                <div className='secondTypeFilterBlock'>
                    <p className='mainText p14_400'>{settings.title}</p>
                    <img src={angleDown} alt="angleDown" />
                    {countLengthObj() > 0 && <div className='activeDot' />}
                </div>
            }
        </Dropdown>
    );
}

export default SecondTypeFilter;