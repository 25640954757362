import { connect } from 'react-redux'
import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import { SAVE_CREATE_TECHNIC_DATA } from 'store/actionTypes';
import { yearList } from 'hooks/listOfYear';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

const { Option } = Select;

const currentYear = (new Date()).getFullYear();

function TechnicModel({ form, nextStep, create_technic, technic_types, SAVE_CREATE_TECHNIC_DATA }) {
    const [fields, setFields] = useState([
        {
            name: 'gos_num',
            value: create_technic.gos_num,
        },
        {
            name: 'technic_type_id',
            value: create_technic.technic_type_id
        },
        {
            name: 'manufacturer',
            value: create_technic.manufacturer,
        },
        {
            name: 'model',
            value: create_technic.model
        },
        {
            name: 'release_date',
            value: create_technic.release_date,
        }
    ]);

    const onFinish = ({gos_num, technic_type_id, manufacturer, model, release_date}) => {
        let obj = {...create_technic}
        obj.gos_num = gos_num
        obj.technic_type_id = technic_type_id
        obj.manufacturer = manufacturer
        obj.model = model
        obj.release_date = release_date
        SAVE_CREATE_TECHNIC_DATA(obj)
        nextStep()
    };

    return (
        <InfoBlock>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label={'Гос. номер'}
                    name="gos_num"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите гос. номер' />
                </Form.Item>
                <Form.Item
                    label={'Тип техники'}
                    name="technic_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип">
                        {technic_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Производитель'}
                    name="manufacturer"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите производителя' />
                </Form.Item>
                <Form.Item
                    label={'Модель'}
                    name="model"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите модель' />
                </Form.Item>
                <Form.Item
                    label={'Год выпуска'}
                    name="release_date"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите год">
                        {yearList(currentYear, currentYear - 50, -1).map(el => (
                            <Option value={el} key={`release_date_${el}`}>{el}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
        technic_types: state.technicTypes.technic_types
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(TechnicModel);