import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import { fetchCreateConflict, fetchUploadConflictDocs, fetchUploadConflictImage } from 'store/actions/conflicts';
import { fetchTerminateContract } from 'store/actions/contractById';
import { terminationContractBreadcrumb } from 'data/brebcrumb';
import { successNotification } from 'hooks/notifications';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import TerminationContractForm from 'components/components/forms/contract/terminationContract/TerminationContractForm';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function TerminationContract({ fetchCreateConflict, fetchUploadConflictDocs, fetchUploadConflictImage, fetchTerminateContract }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const [form] = Form.useForm();

    const reject = async (values, images, docs) => {
        values.contract_id = id
        await fetchCreateConflict(values)
            .then(async res => {
                if (res) {
                    for (let i = 0; i < images.length; i++) {
                        let data = new FormData();
                        let filedata = images[i].originFileObj
                        delete filedata.uid
                        data.append('image_file', filedata);
                        data.append('title', images[i].name);
                        await fetchUploadConflictImage(res, data)
                    }
                    for (let i = 0; i < docs.length; i++) {
                        let data = new FormData();
                        let filedata = docs[i].originFileObj
                        delete filedata.uid
                        data.append('file', filedata);
                        data.append('name', docs[i].name);
                        await fetchUploadConflictDocs(res, data)
                    }
                }
            })
        await fetchTerminateContract(id)
            .then(res => {
                if (res) {
                    successNotification('Контракт расторгнут!', 'Контракт успешно расторгнут')
                    navigate(`/user/my-contracts/${id}`)
                }
            })
    }

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={terminationContractBreadcrumb(id)} activeLink={'Расторжение контракта'} />
            <div className='userProfile_header'>
                <TextHeading text={'Расторжение контракта'} />
                <div className='userProfile_header__actions'>
                    <SimpleButton
                        title={'Отмена'}
                        style={{
                            color: '#01963A',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500,
                            marginRight: 16
                        }}
                        onClick={() => navigate(`/user/my-contracts/${id}`)}
                    />
                    <SimpleButton
                        title={'Сохранить'}
                        style={{
                            color: '#FFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500
                        }}
                        onClick={() => form.submit()}
                    />
                </div>
            </div>
            <TerminationContractForm form={form} reject={(values, images, docs) => reject(values, images, docs)} />
        </div>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateConflict: (data) => dispatch(fetchCreateConflict(data)),
        fetchUploadConflictImage: (id, data) => dispatch(fetchUploadConflictImage(id, data)),
        fetchUploadConflictDocs: (id, data) => dispatch(fetchUploadConflictDocs(id, data)),
        fetchTerminateContract: (contract_id) => dispatch(fetchTerminateContract(contract_id))
    }
}

export default connect(null, mapDispatvhToProps)(TerminationContract);