import UserLayout from "components/layout/user/UserLayout";

import UserProfile from "views/user/profile/Profile";
import AllTechnics from "views/user/technics/allTechnics/AllTechnics";
import TechnicById from "views/user/technics/technicById/TechnicById";
import CreateTechnic from "views/user/technics/createTechnic/CreateTechnic";
import AllFields from "views/user/fields/allFields/AllFields";
import QuickCreatingField from "views/user/fields/createField/quickCreatingField/QuickCreatingField";
import FullCreatingField from "views/user/fields/createField/fullCreating/FullCreatingField";
import FieldById from "views/user/fields/fieldById/FieldById";
import Plot from "views/user/fields/createField/plot/Plot";
import PlotById from "views/user/fields/plot/PlotById";
import MarketAllTechics from "views/market/technics/allTechics/AllTechics";
import MarketTechnicById from "views/market/technics/techicById/MarketTechnicById";
import MarketAllSquares from "views/market/squares/allSquareas/MarketAllSquares";
import MarketSquareById from "views/market/squares/squareById/MarketSquareById";
import AllApplications from "views/user/applications/allAplications/AllApplications";
import ApplicationById from "views/user/applications/applicationById/ApplicationById";
import CreateApplication from "views/user/applications/createApplication/CreateApplication";
import Favorites from "views/user/favorites/Favorites";
import Notifications from "views/user/notifications/Notifications";
import NotificationById from "views/user/notifications/NotificationById";
import AllOffers from "views/user/offers/allOffers/AllOffers";
import AllConflicts from "views/user/conflicts/allConflicts/AllConflicts";
import ConflictById from "views/user/conflicts/conflictById/ConflictById";
import AllContracts from "views/user/contracts/allContracts/AllContracts";
import ContractById from "views/user/contracts/contractById/ContractById";
import RepeatContract from "views/user/contracts/repeatContract/RepeatContract";
import TerminationContract from "views/user/contracts/terminationContract/TerminationContract";
import ContractEvaluation from "views/user/contracts/contractEvaluation/ContractEvaluation";
import RulesPage from "views/user/rulesPage/RulesPage";
import MarketPlotById from "views/market/squares/plotById/MarketPlotById";
import TechnicByIdCreateReply from "views/market/technics/technicByIdCreateReply/TechnicByIdCreateReply";
import SquareByIdCreateReply from "views/market/squares/squareByIdCreateReply/SquareByIdCreateReply";
import Chats from "views/user/chats/Chats";
import UserById from "views/market/users/userById/UserById";

export const userRoutings = [
    {
        path: '/user/',
        element: <UserLayout />,
        navigate: '/user/my-profile',
        children: [
            {
                path: 'my-profile',
                index: false,
                element: <UserProfile />
            },
            {
                path: 'my-cars',
                index: false,
                element: <AllTechnics />
            },
            {
                path: 'my-cars/:id',
                index: false,
                element: <TechnicById />
            },
            {
                path: 'my-cars/create',
                index: false,
                element: <CreateTechnic />
            },
            {
                path: 'my-fields',
                index: false,
                element: <AllFields />
            },
            {
                path: 'my-fields/:id',
                index: false,
                element: <FieldById />
            },
            {
                path: 'my-fields/quick-create',
                index: false,
                element: <QuickCreatingField />
            },
            {
                path: 'my-fields/full-create',
                index: false,
                element: <FullCreatingField />
            },
            {
                path: 'my-fields/create-field/region/:id',
                index: false,
                element: <Plot mode={'byId'} />
            },
            {
                path: 'my-fields/create-field/region/create',
                index: false,
                element: <Plot mode={'create'} />
            },
            {
                path: 'my-fields/field/:square_id/:plot_id',
                index: false,
                element: <PlotById mode={'byId'} />
            },
            {
                path: 'my-fields/field/:square_id/plot/create',
                index: false,
                element: <PlotById mode={'create'} />
            },
            {
                path: 'my-applications',
                index: false,
                element: <AllApplications />
            },
            {
                path: 'my-applications/:id',
                index: false,
                element: <ApplicationById />
            },
            {
                path: 'my-applications/create',
                index: false,
                element: <CreateApplication />
            },
            {
                path: 'my-offers',
                index: false,
                element: <AllOffers />
            },
            {
                path: 'my-conflicts',
                index: false,
                element: <AllConflicts />
            },
            {
                path: 'my-conflicts/:id',
                index: false,
                element: <ConflictById />
            },
            {
                path: 'my-contracts',
                index: false,
                element: <AllContracts />
            },
            {
                path: 'my-contracts/:id',
                index: false,
                element: <ContractById />
            },
            {
                path: 'my-contracts/:id/repeat',
                index: false,
                element: <RepeatContract />
            },
            {
                path: 'my-contracts/:id/termination',
                index: false,
                element: <TerminationContract />
            },
            {
                path: 'my-contracts/:id/evaluation',
                index: false,
                element: <ContractEvaluation />
            },
            {
                path: 'favorites',
                index: false,
                element: <Favorites />
            },
            {
                path: 'notifications',
                index: false,
                element: <Notifications />
            },
            {
                path: 'notifications/:id',
                index: false,
                element: <NotificationById />
            },
            {
                path: 'messages',
                index: false,
                element: <Chats />
            },
        ]
    },
    {
        path: '/market/',
        element: <UserLayout />,
        children: [
            {
                path: 'technics',
                index: false,
                element: <MarketAllTechics />
            },
            {
                path: 'technics/:id',
                index: false,
                element: <MarketTechnicById />
            },
            {
                path: 'technics/:id/create-reply',
                index: false,
                element: <TechnicByIdCreateReply />
            },
            {
                path: 'fields',
                index: false,
                element: <MarketAllSquares />
            },
            {
                path: 'fields/:id',
                index: false,
                element: <MarketSquareById />
            },
            {
                path: 'fields/:id/create-reply',
                index: false,
                element: <SquareByIdCreateReply />
            },
            {
                path: 'fields/:square_id/plots/:plot_id',
                index: false,
                element: <MarketPlotById />
            }
        ]
    },
    {
        path: '/public/',
        element: <UserLayout />,
        children: [
            {
                path: 'user/:id',
                index: false,
                element: <UserById />
            },
        ]
    },
    {
        path: '/rules/',
        element: <UserLayout />,
        children: [
            {
                path: ':page',
                index: false,
                element: <RulesPage />
            },
        ]
    }
]