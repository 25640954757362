import { api_reviews } from 'api/reviews'

export function fetchCreateReview(data) {
    return async dispatch => {
        try {
             await api_reviews.PostCreateReviews(data)
            return true
        } catch (e) {
            return false
        }
    }
}