import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Form, Radio } from 'antd';
import { fetchMyTechnic } from 'store/actions/techics';
import { SAVE_CREATE_APPLICATION_DATA } from 'store/actionTypes';
import { createReplyBySquareTable } from 'data/tableHeader';
import { createReplyBySquareTableWidth } from 'data/tableWidth';
import { carFromCreateApplicationBreadcrumb } from 'data/brebcrumb';
import { errorNotification } from 'hooks/notifications';
import Table from 'components/ui/table/Table';
import Pagination from 'components/shared/pagination/Pagination';
import NoDataBlock from 'components/ui/noDataBlock/NoDataBlock';

import tractor from 'assets/images/messageIcons/tractor.svg';

function ApplicationTechnicData({ form, nextStep, fetchMyTechnic, user_data_technics, pages, create_application, SAVE_CREATE_APPLICATION_DATA }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [fields, setFields] = useState([
        {
            name: 'technics',
            value: create_application.technic_data,
        },
    ]);

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyTechnic(query.page, 50, '')
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
    ])


    const createTableData = () => {
        return user_data_technics.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-cars/${el.id}`, { state: { breadcrumb: carFromCreateApplicationBreadcrumb } })}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.gos_num
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        {el.status === "VERIFIED"
                            ?
                            <Radio value={el.id} />
                            :
                            <div
                                style={{
                                    background: '#01963A',
                                    borderRadius: '24px',
                                    padding: '4px 8px',
                                    width: 'max-content'
                                }}
                                className="whiteButtonText p12_400"
                            >
                                Пройти верификацию
                            </div>
                        }
                    </div >
                },
            ]
        ))
    }

    const onFinish = ({ technics }) => {
        if (technics.length > 0) {
            let obj = { ...create_application }
            obj.technic_data = technics
            SAVE_CREATE_APPLICATION_DATA(obj)
            nextStep()
        } else {
            errorNotification('Вы не выбрали технику')
        }
    };

    return (
        user_data_technics && (
            user_data_technics.length > 0
                ?
                <Form
                    layout="vertical"
                    requiredMark={false}
                    autoComplete="off"
                    className="modalForm"
                    fields={fields}
                    onFieldsChange={(_, allFields) => {
                        setFields(allFields)
                    }}
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="technics"
                        label="Выберите технику (допускается только верифицированная техника)"
                    >
                        <Radio.Group style={{ width: '100%', display: 'block' }}>
                            <Table
                                header={createReplyBySquareTable}
                                width={createReplyBySquareTableWidth}
                                data={createTableData()}
                            />
                            {pages > 1 && <Pagination pages={pages} />}
                        </Radio.Group>
                    </Form.Item>
                </Form>
                :
                <NoDataBlock
                    img={tractor}
                    title={'Нет информации о технике'}
                    description={'На данный момент у нас нет информации о вашей технике, чтобы внести ее - нажмите на кнопку ниже.'}
                    buttonTitle={['Добавить технику']}
                    onClick={[() => navigate(`/user/my-cars/create`)]}
                    style={{ position: 'relative' }}
                />
        )
    );
}

function mapStateToProps(state) {
    return {
        user_data_technics: state.userTechnics.technics,
        create_application: state.createApplication,
        pages: state.userTechnics.pages,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyTechnic: (page, size, params) => dispatch(fetchMyTechnic(page, size, params)),
        SAVE_CREATE_APPLICATION_DATA: (data) => dispatch({ type: SAVE_CREATE_APPLICATION_DATA, create_application: data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ApplicationTechnicData);