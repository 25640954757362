import { useParams } from 'react-router-dom';
import { rulesPageList } from 'data/routings';
import { aboutUsList, contactsList, faqList, policy, terms_of_use } from 'data/testData';
import Collapse from 'components/shared/collapse/Collapse';
import FullPageMenu from 'components/components/menu/fullPageMenu/FullPageMenu';

import './style.css';
import './media.css';

function RulesPage() {
    const { page } = useParams()
    
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target.result)
      alert(text)
    };
    
    return (
        <div className='rulesPage'>
            <FullPageMenu list={rulesPageList} />
            <div className='rulesPage_content'>
                {page === 'faq' && <Collapse list={faqList} />}
                {page=== 'privacy' && <div className='subtext p14_400'>{policy}</div>}
                {page=== 'terms-of-use' && <div className='subtext p14_400'>{terms_of_use}</div>}
                {page=== 'about-us' && <Collapse list={aboutUsList} />}
                {page=== 'contact-us' && <Collapse list={contactsList} />}
            </div>
        </div>
    );
}

export default RulesPage;