import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { fetchNotificationSetReaded } from 'store/actions/notifications';
import { formatDateTime } from "hooks/formatDate";

import doubleCheck from 'assets/images/icons/double-check.svg';
import eyeGreen from 'assets/images/icons/eye-green.svg';

import './style.css';

function NotificationCard({ title, body, created_at, id, is_readed, onSetReaded }) {
    const navigate = useNavigate()

    return (
        <div className='notificationCard'>
            <div className='notificationCard_textBlock'>
                <div>
                    <p className='p14_400 activeText p_mb8'>{title}</p>
                    <p className='p12_400 mainText'>{body}</p>
                </div>
                <div>
                    <img src={eyeGreen} alt="eyeGreen" onClick={() => navigate(`/user/notifications/${id}`)} />
                    {!is_readed && <img src={doubleCheck} alt="doubleCheck" style={{marginLeft: 15}} onClick={() => onSetReaded(id)}/>}
                </div>
            </div>
            <div className='notificationCard_dateBlock'>
                <p className='p12_400 placeholder'>{formatDateTime(created_at)}</p>
            </div>
        </div>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchNotificationSetReaded: (data, page, size, params) => dispatch(fetchNotificationSetReaded(data, page, size, params))
    }
}

export default connect(null, mapDispatvhToProps)(NotificationCard);