import { api_conflicts } from 'api/conflicts'
import {
    FETCH_USER_DATA_CONFLICTS_SUCCESS
} from '../actionTypes'
import { fetchConflictById } from './conflictById'
import { fetchContractById } from './contractById'
import { fetchSuccess } from './fetchStatuses'

export function fetchMyConflicts(page, size, params) {
    return async dispatch => {
        try {
            const conflicts_data = await api_conflicts.GetMyConflicts(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_CONFLICTS_SUCCESS, {
                data: conflicts_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchCreateConflict(data) {
    return async dispatch => {
        try {
            const conflict = await api_conflicts.PostCreateConflict(data)
            dispatch(fetchContractById(data.contract_id))
            return conflict.data.data.id
        } catch (e) {
            return false
        }
    }
}

export function fetchUploadConflictImage(id, data) {
    return async dispatch => {
        try {
            await api_conflicts.UploadConflictImageById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteConflictImage(id, img_id) {
    return async dispatch => {
        try {
            await api_conflicts.DeleteConflictImageById(id, img_id)
        } catch (e) {
        }
    }
}

export function fetchUploadConflictDocs(id, data) {
    return async dispatch => {
        try {
            await api_conflicts.UploadConflictDocumentById(id, data)
            dispatch(fetchConflictById(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteConflictDocs(id, doc_id) {
    return async dispatch => {
        try {
            await api_conflicts.DeleteConflictDocumentById(id, doc_id)
            dispatch(fetchConflictById(id))
        } catch (e) {
        }
    }
}
