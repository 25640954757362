import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { FullscreenControl, GeolocationControl, Map, Placemark, Polyline, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import { CLEAR_CONTRACT_BY_ID_DATA_STATISTICS, FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS, FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS } from 'store/actionTypes';
import SelectDate from 'components/shared/selectDate/SelectDate';

import whitePin from 'assets/images/pins/whiteCombine.svg'
import startReportPin from 'assets/images/pins/startReportPin.svg'

import './style.css';

function Repors({
    contract_by_id_data,
    technic_movement,
    technic_statistics,
    reportDateStart,
    reportDateEnd,
    FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS,
    CLEAR_CONTRACT_BY_ID_DATA_STATISTICS,
    FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS
}) {
    const [viewTechnics, setViewTechnics] = useState([])
    const [map, setMap] = useState(null)

    useEffect(() => {
        setViewTechnics(contract_by_id_data.technics.map(el => (el.id)))
    }, [])

    useEffect(() => {
        setMap(<Map
            defaultState={{
                center: contract_by_id_data.square.address ? [contract_by_id_data.square.address.latitude, contract_by_id_data.square.address.longitude] : [55.751574, 37.573856],
                zoom: 13,
                type: 'yandex#hybrid'
            }}
            width='100%'
            height='100%'
        >
            {technic_statistics.map(el => {
                if (viewTechnics.indexOf(el.technic_id) !== -1) {
                    return <Polyline
                        geometry={el.statistics.map(coord => ([coord.latitude, coord.longitude]))}
                        options={{
                            balloonCloseButton: false,
                            strokeColor: "#FFFFFF",
                            strokeWidth: 4,
                            strokeOpacity: 1,
                        }}
                        key={`polyline_${el.technic_id}`}
                    />
                }
            })}
            {technic_statistics.map(el => {
                if (el.statistics.length > 0 && viewTechnics.indexOf(el.technic_id) !== -1) {
                    return <Placemark
                        geometry={[el.statistics[0].latitude, el.statistics[0].longitude]}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: startReportPin,
                            iconImageSize: [17, 17],
                            iconImageOffset: [-10, -10]
                        }}
                    />
                }
            })}
            {technic_statistics.map(el => {
                if (el.statistics.length > 0 && viewTechnics.indexOf(el.technic_id) !== -1) {
                    return <Placemark
                        geometry={[el.statistics[el.statistics.length - 1].latitude, el.statistics[el.statistics.length - 1].longitude]}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: whitePin,
                            iconImageSize: [70, 70],
                            iconImageOffset: [-35, -60]
                        }}
                    />
                }
            })}
            <FullscreenControl />
            <SearchControl options={{ float: "right" }} />
            <GeolocationControl options={{ float: "left" }} />
            <TypeSelector options={{ float: 'right' }} />
        </Map>)
    }, [technic_movement, technic_statistics, viewTechnics])

    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }
    const handleChange = (value) => {
        setViewTechnics(value);
    };

    return (
        <>
            <div className='selectDateBlock'>
                <div className='selectDateBlock_item firstItem'>
                    <SelectDate
                        placeholder={'Дата начала'}
                        minDate={new Date(contract_by_id_data.valid_from)}
                        maxDate={new Date(contract_by_id_data.valid_to)}
                        selectDate={new Date(reportDateStart)}
                        onChangeData={(reportDateStart) => {
                            CLEAR_CONTRACT_BY_ID_DATA_STATISTICS()
                            FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS(reportDateStart)
                        }
                        }
                    />
                </div>
                <div className='selectDateBlock_item secondItem'>
                    <SelectDate
                        placeholder={'Дата окончания'}
                        minDate={new Date(contract_by_id_data.valid_from)}
                        maxDate={new Date(contract_by_id_data.valid_to)}
                        selectDate={new Date(reportDateEnd)}
                        onChangeData={(reportDateEnd) => {
                            CLEAR_CONTRACT_BY_ID_DATA_STATISTICS()
                            FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS(reportDateEnd)
                        }}
                    />
                </div>
                <div className='selectDateBlock_item secondItem'>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Выберите технику"
                        defaultValue={contract_by_id_data.technics.map(el => (el.id))}
                        onChange={handleChange}
                        options={contract_by_id_data.technics.map(el => ({
                            label: `${el.technic_type.name} (${el.gos_num})`,
                            value: el.id,
                        }))}
                    />
                </div>
            </div>
            <div className='locationBlock_map'>
                {map}
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.contractById.contract_by_id_data,
        technic_movement: state.contractById.technic_movement,
        technic_statistics: state.contractById.technic_statistics,
        reportDateStart: state.contractById.reportDateStart,
        reportDateEnd: state.contractById.reportDateEnd
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS: (reportDateStart) => dispatch({ type: FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS, reportDateStart }),
        FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS: (reportDateEnd) => dispatch({ type: FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS, reportDateEnd }),
        CLEAR_CONTRACT_BY_ID_DATA_STATISTICS: () => dispatch({ type: CLEAR_CONTRACT_BY_ID_DATA_STATISTICS })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Repors);