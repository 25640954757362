import { useRef } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { fieldDocsTable } from 'data/tableHeader';
import { fieldDocsTableWidth } from 'data/tableWidth';
import { notification } from 'antd';
import { fetchConflictById, fetchDeleteConflictDocs, fetchUploadConflictDocs } from 'store/actions/admin/conflicts';
import { warningNotification } from 'hooks/notifications';
import { formatDate } from 'hooks/formatDate';
import { handleChangeFiles } from 'hooks/handleChangeFiles';
import Table from 'components/ui/table/Table';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import deleteIcon from 'assets/images/icons/delete.svg';
import downloading from 'assets/images/icons/downloading.svg';

function Docs({ conflict_by_id_data_docs, fetchConflictById, fetchUploadConflictDocs, fetchDeleteConflictDocs }) {
    const fileInputRef = useRef();
    const [api, contextHolder] = notification.useNotification();

    const { id } = useParams()

    const createTableData = () => {
        return conflict_by_id_data_docs.map(el => (
            [
                {
                    type: 'text',
                    content: 'Документ'
                },
                {
                    type: 'active_text',
                    content: el.name
                },
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <a href={el.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                fetchDeleteConflictDocs(id, el.id)
                                api.destroy()
                            }, 'удаление документа.')}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={fieldDocsTable}
                width={fieldDocsTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Добавить документ'}
                style={{
                    marginTop: 20,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => fileInputRef.current.click()}
            />
            <input
                onChange={(e) => handleChangeFiles(e, async (data) => {
                    await fetchUploadConflictDocs(id, data)
                    await fetchConflictById(id)
                    fileInputRef.current.value = null
                }
                )}
                multiple={false}
                ref={fileInputRef}
                type='file'
                accept='.png, .jpg, .doc, .docx, .pdf, .word'
                hidden
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        conflict_by_id_data_docs: state.adminConflictById.conflict_by_id_data.documents,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchConflictById: (id) => dispatch(fetchConflictById(id)),
        fetchUploadConflictDocs: (id, data) => dispatch(fetchUploadConflictDocs(id, data)),
        fetchDeleteConflictDocs: (id, doc_id) => dispatch(fetchDeleteConflictDocs(id, doc_id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Docs);