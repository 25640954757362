import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLogin } from 'store/actions/login';
import AuthenticationBlock from 'components/ui/authenticationBlock/AuthenticationBlock';
import FormButton from 'components/shared/buttons/formButton/FormButton';

import tractor from 'assets/images/messageIcons/tractor.svg';

import './style.css';
import './media.css';

function SignupGreate({ user_data, password, saveDataForActive, fetchLogin }) {
    const navigate = useNavigate()

    const onLogin = () => {
        const data = {
            email: user_data.email,
            password
        }
        fetchLogin(saveDataForActive)
            .then(res => {
                if (res !== "Email or Password incorrect" && res !== 'Error: Network Error') {
                    navigate('/user/my-profile')
                }
            })
    }

    return (
        <AuthenticationBlock>
            <div className='signupGreate'>
                <img src={tractor} alt='tractor' />
                <p className='signupGreate_title'>Профиль успешно создан!</p>
                <p className='signupGreate_description'>Вы можете начать пользоваться функционалом сервиса</p>
                <p className='signupGreate_description'>Администратор проверит ваши данные и если будут какие-то вопросы свяжется с вами по указанным контактным данным для уточнения вопросов при необходимости.</p>
                <FormButton
                    title={'Перейти в личный профиль'}
                    style={{ width: '100%', marginTop: 24 }}
                    onClick={onLogin}
                />
            </div>
        </AuthenticationBlock>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.signup.user_data,
        password: state.signup.password,
        saveDataForActive: state.userEnv.saveDataForActive,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchLogin: (data) => dispatch(fetchLogin(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SignupGreate);