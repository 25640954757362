import { connect } from 'react-redux'
import { useState, useEffect } from 'react';
import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import { createFio } from 'hooks/createFio';
import EditField from 'components/components/forms/field/editField/EditField';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import pin from 'assets/images/pins/field.svg'

import './style.css';

function MainInfo({ form, editMainInfo, setEditMainInfo, square_by_id_data, default_address }) {
    const [coords, setCoords] = useState(null)

    useEffect(() => {
        if (default_address) {
            setCoords([default_address.latitude, default_address.longitude])
        }
    }, [])

    return (
        <>
            {!editMainInfo
                ?
                <InfoBlock
                    style={{ marginTop: 24 }}
                    className="infoBlock twoRow"
                >
                    <div style={{ width: '100%' }}>
                        <p className='p12_400 mainText p_mb8'>ФИО фермера</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.farmer ? square_by_id_data.farmer : '-'}</p>
                        <p className='p12_400 mainText p_mb8'>Владеет полем</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.is_owner ? createFio(square_by_id_data.owner) : '-'}</p>
                        <p className='p12_400 mainText p_mb8'>Компания</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.is_owner ? (square_by_id_data.owner.company ? square_by_id_data.owner.company.name : '-') : '-'}</p>
                        <p className='p12_400 mainText p_mb8'>Фактический посев</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.sowing_type ? square_by_id_data.sowing_type.name : '-'}</p>
                        <p className='p12_400 mainText p_mb8'>Кадастровый номер</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.cadastral ? square_by_id_data.cadastral : '-'}</p>
                        <p className='p12_400 mainText p_mb8'>Номер</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.number}</p>
                        <p className='p12_400 mainText p_mb8'>Фотография</p>
                        <div className='imagesBlock'>
                            {square_by_id_data.Images.length === 0
                                ? '-'
                                : square_by_id_data.Images.map((el, key) => (
                                    <img key={key} src={el.media.link} alt={'image'} />
                                ))
                            }
                        </div>
                        <p className='p12_400 mainText p_mb8'>Описание</p>
                        <p className='p14_400 mainText'>{square_by_id_data.description ? square_by_id_data.description : '-'}</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <p className='p12_400 mainText p_mb8'>Культура</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.crop_type ? square_by_id_data.crop_type.name : '-'}</p>
                        {/* <p className='p12_400 mainText p_mb8'>Адрес поля</p>
                        <p className='p14_400 mainText p_mb16'>В РАБОТЕ</p>
                        <p className='p12_400 mainText p_mb8'>Регион</p>
                        <p className='p14_400 mainText p_mb16'>В РАБОТЕ</p> */}
                        <p className='p12_400 mainText p_mb8'>Тип поля</p>
                        <p className='p14_400 mainText p_mb16'>{square_by_id_data.square_type.name}</p>
                        <p className='p12_400 mainText p_mb8'>Размер поля, Га</p>
                        <p className='p14_400 mainText'>{square_by_id_data.size}</p>
                    </div>
                </InfoBlock>
                : <InfoBlock>
                    <EditField form={form} setEditMainInfo={setEditMainInfo} coords={coords} />
                </InfoBlock>}
            <div className='locationBlock'>
                <div className='locationBlock_map'>
                    <Map
                        defaultState={{
                            center: default_address ? [default_address.latitude, default_address.longitude] : [55.751574, 37.573856],
                            zoom: 15,
                            type: 'yandex#hybrid'
                        }}
                        width='100%'
                        height='100%'
                        onClick={(e) => {
                            if (editMainInfo) {
                                setCoords(e.get('coords'))
                            }
                        }}
                    >
                        <FullscreenControl />
                        <SearchControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                        <TypeSelector
                            options={{
                                float: 'right'
                            }}
                        />
                        {coords &&
                            <Placemark
                                geometry={coords}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: pin,
                                    iconImageSize: [70, 70],
                                    // iconImageOffset: [-5, -38]
                                }}
                            />}
                    </Map>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        square_by_id_data: state.squareById.square_by_id_data,
        default_address: state.squareById.square_by_id_data?.address,
    }
}

export default connect(mapStateToProps, null)(MainInfo);