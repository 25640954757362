import { api_notifications } from 'api/notifications'
import {
    FETCH_USER_DATA_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATION_BY_ID_DATA_SUCCESS,
    FETCH_USER_UNREADED_NOTIFICATIONS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchMyNotifications(page, size, params) {
    return async dispatch => {
        try {
            const notifications_data = await api_notifications.GetMyNotifications(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_NOTIFICATIONS_SUCCESS, {
                data: notifications_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchUserUnreadedNotifications(page, size, params) {
    return async dispatch => {
        try {
            const notifications_data = await api_notifications.GetMyNotifications(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_UNREADED_NOTIFICATIONS, {
                notifications: notifications_data.data.data.items
            }))
        } catch (e) {
        }
    }
}

export function fetchNotificationById(id) {
    return async dispatch => {
        try {
            const notification_data_by_id = await api_notifications.GetNotificationById(id)
            dispatch(fetchSuccess(FETCH_NOTIFICATION_BY_ID_DATA_SUCCESS, {
                notification_by_id_data: notification_data_by_id.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchNotificationSetReaded(data, page, size, params) {
    return async dispatch => {
        try {
            await api_notifications.PostNotificationSetReaded(data)
            dispatch(fetchMyNotifications(page, size, params))
            dispatch(fetchUserUnreadedNotifications(page, size, params))
        } catch (e) {
        }
    }
}