import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { contractFieldsTable } from 'data/adminTableHeader';
import { contractFieldsTableWidth } from 'data/adminTableWidth';
import { verificationStatusesStyle } from 'data/lists';
import { createFio } from 'hooks/createFio';
import Table from 'components/ui/table/Table';

import eye from 'assets/images/icons/eye-green.svg';

function Squares({contract_by_id_data,setVisible }) {
    const navigate = useNavigate()

    const createTableData = () => {
        return [
            [
                {
                    type: 'active_text',
                    content: <snap onClick={() => { navigate(`/admin/fields/${contract_by_id_data.square.id}`) }}>{contract_by_id_data.square.id}</snap>
                },
                {
                    type: 'active_text',
                    content: <span onClick={() => navigate(`/admin/users/${contract_by_id_data.square.owner.id}`) }>{createFio(contract_by_id_data.square.owner)}</span>
                },
                {
                    type: 'text',
                    content: contract_by_id_data.square.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[contract_by_id_data.square.status].bg }}>{verificationStatusesStyle[contract_by_id_data.square.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: contract_by_id_data.square.owner.company ? contract_by_id_data.square.owner.company.name : '-'
                },
                {
                    type: 'text',
                    content: contract_by_id_data.square.square_type ? contract_by_id_data.square.square_type.name : '-'
                },
                {
                    type: 'text',
                    content: contract_by_id_data.square.size
                },
                {
                    type: 'text',
                    content: contract_by_id_data.square.number
                },
                {
                    type: 'text',
                    content: contract_by_id_data.square.sowing_type ? contract_by_id_data.square.sowing_type.name : '-'
                },
                {
                    type: 'actions',
                    content: <img
                        src={eye}
                        alt="eye"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setVisible('plotsInfo', {plots: contract_by_id_data.plots})}
                    />
                },
            ]
        ]
    }

    return (
        <Table
            header={contractFieldsTable}
            width={contractFieldsTableWidth}
            data={createTableData()}
        />
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.adminContractById.contract_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    }
}
export default connect(mapStateToProps, mapDispatvhToProps)(Squares);