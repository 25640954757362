import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { CLEAR_USER_BY_ID_DATA } from 'store/actionTypes';
import { fetchUpdateUserRole, fetchUserByID } from 'store/actions/admin/user';
import { profileSwitch } from 'data/adminLists';
import { userBreadcrumb } from 'data/brebcrumb';
import { confirmNotification } from 'hooks/notifications';
import Technics from './technics/Technics';
import Contracts from './contracts/Contracts';
import Fields from './fields/Fields';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import MainInfo from './mainInfo/MainInfo';
import Switch from 'components/shared/switch/Switch';
import Company from './company/Company';
import Docs from './docs/Docs';
import Verification from './verification/Verification';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';

import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';
import user_tag_blue from 'assets/images/icons/user-tag-blue.svg';

import './style.css';
import './media.css';

function AdminUserProfile({ fetchUserByID, user_by_id_data, role, CLEAR_USER_BY_ID_DATA, fetchUpdateUserRole }) {
    const { id } = useParams()
    const [api, contextHolder] = notification.useNotification();
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')
    const [form] = Form.useForm();
    const [edit, setEdit] = useState({
        mainInfo: false,
        company: false,
        docs: false,
        verification: false
    })

    const onEdit = () => {
        let copyOfObject = { ...edit }
        copyOfObject[activeSwitch] = !copyOfObject[activeSwitch]
        setEdit(copyOfObject)
    }

    useEffect(() => {
        fetchUserByID(id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_USER_BY_ID_DATA()
        }
    }, [])

    return (
        user_by_id_data &&
        <div className='userProfile'>
            {contextHolder}
            <Breadcrumb list={userBreadcrumb} activeLink={user_by_id_data.first_name + ' ' + user_by_id_data.last_name + ' ' + user_by_id_data.third_name} />
            <div className='userProfile_header'>
                <div className='userProfile_header__info'>
                    {user_by_id_data.image
                        ?
                        <div className='userProfile_header__info__avatar__photo' style={{ backgroundImage: `url(${user_by_id_data.image.media.link})` }} />
                        :
                        <div className='userProfile_header__info__avatar p14_500'>{user_by_id_data.first_name[0].toUpperCase() + user_by_id_data.last_name[0].toUpperCase()}</div>
                    }
                    <p className='p24_600 mainText' style={{ textAlign: 'center' }}>{user_by_id_data.first_name + ' ' + user_by_id_data.last_name + ' ' + user_by_id_data.third_name}</p>
                </div>
                {edit[activeSwitch]
                    ? <div className='userProfile_header__actions'>
                        <SimpleButton
                            title={'Отмена'}
                            style={{
                                color: '#01963A',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500,
                                marginRight: 16
                            }}
                            onClick={() => onEdit()}
                        />
                        <SimpleButton
                            title={'Сохранить'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={() => form.submit()}
                        />
                    </div>
                    : <div className='userProfile_header__actions'>
                        {role === 'admin' && (user_by_id_data.role.name === 'user'
                            ?
                            <div
                                className='userProfile_header__actions__edit'
                                onClick={() => confirmNotification(
                                    api,
                                    () => {
                                        fetchUpdateUserRole(id, 'manager')
                                        .then(() => {
                                            fetchUserByID(id)
                                            api.destroy()
                                        })
                                    },
                                    'назначение этого пользователя менеджером'
                                )}
                            >
                                <img src={user_tag_blue} alt="edit_green_pen" />
                                <p className='blueText p14_500'>Назначение администратором сервиса</p>
                            </div>
                            : 
                            <div
                                className='userProfile_header__actions__edit'
                                onClick={() => confirmNotification(
                                    api,
                                    () => {
                                        fetchUpdateUserRole(id, 'user')
                                        .then(() => {
                                            fetchUserByID(id)
                                            api.destroy()
                                        })
                                    },
                                    'снятие этого пользователя с администрирования'
                                )}
                            >
                                <p className='errorColor p14_500'>Отменить администрирование сервиса</p>
                            </div>
                        )}
                        {(activeSwitch === 'mainInfo' || activeSwitch === 'company') &&
                            <div className='userProfile_header__actions__edit' onClick={() => onEdit()}>
                                <img src={edit_green_pen} alt="edit_green_pen" />
                                <p className='activeText p14_500'>Редактировать</p>
                            </div>
                        }
                    </div>
                }
            </div>
            <Switch
                items={profileSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo edit={edit[activeSwitch]} onEdit={() => onEdit(false)} form={form} />}
            {activeSwitch === 'company' && <Company edit={edit[activeSwitch]} onEdit={() => onEdit(false)} form={form} />}
            {activeSwitch === 'docs' && <Docs />}
            {activeSwitch === 'verification' && <Verification />}
            {activeSwitch === 'cars' && <Technics />}
            {activeSwitch === 'contracts' && <Contracts />}
            {activeSwitch === 'fields' && <Fields />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data: state.userById.user_by_id_data,
        role: state.userEnv.role,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUserByID: (id) => dispatch(fetchUserByID(id)),
        CLEAR_USER_BY_ID_DATA: () => dispatch({ type: CLEAR_USER_BY_ID_DATA }),
        fetchUpdateUserRole: (user_id, role_name) => dispatch(fetchUpdateUserRole(user_id, role_name))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AdminUserProfile);