import { ApiUrl, instance } from '../apiEnv';

export const api_technics = {

    async GetAllTechnics(page, size, params) {
        return await instance.get(`${ApiUrl}technics?page=${page}&size=${size}&${params}`)
    },

    async GetTechnicsById(id) {
        return await instance.get(`${ApiUrl}technics/${id}/admin`)
    },

    async PostCreateTechic(data, user_id) {
        return await instance.post(`${ApiUrl}technics/admin?user_id=${user_id}`, data)
    },

    async UpdateTechic(data, id) {
        return await instance.put(`${ApiUrl}technics/${id}`, data)
    },

    async UploadTechicDocumentById(id, data) {
        return await instance.put(`${ApiUrl}technics/${id}/document`, data)
    },

    async DeleteTechicDocumentById(id, doc_id) {
        return await instance.delete(`${ApiUrl}technics/${id}/document/${doc_id}`)
    },

    async UploadTechicImageById(id, data) {
        return await instance.put(`${ApiUrl}technics/${id}/upload_image`, data)
    },

    async DeleteTechicImageById(id, img_id) {
        return await instance.delete(`${ApiUrl}technics/${id}/image/${img_id}`)
    },

    async DeleteTechnicById(id) {
        return await instance.delete(`${ApiUrl}technics/${id}`)
    },

    async GetUniqueByPsm(psm_serial, psm_number) {
        return await instance.get(`${ApiUrl}technics/unique_by_psm?psm_serial=${psm_serial}&psm_number=${psm_number}`)
    },

    async PostCurrentLocationTechic(id) {
        return await instance.post(`${ApiUrl}technics/${id}/online_data`)
    },

    async ConfirmTechnicVerificstion(technic_id, verification_id) {
        return await instance.put(`${ApiUrl}technics/${technic_id}/confirm/${verification_id}`)
    },

    async RejectTechnicVerificstion(technic_id, verification_id) {
        return await instance.put(`${ApiUrl}technics/${technic_id}/reject/${verification_id}`)
    },
}