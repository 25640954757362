import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { fetchPasswordRecoveryPhone } from 'store/actions/login';
import { numberInputFormat } from 'hooks/numderInputFormat';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function PhonePasswordRecovery({ fetchPasswordRecoveryPhone }) {

    const onFinish = ({ phone_number }) => {
        fetchPasswordRecoveryPhone('7' + phone_number)
        // navigate()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Номер телефона'}
                name="phone_number"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" onKeyDown={numberInputFormat} />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Отправить код'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchPasswordRecoveryPhone: (phone, nav) => dispatch(fetchPasswordRecoveryPhone(phone, nav))
    }
}

export default connect(null, mapDispatvhToProps)(PhonePasswordRecovery);