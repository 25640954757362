import { api_replies } from 'api/replies'
import {
    FETCH_USER_DATA_REPLIES_START,
    FETCH_USER_DATA_REPLIES_SUCCESS,
    FETCH_REPLIES_BY_ID_DATA_START,
    FETCH_REPLIES_BY_ID_DATA_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchMyReplies(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_USER_DATA_REPLIES_START))
        try {
            const replies_data = await api_replies.GetMyReplies(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_REPLIES_SUCCESS, {
                data: replies_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchReplyByID(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_REPLIES_BY_ID_DATA_START))
        try {
            const reply_data = await api_replies.GetRepliesById(id)
            dispatch(fetchSuccess(FETCH_REPLIES_BY_ID_DATA_SUCCESS, {
                reply_by_id_data: reply_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchCreateReplies(data) {
    return async dispatch => {
        try {
            const replay = await api_replies.PostCreateReplies(data)
            return replay.data.data.id
        } catch (e) {
            return false
        }
    }
}

export function fetchAddTechnicToReplies(replay_id, data) {
    return async dispatch => {
        try {
            await api_replies.PutAddTechnicsToReplies(replay_id, data)
        } catch (e) {
        }
    }
}

export function fetchAddPlotsReplies(replay_id, data) {
    return async dispatch => {
        try {
            await api_replies.PutAddPlotsToReplies(replay_id, data)
        } catch (e) {
        }
    }
}