import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { fetchUpdateMyData } from 'store/actions/user';
import { numberInputFormat } from 'hooks/numderInputFormat';

function EditCompany({ onEdit, form, user_data_company, fetchUpdateMyData }) {
    const [fields, setFields] = useState([
        {
            name: 'name',
            value: user_data_company && user_data_company.name,
        },
        {
            name: 'address',
            value: user_data_company && user_data_company.address,
        },
        {
            name: 'inn',
            value: user_data_company && user_data_company.inn,
        },
        {
            name: 'ogrn',
            value: user_data_company && user_data_company.ogrn,
        },
        {
            name: 'email',
            value: user_data_company && user_data_company.email,
        },
        {
            name: 'phone',
            value: user_data_company && user_data_company.phone.substring(1),
        }
    ]);

    const onFinish = ({ name, address, inn, email, phone, ogrn }) => {
        const data = {
            company: {
                name,
                address,
                inn: Number(inn),
                ogrn: Number(ogrn),
                email,
                phone: '7' + phone,
            }
        }
        fetchUpdateMyData(data)
        onEdit()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                label={'Название компании'}
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите название компании' />
            </Form.Item>
            <Form.Item
                label={'Адрес компании'}
                name="address"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите адрес компании' />
            </Form.Item>
            <Form.Item
                label={'ИНН'}
                name="inn"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='ИНН' />
            </Form.Item>
            <Form.Item
                label={'ОГРН'}
                name="ogrn"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='ОГРН' />
            </Form.Item>
            <Form.Item
                label={'Коммерческая почта'}
                name="email"
                rules={[
                    {
                        required: true,
                        type: 'email',
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите коммерческую почту' />
            </Form.Item>
            <Form.Item
                label={'Телефон для связи'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input prefix={'+7'} placeholder='Введите телефон для связи' type="number" onKeyDown={numberInputFormat} />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data_company: state.user.user_data.company
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateMyData: (data) => dispatch(fetchUpdateMyData(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditCompany);