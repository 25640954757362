import { connect } from 'react-redux'
import { FullscreenControl, GeolocationControl, Map, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { CLOSE_MODAL } from 'store/actionTypes';
import { createFio } from 'hooks/createFio';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import pin from 'assets/images/pins/field.svg';

function DetailInfo({ data, setVisible }) {

    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center', width: '50vw' }}>Информация о {data.type === 'technic' ? 'технике' : 'поле'}</p>
            {data.type === 'technic'
                ?
                <>

                    <InfoBlock
                        style={{
                            marginBottom: 20,
                            marginTop: 20
                        }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Серия ПСМ</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.PSM_serial || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Гос. номер</p>
                            <p className='p14_400 mainText'>{data.data.gos_num || '-'}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Номер ПСМ</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.PSM_number || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Дата выдачи ПСМ</p>
                            <p className='p14_400 mainText'>{data.data.PSM_date || '-'}</p>
                        </div>
                    </InfoBlock>
                    <InfoBlock
                        style={{
                            marginBottom: 20,
                        }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Тип работ</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.work_type.name || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Модель</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.model || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Мощность (л.с)</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.power || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Производитель</p>
                            <p className='p14_400 mainText'>{data.data.manufacturer || '-'}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Собственник</p>
                            <p className='p14_400 mainText p_mb16'>{createFio(data.data.owner) || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Тип техники</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.technic_type.name || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Год выпуска</p>
                            <p className='p14_400 mainText'>{data.data.release_date || '-'}</p>
                        </div>
                    </InfoBlock>
                    <InfoBlock
                        style={{
                            marginBottom: 20,
                        }}
                    >
                        <p className='p12_400 mainText p_mb8'>Дополнительное оборудование</p>
                        <p className='p14_400 mainText p_mb16'>{data.data.extra_devices || '-'}</p>
                        <p className='p12_400 mainText p_mb8'>Описание техники</p>
                        <p className='p14_400 mainText'>{data.data.description || '-'}</p>
                    </InfoBlock>
                </>
                :
                <>
                    <InfoBlock
                        style={{
                            marginBottom: 20,
                            marginTop: 20
                        }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Номер</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.number}</p>
                            <p className='p12_400 mainText p_mb8'>Фактический посев, Га</p>
                            <p className='p14_400 mainText'>{data.data.fact_sowing}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Порядковый номер</p>
                            <p className='p14_400 mainText p_mb16'>{data.data.serial}</p>
                            <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                            <p className='p14_400 mainText'>{data.data.crop_type.name}</p>
                        </div>
                    </InfoBlock>
                    <div className='locationBlock_map' style={{ height: '300px', width: '100%' }}>
                        <Map
                            defaultState={{
                                center: [data.data.address.latitude, data.data.address.longitude],
                                zoom: 18,
                                type: 'yandex#hybrid'
                            }}
                            width='100%'
                            height='100%'
                        >
                            <FullscreenControl />
                            <GeolocationControl options={{ float: "left" }} />
                            <TypeSelector options={{ float: 'right' }} />
                            <Placemark
                                geometry={[data.data.address.latitude, data.data.address.longitude]}
                                key={`pin_${data.data.id}`}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: pin,
                                    iconImageSize: [70, 70],
                                    // iconImageOffset: [-5, -38]
                                }}
                            />
                        </Map>
                    </div>
                </>
            }
            <p className='activeText p14_500 p_mt24' style={{ textAlign: 'center', cursor: 'pointer' }} onClick={setVisible}>Отменить</p>
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(DetailInfo);