import { api_roles } from 'api/roles'
import {
    FETCH_ROLES_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchGetRoles() {
    return async dispatch => {
        try {
            const roles = await api_roles.GetRoles()
            dispatch(fetchSuccess(FETCH_ROLES_SUCCESS, {
                roles: roles.data.data.items
            }))
        } catch (e) {
        }
    }
}