import { connect } from 'react-redux'
import RejectVerivication from "components/components/forms/modal/rejectVerification/RejectVerivication";
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from 'store/actionTypes';
import FileComplaintForm from 'components/components/forms/modal/fileComplaint/FileComplaintForm';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import { useNavigate } from 'react-router-dom';

function NoVerification({ data, setVisible }) {
    const navigate = useNavigate()

    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center' }}>Пройдите верификацию</p>
            <p className='mainText p14_400 p_mb24' style={{maxWidth: '330px'}}>{data ? data.worning_text : 'Чтобы увидеть конфиденциальные данные, необходимо пройти проверку у менеджера. Для этого в личном профиле укажите все необходимые данные и получите подтверждение от менеджера'}</p>
            <SimpleButton
                title={'Пройти верификацию'}
                style={{
                    color: '#FFF',
                    border: '1px solid #01963A',
                    borderRadius: '100px',
                    backgroundColor: '#01963A',
                    fontWeight: 500,
                    width: '100%',
                    marginBottom: 24
                }}
                onClick={() => {navigate(`/`); setVisible()}}
            />
            <p className='activeText p14_500' style={{textAlign: 'center', cursor: 'pointer'}} onClick={setVisible}>Отменить</p>
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(NoVerification);