import { useNavigate } from 'react-router-dom';
import { createFio } from 'hooks/createFio';

import squareAvatar from 'assets/images/testImages/squareAvatar.png';

import './style.css';

function SquareCard({ item, mode }) {
    const navigate = useNavigate()

    return (
        <div
            className='squareCard'
            onClick={() => navigate(`/market/fields/${item.id}`)}
        >
            <img src={item.Images.length > 0 ? item.Images[0].media.link : squareAvatar} alt="squareAvatar" />
            <div className='squareCard_description'>
                <p className='p14_600 p_mb8'>Пашня <span className='p14_400'>Участок 1 - 000001 </span></p>
                <p className='p14_400'>{createFio(item.owner)}</p>
                <p className='p14_400 p_mb8'>{item.owner.company ? item.owner.company.name : '-'}</p>
                {mode !== 'miniCard' &&
                    <div>
                        <p className='p14_400 p_mb8'>{item.address.address}</p>
                        <p className='p14_400'>{item.square_type.name} - {item.size} Га</p>
                    </div>
                }
            </div>
        </div>
    );
}

export default SquareCard;