import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function RejectVerivication({ setBack, data, reject, ...props }) {
    
    const onFinish = ({ reason_deletion }) => {
        reject()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            {...props}
        >
            <Form.Item
                label={'Причина отклонения:'}
                name="reason_deletion"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input.TextArea placeholder='Введите причина отклонения' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500"
                        style={{
                            border: '1px solid #01963A',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: 'white',
                            marginRight: 16
                        }}
                        onClick={() => setBack(data)}
                    />
                    <SimpleButton
                        title={'Отправить'}
                        className="p14_500"
                        htmltype="submit"
                        style={{
                            color: 'white',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: '#D32F2F'
                        }}
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}


export default connect(mapStateToProps, null)(RejectVerivication);