export const FETCH_LOGIN_START = 'FETCH_LOGIN_START'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_REFRESH_TOKEN_SUCCESS = 'FETCH_REFRESH_TOKEN_SUCCESS'
export const SAVE_DATA_FOR_ACTIVE = 'SAVE_DATA_FOR_ACTIVE'
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR'
export const EXIT = 'EXIT'

export const SIGNUP_FIRST_STAGE_SAVE_DATA = 'SIGNUP_FIRST_STAGE_SAVE_DATA'
export const SIGNUP_SECOND_STAGE_SAVE_COMPANY_DATA = 'SIGNUP_SECOND_STAGE_SAVE_COMPANY_DATA'

export const FETCH_USER_DATA_START = 'FETCH_USER_DATA_START'
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS'
export const FETCH_USER_DATA_TECHNICS_SUCCESS = 'FETCH_USER_DATA_TECHNICS_SUCCESS'
export const FETCH_USER_DATA_SQUARES_SUCCESS = 'FETCH_USER_DATA_SQUARES_SUCCESS'
export const FETCH_USER_DATA_APPLICATIONS_SUCCESS = 'FETCH_USER_DATA_APPLICATIONS_SUCCESS'
export const FETCH_USER_DATA_DEALS_SUCCESS = 'FETCH_USER_DATA_DEALS_SUCCESS'
export const FETCH_USER_DATA_CONFLICTS_SUCCESS = 'FETCH_USER_DATA_CONFLICTS_SUCCESS'
export const FETCH_USER_DATA_REPLIES_SUCCESS = 'FETCH_USER_DATA_REPLIES_SUCCESS'
export const FETCH_USER_DATA_REPLIES_START = 'FETCH_USER_DATA_REPLIES_START'
export const FETCH_USER_DATA_CONTRACTS_SUCCESS = 'FETCH_USER_DATA_CONTRACTS_SUCCESS'
export const FETCH_USER_DATA_NOTIFICATIONS_SUCCESS = 'FETCH_USER_DATA_NOTIFICATIONS_SUCCESS'
export const FETCH_USER_CHATS_UNREADED_MESSAGES = 'FETCH_USER_CHATS_UNREADED_MESSAGES'
export const FETCH_USER_UNREADED_NOTIFICATIONS = 'FETCH_USER_UNREADED_NOTIFICATIONS'
export const FETCH_USER_DATA_NOTIFICATIONS = 'FETCH_USER_DATA_NOTIFICATIONS'
export const CLEARE_USER_DATA = 'CLEARE_USER_DATA'
export const CLEARE_HEADERINFO_DATA = 'CLEARE_HEADERINFO_DATA'

export const FETCH_DADATA_START = 'FETCH_DADATA_START'
export const FETCH_DADATA_SUCCESS = 'FETCH_DADATA_SUCCESS'

export const SET_VISIBLE_MODAL = 'SET_VISIBLE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CHANGE_MODAL_TYPE = 'CHANGE_MODAL_TYPE'

export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START'
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS'

export const FETCH_USER_BY_ID_DATA_START = 'FETCH_USER_BY_ID_DATA_START'
export const FETCH_USER_BY_ID_DATA_SUCCESS = 'FETCH_USER_BY_ID_DATA_SUCCESS'
export const CLEAR_USER_BY_ID_DATA = 'CLEAR_USER_BY_ID_DATA'

export const FETCH_TEHNICS_BY_ID_DATA_START = 'FETCH_TEHNICS_BY_ID_DATA_START'
export const FETCH_TEHNICS_BY_ID_DATA_SUCCESS = 'FETCH_TEHNICS_BY_ID_DATA_SUCCESS'
export const FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS = 'FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS'

export const FETCH_APPLICATION_BY_ID_DATA_START = 'FETCH_APPLICATION_BY_ID_DATA_START'
export const FETCH_APPLICATION_BY_ID_DATA_SUCCESS = 'FETCH_APPLICATION_BY_ID_DATA_SUCCESS'
export const CLEAR_APPLICATION_BY_ID_DATA = 'CLEAR_APPLICATION_BY_ID_DATA'

export const FETCH_ALL_CONTRACTS_START = 'FETCH_ALL_CONTRACTS_START'
export const FETCH_ALL_CONTRACTS_SUCCESS = 'FETCH_ALL_CONTRACTS_SUCCESS'

export const FETCH_CONTRACT_BY_ID_DATA_START = 'FETCH_CONTRACT_BY_ID_DATA_START'
export const FETCH_CONTRACT_BY_ID_DATA_SUCCESS = 'FETCH_CONTRACT_BY_ID_DATA_SUCCESS'
export const FETCH_CONTRACT_BY_ID_TECHNIC_MOVEMENT_DATA_SUCCESS = 'FETCH_CONTRACT_BY_ID_TECHNIC_MOVEMENT_DATA_SUCCESS'
export const FETCH_CONTRACT_BY_ID_TECHNIC_STATISCICS_DATA_SUCCESS = 'FETCH_CONTRACT_BY_ID_TECHNIC_STATISCICS_DATA_SUCCESS'
export const FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS = 'FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS'
export const FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS = 'FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS'
export const CLEAR_CONTRACT_BY_ID_DATA_STATISTICS = 'CLEAR_CONTRACT_BY_ID_DATA_STATISTICS'
export const CLEAR_CONTRACT_BY_ID_DATA = 'CLEAR_CONTRACT_BY_ID_DATA'
export const ADMIN_FETCH_CONTRACT_BY_ID_DATA_START = 'ADMIN_FETCH_CONTRACT_BY_ID_DATA_START'
export const ADMIN_FETCH_CONTRACT_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_CONTRACT_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_CONTRACT_BY_ID_DATA = 'ADMIN_CLEAR_CONTRACT_BY_ID_DATA'

export const FETCH_PLOTS_BY_ID_DATA_START = 'FETCH_PLOTS_BY_ID_DATA_START'
export const FETCH_PLOTS_BY_ID_DATA_SUCCESS = 'FETCH_PLOTS_BY_ID_DATA_SUCCESS'
export const CLEAR_PLOTS_BY_ID_DATA = 'CLEAR_PLOTS_BY_ID_DATA'
export const ADMIN_FETCH_PLOTS_BY_ID_DATA_START = 'ADMIN_FETCH_PLOTS_BY_ID_DATA_START'
export const ADMIN_FETCH_PLOTS_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_PLOTS_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_PLOTS_BY_ID_DATA = 'ADMIN_CLEAR_PLOTS_BY_ID_DATA'

export const FETCH_ALL_TECHNICS_START = 'FETCH_ALL_TECHNICS_START'
export const FETCH_ALL_TECHNICS_SUCCESS = 'FETCH_ALL_TECHNICS_SUCCESS'

export const FETCH_TECHNIC_BY_ID_DATA_START = 'FETCH_TECHNIC_BY_ID_DATA_START'
export const FETCH_TECHNIC_BY_ID_DATA_SUCCESS = 'FETCH_TECHNIC_BY_ID_DATA_SUCCESS'
export const CLEAR_TECHNIC_BY_ID_DATA = 'CLEAR_TECHNIC_BY_ID_DATA'
export const ADMIN_FETCH_TECHNIC_BY_ID_DATA_START = 'ADMIN_FETCH_TECHNIC_BY_ID_DATA_START'
export const ADMIN_FETCH_TECHNIC_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_TECHNIC_BY_ID_DATA_SUCCESS'
export const ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS = 'ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_TECHNIC_BY_ID_DATA = 'ADMIN_CLEAR_TECHNIC_BY_ID_DATA'

export const FETCH_TECHNIC_TYPES_SUCCESS = 'FETCH_TECHNIC_TYPES_SUCCESS'

export const FETCH_WORK_TYPES_SUCCESS = 'FETCH_WORK_TYPES_SUCCESS'

export const FETCH_SQUARE_TYPES_SUCCESS = 'FETCH_SQUARE_TYPES_SUCCESS'

export const FETCH_CROP_TYPES_SUCCESS = 'FETCH_CROP_TYPES_SUCCESS'

export const FETCH_SOWING_TYPES_SUCCESS = 'FETCH_SOWING_TYPES_SUCCESS'

export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'

export const FETCH_TECHNIC_MANUFACTURER_SUCCESS = 'FETCH_TECHNIC_MANUFACTURER_SUCCESS'
export const FETCH_TECHNIC_MODEL_SUCCESS = 'FETCH_TECHNIC_MODEL_SUCCESS'

export const SAVE_CREATE_TECHNIC_DATA = 'SAVE_CREATE_TECHNIC_DATA'
export const SAVE_CREATE_TECHNIC_MAIN_FILES = 'SAVE_CREATE_TECHNIC_MAIN_FILES'
export const SAVE_CREATE_TECHNIC_OTHER_FILES = 'SAVE_CREATE_TECHNIC_OTHER_FILES'
export const SAVE_CREATE_TECHNIC_BUSY_PERIOD = 'SAVE_CREATE_TECHNIC_BUSY_PERIOD'
export const CLEAR_CREATE_TECHNIC_DATA = 'CLEAR_CREATE_TECHNIC_DATA'
export const SAVE_COORDS = 'SAVE_COORDS'

export const SAVE_CREATE_SQUARE_DATA = 'SAVE_CREATE_SQUARE_DATA'
export const SAVE_CREATE_SQUARE_PLOTS = 'SAVE_CREATE_SQUARE_PLOTS'
export const DELETE_CREATE_SQUARE_PLOT = 'DELETE_CREATE_SQUARE_PLOT'
export const SAVE_CREATE_SQUARE_SEASONS = 'SAVE_CREATE_SQUARE_SEASONS'
export const DELETE_CREATE_SQUARE_SEASONS = 'DELETE_CREATE_SQUARE_SEASONS'
export const SAVE_CREATE_SQUARE_EDIT_SEASONS = 'SAVE_CREATE_SQUARE_EDIT_SEASONS'
export const SAVE_CREATE_SQUARE_MAIN_FILES = 'SAVE_CREATE_SQUARE_MAIN_FILES'
export const SAVE_CREATE_SQUARE_OTHER_FILES = 'SAVE_CREATE_SQUARE_OTHER_FILES'
export const SAVE_CREATE_SQUARE_OWNER_FILES = 'SAVE_CREATE_SQUARE_OWNER_FILES'
export const SAVE_CREATE_SQUARE_DOCS = 'SAVE_CREATE_SQUARE_DOCS'
export const CLEAR_CREATE_SQUARE_DATA = 'CLEAR_CREATE_SQUARE_DATA'
export const SAVE_SQUARE_COORDS = 'SAVE_SQUARE_COORDS'

export const FETCH_SQUARE_BY_ID_DATA_START = 'FETCH_SQUARE_BY_ID_DATA_START'
export const FETCH_SQUARE_BY_ID_DATA_SUCCESS = 'FETCH_SQUARE_BY_ID_DATA_SUCCESS'
export const CLEAR_SQUARE_BY_ID_DATA = 'CLEAR_SQUARE_BY_ID_DATA'
export const ADMIN_FETCH_SQUARE_BY_ID_DATA_START = 'ADMIN_FETCH_SQUARE_BY_ID_DATA_START'
export const ADMIN_FETCH_SQUARE_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_SQUARE_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_SQUARE_BY_ID_DATA = 'ADMIN_CLEAR_SQUARE_BY_ID_DATA'

export const FETCH_REPLIES_BY_ID_DATA_START = 'FETCH_REPLIES_BY_ID_DATA_START'
export const FETCH_REPLIES_BY_ID_DATA_SUCCESS = 'FETCH_REPLIES_BY_ID_DATA_SUCCESS'
export const CLEAR_REPLIES_BY_ID_DATA = 'CLEAR_REPLIES_BY_ID_DATA'
export const ADMIN_FETCH_REPLIES_BY_ID_DATA_START = 'ADMIN_FETCH_REPLIES_BY_ID_DATA_START'
export const ADMIN_FETCH_REPLIES_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_REPLIES_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_REPLIES_BY_ID_DATA = 'ADMIN_CLEAR_REPLIES_BY_ID_DATA'

export const FETCH_ALL_SQUARES_START = 'FETCH_ALL_SQUARES_START'
export const FETCH_ALL_SQUARES_SUCCESS = 'FETCH_ALL_SQUARES_SUCCESS'

export const FETCH_CONFLICT_BY_ID_DATA_START = 'FETCH_CONFLICT_BY_ID_DATA_START'
export const FETCH_CONFLICT_BY_ID_DATA_SUCCESS = 'FETCH_CONFLICT_BY_ID_DATA_SUCCESS'
export const CLEAR_CONFLICT_BY_ID_DATA = 'CLEAR_CONFLICT_BY_ID_DATA'

export const FETCH_NOTIFICATION_BY_ID_DATA_START = 'FETCH_NOTIFICATION_BY_ID_DATA_START'
export const FETCH_NOTIFICATION_BY_ID_DATA_SUCCESS = 'FETCH_NOTIFICATION_BY_ID_DATA_SUCCESS'
export const CLEAR_NOTIFICATION_BY_ID_DATA = 'CLEAR_NOTIFICATION_BY_ID_DATA'

export const SAVE_CREATE_APPLICATION_DATA = 'SAVE_CREATE_APPLICATION_DATA'
export const SAVE_CREATE_APPLICATION_DATA_DATE = 'SAVE_CREATE_APPLICATION_DATA_DATE'
export const CLEAR_CREATE_APPLICATION_DATA = 'CLEAR_CREATE_APPLICATION_DATA'
export const START_CREATE_APPLICATION_FETCH = 'START_CREATE_APPLICATION_FETCH'
export const STOP_CREATE_APPLICATION_FETCH = 'STOP_CREATE_APPLICATION_FETCH'

export const FETCH_MARKET_ALL_TECHNICS_START = 'FETCH_MARKET_ALL_TECHNICS_START'
export const FETCH_MARKET_ALL_TECHNICS_SUCCESS = 'FETCH_MARKET_ALL_TECHNICS_SUCCESS'

export const MARKET_FETCH_TECHNIC_BY_ID_DATA_START = 'MARKET_FETCH_TECHNIC_BY_ID_DATA_START'
export const MARKET_FETCH_TECHNIC_BY_ID_DATA_SUCCESS = 'MARKET_FETCH_TECHNIC_BY_ID_DATA_SUCCESS'
export const MARKET_FETCH_TECHNIC_CONFIDATIAL_DATA_BY_ID_SUCCESS = 'MARKET_FETCH_TECHNIC_CONFIDATIAL_DATA_BY_ID_SUCCESS'
export const MARKET_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS = 'MARKET_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS'
export const MARKET_FETCH_ANOTHER_TECHNICS = 'MARKET_FETCH_ANOTHER_TECHNICS'
export const MARKET_FETCH_BIDS_BY_TECHNICS = 'MARKET_FETCH_BIDS_BY_TECHNICS'
export const MARKET_CLEAR_TECHNIC_BY_ID_DATA = 'MARKET_CLEAR_TECHNIC_BY_ID_DATA'

export const FETCH_MARKET_ALL_SQUARES_START = 'FETCH_MARKET_ALL_SQUARES_START'
export const FETCH_MARKET_ALL_SQUARES_SUCCESS = 'FETCH_MARKET_ALL_SQUARES_SUCCESS'

export const MARKET_FETCH_SQUARES_BY_ID_DATA_START = 'MARKET_FETCH_SQUARES_BY_ID_DATA_START'
export const MARKET_FETCH_SQUARES_BY_ID_DATA_SUCCESS = 'MARKET_FETCH_SQUARES_BY_ID_DATA_SUCCESS'
export const MARKET_FETCH_ANOTHER_SQUARES = 'MARKET_FETCH_ANOTHER_SQUARES'
export const MARKET_FETCH_BIDS_BY_SQUARES = 'MARKET_FETCH_BIDS_BY_SQUARES'
export const MARKET_CLEAR_SQUARES_BY_ID_DATA = 'MARKET_CLEAR_SQUARES_BY_ID_DATA'

export const FETCH_PUBLIC_USER_DATA_START = 'FETCH_PUBLIC_USER_DATA_START'
export const FETCH_PUBLIC_USER_DATA_SUCCESS = 'FETCH_PUBLIC_USER_DATA_SUCCESS'
export const CLEAR_PUBLIC_USER_DATA = 'CLEAR_PUBLIC_USER_DATA'

export const FETCH_LIKES_START = 'FETCH_LIKES_START'
export const FETCH_LIKES_SUCCESS = 'FETCH_LIKES_SUCCESS'

export const FETCH_ALL_COMPLAINTS_START = 'FETCH_ALL_COMPLAINTS_START'
export const FETCH_ALL_COMPLAINTS_SUCCESS = 'FETCH_ALL_COMPLAINTS_SUCCESS'

export const ADMIN_FETCH_COMPLAINT_BY_ID_DATA_START = 'ADMIN_FETCH_COMPLAINT_BY_ID_DATA_START'
export const ADMIN_FETCH_COMPLAINT_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_COMPLAINT_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_COMPLAINT_BY_ID_DATA = 'ADMIN_CLEAR_COMPLAINT_BY_ID_DATA'

export const FETCH_ALL_CONFLICTS_START = 'FETCH_ALL_CONFLICTS_START'
export const FETCH_ALL_CONFLICTS_SUCCESS = 'FETCH_ALL_CONFLICTS_SUCCESS'

export const ADMIN_FETCH_CONFLICT_BY_ID_DATA_START = 'ADMIN_FETCH_CONFLICT_BY_ID_DATA_START'
export const ADMIN_FETCH_CONFLICT_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_CONFLICT_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_CONFLICT_BY_ID_DATA = 'ADMIN_CLEAR_CONFLICT_BY_ID_DATA'

export const FETCH_ALL_DELETED_USERS_START = 'FETCH_ALL_DELETED_USERS_START'
export const FETCH_ALL_DELETED_USERS_SUCCESS = 'FETCH_ALL_DELETED_USERS_SUCCESS'

export const FETCH_ALL_USER_CHATS_START = 'FETCH_ALL_USER_CHATS_START'
export const FETCH_ALL_USER_CHATS_CONCAT = 'FETCH_ALL_USER_CHATS_CONCAT'
export const FETCH_CHECK_CHAT_BY_USER_ID = 'FETCH_CHECK_CHAT_BY_USER_ID'
export const CLEAR_ALL_USER_CHATS = 'CLEAR_ALL_USER_CHATS'
export const CLEAR_CHECK_CHAT_BY_USER_ID = 'CLEAR_CHECK_CHAT_BY_USER_ID'
export const FETCH_CHAT_ROOM_BY_ID_DATA_START = 'FETCH_CHAT_ROOM_BY_ID_DATA_START'
export const FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS = 'FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS'
export const CLEAR_CHAT_ROOM_BY_ID_DATA = 'CLEAR_CHAT_ROOM_BY_ID_DATA'
export const FETCH_ALL_USER_FOLDERS_START = 'FETCH_ALL_USER_FOLDERS_START'
export const FETCH_ALL_USER_FOLDERS_CONCAT = 'FETCH_ALL_USER_FOLDERS_CONCAT'
export const CLEAR_ALL_USER_FOLDERS = 'CLEAR_ALL_USER_FOLDERS'