import { api_squares } from 'api/square'
import {
    CLEAR_CREATE_SQUARE_DATA,
    FETCH_SQUARE_BY_ID_DATA_START,
    FETCH_SQUARE_BY_ID_DATA_SUCCESS,
    FETCH_USER_DATA_SQUARES_SUCCESS
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'
import { fetchMyData } from './user'
import { fetchCreateAddress } from './address'
import { fetchCreatePlot } from './plots'
import { fetchCreateSeason, fetchReloadCropType, fetchAddPlotOnSeason } from './seasons'

export function fetchMySquares(page, size, params) {
    return async dispatch => {
        try {
            const squares_data = await api_squares.GetMySquares(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_SQUARES_SUCCESS, {
                data: squares_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchSquareByID(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_SQUARE_BY_ID_DATA_START))
        try {
            const seasons = []
            let squareSeasonCopy
            const square_data = await api_squares.GetSquareById(id)
            squareSeasonCopy = JSON.parse(JSON.stringify(square_data.data.data.seasons))
            square_data.data.data.seasons.forEach(season => {
                season.plots.forEach(plot => {
                    seasons.push({
                        season_id: season.id,
                        plot_id: plot.id
                    })
                })
            })
            await dispatch(fetchReloadCropType(seasons))
                .then(res => {
                    res.forEach(el => {
                        squareSeasonCopy.find(season => season.id === el.season_id).plots.find(plot => plot.id === el.plot_id).crop_type_id = el.crop_type
                    })
                })
            dispatch(fetchSuccess(FETCH_SQUARE_BY_ID_DATA_SUCCESS, {
                square_by_id_data: square_data.data.data,
                seasons: squareSeasonCopy
            }))
        } catch (e) {
        }
    }
}

export function fetchCreateSquare(data) {
    return async dispatch => {
        try {
            const square = await api_squares.PostCreateSquare(data)
            return square.data.data.id
        } catch (e) {
        }
    }
}

export function fetchUpdateSquare(id, data) {
    return async dispatch => {
        try {
            await api_squares.UpdateSquare(data, id)
            await dispatch(fetchSquareByID(id))
        } catch (e) {
        }
    }
}

export function fetchUploadSquareImage(id, data) {
    return async dispatch => {
        try {
            await api_squares.UploadSquareImageById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteSquareImage(id, img_id) {
    return async dispatch => {
        try {
            await api_squares.DeleteSquareImageById(id, img_id)
        } catch (e) {
        }
    }
}

export function fetchUploadSquareDocs(id, data) {
    return async dispatch => {
        try {
            await api_squares.UploadSquareDocsById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteSquareDoc(id, doc_id) {
    return async dispatch => {
        try {
            await api_squares.DeleteSquareDocumentById(id, doc_id)
            dispatch(fetchSquareByID(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteSquare(id) {
    return async dispatch => {
        try {
            await api_squares.DeleteSquareById(id)
        } catch (e) {
        }
    }
}

export function fetchFullCreatingSquare({ create_square_quick, plots, seasons, main_info_files_photo, main_info_files_isOwner, docs, coords }) {
    return async dispatch => {
        try {
            const createObj = JSON.parse(JSON.stringify(create_square_quick))
            const seasonsCopy = JSON.parse(JSON.stringify(seasons))
            const saveCreatePlots = []
            const allDocs = [...main_info_files_isOwner, ...docs]
            const address = {
                latitude: coords[0],
                longitude: coords[1]
            }

            await dispatch(fetchCreateAddress(address))
                .then(res => createObj.address_id = res)
            await dispatch(fetchCreateSquare(createObj))
                .then(async (res) => {
                    for (let i = 0; i < main_info_files_photo.length; i++) {
                        let data = new FormData();
                        let filedata = main_info_files_photo[i].originFileObj
                        delete filedata.uid
                        data.append('image_file', filedata);
                        data.append('title', main_info_files_photo[i].name);
                        await dispatch(fetchUploadSquareImage(res, data))
                    }
                    for (let i = 0; i < allDocs.length; i++) {
                        let data = new FormData();
                        data.append('file', allDocs[i]);
                        data.append('name', allDocs[i].name);
                        await dispatch(fetchUploadSquareDocs(res, data))
                    }
                    for (let i = 0; i < plots.length; i++) {
                        const plotCopy = JSON.parse(JSON.stringify(plots[i]))
                        const plotAddress = {
                            latitude: plotCopy.address[0],
                            longitude: plotCopy.address[1]
                        }
                        delete plotCopy.crop_type_name
                        plotCopy.square_id = res
                        await dispatch(fetchCreateAddress(plotAddress))
                            .then(address_id => {
                                delete plotCopy.address
                                plotCopy.address_id = address_id
                            })
                        await dispatch(fetchCreatePlot(plotCopy))
                            .then(plot_id => {
                                saveCreatePlots.push({
                                    index: plotCopy.index,
                                    plot_id
                                })
                            })
                    }
                    for (let i = 0; i < seasons.length; i++) {
                        const season = {
                            year: seasons[i].year || 0,
                            square_id: res
                        }
                        await dispatch(fetchCreateSeason(season))
                            .then(async (season_id) => {
                                for (let j = 0; j < seasonsCopy[i].plots.length; j++) {
                                    const seasonPlot = seasonsCopy[i].plots[j]
                                    const togglePlot = {
                                        crop_type_id: seasonPlot.crop_type_id,
                                        plot_id: saveCreatePlots.find(el => el.index === seasonPlot.index).plot_id
                                    }
                                    await dispatch(fetchAddPlotOnSeason(season_id, togglePlot))
                                }
                            })
                    }
                })
            dispatch(fetchMyData())
            dispatch({ type: CLEAR_CREATE_SQUARE_DATA })
            return true
        } catch (e) {
        }
    }
}