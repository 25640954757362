import { Link, useNavigate } from 'react-router-dom';
import { headerList } from 'data/routings';
import SimpleButton from '../buttons/simpleButton/SimpleButton';

import cross from 'assets/images/landing/icons/cross.svg';
import HeaderLogo from 'assets/images/headerLogo.svg';

import './style.css';
import './media.css';
import { footerList } from 'data/routings';

const BurgerMenu = ({ active, setActive }) => {
    const navigate = useNavigate()

    return (
        <>
            <div className={`burgerMenu ${active ? 'active' : ''}`}>
                <div>
                    <div className='burgerMenu_header'>
                        <img src={HeaderLogo} alt="HeaderLogo" />
                        <img src={cross} alt="cross" onClick={() => setActive()} />
                    </div>
                    <div className='burgerMenu_links'>
                        {headerList.map((item, key) => (
                            <Link to={item.to} key={`headerLink_${key}`} className='p_mb16 p14_400'>{item.title}</Link>
                        ))}
                        {footerList.map((item, key) => (
                            <Link to={item.to} key={`footer_link${key}`} className='p_mb16 p14_400'>{item.title}</Link>
                        ))}
                    </div>
                </div>
                <div className='burgerMenu_buttons'>
                    <SimpleButton
                        title={'Войти'}
                        className='greenButton'
                        onClick={() => navigate(`/login`)}
                    />
                    <SimpleButton
                        title={'Зарегистрироваться'}
                        className='greenButton'
                        onClick={() => navigate(`/signup/first-stage`)}
                        style={{
                            marginTop: 16
                        }}
                    />
                </div>
            </div>
            {active && <div className={`burgerMenuBg`} onClick={() => setActive()}></div>}
        </>
    )
}

export default BurgerMenu