import { connect } from 'react-redux'
import { FullscreenControl, GeolocationControl, Map, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { CLOSE_MODAL } from 'store/actionTypes';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import pin from 'assets/images/pins/field.svg';

function BidInfo({ data, setVisible }) {
    
    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center', width: '50vw' }}>Информация о заявке</p>
            <InfoBlock
                style={{
                    marginBottom: 20,
                    marginTop: 20
                }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Дата начала</p>
                    <p className='p14_400 mainText p_mb16'>{data.bid.date_from}</p>
                    <p className='p12_400 mainText p_mb8'>Дата окончания</p>
                    <p className='p14_400 mainText '>{data.bid.date_to}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Тип работ</p>
                    <p className='p14_400 mainText p_mb16'>{data.bid.work_type.name}</p>
                    <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                    <p className='p14_400 mainText p_mb16'>{data.bid.crop_type ? data.bid.crop_type.name : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Стоимость заявки</p>
                    <p className='p14_400 mainText'>{data.bid.amount}</p>
                </div>
            </InfoBlock>
            <InfoBlock
                style={{
                    marginBottom: 20,
                }}
            >
                <p className='p12_400 mainText p_mb8'>Описание заявки</p>
                <p className='p14_400 mainText p_mb16'>{data.bid.description}</p>
                <p className='p12_400 mainText p_mb8'>Условия оплаты</p>
                <p className='p14_400 mainText'>{data.bid.payment_desc}</p>
            </InfoBlock>
            <div className='locationBlock_map' style={{ height: '300px', width: '100%' }}>
                <Map
                    defaultState={{
                        center: data.map_center,
                        zoom: 18,
                        type: 'yandex#hybrid'
                    }}
                    width='100%'
                    height='100%'
                >
                    <FullscreenControl />
                    <GeolocationControl options={{ float: "left" }} />
                    <TypeSelector options={{ float: 'right' }} />
                    {data.bid.plots.map(el => (
                        <Placemark
                            geometry={[el.address.latitude, el.address.longitude]}
                            key={`pin_${el.id}`}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: pin,
                                iconImageSize: [70, 70],
                                // iconImageOffset: [-5, -38]
                            }}
                        />
                    ))}
                </Map>
            </div>
            <p className='activeText p14_500 p_mt24' style={{ textAlign: 'center', cursor: 'pointer' }} onClick={setVisible}>Отменить</p>
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(BidInfo);