import { useState } from "react";
import { useLocation } from "react-router-dom";
import { signupSecondStageSwitch } from "data/lists";
import PrivatePerson from "components/components/forms/authentication/signup/secondStageSignup/privatePaerson/PrivatePersom";
import LegalPerson from "components/components/forms/authentication/signup/secondStageSignup/legalPerson/LegalPerson";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Switch from "components/shared/switch/Switch";

import '../../style.css';

function SecondStageSignup() {
    const [activeSwitch, setActiveSwitch] = useState('fiz')
    const location = useLocation()
    
    return (
        <AuthenticationBlock>
            <TextHeading text={'Регистрация'} style={{ textAlign: 'center' }} />
            <Switch
                items={signupSecondStageSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
            />
            {activeSwitch === 'fiz' && <PrivatePerson mode={location.state} />}
            {activeSwitch === 'ur' && <LegalPerson mode={location.state} />}
        </AuthenticationBlock>
    );
}

export default SecondStageSignup;