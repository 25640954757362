import {
    FETCH_CONFLICT_BY_ID_DATA_START,
    FETCH_CONFLICT_BY_ID_DATA_SUCCESS,
    CLEAR_CONFLICT_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    conflict_by_id_data: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONFLICT_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_CONFLICT_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                conflict_by_id_data: action.data.conflict_by_id_data,
                loading: false
            }
        case CLEAR_CONFLICT_BY_ID_DATA:
            return {
                conflict_by_id_data: null,
                loading: false
            }
        default: return state
    }
}