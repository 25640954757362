import {
    FETCH_USER_CHATS_UNREADED_MESSAGES,
    FETCH_USER_UNREADED_NOTIFICATIONS,
    CLEARE_HEADERINFO_DATA
} from '../../actionTypes'

const initialState = {
    unreaded_messages: [],
    notifications: []
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_CHATS_UNREADED_MESSAGES:
            return {
                ...state,
                unreaded_messages: action.data.unreaded_messages,
                loading: false
            }
        case FETCH_USER_UNREADED_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.data.notifications,
                loading: false
            }
        case CLEARE_HEADERINFO_DATA:
            return {
                unreaded_messages: [],
                notifications: []
            }
        default: return state
    }
}