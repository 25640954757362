import { connect } from 'react-redux'
import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SAVE_CREATE_SQUARE_PLOTS } from 'store/actionTypes';
import { fetchCreateAddress, fetchUpdateAddress } from 'store/actions/address';
import { fetchCreatePlot } from 'store/actions/plots';
import { fetchSquareByID } from 'store/actions/square';
import { fetchUpdatePlotByID } from 'store/actions/plotById';
import { errorNotification } from 'hooks/notifications';

const { Option } = Select;

function CreatePlot({
    form,
    coords,
    step,
    page,
    crop_types,
    plots,
    square_by_id_data,
    create_square_quick,
    plotById,
    SAVE_CREATE_SQUARE_PLOTS,
    fetchCreateAddress,
    fetchUpdateAddress,
    fetchCreatePlot,
    fetchUpdatePlotByID,
    fetchSquareByID
}) {
    const navigate = useNavigate()
    const [fields, setFields] = useState([
        {
            name: 'number',
            value: page === 'byId_edit' ? plotById.number : null,
        },
        {
            name: 'fact_sowing',
            value: page === 'byId_edit' ? plotById.fact_sowing : null,
        },
        {
            name: 'serial',
            value: page === 'byId_edit' ? plotById.serial : null
        },
        {
            name: 'crop_type_id',
            value: page === 'byId_edit' ? plotById.crop_type_id : null,
        }
    ]);

    const onFinish = async ({ number, fact_sowing, serial, crop_type_id }) => {
        if (coords) {
            let count = 0
            if (page === 'byId') {
                square_by_id_data.plots.forEach(el => count += parseInt(el.fact_sowing))
                if ((count + parseInt(fact_sowing)) <= parseInt(square_by_id_data.size)) {
                    const address = {
                        latitude: coords[0],
                        longitude: coords[1]
                    }
                    fetchCreateAddress(address)
                        .then(async (res) => {
                            const obj = {
                                number,
                                fact_sowing,
                                serial,
                                crop_type_id,
                                square_id: square_by_id_data.id,
                                address_id: res,
                            }
                            await fetchCreatePlot(obj)
                            await fetchSquareByID(square_by_id_data.id)
                            navigate(`/user/my-fields/${square_by_id_data.id}`, { state: { step } })
                        })
                } else {
                    errorNotification('Большой размер', 'Суммарная площадь всех участков превышает размер поля')
                }
            } else if (page === 'byId_edit') {
                square_by_id_data.plots.forEach(el => count += parseInt(el.fact_sowing))
                if ((count + parseInt(fact_sowing)) <= parseInt(square_by_id_data.size)) {
                    const address = {
                        latitude: coords[0],
                        longitude: coords[1]
                    }
                    const obj = {
                        number,
                        fact_sowing,
                        serial,
                        crop_type_id,
                    }
                    await fetchUpdateAddress(address, plotById.address_id)
                    await fetchUpdatePlotByID(obj, plotById.id)
                    fetchSquareByID(square_by_id_data.id)
                    navigate(`/user/my-fields/${square_by_id_data.id}`, { state: { step } })
                } else {
                    errorNotification('Большой размер', 'Суммарная площадь всех участков превышает размер поля')
                }
            } else {
                plots.forEach(el => count += parseInt(el.fact_sowing))
                if ((count + parseInt(fact_sowing)) <= parseInt(create_square_quick.size)) {
                    const obj = {
                        number,
                        fact_sowing,
                        serial,
                        crop_type_id,
                        crop_type_name: crop_types.find(el => el.id === crop_type_id).name,
                        address: coords,
                        index: plots.length
                    }
                    SAVE_CREATE_SQUARE_PLOTS(obj)
                    navigate(`/user/my-fields/full-create`, { state: { step } })
                } else {
                    errorNotification('Большой размер', 'Суммарная площадь всех участков превышает размер поля')
                }
            }
        } else {
            errorNotification('Нет местоположения', 'Укажите местоположение')
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            className="twoColumnForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            form={form}
            onFinish={onFinish}
        >
            <div className='twoColumnForm_column'>
                <Form.Item
                    label={'Номер'}
                    name="number"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите номер участка' type="number" />
                </Form.Item>
                <Form.Item
                    label={'Фактический посев, Га'}
                    name="fact_sowing"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите фактический посев' type="number" />
                </Form.Item>
            </div>
            <div className='twoColumnForm_column'>
                <Form.Item
                    label={'Порядковый номер'}
                    name="serial"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите порядковый номер' type="number" />
                </Form.Item>
                <Form.Item
                    label={'Выращиваемая культура'}
                    name="crop_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип культуры">
                        {crop_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        crop_types: state.cropTypes.crop_types,
        plots: state.createSquare.plots,
        create_square_quick: state.createSquare.create_square_quick,
        square_by_id_data: state.squareById.square_by_id_data,
        plotById: state.plotById.plots_by_id_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_SQUARE_PLOTS: (data) => dispatch({ type: SAVE_CREATE_SQUARE_PLOTS, plot: data }),
        fetchCreateAddress: (data) => dispatch(fetchCreateAddress(data)),
        fetchUpdateAddress: (data, address_id) => dispatch(fetchUpdateAddress(data, address_id)),
        fetchCreatePlot: (data) => dispatch(fetchCreatePlot(data)),
        fetchUpdatePlotByID: (data, id) => dispatch(fetchUpdatePlotByID(data, id)),
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreatePlot);