import {
    FETCH_SOWING_TYPES_SUCCESS
} from '../actionTypes'

const initialState = {
    sowing_types: [],
    loading: true
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SOWING_TYPES_SUCCESS:
            return {
                ...state,
                sowing_types: action.data.sowing_types,
                loading: false
            }
        default: return state
    }
}