import { api_user } from 'api/user'
import {
    FETCH_LIKES_SUCCESS
} from 'store/actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchMyLikes(page, size, params) {
    return async dispatch => {
        try {
            const likes_data = await api_user.GetMyLikes(page, size, params)
            dispatch(fetchSuccess(FETCH_LIKES_SUCCESS, {
                data: likes_data.data.data
            }))
        } catch (e) {
        }
    }
}
