import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Radio, Select, Checkbox } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CLEAR_SQUARE_BY_ID_DATA, SAVE_CREATE_TECHNIC_DATA, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchMySquares, fetchSquareByID } from 'store/actions/square';
import { fetchAddPlotsReplies, fetchCreateReplies } from 'store/actions/replies';
import { fetchAddPlotsDeals, fetchAddTechnicToDeals, fetchCreateDeals } from 'store/actions/deals';
import { fetchBidsByTechnic, fetchTechnicsByID } from 'store/actions/market/technics';
import { errorNotification, successNotification } from 'hooks/notifications';
import { formatDate } from 'hooks/formatDate';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { fieldByIdPlotsAndSeasonsSwitch } from 'data/lists';
import { createReplyByTchnicBidsTable, createReplyByTechnicTable } from 'data/tableHeader';
import { createReplyByTchnicBidsTableWidth, createReplyByTechnicTableWidth } from 'data/tableWidth';
import SeasonCollapseItem from 'components/shared/seasonCollapse/SeasonCollapseItem';
import SeasonCollapse from 'components/shared/seasonCollapse/SeasonCollapse';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import CustomDatePicker from '../../../../shared/datePicker/DatePicker';
import Table from 'components/ui/table/Table';
import Pagination from 'components/shared/pagination/Pagination';
import Switch from 'components/shared/switch/Switch';

import field from 'assets/images/messageIcons/field.svg';

const { Option } = Select;

function CreateReplyByTechnic({
    form,
    work_types,
    user_data,
    bids, crop_types,
    user_data_squares,
    pages,
    square_by_id_data,
    technic_by_id_data,
    fetchMySquares,
    fetchBidsByTechnic,
    fetchSquareByID,
    fetchTechnicsByID,
    setVisible,
    fetchCreateReplies,
    fetchAddPlotsReplies,
    fetchCreateDeals,
    fetchAddPlotsDeals,
    fetchAddTechnicToDeals,
    CLEAR_SQUARE_BY_ID_DATA
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeSwitch, setActiveSwitch] = useState('plots')
    const [range, setRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMySquares(query.page, 50, '')
            fetchBidsByTechnic(id)
            fetchTechnicsByID(id)
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
    ])

    useEffect(() => {
        return () => {
            CLEAR_SQUARE_BY_ID_DATA()
        }
    }, [])

    const createTableData = () => {
        return user_data_squares.map(el => (
            [
                {
                    type: 'text',
                    content: el.number
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
                {
                    type: 'text',
                    content: el.square_type.name
                },
                {
                    type: 'text',
                    content: el.size
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <Radio value={el.id} />
                    </div>
                },
            ]
        ))
    }

    const onFinish = async (value) => {
        if (user_data.status === "VERIFIED") {
            if (bids.length > 0 && (!value.bids || value.bids.length === 0)) {
                errorNotification('Вы не выбрали заявку')
                return
            }
            if (!value.plots || value.plots.length === 0) {
                errorNotification('Вы не выбрали участки')
                return
            }
            if (bids.length > 0) {
                const data = {
                    reply_type: "FARMER",
                    date_from: formatDate(range[0].startDate),
                    date_to: formatDate(range[0].endDate),
                    description: value.description,
                    payment_desc: value.payment_desc,
                    amount: value.amount,
                    bid_id: value.bids,
                    square_id: value.square,
                    work_type_id: value.work_type_id,
                    crop_type_id: value.crop_type_id
                }
                await fetchCreateReplies(data)
                    .then(async (res) => {
                        if (res) {
                            await fetchAddPlotsReplies(res, value.plots)
                            navigate(`/market/technics/${id}`)
                            successNotification('Заявка отправлена', 'Заявка успешно отправлена')
                        } else {
                            errorNotification('Возникла ошибка')
                        }
                    })
            } else {
                const data = {
                    amount: value.amount,
                    valid_from: formatDate(range[0].startDate),
                    valid_to: formatDate(range[0].endDate),
                    description: value.description,
                    payment_desc: value.payment_desc,
                    is_viewed: false,
                    reply_type: "FARMER",
                    crop_type_id: value.crop_type_id,
                    work_type_id: value.work_type_id,
                    executor_id: technic_by_id_data.owner.id,
                    square_id: value.square
                }
                await fetchCreateDeals(data)
                    .then(async (res) => {
                        if (res) {
                            await fetchAddPlotsDeals(res, value.plots)
                            await fetchAddTechnicToDeals(res, [id])
                            navigate(`/market/technics/${id}`)
                            successNotification('Предложение о сотрудничестве отправлено', 'Предложение о сотрудничестве успешно отправлено')
                        } else {
                            errorNotification('Возникла ошибка')
                        }
                    })
            }
        } else {
            setVisible('noVerification', { worning_text: 'Чтобы откликнуться на заявку или оставить предложение о сотрудничестве вам необходимо пройти верификацию. Нажмите на кнопку ниже для продолжения.' })
        }
    };

    const createBidsTableData = () => {
        return bids.map(el => (
            [
                {
                    type: 'text',
                    content: 'с ' + el.date_from + ' по ' + el.date_to
                },
                {
                    type: 'text',
                    content: el.description
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: el.payment_desc
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <Radio value={el.id} />
                    </div >
                },
            ]
        ))
    }

    return (
        user_data_squares &&
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            autoComplete="off"
            className="modalForm"
            form={form}
            onFinish={onFinish}
        >
            {bids.length > 0 &&
                <Form.Item
                    name="bids"
                    label="Выберите заявку по этой технике"
                >
                    <Radio.Group style={{ width: '100%', display: 'block' }}>
                        <Table
                            header={createReplyByTchnicBidsTable}
                            width={createReplyByTchnicBidsTableWidth}
                            data={createBidsTableData()}
                        />
                    </Radio.Group>
                </Form.Item>
            }
            <Form.Item
                name="square"
                label="Выберите поле"
            >
                <Radio.Group
                    style={{ width: '100%' }}
                    onChange={(e) => {
                        fetchSquareByID(e.target.value)
                    }}
                >
                    <Table
                        header={createReplyByTechnicTable}
                        width={createReplyByTechnicTableWidth}
                        data={createTableData()}
                    />
                    {pages > 1 && <Pagination pages={pages} />}
                </Radio.Group>
            </Form.Item>
            {
                square_by_id_data && (
                    square_by_id_data.plots.length > 0
                        ?
                        <Form.Item
                            name="plots"
                            label="Выберите участок"
                        >
                            <Checkbox.Group style={{ width: '100%', display: 'block' }}>
                                <Switch
                                    items={fieldByIdPlotsAndSeasonsSwitch}
                                    activeItem={activeSwitch}
                                    onSwitch={setActiveSwitch}
                                    style={{
                                        marginBottom: 24
                                    }}
                                    className="switch miniSwitch"
                                />
                                <div className='scrollContent'>
                                    {activeSwitch === 'plots'
                                        ? square_by_id_data.plots.map(el => (
                                            <SeasonCollapseItem
                                                key={`seasonCollapsItem_${el.id}`}
                                                item={el}
                                                page={'check'}
                                            />
                                        ))
                                        : square_by_id_data.seasons.map(el => (
                                            <SeasonCollapse
                                                key={`season_${el.id}`}
                                                item={el}
                                                index={el.id}
                                                page={'check'}
                                            />
                                        ))
                                    }
                                </div>
                            </Checkbox.Group>
                        </Form.Item>
                        :
                        <InfoBlock style={{ textAlign: 'center' }}>
                            <img src={field} alt="field" />
                            <p className='mainText p24_600 p_mb24'>Нет участков</p>
                            <p className='mainText p14_400 p_mb24'>На данный момент у нас нет информации о ваших участков по выбранному полю, чтобы внести ее - нажмите на кнопку ниже.</p>
                            <SimpleButton
                                title={'Добавить участки'}
                                className="simpleButton greenButton"
                                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                onClick={() => navigate(`/user/my-fields/${square_by_id_data.id}`)}
                            />
                        </InfoBlock>
                )
            }
            <p>Срок сотрудничества</p>
            <div className='calendarBlock'>
                <CustomDatePicker
                    ranges={range}
                    mode="userCreateReply"
                    saveRange={state => setRange(state)}
                    edit={false}
                />
            </div>
            <InfoBlock>
                <Form.Item
                    label={'Тип работ'}
                    name="work_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип работ">
                        {work_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Выращиваемые культуры '}
                    name="crop_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип выращиваемой культуры">
                        {crop_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Описание предложения'}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите описание заявки' />
                </Form.Item>
                <Form.Item
                    label={'Ваши условия оплаты'}
                    name="payment_desc"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите предварительные условия оплаты' />
                </Form.Item>
                <Form.Item
                    label={'Предлагаемая цена, ₽'}
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите стоимость' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
            </InfoBlock>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
        work_types: state.workTypes.work_types,
        user_data_squares: state.userSquares.squares,
        pages: state.userSquares.pages,
        bids: state.marketTechnicById.bids,
        square_by_id_data: state.squareById.square_by_id_data,
        user_data: state.user.user_data,
        crop_types: state.cropTypes.crop_types,
        technic_by_id_data: state.marketTechnicById.technic_by_id_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
        fetchMySquares: (page, size, params) => dispatch(fetchMySquares(page, size, params)),
        fetchBidsByTechnic: (id) => dispatch(fetchBidsByTechnic(id)),
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
        fetchTechnicsByID: (id) => dispatch(fetchTechnicsByID(id)),
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchCreateReplies: (data) => dispatch(fetchCreateReplies(data)),
        fetchAddPlotsReplies: (replay_id, data) => dispatch(fetchAddPlotsReplies(replay_id, data)),
        fetchCreateDeals: (data) => dispatch(fetchCreateDeals(data)),
        fetchAddPlotsDeals: (deal_id, data) => dispatch(fetchAddPlotsDeals(deal_id, data)),
        fetchAddTechnicToDeals: (deal_id, data) => dispatch(fetchAddTechnicToDeals(deal_id, data)),
        CLEAR_SQUARE_BY_ID_DATA: () => dispatch({ type: CLEAR_SQUARE_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateReplyByTechnic);