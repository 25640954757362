import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Dropdown, notification } from "antd";
import { fetchMyApplications } from 'store/actions/applications';
import { allApplicationsTable } from 'data/tableHeader';
import { allApplicationsTableWidth } from 'data/tableWidth';
import { allApplicationsFilter } from 'data/adminLists';
import { applicationStatusStyles } from 'data/lists';
import { createQuery } from 'hooks/createQuery';
import Table from 'components/ui/table/Table';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Filter from 'components/shared/filter/Filter';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import NoDataBlock from 'components/ui/noDataBlock/NoDataBlock';
import Pagination from 'components/shared/pagination/Pagination';

import field from 'assets/images/messageIcons/field.svg';

function AllApplications({ user_data_applications, pages, square_types, crop_types, sowing_types, fetchMyApplications }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyApplications(query.page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return user_data_applications.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-applications/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.bid_type === "TECHNICIAN" ? '-' : el.work_type.name
                },
                {
                    type: 'text',
                    content: el.bid_type === "TECHNICIAN" ? 'На технику' : 'На поле'
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: el.replies_count
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: applicationStatusStyles[el.is_canceled].bg }}>{applicationStatusStyles[el.is_canceled].title}</div>
                },
            ]
        ))
    }

    return (
        (user_data_applications) && (
            (createTableData().length !== 0 || createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order) !== '')
                ?
                <div className='allUsersBlock'>
                    <div className='allUsersBlock_header'>
                        <div className='allUsersBlock_header__leftHalf'>
                            <TextHeading text={'Заявки'} />
                        </div>
                        <div className='allUsersBlock_header__rightHalf'>
                            <Filter
                                className={'filter_marginRight'}
                                filterList={allApplicationsFilter(square_types, crop_types, sowing_types)}
                            />
                            <SimpleButton
                                title={'Добавить заявку'}
                                className="greenButton"
                                onClick={() => navigate(`/user/my-applications/create`)}
                            />
                        </div>
                    </div>
                    {contextHolder}
                    <Table
                        header={allApplicationsTable}
                        width={allApplicationsTableWidth}
                        data={createTableData()}
                    />
                    {user_data_applications.length > 0 && <Pagination pages={pages} />}
                </div>
                :
                <NoDataBlock
                    img={field}
                    title={'Создайте заявку'}
                    description={'Заявки позволяют владельцам поля найти себе технику для исполнения работ. Опубликуйте заявку и соискатели будут отправлять вам свои предложения о сотрудничестве'}
                    buttonTitle={['Добавить заявку']}
                    onClick={[() => navigate(`/user/my-applications/create`)]}
                />
        )
    );
}

function mapStateToProps(state) {
    return {
        user_data_applications: state.userApplications.applications,
        pages: state.userApplications.pages,
        square_types: state.squareTypes.square_types,
        crop_types: state.cropTypes.crop_types,
        sowing_types: state.sowingTypes.sowing_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyApplications: (page, size, params) => dispatch(fetchMyApplications(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllApplications);