import './style.css';

function TextHeading({ text, ...props }) {
    return (
        <p
            className="textHeading mainText"
            {...props}
        >
            {text}
        </p>
    );
}

export default TextHeading;