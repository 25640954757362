import { useState } from "react";
import { Link } from "react-router-dom";
import { signupSwitch } from "data/lists";
import { signupList } from "data/routings";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Switch from "components/shared/switch/Switch";
import EmailSignup from "components/components/forms/authentication/signup/emailSignup/EmailSignup";
import PhoneSignup from "components/components/forms/authentication/signup/phoneSignup/PhoneSignup";

import '../../style.css';

function FirstStageSignup() {
    const [activeSwitch, setActiveSwitch] = useState('email')

    return (
        <AuthenticationBlock style={{width: 364}}>
            <TextHeading text={'Регистрация'} style={{ textAlign: 'center' }} />
            <Switch
                items={signupSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
            />
            {activeSwitch === 'email' && <EmailSignup method={activeSwitch} />}
            {activeSwitch === 'phone' && <PhoneSignup method={activeSwitch} />}
            <div className="autehenticationLinksBlock">
                <Link to={signupList[0].to} className="activeText autehenticationLink" key={`signupLink`}>{signupList[0].title}</Link>
            </div>
            <div className="registrationConditionsBlock subtext">
                Регистрируясь на платформе вы соглашаетесь с <Link to={signupList[1].to} className="subtext autehenticationSubLink" key={`signupLink`}>{signupList[1].title}</Link> вы соглашаетесь с политикой конфиденциальности и <Link to={signupList[2].to} className="subtext autehenticationSubLink" key={`signupLink`}>{signupList[2].title}</Link> приложения
            </div>
        </AuthenticationBlock>
    );
}

export default FirstStageSignup;