import axios from "axios";
import { ApiUrl } from "./apiEnv";
import { errorNotification } from "hooks/notifications";

export const api_login = {
    async EmailLoginDeforeSignUp(data) {
        return axios.post(`${ApiUrl}login`, data, {
            headers: { "Content-Type": "application/json" }
        })
    },

    async Login(data, errorFunc) {
        return axios.post(`${ApiUrl}login`, data, {
            headers: { "Content-Type": "application/json" }
        }).then(res => {
            return res.data
        }).catch(function (error) {
            if (error.response) {
                errorNotification('Некорректные данные при авторизации', 'Проверьте логин и пароль.')
                errorFunc()
                return error.response.data.detail
            } else {
                return 'Error: Network Error'
            }
        });
    },

    async RefreshToken(data) {
        return axios.post(`${ApiUrl}login/refresh_token`, data)
    },

    async PasswordRecovery(email) {
        return axios.post(`${ApiUrl}login/password_recovery/${email}`)
    },

    async PasswordReset(data) {
        return axios.post(`${ApiUrl}login/reset_password`, data)
    }
}