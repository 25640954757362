import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import SpecifiedDataLegalPerson from "components/components/forms/authentication/signup/secondStageSignup/specifiedData/SpecifiedDataLegalPerson";

import '../../style.css';

function SpecifiedDataLegalPersonSignup() {
    
    return (
        <AuthenticationBlock>
            <TextHeading text={'Проверьте ваши данные'} style={{ textAlign: 'center' }} />
            <SpecifiedDataLegalPerson/>
        </AuthenticationBlock>
    );
}

export default SpecifiedDataLegalPersonSignup;