import './style.css';

function MessageCard({ user, time, lastMessage, new_messages, onClick, active }) {
    return (
        <div
            className={`messageCard  ${active ? 'active' : ''}`}
            onClick={onClick}
        >
            <div className='messageCard_row'>
                <p className='p16_500'>{user}</p>
                <p className='p12_400 subtext'>{time}</p>
            </div>
            <div className='messageCard_row'>
                <p className='p12_400 subtext' style={{maxWidth: '60%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{lastMessage || 'Файл'}</p>
                {new_messages > 0 && <div className='new_messages_count p12_400 whiteButtonText'>{new_messages}</div>}
            </div>
        </div>
    );
}

export default MessageCard;