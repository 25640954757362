import { useRef } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { fetchDeleteUserDocs, fetchUploadUserDocs } from 'store/actions/admin/user';
import { profileDocsTable } from 'data/tableHeader';
import { profileDocsTableWidth } from 'data/tableWidth';
import { formatDate } from 'hooks/formatDate';
import { warningNotification } from 'hooks/notifications';
import { handleChangeFiles } from 'hooks/handleChangeFiles';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';
import downloading from 'assets/images/icons/downloading.svg';

function Docs({ user_by_id_data_docs, fetchUploadUserDocs, fetchDeleteUserDocs }) {
    const [api, contextHolder] = notification.useNotification();
    const fileInputRef = useRef();
    const { id } = useParams()

    const createTableData = () => {
        return user_by_id_data_docs.map(el => (
            [
                {
                    type: 'text',
                    content: el.name
                },
                {
                    type: 'active_text',
                    content: <a href={el.media.link} download target="_blank" className='activeText'>{el.name}</a>
                },
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <a href={el.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                fetchDeleteUserDocs(id, el.id)
                                api.destroy()
                            }, 'удаление документа.')}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={profileDocsTable}
                width={profileDocsTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Добавить документ'}
                style={{
                    marginTop: 20,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => fileInputRef.current.click()}
            />
            <input onChange={(e) => handleChangeFiles(e, (data) => {
                fetchUploadUserDocs(id, data)
                fileInputRef.current.value = null
            })} multiple={false} ref={fileInputRef} type='file' accept='.png, .jpg, .doc, .docx, .pdf, .word' hidden />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data_docs: state.userById.user_by_id_data.documents
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUploadUserDocs: (user_id, data) => dispatch(fetchUploadUserDocs(user_id, data)),
        fetchDeleteUserDocs: (user_id, doc_id) => dispatch(fetchDeleteUserDocs(user_id, doc_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Docs);