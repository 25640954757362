
import { api_deleted_users } from 'api/admin/deletedUsers'
import {
    FETCH_ALL_DELETED_USERS_START,
    FETCH_ALL_DELETED_USERS_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllDeletedUsers(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_DELETED_USERS_START))
        try {
            const deleted_users_data = await api_deleted_users.GetAllDeletedUsers(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_DELETED_USERS_SUCCESS, {
                data: deleted_users_data.data.data
            }))
        } catch (e) {
        }
    }
}
