import { api_conflicts } from 'api/conflicts'
import {
    FETCH_CONFLICT_BY_ID_DATA_START,
    FETCH_CONFLICT_BY_ID_DATA_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchConflictById(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_CONFLICT_BY_ID_DATA_START))
        try {
            const conflict_data = await api_conflicts.GetConflictById(id)
            dispatch(fetchSuccess(FETCH_CONFLICT_BY_ID_DATA_SUCCESS, {
                conflict_by_id_data: conflict_data.data.data
            }))
        } catch (e) {
        }
    }
}