import {
    FETCH_USER_BY_ID_DATA_START,
    FETCH_USER_BY_ID_DATA_SUCCESS,
    CLEAR_USER_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    user_by_id_data: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_USER_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                user_by_id_data: action.data.user_by_id_data
            }
        case CLEAR_USER_BY_ID_DATA:
            return {
                user_by_id_data: null
            }
        default: return state
    }
}