import { connect } from 'react-redux'
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import { createFio } from 'hooks/createFio';
import { useNavigate } from 'react-router-dom';

function Participants({ contract_by_id_data }) {
    const navigate = useNavigate()

    return (
        <>
            <InfoBlock
                className="infoBlock twoRow"
                style={{ marginBottom: 24 }}
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>ФИО заказчика</p>
                    <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/public/user/${contract_by_id_data.owner.id}`)}>{createFio(contract_by_id_data.owner)}</p>
                    <p className='p12_400 mainText p_mb8'>Компания заказчика</p>
                    <p className='p14_400 mainText'>{contract_by_id_data.owner.company ? contract_by_id_data.owner.company.name : '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>ФИО исполнителя</p>
                    <p className='p14_400 mainText p_mb16 activeText' onClick={() => navigate(`/public/user/${contract_by_id_data.executor.id}`)}>{createFio(contract_by_id_data.executor)}</p>
                    <p className='p12_400 mainText p_mb8'>Компания исполнителя</p>
                    <p className='p14_400 mainText'>{contract_by_id_data.executor.company ? contract_by_id_data.executor.company.name : '-'}</p>
                </div>
            </InfoBlock>
        </>
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.contractById.contract_by_id_data
    }
}

export default connect(mapStateToProps, null)(Participants);