import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { carBreadcrumb } from 'data/brebcrumb';
import { Form } from 'antd';
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { CLEAR_CREATE_TECHNIC_DATA, SAVE_COORDS } from 'store/actionTypes';
import { stepsCreateCar } from 'data/steps';
import MainInfo from 'components/components/forms/technics/createTechnic/MainInfo';
import TechnicModel from 'components/components/forms/technics/createTechnic/TechnicModel';
import AdditionalEquipment from 'components/components/forms/technics/createTechnic/AdditionalEquipment';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import TechnicDescription from 'components/components/forms/technics/createTechnic/TechnicDescription';
import OtherInfo from 'components/components/forms/technics/createTechnic/OtherInfo';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Steps from 'components/ui/steps/Steps';

import pin from 'assets/images/pins/combine.svg'

import './style.css';
import './media.css';

const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 10,
    type: 'yandex#hybrid'
};

function CreateTechnic({ coords, CLEAR_CREATE_TECHNIC_DATA, SAVE_COORDS }) {
    const [activeStep, setActiveStep] = useState(0)
    const [viewMap, setViewMap] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const nextStep = () => {
        if (activeStep < 5) setActiveStep(activeStep + 1)
    }

    const onClickSaveForm = () => {
        if (activeStep !== 5) {
            form.submit()
        } else if (activeStep === 5) {
            navigate(`/user/my-cars`)
            CLEAR_CREATE_TECHNIC_DATA()
        }
    }

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={carBreadcrumb} activeLink={'Создание техники'} />
            <div className='createCarBlock_header'>
                <TextHeading text={'Создание техники'} />
                {activeStep === 0
                    ?
                    <SimpleButton
                        title={'Далее'}
                        style={{
                            background: '#01963A',
                            borderRadius: '100px'
                        }}
                        onClick={onClickSaveForm}
                    />
                    :
                    <div className='userProfile_header__actions'>
                        {activeStep !== 5 &&
                            <SimpleButton
                                title={'Назад'}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500,
                                    marginRight: 16
                                }}
                                onClick={() => setActiveStep(activeStep - 1)}
                            />
                        }
                        <SimpleButton
                            title={activeStep === 5 ? 'Завершить' : (activeStep === 4 ? 'Создать технику' : 'Далее')}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={onClickSaveForm}
                        />
                    </div>
                }
            </div>
            <div className='createCarBlock'>
                <Steps stepsList={stepsCreateCar} activeStep={activeStep} />
                <div style={{ marginTop: 24 }} className='createCarBlock_forms'>
                    {activeStep === 0 && <MainInfo nextStep={nextStep} form={form} />}
                    {activeStep === 1 && <TechnicModel nextStep={nextStep} form={form} />}
                    {activeStep === 2 && <TechnicDescription nextStep={nextStep} form={form} />}
                    {activeStep === 3 && <AdditionalEquipment nextStep={nextStep} form={form} />}
                    {activeStep === 4 &&
                        <>
                            <OtherInfo nextStep={nextStep} form={form} />
                            <div className='locationBlock'>
                                <div className='locationBlock_header'>
                                    <TextHeading text={'Выбор местоположения'} />
                                    <SimpleButton
                                        title={'Выбрать местоположение'}
                                        style={{
                                            color: '#FFF',
                                            border: '1px solid #01963A',
                                            borderRadius: '100px',
                                            backgroundColor: '#01963A',
                                            fontWeight: 500
                                        }}
                                        onClick={() => setViewMap(!viewMap)}
                                    />
                                </div>
                                {viewMap &&
                                    <div className='locationBlock_map'>
                                        <Map
                                            defaultState={defaultState}
                                            width='100%'
                                            height='100%'
                                            onClick={(e) => SAVE_COORDS(e.get('coords'))}
                                        >
                                            <FullscreenControl />
                                            <SearchControl options={{ float: "right" }} />
                                            <GeolocationControl options={{ float: "left" }} />
                                            <TypeSelector options={{ float: 'right' }} />
                                            {coords &&
                                                <Placemark
                                                    geometry={coords}
                                                    options={{
                                                        iconLayout: 'default#image',
                                                        iconImageHref: pin,
                                                        iconImageSize: [70, 70],
                                                        // iconImageOffset: [-5, -38]
                                                    }}
                                                />
                                            }
                                        </Map>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {activeStep === 5 &&
                        <InfoBlock style={{ borderColor: '#01963A' }}>
                            <p className='activeText p_mb16 p12_400' style={{ maxWidth: 600 }}>Для корректного отображения вашей техники на платформе, вам нужно подать заявку в систему СКАУТ,
                                они с вами свяжутся и уточнят все необходимые данные для подключения к нашей системе трекинга.</p>
                            <SimpleButton
                                title={'Оставить заявку на подключение'}
                                style={{
                                    color: '#FFF',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#01963A',
                                    fontWeight: 500
                                }}
                                onClick={() => {
                                    // navigate(`/user/my-cars`)
                                    window.open(process.env.REACT_APP_SCAYT_FORM_LINK, '_blank');
                                    // CLEAR_CREATE_TECHNIC_DATA()
                                }}
                            />
                        </InfoBlock>
                    }
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
        coords: state.createTechic.coords
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        CLEAR_CREATE_TECHNIC_DATA: () => dispatch({ type: CLEAR_CREATE_TECHNIC_DATA }),
        SAVE_COORDS: (coords) => dispatch({ type: SAVE_COORDS, coords })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateTechnic);