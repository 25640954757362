import { api_applications } from 'api/applications'
import {
    FETCH_APPLICATION_BY_ID_DATA_START,
    FETCH_APPLICATION_BY_ID_DATA_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchApplicationById(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_APPLICATION_BY_ID_DATA_START))
        try {
            const application_data = await api_applications.GetApplicationById(id)
            dispatch(fetchSuccess(FETCH_APPLICATION_BY_ID_DATA_SUCCESS, {
                application_by_id_data: application_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchToggleCanceledApplicationById(id) {
    return async dispatch => {
        try {
            await api_applications.PutToggleConceledOfApplications(id)
            dispatch(fetchApplicationById(id))
        } catch (e) {
        }
    }
}