import { api_crop_types } from 'api/cropTypes';
import {
    FETCH_CROP_TYPES_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchCropTypes() {
    return async dispatch => {
        try {
            const crop_types = await api_crop_types.GetCropTypes()
            dispatch(fetchSuccess(FETCH_CROP_TYPES_SUCCESS, {
                crop_types: crop_types.data.data.items
            }))
        } catch (e) {
        }
    }
}