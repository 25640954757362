import { connect } from 'react-redux'
import { userContractsTable } from 'data/adminTableHeader';
import { userContractsTableWidth } from 'data/adminTableWidth';
import { useNavigate } from 'react-router-dom';
import { createFio } from 'hooks/createFio';
import { contractsStatusesStyle } from 'data/lists';

import Table from 'components/ui/table/Table';

function Contracts({user_by_id_data_contracts}) {
    const navigate = useNavigate()

    const createTableData = () => {
        return user_by_id_data_contracts.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/contracts/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/users/${el.executor.id}`)}>{createFio(el.executor)}</p>
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/fields/${el.square_id}`)}>{el.square.number}</p>
                },
                {
                    type: 'active_text',
                    content: <p>{el.technics.length > 0 ? (el.technics[0].technic_type.name + (el.technics.length > 1 ? ` + ${el.technics.length - 1}` : '')) : '-'}</p>
                },
                {
                    type: 'text',
                    content: el.amount + '₽'
                },
                {
                    type: 'text',
                    content: 'с ' + el.valid_from + ' по ' + el.valid_to
                },
                {
                    type: 'actions',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: contractsStatusesStyle[el.status].bg }}>{contractsStatusesStyle[el.status].title}</div>
                },
            ]
        ))
    }

    return (
        <Table
            header={userContractsTable}
            width={userContractsTableWidth}
            data={createTableData()}
        />
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data_contracts: state.userById.user_by_id_data.owner_contracts
    }
}

export default connect(mapStateToProps, null)(Contracts);