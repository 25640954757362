import { useRef } from 'react';
import { connect } from 'react-redux'
import { notification } from 'antd';
import { fetchDeleteMyDocs, fetchUploadDocs } from 'store/actions/user';
import { profileDocsTable } from 'data/tableHeader';
import { profileDocsTableWidth } from 'data/tableWidth';
import { warningNotification } from 'hooks/notifications';
import { handleChangeFiles } from 'hooks/handleChangeFiles';
import { formatDate } from 'hooks/formatDate';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';
import downloading from 'assets/images/icons/downloading.svg';

function Docs({ user_data_docs, fetchUploadDocs, fetchDeleteMyDocs }) {
    const fileInputRef = useRef();
    const [api, contextHolder] = notification.useNotification();

    const createTableData = () => {
        return user_data_docs.map(el => (
            [
                {
                    type: 'text',
                    content: 'Документ'
                },
                {
                    type: 'active_text',
                    content: el.name
                },
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                // {
                //     type: 'text',
                //     content: 'В РАБОТЕ'
                // },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <a href={el.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                fetchDeleteMyDocs(el.id)
                                api.destroy()
                            }, 'удаление документа.')}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <Table
                header={profileDocsTable}
                width={profileDocsTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Добавить документ'}
                style={{
                    marginTop: 20,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
                onClick={() => fileInputRef.current.click()}
            />
            <input onChange={(e) => handleChangeFiles(e, (data) => {
                fetchUploadDocs(data)
                fileInputRef.current.value = null
            })} multiple={false} ref={fileInputRef} type='file' accept='.png, .jpg, .doc, .docx, .pdf, .word' hidden />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_data_docs: state.user.user_data.documents
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUploadDocs: (data) => dispatch(fetchUploadDocs(data)),
        fetchDeleteMyDocs: (doc_id) => dispatch(fetchDeleteMyDocs(doc_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Docs);