import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import './style.css';
import './media.css';

function NoDataBlock({ img, title, description, buttonTitle, onClick, ...props }) {
    return (
        <div className='NoDataBlock' {...props}>
            <img src={img} alt="icon" />
            <p className='mainText p24_600 p_mb24'>{title}</p>
            <p className='mainText p14_400 p_mb24'>{description}</p>
            {buttonTitle.map((item, index) => (
                <SimpleButton
                    key={`noData_${index}`}
                    title={item}
                    onClick={onClick[index]}
                    style={{
                        background: '#01963A',
                        borderRadius: '100px',
                        width: '100%',
                        padding: '11px',
                        marginTop: 6
                    }}
                />
            ))}
        </div>
    );
}

export default NoDataBlock;