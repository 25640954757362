import EnterCodeForm from "components/components/forms/authentication/passwordRecovery/enterCode/EnterCodeForm";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";

import '../style.css';

function EnterCode() {
    return (
        <AuthenticationBlock>
            <TextHeading text={'Введите код из письма'} style={{ textAlign: 'center' }} />
            <div style={{ marginTop: 24 }}>
                <EnterCodeForm
                    buttonTtile={'Ввести новый пароль'}
                    type={'recovery'}
                />
            </div>
            <div className="autehenticationLinksBlock">
                <p className="activeText p2" style={{ cursor: 'pointer' }}>Отправить код повторно</p>
            </div>
        </AuthenticationBlock>
    );
}

export default EnterCode;