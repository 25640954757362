import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { fetchReactiveUser } from 'store/actions/user';
import { signupSwitch } from "data/lists";
import EnterCodeForm from "components/components/forms/authentication/passwordRecovery/enterCode/EnterCodeForm";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Switch from "components/shared/switch/Switch";

import '../../style.css';

function EnterCodeSignUp({ fetchReactiveUser }) {
    const [timer, setTimer] = useState(0)
    const [activeSwitch, setActiveSwitch] = useState('email')
    const [viewTimer, setViewTimer] = useState(false)
    const [reactiveData, setReactiveData] = useState(null)

    useEffect(() => {
        const timerConst =
            timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => clearInterval(timerConst);
    }, [timer])


    const sendCode = () => {
        setTimer(60)
        fetchReactiveUser(reactiveData)
    }

    const onViewTimer = (data) => {
        setViewTimer(true)
        setReactiveData(data)
        setTimer(60)
    }

    return (
        <AuthenticationBlock>
            <TextHeading text={`Подтвердите данные`} style={{ textAlign: 'center' }} />
            <Switch
                items={signupSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
            />
            <EnterCodeForm
                buttonTtile={'Подтвердить учетную запись'}
                type={'sigup'}
                activeMode={activeSwitch}
                onViewTimer={(data) => onViewTimer(data)}
            />
            {viewTimer && (timer === 0
                ? <p
                    className="sendCodeAgain activeText"
                    style={{ cursor: 'pointer' }}
                    onClick={sendCode}
                >Отправить код еще раз</p>
                : <p
                    className="sendCodeAgain placeholder"
                    style={{ cursor: 'not-allowed' }}
                >Отправить код еще раз (Через {timer})</p>
            )}
        </AuthenticationBlock>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchReactiveUser: (data) => dispatch(fetchReactiveUser(data))
    }
}

export default connect(null, mapDispatvhToProps)(EnterCodeSignUp);