import React from 'react'
import { useNavigate } from 'react-router-dom';
import { findOrderData } from 'data/mainPage';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import yellowLine from 'assets/images/landing/yellowLine.svg';
import ear from 'assets/images/landing/icons/ear.svg';

import './style.css';
import './media.css';

const FindOrder = () => {
    const navigate = useNavigate()

    return (
        <div className='findOrderBlock'>
            {findOrderData.map((item, key) => (
                <div
                    className='findOrderBlock_item'
                    key={`findOrderBlock_item__${key}`}
                    style={{
                        flexDirection: item.flexDirection
                    }}
                >
                    <div className='findOrderBlock_item__content'>
                        <p className='p36_700 p_mb24'>{item.title}</p>
                        <p className='p18_500 descriptionBlock_subText'>{item.desc}</p>
                        <div className='findOrderBlock_item__steps'>
                            <img src={yellowLine} alt="yellowLine" className='findOrderBlock_item__steps__yellowLine'/>
                            <div className='findOrderBlock_item__steps__item'>
                                {item.steps.map((step, i) => (
                                    <div
                                        key={`step_${i}`}
                                        className='findOrderBlock_item__steps__item_content'
                                    >
                                        <img src={ear} alt="ear" />
                                        <p className='p14_400'>{step}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SimpleButton
                            title={item.buttonTitle}
                            className='greenButton findOrderButton'
                            style={{marginTop: 40}}
                            onClick={() => navigate(item.buttonLink)}
                        />
                    </div>
                    <img src={item.mainImg} alt="img" className='findOrderBlock_item__img' />
                </div>
            ))}
        </div>
    )
}

export default FindOrder