import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchAllTechnics } from 'store/actions/admin/technics';
import { fetchDeleteTechnic } from 'store/actions/techics';
import { allTechnicsTable } from 'data/adminTableHeader';
import { allTechnicsTableWidth } from 'data/adminTableWidth';
import { allCarsFilter } from 'data/adminLists';
import { verificationStatusesStyle } from 'data/lists';
import { createFio } from 'hooks/createFio';
import { createQuery } from 'hooks/createQuery';
import { warningNotification } from 'hooks/notifications';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';
import Filter from 'components/shared/filter/Filter';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import deleteIcon from 'assets/images/icons/delete.svg';
import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';

function AllTechnicsAdmin({ fetchAllTechnics, technic_types, work_types, allTechnics, pages, fetchDeleteTechnic }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllTechnics(query.page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [Object.fromEntries([...searchParams]).page,
    Object.fromEntries([...searchParams]).search,
    Object.fromEntries([...searchParams]).order_by,
    Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return allTechnics.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/cars/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: createFio(el.owner)
                },
                {
                    type: 'text',
                    content: el.owner.company ? el.owner.company.name : '-'
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                // {
                //     type: 'text',
                //     content: 'В РАБОТЕ'
                // },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img src={edit_green_pen} alt="edit_green_pen" onClick={() => navigate(`/admin/cars/${el.id}`, { state: { edit: true } })} />
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchDeleteTechnic(el.id)
                                await fetchAllTechnics(Object.fromEntries([...searchParams]).page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
                                api.destroy()
                            }, 'удаление техники.')} />
                    </div>
                },
            ]
        ))
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                    <Filter
                        className={'filter_marginRight'}
                        filterList={allCarsFilter(technic_types, work_types)}
                    />
                    <SimpleButton
                        title={'Создать технику'}
                        className="greenButton"
                        onClick={() => navigate(`/admin/cars/create`)}
                    />
                </div>
            </div>
            {contextHolder}
            <Table
                header={allTechnicsTable}
                width={allTechnicsTableWidth}
                data={createTableData()}
            />
            {allTechnics.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        allTechnics: state.allTechnics.technics,
        pages: state.allTechnics.pages,
        technic_types: state.technicTypes.technic_types,
        work_types: state.workTypes.work_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllTechnics: (page, size, params) => dispatch(fetchAllTechnics(page, size, params)),
        fetchDeleteTechnic: (id) => dispatch(fetchDeleteTechnic(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllTechnicsAdmin);