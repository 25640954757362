import { useSearchParams } from 'react-router-dom';

import angel_left_grey from 'assets/images/icons/angle-left-grey.svg';
import angel_right_grey from 'assets/images/icons/angle-right-grey.svg';

import './style.css';

function Pagination({ pages }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const lastPage = () => {
        const query = Object.fromEntries([...searchParams]);
        if (parseInt(query.page) > 1) {
            query.page = parseInt(query.page) - 1
            setSearchParams(query)
        }
    }

    const nextPage = () => {
        const query = Object.fromEntries([...searchParams]);
        if (pages > parseInt(query.page)) {
            query.page = parseInt(query.page) + 1
            setSearchParams(query)
        }
    }

    const onClickPaginationItem = (page) => {
        const query = Object.fromEntries([...searchParams]);
        query.page = page
        setSearchParams(query)
    }

    const createPaginationCounter = () => {
        const array = []
        const query = Object.fromEntries([...searchParams]);
        if (parseInt(query.page) <= 4) {
            for (let i = 0; i < (pages <= 5 ? pages : 5); i++) {
                array.push(
                    <div
                        className={`mainText p14_400 paginationItem ${parseInt(query.page) === i + 1 && 'active'}`}
                        onClick={() => onClickPaginationItem(i + 1)}
                        key={`paginationItem_${i+1}`}
                    >
                        {i + 1}
                    </div>
                )
            }
            if (pages > 5) {
                if (pages === 6) {
                    array.push(
                        <div
                            className={`mainText p14_400 paginationItem ${parseInt(query.page) === 6 && 'active'}`}
                            onClick={() => onClickPaginationItem(6)}
                            key={`paginationItem_${6}`}
                        >
                            6
                        </div>
                    )
                } else {
                    array.push(
                        <div
                            className={`mainText p14_400 paginationItem`}
                            key={`paginationItem_dots`}
                        >
                            ...
                        </div>
                    )
                    array.push(
                        <div
                            className={`mainText p14_400 paginationItem ${parseInt(query.page) === pages && 'active'}`}
                            onClick={() => onClickPaginationItem(pages)}
                            key={`paginationItem_pages`}
                        >
                            {pages}
                        </div>
                    )
                }
            }
        } else if ((pages - parseInt(query.page)) > 2) {
            array.push(
                <div
                    className={`mainText p14_400 paginationItem ${parseInt(query.page) === 1 && 'active'}`}
                    onClick={() => onClickPaginationItem(1)}
                    key={`paginationItem_1`}
                >
                    1
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem`}
                    key={`paginationItem_dots`}
                >
                    ...
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem`}
                    onClick={() => onClickPaginationItem(parseInt(query.page) - 1)}
                    key={`paginationItem_${parseInt(query.page) - 1}`}
                >
                    {parseInt(query.page) - 1}
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem active`}
                    onClick={() => onClickPaginationItem(parseInt(query.page))}
                    key={`paginationItem_${parseInt(query.page)}`}
                >
                    {parseInt(query.page)}
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem`}
                    onClick={() => onClickPaginationItem(parseInt(query.page) + 1)}
                    key={`paginationItem_${parseInt(query.page) + 1}`}
                >
                    {parseInt(query.page) + 1}
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem`}
                    key={`paginationItem_dots`}
                >
                    ...
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem ${parseInt(query.page) === pages && 'active'}`}
                    onClick={() => onClickPaginationItem(pages)}
                    key={`paginationItem_${pages}`}
                >
                    {pages}
                </div>
            )
        } else {
            array.push(
                <div
                    className={`mainText p14_400 paginationItem ${parseInt(query.page) === 1 && 'active'}`}
                    onClick={() => onClickPaginationItem(1)}
                    key={`paginationItem_1`}
                >
                    1
                </div>
            )
            array.push(
                <div
                    className={`mainText p14_400 paginationItem`}
                    key={`paginationItem_dots`}
                >
                    ...
                </div>
            )
            for (let i = pages - 3; i < pages; i++) {
                array.push(
                    <div
                        className={`mainText p14_400 paginationItem ${parseInt(query.page) === i + 1 && 'active'}`}
                        onClick={() => onClickPaginationItem(i + 1)}
                        key={`paginationItem_${i + 1}`}
                    >
                        {i + 1}
                    </div>
                )
            }
        }
        return array
    }

    return (
        <div className='pagination'>
            <img src={angel_left_grey} alt='angel_left_grey' onClick={lastPage} style={{ marginRight: 5 }} />
            {createPaginationCounter()}
            <img src={angel_right_grey} alt='angel_right_grey' onClick={nextPage} style={{ marginLeft: 5 }} />
        </div>
    );
}

export default Pagination;