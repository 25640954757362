import { ApiUrl, instance } from '../apiEnv';

export const api_squares = {

    async GetAllSquares(page, size, params) {
        return await instance.get(`${ApiUrl}squares?page=${page}&size=${size}&${params}`)
    },

    async GetSquareById(id) {
        return await instance.get(`${ApiUrl}squares/${id}/admin`)
    },

    async DeleteSquareById(id) {
        return await instance.delete(`${ApiUrl}squares/${id}`)
    },

    async UpdateSquare(data, id) {
        return await instance.put(`${ApiUrl}squares/${id}`, data)
    },

    async UploadSquareImageById(id, data) {
        return await instance.put(`${ApiUrl}squares/${id}/upload_image`, data)
    },

    async DeleteSquareImageById(id, img_id) {
        return await instance.delete(`${ApiUrl}squares/${id}/image/${img_id}`)
    },
    
    async UploadSquareDocsById(id, data) {
        return await instance.put(`${ApiUrl}squares/${id}/document`, data)
    },

    async DeleteSquareDocumentById(id, doc_id) {
        return await instance.delete(`${ApiUrl}squares/${id}/document/${doc_id}`)
    },

    async ConfirmSquareVerificstion(square_id, verification_id) {
        return await instance.put(`${ApiUrl}squares/${square_id}/confirm/${verification_id}`)
    },

    async RejectSquareVerificstion(square_id, verification_id) {
        return await instance.put(`${ApiUrl}squares/${square_id}/reject/${verification_id}`)
    },
}