import {
    FETCH_CHAT_ROOM_BY_ID_DATA_START,
    FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS,
    CLEAR_CHAT_ROOM_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    chat_room_by_id: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHAT_ROOM_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                chat_room_by_id: action.data.chat_room_by_id,
                loading: false
            }
        case CLEAR_CHAT_ROOM_BY_ID_DATA:
            return {
                chat_room_by_id: null,
                loading: false
            }
        default: return state
    }
}