import { ApiUrl, instance } from './apiEnv';

export const api_deals = {

    async GetMyDeals(page, size, params) {
        return await instance.get(`${ApiUrl}deals/my?page=${page}&size=${size}&${params}`)
    },

    async GetDealsById(id) {
        return await instance.get(`${ApiUrl}deals/${id}`)
    },

    async PostCreateDeals(data) {
        return await instance.post(`${ApiUrl}deals`, data)
    },

    async PutUpdateDeal(deal_id, data) {
        return await instance.put(`${ApiUrl}deals/${deal_id}`, data)
    },

    async PutAddTechnicsToDeals(deal_id, data) {
        return await instance.put(`${ApiUrl}deals/${deal_id}/add_technics`, data)
    },

    async PutAddPlotsToDeals(deal_id, data) {
        return await instance.put(`${ApiUrl}deals/${deal_id}/add_plots`, data)
    },

    async PostMakeContract(deal_id, data) {
        return await instance.post(`${ApiUrl}deals/make_contract?deal_id=${deal_id}`, data)
    },

}