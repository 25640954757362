import { ApiUrl, instance } from './apiEnv';

export const api_notifications = {

    async GetMyNotifications(page, size, params) {
        return await instance.get(`${ApiUrl}notifications/my?page=${page}&size=${size}&${params}`)
    },

    async GetNotificationById(id) {
        return await instance.get(`${ApiUrl}notifications/${id}`)
    },

    async PostNotificationSetReaded(data) {
        return await instance.post(`${ApiUrl}notifications/set_readed`, data)
    },
}