import { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { createFieldBreadcrumb } from 'data/brebcrumb';
import { Form } from 'antd';
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { CLEAR_CREATE_SQUARE_DATA, SAVE_SQUARE_COORDS } from 'store/actionTypes';
import { fetchCreateAddress } from 'store/actions/address';
import { fetchCreateSquare, fetchUploadSquareImage } from 'store/actions/square';
import { fetchMyData } from 'store/actions/user';
import { stepsQuickCreateField } from 'data/steps';
import { errorNotification } from 'hooks/notifications';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Steps from 'components/ui/steps/Steps';
import MainInfo from 'components/components/forms/field/createField/quickCreatingField/MainInfo';

import pin from 'assets/images/pins/field.svg'

const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 10,
    type: 'yandex#hybrid'
};

function QuickCreatingField({ coords, create_square_quick, main_info_files_photo, main_info_files_isOwner, SAVE_COORDS, CLEAR_CREATE_SQUARE_DATA, fetchCreateAddress, fetchCreateSquare, fetchUploadSquareImage, fetchMyData }) {
    const [activeStep, setActiveStep] = useState(0)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const nextStep = () => {
        if (activeStep < 1) setActiveStep(activeStep + 1)
    }

    const onClickSaveForm = async () => {
        if (activeStep !== 1) {
            form.submit()
        } else if (activeStep === 1) {
            const allFiles = [...main_info_files_photo, ...main_info_files_isOwner]
            let obj = { ...create_square_quick }
            if (coords) {
                const data = {
                    latitude: coords[0],
                    longitude: coords[1]
                }
                await fetchCreateAddress(data)
                    .then(res => {
                        obj.address_id = res
                    })
                await fetchCreateSquare(obj)
                    .then(res => {
                        allFiles.forEach(el => {
                            let data = new FormData();
                            let filedata = el.originFileObj
                            delete filedata.uid
                            data.append('image_file', filedata);
                            data.append('title', el.name);
                            fetchUploadSquareImage(res, data)
                        })
                    })
                fetchMyData()
                navigate(`/user/my-fields`)
                CLEAR_CREATE_SQUARE_DATA()
            } else {
                errorNotification('Нет местопожения', 'Укажите местопожение')
            }
        }
    }

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={createFieldBreadcrumb} activeLink={'Создать поле'} />
            <div className='createCarBlock_header'>
                <TextHeading text={'Создание поля'} />
                {activeStep === 0
                    ?
                    <SimpleButton
                        title={'Далее'}
                        style={{
                            background: '#01963A',
                            borderRadius: '100px'
                        }}
                        onClick={onClickSaveForm}
                    />
                    :
                    <div className='userProfile_header__actions'>
                        <SimpleButton
                            title={'Назад'}
                            style={{
                                color: '#01963A',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500,
                                marginRight: 16
                            }}
                            onClick={() => setActiveStep(activeStep - 1)}
                        />
                        <SimpleButton
                            title={activeStep !== 1 ? 'Далее' : 'Завершить'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={onClickSaveForm}
                        />
                    </div>
                }
            </div>
            <div className='createCarBlock'>
                <Steps stepsList={stepsQuickCreateField} activeStep={activeStep} />
                <div style={{ marginTop: 24 }} className='createCarBlock_forms'>
                    {activeStep === 0 && <MainInfo nextStep={nextStep} form={form} />}
                    {activeStep === 1 &&
                        <div className='locationBlock_map'>
                            <Map
                                defaultState={defaultState}
                                width='100%'
                                height='100%'
                                onClick={(e) => SAVE_COORDS(e.get('coords'))}
                            >
                                <FullscreenControl />
                                <SearchControl options={{ float: "right" }} />
                                <GeolocationControl options={{ float: "left" }} />
                                <TypeSelector options={{ float: 'right' }} />
                                {coords &&
                                    <Placemark
                                        geometry={coords}
                                        options={{
                                            iconLayout: 'default#image',
                                            iconImageHref: pin,
                                            iconImageSize: [70, 70],
                                            // iconImageOffset: [-5, -38]
                                        }}
                                    />
                                }
                            </Map>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        create_square_quick: state.createSquare.create_square_quick,
        coords: state.createSquare.coords,
        main_info_files_photo: state.createSquare.main_info_files_photo,
        main_info_files_isOwner: state.createSquare.main_info_files_isOwner,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_COORDS: (coords) => dispatch({ type: SAVE_SQUARE_COORDS, coords }),
        CLEAR_CREATE_SQUARE_DATA: () => dispatch({ type: CLEAR_CREATE_SQUARE_DATA }),
        fetchCreateAddress: (data) => dispatch(fetchCreateAddress(data)),
        fetchCreateSquare: (data) => dispatch(fetchCreateSquare(data)),
        fetchUploadSquareImage: (id, data) => dispatch(fetchUploadSquareImage(id, data)),
        fetchMyData: () => dispatch(fetchMyData()),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(QuickCreatingField);