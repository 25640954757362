import { ApiUrl, instance } from '../apiEnv';

export const api_busyPeriod = {

    async PostCreateBusyPeriod(data) {
        return await instance.post(`${ApiUrl}busy_period`, data)
    },

    async UpdateBusyPeriod(busyPeriod_id, data) {
        return await instance.put(`${ApiUrl}busy_period/${busyPeriod_id}`, data)
    },

    async DeleteBusyPeriod(busyPeriod_id) {
        return await instance.delete(`${ApiUrl}busy_period/${busyPeriod_id}`)
    },
}