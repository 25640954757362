import { connect } from 'react-redux'
import ConfirmProfileChangesForm from 'components/components/forms/modal/confirmProfileChanges/ConfirmProfileChanges';

function ConfirmProfileChanges({ data }) {
    return (
        <div style={{ width: 380 }}>
            <p className='mainText p24_600 p_mb24'>Введите код подтверждения</p>
            <p className='mainText p12_400 p_mb24'>
                {data.type === 'email'
                    ? `На старый Email адрес (${data.info}) был отправлен код для подтверждения сохранения`
                    : `На старый номер телефона (+${data.info}) был отправлен код для подтверждения сохранения`}
            </p>
            <ConfirmProfileChangesForm />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

export default connect(mapStateToProps, null)(ConfirmProfileChanges);