import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Dropdown, notification } from "antd";
import { fetchDeleteSquare, fetchMySquares } from 'store/actions/square';
import { allFieldsTable } from 'data/tableHeader';
import { allFieldsTableWidth } from 'data/tableWidth';
import { allSquareFilter } from 'data/adminLists';
import { warningNotification } from 'hooks/notifications';
import { createQuery } from 'hooks/createQuery';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import NoDataBlock from 'components/ui/noDataBlock/NoDataBlock';
import Table from 'components/ui/table/Table';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Filter from 'components/shared/filter/Filter';
import Pagination from 'components/shared/pagination/Pagination';

import field from 'assets/images/messageIcons/field.svg';
import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';
import deleteIcon from 'assets/images/icons/delete.svg';

import './style.css';

function AllFields({ user_data_squares, pages, square_types, crop_types, sowing_types, fetchMySquares, fetchDeleteSquare }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMySquares(query.page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const items = [
        {
            label: <p className='activeText' onClick={() => navigate(`/user/my-fields/full-create`)}>Полностью</p>,
            key: '0',
        },
        {
            label: <p className='activeText' onClick={() => navigate(`/user/my-fields/quick-create`)}>По-быстрому</p>,
            key: '1',
        }
    ];

    const createTableData = () => {
        return user_data_squares.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-fields/${el.id}`)}>{el.number}</p>
                },
                // {
                //     type: 'text',
                //     content: 'В РАБОТЕ'
                // },
                {
                    type: 'text',
                    content: el.square_type.name
                },
                {
                    type: 'text',
                    content: el.size
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img src={edit_green_pen} alt="edit_green_pen" onClick={() => navigate(`/user/my-fields/${el.id}`, { state: { edit: true } })} />
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchDeleteSquare(el.id)
                                fetchMySquares(Object.fromEntries([...searchParams]).page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
                                api.destroy()
                            }, 'удаление поля.')} />
                    </div>
                },
            ]
        ))
    }

    return (
        (user_data_squares) && (
            (createTableData().length !== 0 || createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order) !== '')
                ?
                <div className='allUsersBlock'>
                    <div className='allUsersBlock_header'>
                        <div className='allUsersBlock_header__leftHalf'>
                            <TextHeading text={'Мои поля'} />
                        </div>
                        <div className='allUsersBlock_header__rightHalf'>
                            <Filter
                                className={'filter_marginRight'}
                                filterList={allSquareFilter(square_types, crop_types, sowing_types)}
                            />
                            <Dropdown menu={{ items }} trigger={['click']}>
                                <SimpleButton
                                    title={'Создать новое поле'}
                                    className="greenButton"
                                />
                            </Dropdown>
                        </div>
                    </div>
                    {contextHolder}
                    <Table
                        header={allFieldsTable}
                        width={allFieldsTableWidth}
                        data={createTableData()}
                    />
                    {user_data_squares.length > 0 && <Pagination pages={pages} />}
                </div>
                :
                <NoDataBlock
                    img={field}
                    title={'Добавить новое поле'}
                    description={'Чтобы добавить новое поле  - нажмите на одну из кнопок ниже.'}
                    buttonTitle={['Добавить поле полностью', 'Добавить поле по-быстрому']}
                    onClick={[() => navigate(`/user/my-fields/full-create`), () => navigate(`/user/my-fields/quick-create`)]}
                // buttonTitle={['Добавить поле по-быстрому']}
                // onClick={[() => navigate(`/user/my-fields/quick-create`)]}
                />
        )
    );
}

function mapStateToProps(state) {
    return {
        user_data_squares: state.userSquares.squares,
        pages: state.userSquares.pages,
        square_types: state.squareTypes.square_types,
        crop_types: state.cropTypes.crop_types,
        sowing_types: state.sowingTypes.sowing_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMySquares: (page, size, params) => dispatch(fetchMySquares(page, size, params)),
        fetchDeleteSquare: (id) => dispatch(fetchDeleteSquare(id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllFields);