export const allUsersTableWidth = [
    160,
    160,
    170,
    200,
    160,
    110,
    170,
    null
]

export const allTechnicsTableWidth = [
    160,
    160,
    170,
    200,
    160,
    110,
    170,
    // 170,
    null
]

export const allFieldsTableWidth = [
    140,
    180,
    160,
    // 170,
    140,
    160,
    140,
    100,
    null
]

export const userCarsTableWidth = [
    160,
    160,
    170,
    200,
    160,
    110,
    null
]

export const userContractsTableWidth = [
    200,
    200,
    170,
    100,
    100,
    100,
    220,
    100,
    null
]

export const userFieldsTableWidth = [
    160,
    160,
    160,
    160,
    160,
    160,
    100,
    null
]

export const userVerificationTableWidth = [
    200,
    200,
    250
]

export const userVerificationModalTableWidth = [
    200,
    200,
    200,
    250
]

export const allDealsTableWidth = [
    140,
    180,
    160,
    170,
    140,
    160,
    140,
    210,
    120
]

export const dealTechnicsTableWidth = [
    160,
    160,
    170,
    200,
    160,
    110,
]

export const dealSquaresTableWidth = [
    140,
    180,
    160,
    170,
    140,
    160,
    140,
    100,
]

export const dealConflictsTableWidth = [
    160,
    160,
    170,
    200,
    160,
    null,
]

export const dealReviewsTableWidth = [
    200,
    710,
    null,
]

export const allConflictsTableWidth = [
    180,
    180,
    160,
    160,
    350,
    null
]

export const allDeletionRequestsTableWidth = [
    300,
    180,
    350,
]

export const allComplainstTableWidth = [
    160,
    180,
    180,
    180,
    170,
    250,
    null
]

export const contractCarsTableWidth = [
    160,
    160,
    170,
    200,
    160,
    110
]

export const contractFieldsTableWidth = [
    160,
    160,
    160,
    160,
    160,
    160,
    160,
    100,
    100
]

export const contractConflictsTableWidth = [
    160,
    160,
    160,
    160,
    160,
    null,
]

export const contractReviewsTableWidth = [
    250,
    160,
    250,
    null
]

export const publicUserReviewsTableWidth = [
    250,
    160,
    null
]