import DeleteProfileForm from "components/components/forms/modal/deleteProfile/DeleteProfile";

function DeleteProfile() {
    return (
        <>
            <p className="errorColor p14_500">Подтвердите удаление профиля</p>
            <DeleteProfileForm 
                style={{marginTop: 16}}
            />
        </>
    );
}

export default DeleteProfile;