import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import './style.css';
import './media.css';

function CustomCalendar({ date, onChange, ...props }) {

    return (
        <Calendar
            onChange={onChange}
            locale={locales['ru']}
            date={date}
            color={'#01963A'}
            {...props}
        />
    );
}

export default CustomCalendar;