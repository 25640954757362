import CollapseItem from './CollapseItem';

import './style.css';

function Collapse({ list }) {
    return (
        <div>
            {list.map((el, key) => (
                <CollapseItem key={`collapseItem_${key}`} item={el} />
            ))}
        </div>
    );
}

export default Collapse;