export const allUsersSwitch = [
    {
        item: 'allUsers',
        title: 'Все пользователи'
    },
    {
        item: 'admins',
        title: 'Администраторы'
    }
]

export const allUsersFilter = [
    {
        title: 'Статус',
        param: 'status',
        childrens: [
            {
                title: 'Верифицирован',
                param: 'VERIFIED'
            },
            {
                title: 'На проверке',
                param: 'ON_CONSIDER'
            },
            {
                title: 'Отклонено',
                param: 'BLOCKED'
            },
            {
                title: 'Не актуально',
                param: 'NOT_RELEVANT'
            },
        ]
    },
    {
        title: 'Тип лица',
        param: 'jur_type',
        childrens: [
            {
                title: 'Юридическое',
                param: 'JURIDICAL'
            },
            {
                title: 'Физическое',
                param: 'PHYSICAL'
            }
        ]
    },
]

export const allCarsFilter = (technic_types, work_types) => {
    return [
        {
            title: 'Статус верификации',
            param: 'status',
            childrens: [
                {
                    title: 'Верифицирован',
                    param: 'VERIFIED'
                },
                {
                    title: 'На проверке',
                    param: 'ON_CONSIDER'
                },
                {
                    title: 'Отклонено',
                    param: 'BLOCKED'
                },
                {
                    title: 'Не актуально',
                    param: 'NOT_RELEVANT'
                },
            ]
        },
        {
            title: 'Тип техники',
            param: 'technic_type_id',
            childrens: technic_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        {
            title: 'Тип работ',
            param: 'work_type_id',
            childrens: work_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        }
    ]
}

export const allSquareFilter = (square_types, crop_types, sowing_types) => {
    return [
        // {
        //     title: 'Статус',
        //     param: '',
        //     childrens: [
        //         {
        //             title: 'Занята',
        //             param: ''
        //         },
        //         {
        //             title: 'Свободна',
        //             param: ''
        //         },
        //     ]
        // },
        {
            title: 'Тип поля',
            param: 'square_type_id',
            childrens: square_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        {
            title: 'Культура',
            param: 'crop_type_id',
            childrens: crop_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        },
        {
            title: 'Фактический посев',
            param: 'sowing_type_id',
            childrens: sowing_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        }
    ]
}

export const allDealsFilter = (square_types, crop_types, sowing_types) => {
    return [
        {
            title: 'Статус',
            param: 'status',
            childrens: [
                {
                    title: 'Активна',
                    param: 'open'
                },
                {
                    title: 'Завершена',
                    param: 'close'
                },
            ]
        }
    ]
}

export const allContractsFilter = (work_types) => {
    return [
        {
            title: 'Статус',
            param: 'status',
            childrens: [
                {
                    title: 'Успешный',
                    param: 'COMPLITED'
                },
                {
                    title: 'Расторгнутый',
                    param: 'TERMINATED'
                },
                {
                    title: 'Незаключённый',
                    param: 'UNCONCLUDED'
                },
                {
                    title: 'В споре',
                    param: 'IN_DICPUTE'
                },
            ]
        },
        {
            title: 'Тип работ',
            param: 'work_type_id',
            childrens: work_types.map(el => ({
                title: el.name,
                param: el.id
            }))
        }
    ]
}

export const allComplainstFilter = [
    {
        title: 'Статус',
        param: 'status',
        childrens: [
            {
                title: 'Верифицирован',
                param: 'VERIFIED'
            },
            {
                title: 'На проверке',
                param: 'ON_CONSIDER'
            },
            {
                title: 'Отклонено',
                param: 'BLOCKED'
            },
        ]
    },
    {
        title: 'Тип лица',
        param: 'jur_type',
        childrens: [
            {
                title: 'Юридическое',
                param: 'JURIDICAL'
            },
            {
                title: 'Физическое',
                param: 'PHYSICAL'
            }
        ]
    },
]

export const allApplicationsFilter = (square_types, crop_types, sowing_types) => {
    return [
        // {
        //     title: 'Статус',
        //     param: '',
        //     childrens: [
        //         {
        //             title: 'Занята',
        //             param: ''
        //         },
        //         {
        //             title: 'Свободна',
        //             param: ''
        //         },
        //     ]
        // },
        {
            title: 'Тип заявки',
            param: 'bid_type',
            childrens: [
                {
                    title: 'На технику',
                    param: 'TECHNICIAN'
                },
                {
                    title: 'На поле',
                    param: 'FARMER'
                }
            ]
        },
        {
            title: 'Статус',
            param: 'is_canceled',
            childrens: [
                {
                    title: 'Завершена',
                    param: true
                },
                {
                    title: 'Активна',
                    param: false
                }
            ]
        },
    ]
}

/*list items in switch profile */
export const profileSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'company',
        title: 'Компания'
    },
    {
        item: 'cars',
        title: 'Техника'
    },
    {
        item: 'contracts',
        title: 'Контракты'
    },
    {
        item: 'fields',
        title: 'Поля'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'verification',
        title: 'Проверка менеджером'
    },
]

export const verificationStatuses = [
    {
        title: 'Активный',
        bg: '#01963A'
    },
    {
        title: 'Заблокированный',
        bg: '#D32F2F'
    }
]

export const carsSwitch = [
    {
        item: 'public',
        title: 'Опубликованная'
    },
    {
        item: 'consideration',
        title: 'На рассмотрении'
    }
]

export const carByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'contracts',
        title: 'Контракты'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'current_location',
        title: 'Отслеживание'
    },
    {
        item: 'verification',
        title: 'Проверка менеджером'
    }
]

export const fieldByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'plotsAndSeasons',
        title: 'Участки'
    },
    {
        item: 'contracts',
        title: 'Контракты'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'verification',
        title: 'Проверка менеджером'
    }
]

export const dealByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'participants',
        title: 'Участники'
    },
    {
        item: 'technics',
        title: 'Техника'
    },
    {
        item: 'squares',
        title: 'Поля'
    },
    {
        item: 'conflicts',
        title: 'Споры'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
    {
        item: 'reviews',
        title: 'Отзывы'
    }
]

export const conflictByIdSwitch = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'docs',
        title: 'Документы'
    },
]

export const allOffersSwitch = [
    {
        item: false,
        title: 'Новые'
    },
    {
        item: true,
        title: 'Просмотренные'
    }
]

export const allContractsSwitch = [
    {
        item: false,
        title: 'Действующие'
    },
    {
        item: true,
        title: 'Архивные'
    }
]

export const rolesNames = {
    admin: 'Администратор',
    manager: 'Менеджер',
    user: 'Пользователь'
}