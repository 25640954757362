import { useEffect } from "react";
import { connect } from 'react-redux'
import { Outlet } from "react-router-dom";
import { fetchMyData } from "store/actions/user";
import { fetchWorkTypes } from "store/actions/workTypes";
import { fetchTechnicTypes } from "store/actions/technicTypes";
import { fetchSquareTypes } from "store/actions/squareTypes";
import { fetchCropTypes } from "store/actions/cropTypes";
import { fetchSowingTypes } from "store/actions/sowingTypes";
import { fetchGetRoles } from "store/actions/roles";
import { menuListAdmin, menuSubList } from "data/routings";
import Header from "./header/Header";
import DrawerMenu from "../../shared/drawerMenu/DrawerManu";

import './style.css';

function AdminLayout({
    fetchMyData,
    fetchWorkTypes,
    fetchTechnicTypes,
    access_token,
    square_types_loading,
    crop_types_loading,
    sowing_types_loading,
    work_types_loading,
    technic_types_loading,
    fetchSquareTypes,
    fetchCropTypes,
    fetchSowingTypes,
    fetchGetRoles
}) {

    useEffect(() => {
        fetchMyData()
        fetchWorkTypes()
        fetchTechnicTypes()
        fetchSquareTypes()
        fetchCropTypes()
        fetchSowingTypes()
        fetchGetRoles()
    }, [access_token])

    return (
        (!square_types_loading && !crop_types_loading && !sowing_types_loading && !work_types_loading && !technic_types_loading) &&
        <div className="userLayout">
            <Header />
            <div className="userLayout_contentBlock">
                <DrawerMenu menuList={menuListAdmin} menuSub={menuSubList} />
                <div className="outletLayout">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
        access_token: state.userEnv.access_token,
        square_types_loading: state.squareTypes.loading,
        crop_types_loading: state.cropTypes.loading,
        sowing_types_loading: state.sowingTypes.loading,
        work_types_loading: state.workTypes.loading,
        technic_types_loading: state.technicTypes.loading,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyData: () => dispatch(fetchMyData()),
        fetchWorkTypes: () => dispatch(fetchWorkTypes()),
        fetchTechnicTypes: () => dispatch(fetchTechnicTypes()),
        fetchSquareTypes: () => dispatch(fetchSquareTypes()),
        fetchCropTypes: () => dispatch(fetchCropTypes()),
        fetchSowingTypes: () => dispatch(fetchSowingTypes()),
        fetchGetRoles: () => dispatch(fetchGetRoles())
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AdminLayout);