import './style.css';
import './media.css';

function Steps({ activeStep, stepsList }) {
    return (
        <div className='steps'>
            {stepsList.map((el, key) => (
                <div className='steps_item' key={`step_${key}`}>
                    <div className='steps_item__mainCircule'>
                        <div className='steps_item__secondCircule'>
                            <div className={`steps_item__lastCircule ${activeStep === key ? 'whiteButtonText activeStep' : 'placeholder'}`}>
                                <p className='p14_500'>{key + 1}</p>
                            </div>
                        </div>
                    </div>
                    <p className={`steps_item__title p12_400 ${activeStep === key ? 'activeText' : ''}`}>{el.title}</p>
                </div>
            ))}
            <div className='steps_line' />
        </div>
    );
}

export default Steps;