import { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Form, Input, Radio, Select } from 'antd';
import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import { fetchCreateAddress } from 'store/actions/admin/address';
import { fetchCreateTechnic, fetchGetUniqueByPsm } from 'store/actions/admin/technics';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchCreateBusyPeriod } from 'store/actions/admin/busyPeriod';
import { formatDate } from 'hooks/formatDate';
import { yearList } from 'hooks/listOfYear';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { createFio } from 'hooks/createFio';
import CustomCalendar from 'components/shared/calendar/Calendar';
import FormButton from 'components/shared/buttons/formButton/FormButton';
import CustomDatePicker from 'components/shared/datePicker/DatePicker';

import pin from 'assets/images/pins/combine.svg'

const { Option } = Select;

const currentYear = (new Date()).getFullYear();

const exceptThisSymbols = [" "]

const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 10,
    type: 'yandex#hybrid'
};

function CreateTechnic({ allUsers, technic_types, work_types, fetchCreateAddress, fetchCreateTechnic, fetchGetUniqueByPsm, setVisible, fetchCreateBusyPeriod }) {
    const navigate = useNavigate()
    const [PSMDate, setPSMDate] = useState(new Date())
    const [coords, setCoords] = useState(null)
    const [value, setValue] = useState(false)
    const [location, setLocation] = useState(false)
    const [ranges, setRanres] = useState([])
    const [fields, setFields] = useState([
        {
            name: 'user_id',
            value: null
        },
        {
            name: 'is_owner',
            value: true,
        },
        {
            name: 'gos_num',
            value: null,
        },
        {
            name: 'technic_type_id',
            value: null
        },
        {
            name: 'manufacturer',
            value: null,
        },
        {
            name: 'model',
            value: null
        },
        {
            name: 'release_date',
            value: null,
        },
        {
            name: 'power',
            value: null,
        },
        {
            name: 'PSM_serial',
            value: null,
        },
        {
            name: 'PSM_number',
            value: null,
        },
        {
            name: 'description',
            value: null,
        },
        {
            name: 'extra_devices',
            value: null,
        },
        {
            name: 'extra_devices_desc',
            value: null,
        },
        {
            name: 'work_type_id',
            value: null,
        },
        {
            name: 'period_of_employment',
            value: false,
        },
        {
            name: 'location',
            value: false
        }
    ]);

    const onFinish = async (values) => {
        const user_id = values.user_id
        delete values.user_id
        values.PSM_date = formatDate(PSMDate)
        fetchGetUniqueByPsm(values.PSM_serial, values.PSM_number)
            .then(async (res) => {
                if (res) {
                    setVisible()
                } else {
                    if (coords) {
                        const data = {
                            latitude: coords[0],
                            longitude: coords[1]
                        }
                        await fetchCreateAddress(data)
                            .then(res => {
                                values.default_address_id = res
                            })
                    }
                    await fetchCreateTechnic(values, user_id)
                        .then(async (res) => {
                            if (res) {
                                navigate(`/admin/cars`)
                            }
                            if (value) {
                                for (let i = 0; i < ranges.length; i++) {
                                    const obj = {
                                        date_from: formatDate(ranges[i].startDate),
                                        date_to: formatDate(ranges[i].endDate),
                                        technic_id: res
                                    }
                                    await fetchCreateBusyPeriod(obj)
                                }
                            }
                        })
                }
            })
    };

    const saveRange = (ranges) => {
        setRanres(ranges)
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            className="twoColumnForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
        >
            <div className='twoColumnForm_column'>
                <Form.Item
                    label={'Пользователь'}
                    name="user_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Выберите пользователя"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={allUsers.map(el => {
                            return {
                                value: el.id,
                                label: createFio(el)
                            }
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label={'Собственник'}
                    name="is_owner"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={true}>Да</Radio>
                        <Radio value={false}>Нет</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={'Гос. номер'}
                    name="gos_num"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите гос. номер' />
                </Form.Item>
                <Form.Item
                    label={'Тип техники'}
                    name="technic_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип">
                        {technic_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Производитель'}
                    name="manufacturer"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите производителя' />
                </Form.Item>
                <Form.Item
                    label={'Модель'}
                    name="model"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите модель' />
                </Form.Item>
                <Form.Item
                    label={'Год выпуска'}
                    name="release_date"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите год">
                        {yearList(currentYear, currentYear - 50, -1).map(el => (
                            <Option value={String(el)} key={`release_date_${el}`}>{el}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Тип работ'}
                    name="work_type_id"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Select placeholder="Выберите тип работ">
                        {work_types.map(el => (
                            <Option value={el.id} key={el.id}>{el.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Мощность (л.с)'}
                    name="power"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите мощность (л.с)' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
                <Form.Item
                    label={'Серия ПСМ'}
                    name="PSM_serial"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите серию ПСМ' onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </Form.Item>
                <Form.Item
                    label={'Номер ПСМ'}
                    name="PSM_number"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите номер ПСМ' />
                </Form.Item>
                <Form.Item
                    label={'Описание техники'}
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input.TextArea placeholder='Введите описание техники' onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </Form.Item>
                <Form.Item
                    label={'Дополнительное оборудование'}
                    name="extra_devices"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Перечислите через запятую доп. оборудование на технике' />
                </Form.Item>
                <Form.Item
                    label={'Описание доп. оборудования'}
                    name="extra_devices_desc"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите описание дополнительного оборудования' />
                </Form.Item>
            </div>
            <div className='twoColumnForm_column'>
                <p>Дата выдачи ПСМ <span className='errorColor'>*</span></p>
                <div className='calendarBlock'>
                    <CustomCalendar date={PSMDate} onChange={(item) => { setPSMDate(item) }} />
                </div>
                <Form.Item
                    label={'Указать период занятости'}
                    name="period_of_employment"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group onChange={(e) => setValue(e.target.value)}>
                        <Radio value={false}>Нет</Radio>
                        <Radio value={true}>Да</Radio>
                    </Radio.Group>
                </Form.Item>
                {value &&
                    <div className='calendarBlock'>
                        <CustomDatePicker
                            monthe={1}
                            ranges={ranges}
                            mode="adminCreateTechnic"
                            saveRange={(ranges) => saveRange(ranges)}
                        />
                    </div>
                }
                <Form.Item
                    label={'Указать месторасположение'}
                    name="location"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group onChange={(e) => setLocation(e.target.value)}>
                        <Radio value={false}>Нет</Radio>
                        <Radio value={true}>Да</Radio>
                    </Radio.Group>
                </Form.Item>
                {location &&
                    <div className='locationBlock_map'>
                        <Map
                            defaultState={defaultState}
                            width='100%'
                            height='100%'
                            onClick={(e) => setCoords(e.get('coords'))}
                        >
                            <FullscreenControl />
                            <SearchControl options={{ float: "right" }} />
                            <GeolocationControl options={{ float: "left" }} />
                            <TypeSelector options={{ float: 'right' }} />
                            {coords &&
                                <Placemark
                                    geometry={coords}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: pin,
                                        iconImageSize: [70, 70],
                                        // iconImageOffset: [-5, -38]
                                    }}
                                />
                            }
                        </Map>
                    </div>
                }
                <Form.Item>
                    <FormButton
                        title={'Сохранить'}
                        style={{ float: 'right' }}
                        htmlType="submit"
                    />
                </Form.Item>
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        allUsers: state.allUsers.users,
        technic_types: state.technicTypes.technic_types,
        work_types: state.workTypes.work_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateAddress: (data) => dispatch(fetchCreateAddress(data)),
        fetchCreateTechnic: (data, user_id) => dispatch(fetchCreateTechnic(data, user_id)),
        fetchGetUniqueByPsm: (psm_serial, psm_number) => dispatch(fetchGetUniqueByPsm(psm_serial, psm_number)),
        setVisible: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'uniqueByPsmAdmin' }),
        fetchCreateBusyPeriod: (data) => dispatch(fetchCreateBusyPeriod(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateTechnic);