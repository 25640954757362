import { connect } from 'react-redux'
import { userVerificationModalTable } from 'data/adminTableHeader';
import { userVerificationModalTableWidth } from 'data/adminTableWidth';
import { formatDate } from 'hooks/formatDate';
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchConfirmUserVerificstion } from 'store/actions/admin/user';
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Table from 'components/ui/table/Table';

import './style.css';

function MainVerificationUser({ user_by_id_data, user_by_id_data_docs, data, setVisible, setReject, fetchConfirmUserVerificstion }) {
    const new_data = JSON.parse(data.new_data)

    const createTableData = () => {
        const data = []
        user_by_id_data_docs.forEach(el => {
            data.push([
                {
                    type: 'text',
                    content: el.name
                },
                {
                    type: 'active_text',
                    content: <a href={el.media.link} download target="_blank" className='activeText'>{el.name}</a>
                },
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
            ])
        })
        return data
    }

    const confirm = () => {
        fetchConfirmUserVerificstion(data.id, data.verification_id)
        setVisible()
    }

    return (
        <>
            <TextHeading
                text={'Верификация'}
                style={{
                    textAlign: 'center',
                    marginBottom: 20
                }}
            />
            <InfoBlock
                style={{
                    marginBottom: 20,
                    marginTop: 20
                }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>ФИО</p>
                    <p className='p14_400 mainText p_mb16'>{user_by_id_data.first_name + ' ' + user_by_id_data.last_name + ' ' + user_by_id_data.third_name}</p>
                    <p className='p12_400 mainText p_mb8'>Электронная почта</p>
                    <p className='p14_400 mainText'>{user_by_id_data.email ? user_by_id_data.email : '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Номер телефона</p>
                    <p className='p14_400 mainText'>{user_by_id_data.phone ? user_by_id_data.phone : '-'}</p>
                </div>
            </InfoBlock>
            {new_data.company !== undefined}{
                <InfoBlock
                    style={{
                        marginBottom: 20
                    }}
                    className="infoBlock twoRow"
                >
                    <div style={{ width: '100%' }}>
                        {new_data.company.name !== undefined &&
                            <>
                                <p className='p12_400 mainText p_mb8'>Название компании</p>
                                <p className='p14_400 mainText p_mb16 '>{new_data.company.name ? new_data.company.name : '-'}</p>
                            </>
                        }
                        {new_data.company.address !== undefined &&
                            <>
                                <p className='p12_400 mainText p_mb8'>Адрес компании</p>
                                <p className='p14_400 mainText p_mb16'>{new_data.company.address ? new_data.company.address : '-'}</p>
                            </>
                        }
                        {new_data.company.inn !== undefined &&
                            <>
                                <p className='p12_400 mainText p_mb8'>ИНН</p>
                                <p className='p14_400 mainText'>{new_data.company.inn ? new_data.company.inn : '-'}</p>
                            </>
                        }
                    </div>
                    <div style={{ width: '100%' }}>
                        {new_data.company.ogrn !== undefined &&
                            <>
                                <p className='p12_400 mainText p_mb8'>ОГРН</p>
                                <p className='p14_400 mainText p_mb16 '>{new_data.company.ogrn ? new_data.company.ogrn : '-'}</p>
                            </>
                        }
                        {new_data.company.email !== undefined &&
                            <>
                                <p className='p12_400 mainText p_mb8'>Коммерческая почта</p>
                                <p className='p14_400 mainText p_mb16'>{new_data.company.email ? new_data.company.email : '-'}</p>
                            </>
                        }
                        {new_data.company.phone !== undefined &&
                            <>
                                <p className='p12_400 mainText p_mb8'>Телефон для связи</p>
                                <p className='p14_400 mainText'>{new_data.company.phone ? new_data.company.phone : '-'}</p>
                            </>
                        }
                    </div>
                </InfoBlock>
            }
            <Table
                header={userVerificationModalTable}
                width={userVerificationModalTableWidth}
                data={createTableData()}
            />
            <div className="modalButtons">
                <SimpleButton
                    title={'Отклонен'}
                    style={{
                        color: '#FFF',
                        border: '1px solid #D32F2F',
                        borderRadius: '100px',
                        backgroundColor: '#D32F2F',
                        fontWeight: 500,
                        width: '100%'
                    }}
                    onClick={() => setReject(data)}
                />
                <SimpleButton
                    title={'Отменить'}
                    style={{
                        color: '#01963A',
                        backgroundColor: '#FFF',
                        fontWeight: 500,
                        width: '100%'
                    }}
                    onClick={() => setVisible()}
                />
                <SimpleButton
                    title={'Верифицирован'}
                    style={{
                        color: '#FFF',
                        border: '1px solid #01963A',
                        borderRadius: '100px',
                        backgroundColor: '#01963A',
                        fontWeight: 500,
                        width: '100%'
                    }}
                    onClick={confirm}
                />
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_by_id_data: state.userById.user_by_id_data,
        user_by_id_data_docs: state.userById.user_by_id_data.documents,
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        setReject: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'rejectVerificationUser', data }),
        fetchConfirmUserVerificstion: (user_id, verification_id) => dispatch(fetchConfirmUserVerificstion(user_id, verification_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainVerificationUser);