import { connect } from 'react-redux'
import ResponseCollaps from 'components/shared/responsCollapse/ResponseCollanse';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import noFound from 'assets/images/noFound.svg';

function Replies({ public_user_by_id }) {
    
    return (
        public_user_by_id.replies.length > 0
            ?
            <>
                {public_user_by_id.replies.map(el => (
                    <ResponseCollaps
                        key={`ResponseCollaps_${el.id}`}
                        item={el}
                        page={'publicUser'}
                    />
                ))}
            </>
            : <InfoBlock style={{ textAlign: 'center' }}>
                <img src={noFound} alt="noFound" />
            </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        public_user_by_id: state.publicUser.public_user_by_id
    }
}

export default connect(mapStateToProps, null)(Replies);