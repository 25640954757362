import {
    FETCH_APPLICATION_BY_ID_DATA_START,
    FETCH_APPLICATION_BY_ID_DATA_SUCCESS,
    CLEAR_APPLICATION_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    application_by_id_data: null,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_APPLICATION_BY_ID_DATA_START:
            return {
                ...state, loading: true
            }
        case FETCH_APPLICATION_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                application_by_id_data: action.data.application_by_id_data,
                loading: false
            }
        case CLEAR_APPLICATION_BY_ID_DATA:
            return {
                application_by_id_data: null,
                loading: false
            }
        default: return state
    }
}