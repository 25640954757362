export const checkPassword = (password) => {
    let count = 0
    count += /[a-z]/.test(password) ? 1 : 0;
    count += /[A-Z]/.test(password) ? 1 : 0;
    count += /[а-я]/.test(password) ? 1 : 0;
    count += /[А-Я]/.test(password) ? 1 : 0;
    count += /[0-9]/.test(password) ? 1 : 0;
    count += /\d/.test(password) ? 1 : 0;
    count += /[@_,.*^&?%$#!()=+-]/.test(password) ? 1 : 0;
    count += password.length >= 8 ? 1 : 0;

    if (count >= 6) {
        return true
    } else {
        return false
    }
}