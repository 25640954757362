import { connect } from 'react-redux'
import { profileVerificationTable } from 'data/tableHeader';
import { profileVerificationTableWidth } from 'data/tableWidth';
import { verificationStatusesStyle } from 'data/lists';
import { formatDate } from 'hooks/formatDate';
import Table from 'components/ui/table/Table';

function Verification({tehnics_by_id_data__verifications}) {

    const createTableData = () => {
        return tehnics_by_id_data__verifications.map(el => (
            [
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div>
                },
                {
                    type: 'text',
                    content: el.comment ? el.comment : '-'
                }
            ]
        ))
    }

    return (
        <>
            <div className='tableBlock'>
                <Table
                    header={profileVerificationTable}
                    width={profileVerificationTableWidth}
                    data={createTableData()}
                />
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        tehnics_by_id_data__verifications: state.tehnicById.tehnics_by_id_data.verifications,
    }
}

export default connect(mapStateToProps, null)(Verification);