import { api_login } from 'api/login'
import { api_user } from 'api/user'
import {
    FETCH_LOGIN_START,
    SAVE_DATA_FOR_ACTIVE,
    SIGNUP_FIRST_STAGE_SAVE_DATA,
    FETCH_LOGIN_ERROR
} from '../actionTypes'
import { fetchError, fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchSignUp(data) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_LOGIN_START))
        try {
            const user = await api_user.Register(data)
            if (user !== 'There is already a user with same email' && user !== 'There is already a user with same phone' && user !== `Users with email (${data.email}) already exists.` && user !== `Users with phone (${data.phone}) already exists.`) {
                dispatch({
                    type: SAVE_DATA_FOR_ACTIVE,
                    data
                })
                const user_data = await api_login.EmailLoginDeforeSignUp(data)
                dispatch(fetchSuccess(SIGNUP_FIRST_STAGE_SAVE_DATA, {
                    token: user_data.data.data.access_token,
                    user_data: user_data.data.data.user,
                    password: data.password
                }))
                return true
            } else {
                return false
            }
        } catch (e) {
            dispatch(fetchError(FETCH_LOGIN_ERROR, e))
        }
    }
}

export const fetchSecondSignUp = (data, token) => {
    return async dispatch => {
        dispatch(fetchStart(FETCH_LOGIN_START))
        try {
            await api_user.SecondRegister(data, token)
            return true
        } catch (e) {
            dispatch(fetchError(FETCH_LOGIN_ERROR, e))
            return e.response.data.detail
        }
    }
}