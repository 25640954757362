import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Upload } from 'antd';
import { CLOSE_MODAL } from 'store/actionTypes';
import { numberInputFormat } from 'hooks/numderInputFormat';
import { errorNotification } from 'hooks/notifications';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';
import CustomDatePicker from '../../../../shared/datePicker/DatePicker';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import uploadIcon from 'assets/images/icons/upload.svg';

const propsDocsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function CreateContractForm({ reject, defData, setVisible }) {
    const [range, setRange] = useState([{
        startDate: new Date(new Date().setHours(0,0,0,0) > new Date(defData.date_from).setHours(0,0,0,0) ? new Date() : defData.date_from),
        endDate: new Date(new Date().setHours(0,0,0,0) > new Date(defData.date_to).setHours(0,0,0,0) ? new Date() : defData.date_to),
        key: 'selection'
    }])
    const [fileDocsList, setFileDocsList] = useState([])
    const [fields, setFields] = useState([
        {
            name: 'amount',
            value: defData.amount,
        },
    ]);

    const onFinish = (values) => {
        if (fileDocsList.length === 0) {
            errorNotification('Отсутствуют файлы!', 'Для продолжения прикрепите файлы')
            return
        }
        reject(values, fileDocsList, range)
    };

    const normFile = (e, type) => {
        setFileDocsList(e.fileList)

        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const files = [...fileDocsList];
        files.splice(index, 1);
        setFileDocsList(files);

    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Стоимость контракта, ₽'}
                name="amount"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите стоимость' type="number" onKeyDown={numberInputFormat} />
            </Form.Item>
            <p className='p14_500' style={{ textAlign: 'start' }}>Срок сотрудничества</p>
            <CustomDatePicker
                ranges={range}
                mode="userCreateReply"
                saveRange={state => setRange(state)}
                edit={false}
            />
            <div style={{ marginTop: 24 }}>
                <p className='p14_500 p_mb12'>Подписанный документ с двух сторон (pdf, doc)</p>
                <div className={`${fileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {fileDocsList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'fileDocsList')} />
                        ))}
                    </div>
                    <Form.Item
                        name="filePhotoList"
                        valuePropName="filePhotoList"
                        getValueFromEvent={(e) => normFile(e, 'fileDocsList')}
                        className={`${fileDocsList.length === 0 ? '' : 'squareUpload'}`}
                    >
                        {fileDocsList.length === 0
                            ?
                            <Upload.Dragger
                                name="fileDocsList"
                                action="/upload.do"
                                itemRender={null}
                                customRequest={dummyRequest}
                                {...propsDocsUpload}
                            >
                                <div style={{ width: 190, margin: '100px auto' }}>
                                    <p className="subtext upload-text">Перетащите сюда, чтобы загрузить файл</p>
                                    <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                </div>
                            </Upload.Dragger>
                            :
                            <Upload.Dragger
                                name="fileDocsList"
                                action="/upload.do"
                                itemRender={null}
                                customRequest={dummyRequest}
                                {...propsDocsUpload}
                                fileList={fileDocsList}
                            >
                                <img src={uploadIcon} alt="uploadIcon" />
                            </Upload.Dragger>
                        }
                    </Form.Item>
                </div>
            </div>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500"
                        style={{
                            border: '1px solid #01963A',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: 'white',
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Начать сотрудничество'}
                        className="p14_500"
                        htmltype="submit"
                        style={{
                            color: 'white',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: '#01963A'
                        }}
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(null, mapDispatvhToProps)(CreateContractForm);