import { ApiUrl, instance } from './apiEnv';

export const api_plots = {

    async GetPlotById(id) {
        return await instance.get(`${ApiUrl}plots/${id}`)
    },

    async PostCreatePlots(data) {
        return await instance.post(`${ApiUrl}plots`, data)
    },

    async PutUpdateTechic(data, id) {
        return await instance.put(`${ApiUrl}plots/${id}`, data)
    },

    async DeletePlotById(id) {
        return await instance.delete(`${ApiUrl}plots/${id}`)
    },

}