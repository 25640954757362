import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { createFio } from 'hooks/createFio';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import unavailable from 'assets/images/icons/unavailable.svg';
import telegram from 'assets/images/icons/telegram.svg';
import chat from 'assets/images/icons/chat.svg';

function MainInfo({ public_user_by_id, access_token, setVisible }) {
    const navigate = useNavigate()

    const onComplainsts = () => {
        if (access_token) {
            setVisible('fileComplaint', {
                accused_id: public_user_by_id.id
            })
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы пожаловаться на пользователя сначала авторизуйтесь в системе' })
        }
    }

    const onStartChat = () => {
        if (access_token) {
            navigate(`/user/messages?reciever_id=${public_user_by_id.id}`)
        } else {
            setVisible('noAuth', { worning_text: 'Чтобы начать чат с пользователем сначала авторизуйтесь в системе' })
        }
    }

    // const onReply = (type) => {
    //     if (access_token) {
    //         // navigate(`/market/technics/${id}/create-reply`)
    //         navigate(`/market/fields/${public_user_by_id.squares[0].id}/create-reply`, { state: { back: `/public/user/${public_user_by_id.id}` } })
    //         if(type === 'byTechnic') {
    //             if(public_user_by_id.technics.length > 0) {
    //                 navigate(`/market/technics/${id}/create-reply`)
    //             }
    //         }
    //     } else {
    //         setVisible('noAuth', { worning_text: 'Чтобы предложить сделку сначала авторизуйтесь в системе' })
    //     }
    // }

    return (
        <div>
            <InfoBlock style={{ marginBottom: 16 }}>
                <p className='p12_400 mainText p_mb8'>Тип лица</p>
                <p className='p14_400 mainText p_mb16'>{public_user_by_id.jur_type === 'PHYSICAL' ? 'Физическое' : 'Юридическое'}</p>
                <p className='p12_400 mainText p_mb8'>ФИО</p>
                <p className='p14_400 mainText p_mb16'>{createFio(public_user_by_id)}</p>
                <p className='p12_400 mainText p_mb8'>Электронная почта</p>
                <p className='p14_400 mainText p_mb16'>{public_user_by_id.email || '-'}</p>
                <p className='p12_400 mainText p_mb8'>Номер телефона</p>
                <p className='p14_400 mainText'>{public_user_by_id.phone ? '+' + public_user_by_id.phone : '-'}</p>
            </InfoBlock>
            <div className='twoButton'>
                <div>
                    <SimpleButton
                        title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={chat} alt="unavailable" style={{ marginRight: 8 }} />Начать чат</p>}
                        style={{
                            width: 220,
                            color: '#FFFFFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                        }}
                        onClick={onStartChat}
                    />
                </div>
                <div className='twoButton_item'>
                    <Dropdown trigger={['click']}>
                        <SimpleButton
                            title={<p><img src={telegram} alt="unavailable" style={{ marginRight: 8 }} />Предложить сделку</p>}
                            style={{
                                width: 220,
                                color: '#FFFFFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500,
                                height: 36,
                                textAlign: 'center'
                            }}
                        // onClick={onReply}
                        />
                    </Dropdown>
                </div>
            </div>
            <div className='twoButton'>
                <SimpleButton
                    title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={unavailable} alt="unavailable" style={{ marginRight: 8 }} />Пожаловаться</p>}
                    style={{
                        width: 220,
                        color: '#D32F2F',
                        border: '1px solid #D32F2F',
                        borderRadius: '100px',
                        backgroundColor: '#FFF',
                        fontWeight: 500,
                        marginTop: 8
                    }}
                    onClick={onComplainsts}
                />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        access_token: state.userEnv.access_token,
        public_user_by_id: state.publicUser.public_user_by_id
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainInfo);