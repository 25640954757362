import { connect } from 'react-redux'
import { Form } from 'antd';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import CustomDatePicker from 'components/shared/datePicker/DatePicker';

function ApplicationDate({ form, nextStep, create_application_date }) {

    const onFinish = () => {
        nextStep()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={true}
            initialValues={{
                'is_owner': true,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <InfoBlock style={{textAlign: 'center'}}>
                <p className='p14_500' style={{textAlign: 'start'}}>Срок сотрудничества</p>
                <CustomDatePicker
                    ranges={create_application_date}
                    mode="userCreateApplication"
                />
            </InfoBlock>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        create_application_date: state.createApplication.date,
    }
}

export default connect(mapStateToProps, null)(ApplicationDate);