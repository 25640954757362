import {
    ADMIN_FETCH_TECHNIC_BY_ID_DATA_START,
    ADMIN_FETCH_TECHNIC_BY_ID_DATA_SUCCESS,
    ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS,
    ADMIN_CLEAR_TECHNIC_BY_ID_DATA
} from '../../actionTypes'

const initialState = {
    technic_by_id_data: null,
    current_location: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case ADMIN_FETCH_TECHNIC_BY_ID_DATA_START:
            return {
                ...state,
            }
        case ADMIN_FETCH_TECHNIC_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                technic_by_id_data: action.data.tehnics_by_id_data,
            }
        case ADMIN_CURRENT_LOCATION_FETCH_TECHNIC_BY_ID_DATA_SUCCESS: {
            return {
                ...state,
                current_location: action.data.current_location
            }
        }
        case ADMIN_CLEAR_TECHNIC_BY_ID_DATA: {
            return {
                technic_by_id_data: null,
                current_location: null
            }
        }
        default: return state
    }
}