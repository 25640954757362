import {
    FETCH_ALL_USER_CHATS_START,
    FETCH_ALL_USER_CHATS_CONCAT,
    CLEAR_ALL_USER_CHATS
} from '../../actionTypes'

const initialState = {
    userChats: [],
    unreaded_messages: [],
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_USER_CHATS_START:
            return {
                userChats: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                unreaded_messages: action.data.unreaded_messages,
                loading: false
            }
        case FETCH_ALL_USER_CHATS_CONCAT: {
            return {
                userChats: state.userChats.concat(action.data.data.items),
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                unreaded_messages: state.unreaded_messages.concat(action.data.unreaded_messages),
                loading: false
            }
        }
        case CLEAR_ALL_USER_CHATS:
            return {
                userChats: [],
                unreaded_messages: [],
                pages: 0,
                page: 0,
                size: 0,
                loading: false
            }
        default: return state
    }
}