import { useState } from 'react';
import { connect } from 'react-redux'
import { notification } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { warningNotification } from 'hooks/notifications';
import SeasonCollapseItem from './SeasonCollapseItem';

import angleDownBlue from 'assets/images/icons/angle-down-blue.svg';
import angleRightGrey from 'assets/images/icons/angle-right-grey.svg';

import './style.css';

function SeasonCollapse({ item, index, deleteSeason, plots, current_plots, setVisible, deletePlotOnSeason, editPlotOnSeason, addPlotOnSeasons, linkOnCreatPlot, page }) {
    const [active, setActive] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    return (
        <>
            {contextHolder}
            <div className='seasonCollapse'>
                <div className='seasonCollapse_header' onClick={() => setActive(!active)}>
                    <div className='seasonCollapse_header__left'>
                        <img src={active ? angleDownBlue : angleRightGrey} alt='angle' />
                        <p className='p14_500'>{item.year === 0 ? 'По-умолчанию (доступы во всех сезонах)' : `Сезон ${item.year}`}</p>
                    </div>
                    {page !== 'market' && page !== 'check' && (
                        (item.being_deleted || (item.year !== 0))
                            ? <p
                                className='errorColor p14_500'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    warningNotification(api, () => {
                                        deleteSeason(index)
                                        api.destroy()
                                    }, 'удаление сезона.')
                                }}
                            >
                                Удалить сезон
                            </p>
                            : <p className='subtext p14_500'>Нельзя удалить</p>)}
                </div>
                <div className='seasonCollapse_content' style={{ display: !active ? 'none' : 'block' }}>
                    <div className='seasonCollapse_content__items'>
                        {item.plots.map((el, key) => (
                            <SeasonCollapseItem
                                key={`seasonCollapsItem_${key}`}
                                item={el}
                                seasonIndex={index}
                                seasonPlotIndex={el.id || key}
                                deletePlotOnSeason={deletePlotOnSeason}
                                editPlotOnSeason={editPlotOnSeason}
                                page={page}
                            />
                        ))}
                    </div>
                    {page !== 'market' && page !== 'check' &&
                        <div
                            className='seasonCollapse_content__buttons mainText p14_500'
                            onClick={() => setVisible({
                                plotsInSeason: item.plots,
                                plots: current_plots || plots,
                                seasonIndex: index,
                                addPlotOnSeasons: (seasonIndex, addedData) => addPlotOnSeasons(seasonIndex, addedData),
                                linkOnCreatPlot: linkOnCreatPlot
                            })}
                        >
                            Добавить участок в сезон
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        plots: state.createSquare.plots,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'addPlotToTheSeason', data }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SeasonCollapse);