import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { fetchAllDeletedUsers } from 'store/actions/admin/deletedUsers';
import { allDeletionRequestsTable } from 'data/adminTableHeader';
import { allDeletionRequestsTableWidth } from 'data/adminTableWidth';
import { createQuery } from 'hooks/createQuery';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';

function AllDeletionRequestsAdmin({ deleted_users, pages, fetchAllDeletedUsers }) {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllDeletedUsers(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
    ])

    const createTableData = () => {
        return deleted_users.map(el => (
            [
                {
                    type: 'text',
                    content: el.fio
                },
                {
                    type: 'text',
                    content: el.company || '-'
                },
                {
                    type: 'text',
                    content: el.comment || '-'
                },
            ]
        ))
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <TextHeading text={'Удаленные пользователи'} style={{ marginBottom: 24 }} />
            </div>
            <Table
                header={allDeletionRequestsTable}
                width={allDeletionRequestsTableWidth}
                data={createTableData()}
            />
            {deleted_users.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        deleted_users: state.allDeletedUsers.deletedUsers,
        pages: state.allDeletedUsers.pages
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllDeletedUsers: (page, size, params) => dispatch(fetchAllDeletedUsers(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllDeletionRequestsAdmin);