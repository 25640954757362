import { ApiUrl, instance } from './apiEnv';

export const api_conflicts = {

    async GetMyConflicts(page, size, params) {
        return await instance.get(`${ApiUrl}conflicts/my?page=${page}&size=${size}&${params}`)
    },

    async GetConflictById(id) {
        return await instance.get(`${ApiUrl}conflicts/${id}`)
    },

    async PostCreateConflict(data) {
        return await instance.post(`${ApiUrl}conflicts`, data)
    },

    async UploadConflictDocumentById(id, data) {
        return await instance.put(`${ApiUrl}conflicts/${id}/document`, data)
    },

    async DeleteConflictDocumentById(id, doc_id) {
        return await instance.delete(`${ApiUrl}conflicts/${id}/document/${doc_id}`)
    },

    async UploadConflictImageById(id, data) {
        return await instance.put(`${ApiUrl}conflicts/${id}/upload_image`, data)
    },

    async DeleteConflictImageById(id, img_id) {
        return await instance.delete(`${ApiUrl}conflicts/${id}/image/${img_id}`)
    },

}