import { connect } from 'react-redux'
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import { userBreadcrumb } from 'data/brebcrumb';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import CreateUser from 'components/components/forms/admin/profile/createUser/CreateUser';

function CreateUserAdmin({ }) {

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={userBreadcrumb} activeLink={'Создание пользователя'} />
            <InfoBlock>
                <CreateUser />
            </InfoBlock>
        </div>
    );
}

export default connect(null, null)(CreateUserAdmin);