import {
    FETCH_SQUARE_TYPES_SUCCESS
} from '../actionTypes'

const initialState = {
    square_types: [],
    loading: true
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SQUARE_TYPES_SUCCESS:
            return {
                ...state,
                square_types: action.data.square_types,
                loading: false
            }
        default: return state
    }
}