import { api_technics } from 'api/technics'
import {
    FETCH_USER_DATA_TECHNICS_SUCCESS,
    FETCH_TECHNIC_MANUFACTURER_SUCCESS,
    FETCH_TECHNIC_MODEL_SUCCESS
} from 'store/actionTypes'
import { fetchSuccess } from './fetchStatuses'
import { fetchTechnicsByID } from './technicsById'

export function fetchMyTechnic(page, size, params) {
    return async dispatch => {
        try {
            const technics_data = await api_technics.GetMyTechnics(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_TECHNICS_SUCCESS, {
                data: technics_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchCreateTechnic(data) {
    return async dispatch => {
        try {
            const technic = await api_technics.PostCreateTechic(data)
            return technic.data.data.id
        } catch (e) {
        }
    }
}

export function fetchUploadTechnicImage(id, data) {
    return async dispatch => {
        try {
            await api_technics.UploadTechicImageById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteTechnicImage(id, img_id) {
    return async dispatch => {
        try {
            await api_technics.DeleteTechicImageById(id, img_id)
        } catch (e) {
        }
    }
}

export function fetchUploadTechnicDocs(id, data) {
    return async dispatch => {
        try {
            await api_technics.UploadTechicDocumentById(id, data)
            dispatch(fetchTechnicsByID(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteTechnicDocs(id, doc_id) {
    return async dispatch => {
        try {
            await api_technics.DeleteTechicDocumentById(id, doc_id)
            dispatch(fetchTechnicsByID(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteTechnic(id) {
    return async dispatch => {
        try {
            await api_technics.DeleteTechnicById(id)
        } catch (e) {
        }
    }
}

export function fetchGetUniqueByPsm(psm_serial, psm_number) {
    return async dispatch => {
        try {
            const unique = await api_technics.GetUniqueByPsm(psm_serial, psm_number)
            return unique.data.Status
        } catch (e) {
        }
    }
}

export function fetchLikeTechnics(id) {
    return async dispatch => {
        try {
            await api_technics.PostLikes(id)
        } catch (e) {
        }
    }
}

export function fetchTechnicManufacturer(param, value) {
    return async dispatch => {
        try {
            const technic_manufacturer = await api_technics.GetTechnicParams(param, value)
            dispatch(fetchSuccess(FETCH_TECHNIC_MANUFACTURER_SUCCESS, {
                technic_manufacturer: technic_manufacturer.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchTechnicModel(param, value) {
    return async dispatch => {
        try {
            const technic_models = await api_technics.GetTechnicParams(param, value)
            dispatch(fetchSuccess(FETCH_TECHNIC_MODEL_SUCCESS, {
                technic_models: technic_models.data.data
            }))
        } catch (e) {
        }
    }
}