import { api_plots } from 'api/admin/plots'
import {
    ADMIN_FETCH_PLOTS_BY_ID_DATA_START,
    ADMIN_FETCH_PLOTS_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchPlotByID(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_PLOTS_BY_ID_DATA_START))
        try {
            const plots_by_id_data = await api_plots.GetPlotById(id)
            dispatch(fetchSuccess(ADMIN_FETCH_PLOTS_BY_ID_DATA_SUCCESS, {
                plots_by_id_data: plots_by_id_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchUpdatePlotByID(data, id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_PLOTS_BY_ID_DATA_START))
        try {
            await api_plots.PutUpdateTechic(data, id)
        } catch (e) {
        }
    }
}

export function fetchDeletePlotById(id) {
    return async dispatch => {
        try {
            await api_plots.DeletePlotById(id)
        } catch (e) {
        }
    }
}