import { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Upload, notification } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUpdateMyDataRegistration, fetchUpdateMyDocsSignUp } from 'store/actions/user';
import { fetchSecondSignUp } from 'store/actions/signup';
import { checkFioLength } from 'hooks/checkFioLength';
import FormButton from 'components/shared/buttons/formButton/FormButton';

import ban from 'assets/images/icons/ban.svg';

import './style.css';
import './media.css';

const openNotification = () => {
    notification.open({
        message: <p className="errorColor p14_500">Пользователь с таким ИНН уже зарегистрирован</p>,
        icon: (
            <img src={ban} />
        )
    });
};

const propsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    maxCount: 1,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function SpecifiedDataLegalPerson({ user_data, token, fetchSecondSignUp, select_company_data, fetchUpdateMyDocsSignUp, fetchUpdateMyDataRegistration }) {
    const location = useLocation()
    const [errorStatus, setErrorStatus] = useState({})
    const [errorStatusPhone, setErrorStatusPhone] = useState({})
    const [fields, setFields] = useState([
        {
            name: 'phone',
            value: user_data.phone ? user_data.phone.substring(1) : null,
        },
        {
            name: 'email',
            value: user_data.email ? user_data.email : null,
        },
        {
            name: 'name',
            value: select_company_data.name,
        },
        {
            name: 'address',
            value: select_company_data.address,
        },
        {
            name: 'inn',
            value: select_company_data.inn,
        },
        {
            name: 'ogrn',
            value: select_company_data.ogrn,
        },
        {
            name: 'company_phone',
            value: '',
        },
        {
            name: 'files',
            value: [],
        }
    ]);
    const navigate = useNavigate()

    const onFinish = ({ fio, email, phone, name, address, inn, ogrn, company_email, company_phone, files }) => {
        if (checkFioLength(fio).length === 3) {
            const fioArray = checkFioLength(fio)
            const data = {
                email,
                phone: '7' + phone,
                first_name: fioArray[0],
                last_name: fioArray[1],
                third_name: fioArray[2],
                company: {
                    name,
                    ogrn,
                    email: company_email,
                    phone: '7' + company_phone,
                    inn,
                    address
                }
            }
            if (location.state) {
                fetchUpdateMyDataRegistration(data, token)
                    .then(res => {
                        if (res === true) { navigate('/signup/complite') }
                        else if (res === `Company with specified inn (${inn}) is already created.`) {
                            openNotification()
                            setErrorStatusPhone({})
                            setErrorStatus({})
                        } else {
                            setErrorStatusPhone({
                                validateStatus: "error",
                                help: "Данный номер уже зарегистрирован"
                            })
                        }
                    })

            } else {
                fetchSecondSignUp(data, token)
                    .then(res => {
                        if (res === true) { navigate('/signup/complite') }
                        else if (res === `Company with specified inn (${inn}) is already created.`) {
                            openNotification()
                            setErrorStatusPhone({})
                            setErrorStatus({})
                        } else {
                            setErrorStatusPhone({
                                validateStatus: "error",
                                help: "Данный номер уже зарегистрирован"
                            })
                        }
                    })
            }
            if (files.length > 0) {
                let data = new FormData();
                let filedata = files[0].originFileObj
                delete filedata.uid
                data.append('file', filedata);
                data.append('name', files[0].name);
                fetchUpdateMyDocsSignUp(data, token)
            }
        } else {
            setErrorStatus({
                validateStatus: "error",
                help: "Некорректные данные"
            })
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            style={{ marginTop: 24 }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
                setErrorStatus({})
                setErrorStatusPhone({})
            }}
        >
            <div className='SpecifiedDataLegalPersonBlock'>
                <div className='SpecifiedDataLegalPersonBlock_column first_column'>
                    <p className='SpecifiedDataLegalPersonBlock_column__title mainText'>Личные данные</p>
                    <Form.Item
                        label={'ФИО'}
                        name="fio"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                        {...errorStatus}
                    >
                        <Input placeholder='Введите ФИО' />
                    </Form.Item>
                    <Form.Item
                        label={'Email'}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите почту' disabled={user_data.email ? true : false} />
                    </Form.Item>
                    <Form.Item
                        label={'Номер телефона'}
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                        {...errorStatusPhone}
                    >
                        <Input placeholder='(000) 000-0000' prefix={'+7'} disabled={user_data.phone ? true : false} type="number" />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item
                            name="files"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload.Dragger
                                // name="files"
                                action="/upload.do"
                                itemRender={null}
                                // onChange={handleChange}
                                customRequest={dummyRequest}
                                {...propsUpload}
                            >
                                <p className="subtext upload-text">Загрузить скан-копию паспорта не обязательно (1,2 развороты) (jpg, png, doc, pdf).</p>
                                <div className='uploadButton whiteButtonText'>Выбрать файл</div>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                </div>
                <div className='SpecifiedDataLegalPersonBlock_column second_column'>
                    <p className='SpecifiedDataLegalPersonBlock_column__title mainText'>Данные компании</p>
                    <Form.Item
                        label={'Название компании'}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите название компании' />
                    </Form.Item>
                    <Form.Item
                        label={'Адрес компании'}
                        name="address"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите адрес компании' />
                    </Form.Item>
                    <Form.Item
                        label={'ИНН'}
                        name="inn"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите ИНН' />
                    </Form.Item>
                    <Form.Item
                        label={'ОГРН'}
                        name="ogrn"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите ОГРН' />
                    </Form.Item>
                    <Form.Item
                        label={'Email компании'}
                        name="company_email"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите email компании' />
                    </Form.Item>
                    <Form.Item
                        label={'Номер телефона компании'}
                        name="company_phone"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" />
                    </Form.Item>
                </div>
            </div>
            <Form.Item>
                <FormButton
                    title={'Далее'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.signup.user_data,
        select_company_data: state.signup.select_company_data,
        token: state.signup.token,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchSecondSignUp: (data, token) => dispatch(fetchSecondSignUp(data, token)),
        fetchUpdateMyDocsSignUp: (data, token) => dispatch(fetchUpdateMyDocsSignUp(data, token)),
        fetchUpdateMyDataRegistration: (data, token) => dispatch(fetchUpdateMyDataRegistration(data, token))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(SpecifiedDataLegalPerson);