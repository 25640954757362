import { connect } from 'react-redux'
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchCreateConflict, fetchUploadConflictDocs, fetchUploadConflictImage } from 'store/actions/conflicts';
import CreateConflictForm from 'components/components/forms/modal/createConflict/CreateConflickForm';

function CreateConflict({ data, setVisible, fetchCreateConflict, fetchUploadConflictImage, fetchUploadConflictDocs }) {

    const reject = (values, images, docs) => {
        values.contract_id = data.contract_id
        fetchCreateConflict(values)
            .then(async res => {
                if (res) {
                    for (let i = 0; i < images.length; i++) {
                        let data = new FormData();
                        let filedata = images[i].originFileObj
                        delete filedata.uid
                        data.append('image_file', filedata);
                        data.append('title', images[i].name);
                        await fetchUploadConflictImage(res, data)
                    }
                    for (let i = 0; i < docs.length; i++) {
                        let data = new FormData();
                        let filedata = docs[i].originFileObj
                        delete filedata.uid
                        data.append('file', filedata);
                        data.append('name', docs[i].name);
                        await fetchUploadConflictDocs(res, data)
                    }
                }
            })
        setVisible()
    }

    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center', width: '50vw' }}>Создать спор</p>
            <CreateConflictForm
                reject={(values, images, docs) => reject(values, images, docs)}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchCreateConflict: (data) => dispatch(fetchCreateConflict(data)),
        fetchUploadConflictImage: (id, data) => dispatch(fetchUploadConflictImage(id, data)),
        fetchUploadConflictDocs: (id, data) => dispatch(fetchUploadConflictDocs(id, data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(CreateConflict);