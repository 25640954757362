import {
    FETCH_TECHNIC_MANUFACTURER_SUCCESS,
    FETCH_TECHNIC_MODEL_SUCCESS
} from '../actionTypes'

const initialState = {
    technic_manufacturer: [],
    technic_models: [],
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TECHNIC_MANUFACTURER_SUCCESS:
            return {
                ...state,
                technic_manufacturer: action.data.technic_manufacturer
            }
        case FETCH_TECHNIC_MODEL_SUCCESS:
            return {
                ...state,
                technic_models: action.data.technic_models
            }
        default: return state
    }
}