import { connect } from 'react-redux'
import { adminConflictBreadcrumb } from 'data/brebcrumb';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ADMIN_CLEAR_CONFLICT_BY_ID_DATA } from 'store/actionTypes';
import { fetchConflictById } from 'store/actions/admin/conflicts';
import { conflictByIdSwitch } from 'data/adminLists';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import Switch from 'components/shared/switch/Switch';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import MainInfo from './mainInfo/MainInfo';
import Docs from './docs/Docs';

function ConflictByIdAdmin({ conflict_by_id_data, fetchConflictById, ADMIN_CLEAR_CONFLICT_BY_ID_DATA }) {
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')

    useEffect(() => {
        fetchConflictById(id)
    }, [])

    useEffect(() => {
        return () => {
            ADMIN_CLEAR_CONFLICT_BY_ID_DATA()
        }
    }, [])
    
    return (
        conflict_by_id_data &&
        <div className='allUsersBlock'>
            <Breadcrumb list={adminConflictBreadcrumb} activeLink={conflict_by_id_data.id} />
            <TextHeading text={`Спор (ID сделки ${conflict_by_id_data.contract_id})`} />
            <Switch
                items={conflictByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo />}
            {activeSwitch === 'docs' && <Docs />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        conflict_by_id_data: state.adminConflictById.conflict_by_id_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchConflictById: (id) => dispatch(fetchConflictById(id)),
        ADMIN_CLEAR_CONFLICT_BY_ID_DATA: () => dispatch({ type: ADMIN_CLEAR_CONFLICT_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ConflictByIdAdmin);