import { useState } from 'react';
import { connect } from 'react-redux'
import { createFieldBreadcrumb } from 'data/brebcrumb';
import { Form } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchFullCreatingSquare } from 'store/actions/square';
import { stepsFullCreateField } from 'data/steps';
import { errorNotification } from 'hooks/notifications';
import Steps from 'components/ui/steps/Steps';
import MainInfo from 'components/components/forms/field/createField/quickCreatingField/MainInfo';
import Address from './address/Address';
import Docs from './docs/Docs';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Plots from './plots/Plots';
import Seasons from './seasons/Seasons';

function FullCreatingField({ plots, coords, data, fetchFullCreatingSquare }) {
    const location = useLocation()
    const [activeStep, setActiveStep] = useState(location.state ? location.state.step : 0)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const nextStep = () => {
        if (activeStep < 4) setActiveStep(activeStep + 1)
    }

    const onClickSaveForm = () => {
        if (activeStep === 0) {
            form.submit()
        } else if (activeStep === 1) {
            if (coords) {
                nextStep()
            } else {
                errorNotification('Нет местопожения', 'Укажите местопожение')
            }
        } else if (activeStep === 2) {
            if (plots.length > 0) {
                nextStep()
            } else {
                errorNotification('Нет участков', 'Укажите участки')
            }
        } else if (activeStep === 3) {
            nextStep()
        } else if (activeStep === 4) {
            if (!loading) {
                setLoading(true)
                fetchFullCreatingSquare(data)
                    .then(res => {
                        if (res) {
                            navigate(`/user/my-fields`)
                        }
                        setLoading(false)
                    })
            }
        }
    }

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={createFieldBreadcrumb} activeLink={'Создать поле'} />
            <div className='createCarBlock_header'>
                <TextHeading text={'Создание поля'} />
                {activeStep === 0
                    ?
                    <SimpleButton
                        title={'Далее'}
                        style={{
                            background: '#01963A',
                            borderRadius: '100px'
                        }}
                        onClick={onClickSaveForm}
                    />
                    :
                    <div className='userProfile_header__actions'>
                        {!loading &&
                            <SimpleButton
                                title={'Назад'}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500,
                                    marginRight: 16
                                }}
                                onClick={() => setActiveStep(activeStep - 1)}
                            />
                        }
                        <SimpleButton
                            title={activeStep !== 4 ? 'Далее' : 'Завершить'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={onClickSaveForm}
                            loading={loading}
                        />
                    </div>
                }
            </div>
            <div className='createCarBlock'>
                <Steps stepsList={stepsFullCreateField} activeStep={activeStep} />
                <div style={{ marginTop: 24 }} className='createCarBlock_forms'>
                    {activeStep === 0 && <MainInfo nextStep={nextStep} form={form} />}
                    {activeStep === 1 && <Address />}
                    {activeStep === 2 && <Plots />}
                    {activeStep === 3 && <Seasons />}
                    {activeStep === 4 && <Docs />}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        plots: state.createSquare.plots,
        coords: state.createSquare.coords,
        data: state.createSquare,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchFullCreatingSquare: (data) => dispatch(fetchFullCreatingSquare(data)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(FullCreatingField);