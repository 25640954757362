import { connect } from 'react-redux'
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { contractsStatusesStyle } from 'data/lists';
import { warningNotification } from 'hooks/notifications';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import redCross from 'assets/images/icons/red_cross.svg';
import check from 'assets/images/icons/check.svg';
import repeat from 'assets/images/icons/repeat_green.svg';

function MainInfo({ contract_by_id_data, user_data }) {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()
    const { id } = useParams()

    return (
        <>
            {contextHolder}
            <InfoBlock
                className="infoBlock twoRow"
                style={{ marginBottom: 24 }}
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>ID сделки</p>
                    <p className='p14_400 mainText p_mb16 activeText'>{contract_by_id_data.id}</p>
                    <p className='p12_400 mainText p_mb8'>Тип культур</p>
                    <p className='p14_400 mainText p_mb16'>{contract_by_id_data.crop_type ? contract_by_id_data.crop_type.name : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Сумма контракта, ₽</p>
                    <p className='p14_400 mainText'>{contract_by_id_data.amount + '₽' || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Дата начала контракта</p>
                    <p className='p14_400 mainText p_mb16'>{contract_by_id_data.valid_from}</p>
                    <p className='p12_400 mainText p_mb8'>Дата завершения контракта</p>
                    <p className='p14_400 mainText p_mb16'>{contract_by_id_data.valid_to}</p>
                    {/* <p className='p12_400 mainText p_mb8'>Адрес выполнения контракта</p>
                    <p className='p14_400 mainText p_mb16'>В РАБОТЕ</p> */}
                    <p className='p12_400 mainText p_mb8'>Статус</p>
                    <div className='statusOnTable p12_400' style={{ backgroundColor: contractsStatusesStyle[contract_by_id_data.status].bg }}>{contractsStatusesStyle[contract_by_id_data.status].title}</div>
                </div>
            </InfoBlock>
            {(contract_by_id_data.status === 'UNCONCLUDED') &&
                <div className='twoButton'>
                    <div>
                        <SimpleButton
                            title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={check} alt="check" style={{ marginRight: 8 }} />Завершить досрочно</p>}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={() => warningNotification(api, () => {
                                navigate(`/user/my-contracts/${id}/evaluation`)
                                api.destroy()
                            }, 'досрочное завершение контракта. Оставьте отзыв о работе.')}
                        />
                    </div>
                    <div className='twoButton_item'>
                        <SimpleButton
                            title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={redCross} alt="redCross" style={{ marginRight: 8 }} />Расторгнуть контракт</p>}
                            style={{
                                color: '#D32F2F',
                                border: '1px solid #D32F2F',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500
                            }}
                            onClick={() => warningNotification(api, () => {
                                navigate(`/user/my-contracts/${id}/termination`)
                                api.destroy()
                            }, 'расторжение контракта')}
                        />
                    </div>
                </div>
            }
            {contract_by_id_data.status === 'COMPLITED' &&
                <div className='twoButton'>
                    <div>
                        {contract_by_id_data?.owner?.id === user_data.id &&
                            <SimpleButton
                                title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={repeat} alt="repeat" style={{ marginRight: 8 }} />Повторить заявку</p>}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500
                                }}
                                onClick={() => navigate(`/user/my-contracts/${id}/repeat`)}
                            />
                        }
                    </div>
                    <div className='twoButton_item'>
                        <SimpleButton
                            title={'Контракт завершен'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500,
                                cursor: 'auto'
                            }}
                        />
                    </div>
                </div>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.contractById.contract_by_id_data,
        user_data: state.user.user_data
    }
}

export default connect(mapStateToProps, null)(MainInfo);