import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchDeleteTechnic, fetchMyTechnic } from 'store/actions/techics';
import { warningNotification } from 'hooks/notifications';
import { createQuery } from 'hooks/createQuery';
import { allCarsTable } from 'data/tableHeader';
import { allCarsTableWidth } from 'data/tableWidth';
import { verificationStatusesStyle } from 'data/lists';
import { allCarsFilter } from 'data/adminLists';
import NoDataBlock from 'components/ui/noDataBlock/NoDataBlock';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Filter from 'components/shared/filter/Filter';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';

import tractor from 'assets/images/messageIcons/tractor.svg';
import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';
import deleteIcon from 'assets/images/icons/delete.svg';

import './style.css';

function AllTechnics({ user_data_technics, pages, technic_types, work_types, fetchDeleteTechnic, fetchMyTechnic }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyTechnic(query.page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return user_data_technics.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-cars/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: el.power
                },
                {
                    type: 'text',
                    content: el.release_date
                },
                // {
                //     type: 'text',
                //     content: 'В РАБОТЕ'
                // },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img src={edit_green_pen} alt="edit_green_pen" onClick={() => navigate(`/user/my-cars/${el.id}`, { state: { edit: true } })} />
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchDeleteTechnic(el.id)
                                await fetchMyTechnic(Object.fromEntries([...searchParams]).page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
                                api.destroy()
                            }, 'удаление техники.')}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        (user_data_technics && technic_types.length > 0 && work_types.length > 0) &&
        ((createTableData().length !== 0 || createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order) !== '')
            ?
            <div className='allUsersBlock'>
                <div className='allUsersBlock_header'>
                    <div className='allUsersBlock_header__leftHalf'>
                        <TextHeading text={'Техника'} />
                    </div>
                    <div className='allUsersBlock_header__rightHalf'>
                        <Filter
                            className={'filter_marginRight'}
                            filterList={allCarsFilter(technic_types, work_types)}
                        />
                        <SimpleButton
                            title={'Добавить технику'}
                            className="greenButton"
                            onClick={() => navigate(`/user/my-cars/create`)}
                        />
                    </div>
                </div>
                {contextHolder}
                <Table
                    header={allCarsTable}
                    width={allCarsTableWidth}
                    data={createTableData()}
                />
                {user_data_technics.length > 0 && <Pagination pages={pages} />}
            </div>
            :
            <NoDataBlock
                img={tractor}
                title={'Нет информации о технике'}
                description={'На данный момент у нас нет информации о вашей технике, чтобы внести ее - нажмите на кнопку ниже.'}
                buttonTitle={['Добавить технику']}
                onClick={[() => navigate(`/user/my-cars/create`)]}
            />)
    );
}

function mapStateToProps(state) {
    return {
        user_data_technics: state.userTechnics.technics,
        pages: state.userTechnics.pages,
        technic_types: state.technicTypes.technic_types,
        work_types: state.workTypes.work_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDeleteTechnic: (id) => dispatch(fetchDeleteTechnic(id)),
        fetchMyTechnic: (page, size, params) => dispatch(fetchMyTechnic(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllTechnics);