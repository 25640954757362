import './style.css';
import './media.css';

function Switch({ items, activeItem, onSwitch, ...props }) {
    return (
        <div
            className="switch"
            {...props}
        >
            {items.map((item, key) => (
                <div
                    key={`switchItem_${key}`}
                    className={`switch_item mainText ${item.item === activeItem ? 'active activeText' : ''}`}
                    onClick={() => onSwitch(item.item)}
                >
                    {item.title}
                </div>
            ))}
        </div>
    );
}

export default Switch;