export const profileDocsTableWidth = [
    200,
    200,
    200,
    // 250,
    null
]

export const profileVerificationTableWidth = [
    200,
    200,
    250
]

export const allCarsTableWidth = [
    160,
    160,
    160,
    160,
    180,
    140,
    // 120,
    160,
    null
]

export const carEmploymentTableWidth = [
    150,
    150,
    150,
    150,
    100,
    100,
    100,
    220,
    220,
]

export const allFieldsTableWidth = [
    200,
    // 520,
    250,
    null
]

export const fieldDocsTableWidth = [
    200,
    200,
    200,
    // 250,
    null
]

export const createFieldDocsTableWidth = [
    200,
    200,
    200,
    null
]

export const allApplicationsTableWidth = [
    200,
    200,
    200,
    150,
    200,
    150,
]

export const offersCarsTableWidth = [
    200,
    200,
    160,
    160,
    200,
    200,
    null
]

export const offersFieldsTableWidth = [
    200,
    200,
    // 500,
    200,
    200,
    200,
    null
]


export const allConflictsTableWidth = [
    180,
    180,
    200,
    200,
    160,
    null
]

export const allContractsTableWidth = [
    180,
    180,
    180,
    160,
    100,
    100,
    100,
    250,
    100
]

export const allContractsWithoutStatusTableWidth = [
    180,
    180,
    180,
    160,
    100,
    100,
    100,
    250
]

export const createReplyByTechnicTableWidth = [
    200,
    550,
    250,
    200,
    null
]

export const createReplyBySquareTableWidth = [
    200,
    250,
    200,
    150,
    null
]

export const marketBidsTableWidth = [
    150,
    200,
    250,
    150,
    150,
    250,
    100
]

export const marketBidsTechnicTableWidth = [
    250,
    250,
    150,
    250,
]

export const createReplyBySquareBidsTableWidth = [
    250,
    250,
    250,
    150,
    150,
    250,
    100,
    null
]

export const createReplyByTchnicBidsTableWidth = [
    250,
    150,
    150,
    250,
    null
]

export const responseTchnicsCarsTableWidth = [
    300,
    160,
    160,
    160,
    160,
    160,
]

export const responseSquaresTableWidth = [
    250,
    250,
    // 250,
    // 150,
    250,
    // 100,
]