import { connect } from 'react-redux'
import { useState } from 'react';
import { notification } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchDealById, fetchMyDeals, fetchUpdateDealById } from 'store/actions/deals';
import { offersCarsTable, offersFieldsTable } from 'data/tableHeader';
import { offersCarsTableWidth, offersFieldsTableWidth } from 'data/tableWidth';
import { formatDateTime } from 'hooks/formatDate';
import { createFio } from 'hooks/createFio';
import { createQuery } from 'hooks/createQuery';
import { confirmNotification } from 'hooks/notifications';
import Table from 'components/ui/table/Table';
import SimpleButton from '../buttons/simpleButton/SimpleButton';

import angle_down from 'assets/images/icons/angle-down.svg';
import chat from 'assets/images/icons/chat.svg';
import check from 'assets/images/icons/check.svg';
import eye from 'assets/images/icons/eye-green.svg';

import './style.css';
import './media.css';

function OfferCollaps({ deal, setVisible, fetchDealById, fetchUpdateDealById, fetchMyDeals }) {
    const [active, setActive] = useState(false)
    const [collapseData, setCollapseData] = useState(null)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [api, contextHolder] = notification.useNotification();

    const createTableData = () => {
        if (collapseData) {
            if (deal.reply_type === 'FARMER') {
                return collapseData.plots.map(el => (
                    [
                        {
                            type: 'active_text',
                            content: <p onClick={() => navigate(`/market/fields/${collapseData.square.id}`)}>{collapseData.square_id}</p>
                        },
                        {
                            type: 'text',
                            content: el.number
                        },
                        {
                            type: 'text',
                            content: collapseData.square.square_type.name
                        },
                        {
                            type: 'text',
                            content: el.crop_type.name
                        },
                        {
                            type: 'text',
                            content: el.fact_sowing
                        },
                        {
                            type: 'actions',
                            content: <div className='tableIconsBlock'>
                                <img
                                    src={eye}
                                    alt="eye"
                                    onClick={() => setVisible('detailInfo', { type: 'plot', data: el })}
                                />
                            </div>
                        },
                    ]
                ))
            } else {
                return collapseData.technics.map(el => (
                    [
                        {
                            type: 'active_text',
                            content: <p onClick={() => navigate(`/market/technics/${el.id}`)}>{el.id}</p>
                        },
                        {
                            type: 'text',
                            content: el.technic_type?.name
                        },
                        {
                            type: 'text',
                            content: el.model
                        },
                        {
                            type: 'text',
                            content: el.gos_num
                        },
                        {
                            type: 'text',
                            content: el.PSM_serial
                        },
                        {
                            type: 'text',
                            content: el.PSM_number
                        },
                        {
                            type: 'actions',
                            content: <div className='tableIconsBlock'>
                                <img
                                    src={eye}
                                    alt="eye"
                                    onClick={() => setVisible('detailInfo', { type: 'technic', data: el })}
                                />
                            </div>
                        },
                    ]
                ))
            }
        }
    }

    const onActive = () => {
        setActive(!active)
        fetchDealById(deal.id)
            .then(res => {
                if (res && !collapseData) {
                    setCollapseData(res)
                }
            })
    }

    const onViews = async () => {
        const query = Object.fromEntries([...searchParams]);
        await fetchUpdateDealById(deal.id, { is_viewed: true })
        fetchMyDeals(
            query.page,
            50,
            createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
        )
    }

    return (
        <div className={active ? 'responseCollapseItemBlock active' : 'responseCollapseItemBlock'}>
            {contextHolder}
            <div className='responseCollapseItemBlock_header' onClick={onActive}>
                <div className='responseCollapseItemBlock_header__info'>
                    <p
                        className='activeText p14_400 p_mr16'
                        onClick={() => {
                            if (deal.reply_type === 'FARMER') {
                                if (deal.technics[0]?.id) navigate(`/market/technics/${deal.technics[0].id}`)
                            } else {
                                navigate(`/market/fields/${deal.square.id}`)
                            }
                        }}
                    >
                        {deal.reply_type === 'FARMER' ? `${deal.technics[0]?.technic_type.name || 'Техника'} #${deal.technics[0]?.id || ''}` : `Поле #${deal.square.id}`}
                    </p>
                    <p className='mainText p14_500 p_mr16'>{createFio(deal.owner)}</p>
                    <p className='mainText p14_400'>{formatDateTime(deal.created_at)}</p>
                </div>
                <img src={angle_down} alt='angle_down' />
            </div>
            {collapseData &&
                <div className='responseCollapseItemBlock_data'>
                    <p className='p12_400 mainText p_mb8'>Комментарий</p>
                    <p className='p14_400 mainText p_mb16'>{deal.description}</p>
                    <p className='p12_400 mainText p_mb8'>Дата проведения</p>
                    <p className='p14_400 mainText p_mb16'>{'с ' + deal.valid_from + ' по ' + deal.valid_to}</p>
                    <p className='p12_400 mainText p_mb8'>Тип работ</p>
                    <p className='p14_400 mainText p_mb16'>{collapseData.work_type.name}</p>
                    <p className='p12_400 mainText p_mb8'>Предлагаемая цена, ₽</p>
                    <p className='p14_400 mainText p_mb16'>{deal.amount} ₽</p>
                    <p className='p12_400 mainText p_mb8'>Условия оплаты</p>
                    <p className='p14_400 mainText p_mb16'>{deal.payment_desc}</p>
                    <p className='p12_400 mainText p_mb8'>Что предлагает</p>
                    <div
                        style={{
                            padding: '8px 24px',
                            borderRadius: '8px',
                            border: '1px solid #01963A',
                            background: '#FFFFFF',
                            textAlign: 'center',
                            marginBottom: 16
                        }}
                    >
                        <p className='p14_500'>{deal.reply_type === 'FARMER' ? 'Поле' : 'Техника'}</p>
                    </div>
                    <Table
                        header={deal.reply_type === 'FARMER' ? offersFieldsTable : offersCarsTable}
                        width={deal.reply_type === 'FARMER' ? offersFieldsTableWidth : offersCarsTableWidth}
                        data={createTableData()}
                    />
                    <div className='twoButton' style={{ marginTop: 16 }}>
                        <div>
                            <SimpleButton
                                title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={chat} alt="chat" style={{ marginRight: 8 }} />Начать чат</p>}
                                style={{
                                    color: '#FFF',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#01963A',
                                    fontWeight: 500
                                }}
                                onClick={() => navigate(`/user/messages?reciever_id=${collapseData.owner.id}`)}
                            />
                        </div>
                        <div className='twoButton_item'>
                            <SimpleButton
                                title={<p style={{ display: 'flex', alignItems: 'center' }}><img src={check} alt="check" style={{ marginRight: 8 }} />Начать сотрудничество</p>}
                                style={{
                                    color: '#FFF',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#01963A',
                                    fontWeight: 500
                                }}
                                onClick={() => confirmNotification(api,
                                    () => {
                                        setVisible('createContract', {
                                            item: {
                                                date_from: deal.valid_from,
                                                date_to: deal.valid_to,
                                                amount: deal.amount,
                                                id: deal.id
                                            },
                                            type: 'offer'
                                        })
                                        api.destroy()
                                    },
                                    'начало сотрудничества')}
                            />
                        </div>
                        {!deal.is_viewed &&
                            <div className='twoButton_item'>
                                <SimpleButton
                                    title={'Отложить в просмотренное'}
                                    style={{
                                        color: '#FFF',
                                        border: '1px solid #3949AB',
                                        borderRadius: '100px',
                                        backgroundColor: '#3949AB',
                                        fontWeight: 500
                                    }}
                                    onClick={onViews}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchDealById: (id) => dispatch(fetchDealById(id)),
        fetchUpdateDealById: (deal_id, data) => dispatch(fetchUpdateDealById(deal_id, data)),
        fetchMyDeals: (page, size, params) => dispatch(fetchMyDeals(page, size, params)),
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    }
}

export default connect(null, mapDispatvhToProps)(OfferCollaps);