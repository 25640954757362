import tractor from 'assets/images/landing/tractor.svg';
import field from 'assets/images/landing/field.svg';
import fieldMarket from 'assets/images/landing/fieldMarket.svg';
import technicMarket from 'assets/images/landing/technicMarket.svg';
import fermer from 'assets/images/landing/icons/fermer.svg';
import cloud from 'assets/images/landing/icons/cloud.svg';
import timer from 'assets/images/landing/icons/timer.svg';
import car from 'assets/images/landing/icons/car.svg';
import home from 'assets/images/landing/icons/home.svg';
import tractorIcon from 'assets/images/landing/icons/tractor.svg';
import location from 'assets/images/landing/icons/location.svg';

export const findOrderData = [
    {
        title: 'Найти заказ владельцу техники',
        desc: 'Вам интересно найти заказы, расположенные поблизости от вашей техники?',
        steps: [
            'Найдите заказы для выполнения между запланированными контрактами',
            'Быстро согласуйте с заказчиками',
            'Получите всю необходимую информацию о местности и приступите к работе'
        ],
        mainImg: tractor,
        buttonTitle: 'Найти заказ',
        buttonLink: '/market/fields',
        flexDirection: 'row'
    },
    {
        title: 'Найти исполнителя владельцу поля',
        desc: 'Вам интересно найти технику, расположенную поблизости от вашего поля?',
        steps: [
            'Следите за передвижением техники с помощью устройств мониторинга',
            'Быстро договоритесь с владельцем техники',
            'Познакомьтесь со всей документацией техники'
        ],
        mainImg: field,
        buttonTitle: 'Найти исполнителя',
        buttonLink: '/market/technics',
        flexDirection: 'row-reverse'
    }
]

export const advantagesData = [
    {
        title: 'Владельцу техники',
        list: [
            'Позволяет находить заказы, которые находятся рядом с техникой',
            'Находить  заказы между  уже заранее запланированными контрактами',
            'Быстро договориться с заказчиками',
            'Узнать всю необходимую информацию о поле'
        ],
        image: fieldMarket,
        flexDirection: 'row'
    },
    {
        title: 'Владельцу поля',
        list: [
            'Находить ближайшую к полю свободную технику',
            'Контролировать перемещение техники за счет устройств мониторинга техники принадлежащих владельцам техники',
            'Контролировать перемещение техники, в том числе уже с установленными устройствами мониторинга',
            'Ознакомиться со всеми документами исполнителя или владельца техники'
        ],
        image: technicMarket,
        flexDirection: 'row-reverse'
    }
]

export const problemsData = [
    {
        title: 'Для владельца поля',
        list: [
            {
                icon: fermer,
                title: 'Поиск альтернативного подрядчика',
                description: 'Действующий исполнитель отказался от работ или плохо работает и надо быстро найти другого исполнителя'
            },
            {
                icon: cloud,
                title: 'Срочный поиск подрядчика',
                description: 'Ожидаются дожди или другие плохие метео условия, которые могут привести к потери урожая и нужно в срочном порядке найти технику'
            },
        ]
    },
    {
        title: 'Для владельца техники',
        list: [
            {
                icon: timer,
                title: 'Минимализация простоя техники',
                description: 'Контракт закончился раньше чем ожидали и образовался простой между контрактами'
            },
            {
                icon: car,
                title: 'Оптимизация затрат на логистику',
                description: 'Очень тяжело и затратно перемещать технику'
            },
        ]
    }
]

export const miniAdvantagesData = [
    {
        icon: home,
        description: 'Маркетплейс специально для участников рынка сельскохозяйственных услуг'
    },
    {
        icon: tractorIcon,
        description: 'Быстрый поиск владельцев техники'
    },
    {
        icon: location,
        description: 'Видеть перемещение техники и заказчиков с локациями'
    },
]