import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Select } from 'antd';
import { SAVE_CREATE_TECHNIC_DATA, START_CREATE_APPLICATION_FETCH, STOP_CREATE_APPLICATION_FETCH } from 'store/actionTypes';
import { fetchAddPlotsToApplication, fetchAddTechnicToApplication, fetchCreateApplication } from 'store/actions/applications';
import { formatDate } from 'hooks/formatDate';
import { numberInputFormat } from 'hooks/numderInputFormat';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

const { Option } = Select;

function ApplicationDescription({
    form,
    nextStep,
    create_application,
    work_types,
    crop_types,
    fetchCreateApplication,
    fetchAddTechnicToApplication,
    fetchAddPlotsToApplication,
    START_CREATE_APPLICATION_FETCH,
    STOP_CREATE_APPLICATION_FETCH
}) {
    const [fields, setFields] = useState([
        {
            name: 'description',
            value: create_application.description.description,
        },
        {
            name: 'work_type_id',
            value: create_application.description.work_type_id,
        },
        {
            name: 'payment_desc',
            value: create_application.description.payment_desc,
        },
        {
            name: 'amount',
            value: create_application.description.amount,
        },
    ]);

    const onFinish = async ({ description, work_type_id, payment_desc, amount, crop_type_id }) => {
        const obj = {
            bid_type: create_application.bid_type,
            date_from: formatDate(create_application.date[0].startDate),
            date_to: formatDate(create_application.date[0].endDate),
            description,
            payment_desc,
            amount: parseInt(amount)
        }
        if (create_application.bid_type === 'FARMER') {
            obj.work_type_id = work_type_id
            obj.square_id = create_application.square_data
            obj.crop_type_id = crop_type_id
        }
        START_CREATE_APPLICATION_FETCH()
        await fetchCreateApplication(obj)
            .then(async (res) => {
                if (res) {
                    if (create_application.bid_type === 'FARMER') {
                        await fetchAddPlotsToApplication(res, create_application.plots)
                    } else {
                        await fetchAddTechnicToApplication(res, [create_application.technic_data])
                    }
                    STOP_CREATE_APPLICATION_FETCH()
                    nextStep()
                }
            })
    };

    return (
        <InfoBlock>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label={'Описание заявки'}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите описание заявки' />
                </Form.Item>
                {create_application.bid_type === 'FARMER' &&
                    <>
                        <Form.Item
                            label={'Тип работ'}
                            name="work_type_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите тип работ">
                                {work_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={'Выращиваемые культуры'}
                            name="crop_type_id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Это обязательное поле',
                                },
                            ]}
                        >
                            <Select placeholder="Выберите тип выращиваемой культуры">
                                {crop_types.map(el => (
                                    <Option value={el.id} key={el.id}>{el.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                }
                <Form.Item
                    label={'Предварительные условия оплаты'}
                    name="payment_desc"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите предварительные условия оплаты' />
                </Form.Item>
                <Form.Item
                    label={create_application.bid_type === 'FARMER' ? 'Стоимость обработки, ₽' : 'Предварительная стоимость для найма, ₽'}
                    name="amount"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите стоимость' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        create_application_description: state.createApplication.description,
        create_application: state.createApplication,
        work_types: state.workTypes.work_types,
        crop_types: state.cropTypes.crop_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
        fetchCreateApplication: (data) => dispatch(fetchCreateApplication(data)),
        fetchAddTechnicToApplication: (bid_id, data) => dispatch(fetchAddTechnicToApplication(bid_id, data)),
        fetchAddPlotsToApplication: (bid_id, data) => dispatch(fetchAddPlotsToApplication(bid_id, data)),
        START_CREATE_APPLICATION_FETCH: () => dispatch({ type: START_CREATE_APPLICATION_FETCH }),
        STOP_CREATE_APPLICATION_FETCH: () => dispatch({ type: STOP_CREATE_APPLICATION_FETCH })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ApplicationDescription);