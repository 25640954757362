import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchChatRoomById, fetchCheckUserChatById, fetchUserChats, fetchUserFolders } from 'store/actions/chats/chats';
import { CLEAR_ALL_USER_CHATS, CLEAR_ALL_USER_FOLDERS, CLEAR_CHAT_ROOM_BY_ID_DATA, CLEAR_CHECK_CHAT_BY_USER_ID, CLEAR_PUBLIC_USER_DATA } from 'store/actionTypes';
import { fetchPublicUserById } from 'store/actions/public/publicUser';
import { chatsSwitch } from 'data/lists';
import { createFio } from 'hooks/createFio';
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Switch from 'components/shared/switch/Switch';
import Search from 'components/shared/search/Search';
import MessageCard from 'components/shared/cards/messageCard/MessageCard';
import MessageGroupCard from 'components/shared/cards/messageGroupCard/MessageGroupCard';
import ChatInfo from './chatInfo/ChatInfo';

import './style.css';
import './media.css';

function Chats({
    userChats,
    userFolders,
    user_data_id,
    unreaded_messages,
    unreaded_messages_onFolder,
    checkChats,
    fetchUserChats,
    fetchUserFolders,
    fetchChatRoomById,
    fetchCheckUserChatById,
    fetchPublicUserById,
    CLEAR_CHAT_ROOM_BY_ID_DATA,
    CLEAR_ALL_USER_CHATS,
    CLEAR_CHECK_CHAT_BY_USER_ID,
    CLEAR_PUBLIC_USER_DATA,
    CLEAR_ALL_USER_FOLDERS,
}) {
    const [activeSwitch, setActiveSwitch] = useState(null)
    const [activeFolder, setActiveFolder] = useState(null)
    const [search, setSearch] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeChat, setActiveChat] = useState(null)
    const [createChat, setCreateChat] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.search) {
            const obj = JSON.parse(decodeURIComponent(((query.search))))
            if (obj.tag) {
                setActiveSwitch(obj.tag)
            } else {
                setActiveSwitch('all')
            }
            setActiveFolder(obj.folder_id)
            setSearch(obj.username)
        } else {
            setActiveSwitch('all')
        }
        fetchUserChats(
            page,
            50,
            createQuery(Object.fromEntries([...searchParams]).search)
        )
        fetchUserFolders(1, 50, null)
    }, [
        Object.fromEntries([...searchParams]).search,
    ])

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.room_id !== undefined) {
            setActiveChat(query.room_id);
            fetchChatRoomById(query.room_id)
        } else {
            CLEAR_CHAT_ROOM_BY_ID_DATA()
            setActiveChat(null);
        }
    }, [
        Object.fromEntries([...searchParams]).room_id,
    ])

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.reciever_id !== undefined) {
            fetchCheckUserChatById(1, 10, createQuery(getQueryStringFromObject(JSON.stringify({ 'user_id': query.reciever_id }))))
        } else {
            setCreateChat(false)
        }
    }, [
        Object.fromEntries([...searchParams]).reciever_id,
    ])

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (checkChats) {
            if (checkChats.length > 0) {
                delete query.reciever_id
                query.room_id = checkChats[0].id
                setSearchParams(query)
                setActiveChat(checkChats[0].id)
                setCreateChat(false)
            } else {
                fetchPublicUserById(query.reciever_id)
                setCreateChat(true)
            }
        }
    }, [checkChats])

    useEffect(() => {
        return () => {
            CLEAR_CHAT_ROOM_BY_ID_DATA()
            CLEAR_ALL_USER_CHATS()
            CLEAR_CHECK_CHAT_BY_USER_ID()
            CLEAR_PUBLIC_USER_DATA()
            CLEAR_ALL_USER_FOLDERS()
        }
    }, [])

    const onSwitch = (param) => {
        const query = Object.fromEntries([...searchParams]);
        if (param !== 'all') {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.tag = param
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "tag": param }))
            }
        } else {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                delete obj.tag
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            }
        }
        setSearchParams(query)
        setActiveSwitch(param)
    }

    const onSearch = (e) => {
        if (!/[,.*^&?%$#!=+-]/.test(e)) {
            const query = Object.fromEntries([...searchParams]);
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.username = e
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "username": e }))
            }
            setSearchParams(query)
            setSearch(e)
        }
    }

    const onClickFolder = (folder_id) => {
        const query = Object.fromEntries([...searchParams]);
        if (query.search) {
            const obj = JSON.parse(decodeURIComponent(((query.search))))
            obj.folder_id = folder_id
            query.search = getQueryStringFromObject(JSON.stringify(obj))
        } else {
            query.search = getQueryStringFromObject(JSON.stringify({ "folder_id": folder_id }))
        }
        setSearchParams(query)
        setActiveFolder(folder_id)
    }

    const onCloseFolder = (event) => {
        event.stopPropagation();
        const query = Object.fromEntries([...searchParams]);
        const obj = JSON.parse(decodeURIComponent(((query.search))))
        delete obj.folder_id
        query.search = getQueryStringFromObject(JSON.stringify(obj))
        setSearchParams(query)
        setActiveFolder(null)
    }

    const onClickMessageCard = (room_id) => {
        const query = Object.fromEntries([...searchParams]);
        query.room_id = room_id
        setSearchParams(query)
        setActiveChat(room_id)
    }

    const fetchMoreData = () => {
        setPage(page + 1)
    }

    return (
        <div className='allUsersBlock chatsBlock'>
            <div className='chatsBlock_header'>
                <TextHeading text={'Чаты'} />
                <div className='chatsBlock_header__switch'>
                    <Switch
                        items={chatsSwitch}
                        activeItem={activeSwitch}
                        onSwitch={onSwitch}
                        className="switch miniSwitch"
                    />
                </div>
            </div>
            <div className='chatsBlock_main'>
                <div className={`chatsBlock_main__items ${activeChat ? 'activeChat' : ''}`}>
                    <Search
                        value={search}
                        setValue={(e) => onSearch(e)}
                        style={{ width: '100%' }}
                    />
                    {userFolders.length > 0 &&
                        <div className='chatsBlock_main__items__groups'>
                            {userFolders.map(folder => (
                                <MessageGroupCard
                                    key={`folder_${folder.id}`}
                                    name={folder.name}
                                    new_messages={unreaded_messages_onFolder.map(unreaded_messages => {
                                        for (var i in unreaded_messages) {
                                            if (i === folder.id) return unreaded_messages[i]
                                        }
                                    })}
                                    onClickFolder={() => onClickFolder(folder.id)}
                                    active={activeFolder === folder.id ? true : false}
                                    onCloseFolder={onCloseFolder}
                                />
                            ))}
                        </div>
                    }
                    {userChats.length > 0
                        ?
                        <div className='chatsBlock_main__items__masseges' id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={userChats.length}
                                next={fetchMoreData}
                                hasMore={true}
                                scrollableTarget="scrollableDiv"

                            >
                                {userChats.map(el => (
                                    <MessageCard
                                        key={`messageCard_${el.id}`}
                                        user={el.members.map(el => {
                                            if (el.id !== user_data_id) {
                                                return createFio(el)
                                            }
                                        })}
                                        time={new Date(el.last_message_time).getHours() + ':' + new Date(el.last_message_time).getMinutes()}
                                        lastMessage={el.last_message_body}
                                        new_messages={unreaded_messages.map(unreaded_messages => {
                                            for (var i in unreaded_messages) {
                                                if (i === el.id) return unreaded_messages[i]
                                            }
                                        })}
                                        onClick={() => onClickMessageCard(el.id)}
                                        active={el.id === activeChat ? true : false}
                                    />
                                ))}
                            </InfiniteScroll>
                        </div>
                        : <p className='p_mt24 p14_500' style={{ textAlign: 'center' }}>Нет чатов</p>
                    }
                </div>
                <div className={`chatsBlock_main__correspondence ${activeChat ? 'activeChat' : ''}`}>
                    <div className='chatsBlock_main__correspondence__cleanBlock'>
                        {activeChat
                            ? <ChatInfo mode='reade'  />
                            :
                            (createChat
                                ? <ChatInfo mode='createRoom' />
                                : <div className='chatsBlock_main__correspondence__cleanBlock__text subtext p12_400'>Выберите чат</div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        userChats: state.allUserChats.userChats,
        userFolders: state.allUserFolders.userFolders,
        unreaded_messages: state.allUserChats.unreaded_messages,
        unreaded_messages_onFolder: state.allUserFolders.unreaded_messages,
        user_data_id: state.user.user_data.id,
        checkChats: state.createChat.checkChats
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUserChats: (page, size, params) => dispatch(fetchUserChats(page, size, params)),
        fetchUserFolders: (page, size, params) => dispatch(fetchUserFolders(page, size, params)),
        fetchCheckUserChatById: (page, size, params) => dispatch(fetchCheckUserChatById(page, size, params)),
        fetchChatRoomById: (room_id) => dispatch(fetchChatRoomById(room_id)),
        fetchPublicUserById: (id) => dispatch(fetchPublicUserById(id)),
        CLEAR_PUBLIC_USER_DATA: () => dispatch({ type: CLEAR_PUBLIC_USER_DATA }),
        CLEAR_CHAT_ROOM_BY_ID_DATA: () => dispatch({ type: CLEAR_CHAT_ROOM_BY_ID_DATA }),
        CLEAR_ALL_USER_CHATS: () => dispatch({ type: CLEAR_ALL_USER_CHATS }),
        CLEAR_CHECK_CHAT_BY_USER_ID: () => dispatch({ type: CLEAR_CHECK_CHAT_BY_USER_ID }),
        CLEAR_ALL_USER_FOLDERS: () => dispatch({ type: CLEAR_ALL_USER_FOLDERS }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Chats);