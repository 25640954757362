import { useState } from 'react';

import angle_down from 'assets/images/icons/angle-down.svg';

import './style.css';

function CollapseItem({ item }) {
    const [active, setActive] = useState(false)

    return (
        <div className={active ? 'collapseItemBlock active' : 'collapseItemBlock'} onClick={() => setActive(!active)}>
            <div className='collapseItemBlock_header'>
                <p className='mainText p14_400'>{item.title}</p>
                <img src={angle_down} alt='angle_down' />
            </div>
            <div className='collapseItemBlock_text subtext p14_400'>
                {item.text}
            </div>
        </div>
    );
}

export default CollapseItem;