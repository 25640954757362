import './style.css';

function AuthenticationBlock({ children, ...props }) {
    return (
        <div
            className='authenticationBlock'
            {...props}
        >
            {children}
        </div>
    );
}

export default AuthenticationBlock;