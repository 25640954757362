import { combineReducers } from 'redux';

/*general data */
import userEnv from './reducers/userEnv';
import signup from './reducers/signup';
import services from './reducers/services';
import modal from './reducers/modal';
import workTypes from './reducers/workTypes';
import squareTypes from './reducers/squareTypes';
import technicTypes from './reducers/technicTypes';
import cropTypes from './reducers/cropTypes';
import sowingTypes from './reducers/sowingTypes';
import roles from './reducers/roles';
import technicParams from './reducers/technicParams';

/*user data*/
import user from './reducers/user/user';
import likes from './reducers/user/likes';
import userTechnics from './reducers/user/allTechnics';
import userSquares from './reducers/user/allSquares';
import userApplications from './reducers/user/allApplications';
import userDeals from './reducers/user/allDeals';
import userReplies from './reducers/user/allReplies';
import userConflicts from './reducers/user/allConflicts';
import userContracts from './reducers/user/allContracts';
import userNotifications from './reducers/user/allNotifications';
import applicationById from './reducers/user/applicationById';
import tehnicById from './reducers/user/technicsById';
import squareById from './reducers/user/squareById';
import plotById from './reducers/user/plotById';
import conflictById from './reducers/user/conflictById';
import contractById from './reducers/user/contractById';
import notificationById from './reducers/user/notificationById';
import headerInfo from './reducers/user/headerInfo';

/*admin data */
import allUsers from './reducers/admin/allUsers';
import allTechnics from './reducers/admin/allTechnics';
import allSquares from './reducers/admin/allSquares';
import allComplaints from './reducers/admin/allComplaints';
import allContracts from './reducers/admin/allContracts';
import allConflicts from './reducers/admin/allConflicts';
import allDeletedUsers from './reducers/admin/allDeletedUsers';
import userById from './reducers/admin/userById';
import adminSquareById from './reducers/admin/squareById';
import adminTehnicById from './reducers/admin/technicById';
import adminPlotById from './reducers/admin/plotById';
import adminComplaintById from './reducers/admin/complaintById';
import adminContractById from './reducers/admin/contractById';
import adminConflictById from './reducers/admin/conflictById';

/*market data */
import marketAllTechnics from './reducers/market/allTechnics';
import marketTechnicById from './reducers/market/technicById';
import marketAllSquares from './reducers/market/allSquares';
import marketSquaresById from './reducers/market/squareById';

/*create form */
import createTechic from './reducers/createTechnic';
import createSquare from './reducers/createSquare';
import createApplication from './reducers/createApplication';

/*public */
import publicUser from './reducers/public/publicUser';

/*chats */
import allUserChats from './reducers/chats/allUserChats';
import chatRoomById from './reducers/chats/chatRoomById';
import createChat from './reducers/chats/createChat';
import allUserFolders from './reducers/chats/allFolders';

export default combineReducers({
    /*general data */
    userEnv,
    signup,
    services,
    modal,
    workTypes,
    squareTypes,
    technicTypes,
    sowingTypes,
    cropTypes,
    roles,
    technicParams,

    /*user data*/
    user,
    likes,
    userTechnics,
    userSquares,
    userApplications,
    userReplies,
    userDeals,
    userConflicts,
    userContracts,
    userNotifications,
    applicationById,
    tehnicById,
    squareById,
    plotById,
    conflictById,
    contractById,
    notificationById,
    headerInfo,

    /*admin data */
    allUsers,
    allTechnics,
    allSquares,
    allComplaints,
    allContracts,
    allConflicts,
    allDeletedUsers,
    userById,
    adminTehnicById,
    adminSquareById,
    adminPlotById,
    adminComplaintById,
    adminContractById,
    adminConflictById,

    /*create form */
    createTechic,
    createSquare,
    createApplication,

    /*market data */
    marketAllTechnics,
    marketTechnicById,
    marketAllSquares,
    marketSquaresById,

    /*public */
    publicUser,

    /*chats */
    allUserChats,
    chatRoomById,
    createChat,
    allUserFolders,
})