import { connect } from 'react-redux';
import { publicUserReviewsTable } from 'data/adminTableHeader';
import { publicUserReviewsTableWidth } from 'data/adminTableWidth';
import { createFio } from 'hooks/createFio';
import Table from 'components/ui/table/Table';

import goldStar from 'assets/images/icons/gold-star.svg';
import greyStar from 'assets/images/icons/grey-star.svg';

function Reviews({ public_user_by_id }) {

    const createReviews = (grade) => {
        const stars = []
        for (let i = 1; i < 6; i++) {
            let star = null
            if (i <= grade) {
                star = goldStar
            } else {
                star = greyStar
            }
            stars.push(<img src={star} alt='star' style={{ cursor: 'pointer' }} />)
        }
        return stars
    }

    const createTableData = () => {
        return public_user_by_id.reviews.map(el => (
            [
                {
                    type: 'active_text',
                    content: <span>{createFio(el.owner)}</span>
                },
                {
                    type: 'text',
                    content: el.comment || '-'
                },
                {
                    type: 'actions',
                    content: createReviews(el.grade)
                },
            ]
        ))
    }

    return (
        <Table
            header={publicUserReviewsTable}
            width={publicUserReviewsTableWidth}
            data={createTableData()}
        />
    );
}

function mapStateToProps(state) {
    return {
        public_user_by_id: state.publicUser.public_user_by_id
    }
}

export default connect(mapStateToProps, null)(Reviews);