import { ApiUrl, instance } from './apiEnv';

export const api_technics = {

    async GetMyTechnics(page, size, params) {
        return await instance.get(`${ApiUrl}technics/my?page=${page}&size=${size}&${params}`)
    },

    async GetTechnicsById(id) {
        return await instance.get(`${ApiUrl}technics/${id}`)
    },

    async PostCreateTechic(data) {
        return await instance.post(`${ApiUrl}technics`, data)
    },

    async UpdateTechic(data, id) {
        return await instance.put(`${ApiUrl}technics/${id}`, data)
    },

    async UploadTechicDocumentById(id, data) {
        return await instance.put(`${ApiUrl}technics/${id}/document`, data)
    },

    async DeleteTechicDocumentById(id, doc_id) {
        return await instance.delete(`${ApiUrl}technics/${id}/document/${doc_id}`)
    },

    async UploadTechicImageById(id, data) {
        return await instance.put(`${ApiUrl}technics/${id}/upload_image`, data)
    },

    async DeleteTechicImageById(id, img_id) {
        return await instance.delete(`${ApiUrl}technics/${id}/image/${img_id}`)
    },

    async DeleteTechnicById(id) {
        return await instance.delete(`${ApiUrl}technics/${id}`)
    },

    async GetUniqueByPsm(psm_serial, psm_number) {
        return await instance.get(`${ApiUrl}technics/unique_by_psm?psm_serial=${psm_serial}&psm_number=${psm_number}`)
    },

    async PostCurrentLocationTechic(id) {
        return await instance.post(`${ApiUrl}technics/${id}/online_data`)
    },

    async PostLikes(id) {
        return await instance.post(`${ApiUrl}technics/${id}/toggle_like`)
    },

    async GetTechnicParams(param, value) {
        return await instance.get(`${ApiUrl}technics/params?param=${param}&value=${value}`)
    },

    async GetTechicMovement(contract_id, id, start_date, end_date) {
        return await instance.get(`${ApiUrl}technics/${id}/movement?contract_id=${contract_id}&start_date=${start_date}&end_date=${end_date}`)
    },

    async PostTechicStatistics(technic_id, data) {
        return await instance.post(`${ApiUrl}technics/${technic_id}/statistics`, data)
    },
}