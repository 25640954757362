import { connect } from 'react-redux'
import { terminationContractBreadcrumb } from 'data/brebcrumb';
import { useParams } from 'react-router-dom';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import EvaluationContractForm from 'components/components/forms/contract/evaluationContractForm/EvaluationContractForm';

function ContractEvaluation({ }) {
    const { id } = useParams()

    return (
        <div className='allUsersBlock'>
            <Breadcrumb list={terminationContractBreadcrumb(id)} activeLink={'Отзыв о работе'} />
            <TextHeading text={'Отзыв о работе'} />
            <EvaluationContractForm />
        </div>
    );
}

export default connect(null, null)(ContractEvaluation);