import {
    FETCH_ALL_USER_FOLDERS_START,
    FETCH_ALL_USER_FOLDERS_CONCAT,
    CLEAR_ALL_USER_FOLDERS
} from '../../actionTypes'

const initialState = {
    userFolders: [],
    unreaded_messages: [],
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_USER_FOLDERS_START:
            return {
                userFolders: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                unreaded_messages: action.data.unreaded_messages,
                loading: false
            }
        case FETCH_ALL_USER_FOLDERS_CONCAT: {
            return {
                userFolders: state.userFolders.concat(action.data.data.items),
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                unreaded_messages: state.unreaded_messages.concat(action.data.unreaded_messages),
                loading: false
            }
        }
        case CLEAR_ALL_USER_FOLDERS:
            return {
                userFolders: [],
                unreaded_messages: [],
                pages: 0,
                page: 0,
                size: 0,
                loading: false
            }
        default: return state
    }
}