import { connect } from 'react-redux'
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchCreateComplaint } from 'store/actions/complaints';
import FileComplaintForm from 'components/components/forms/modal/fileComplaint/FileComplaintForm';

function FileComplaint({ data, setVisible, fetchCreateComplaint }) {

    const reject = (values) => {
        values.accused_id = data.accused_id
        fetchCreateComplaint(values)
        setVisible()
    }

    return (
        <>
            <p className="errorColor p14_500 p_mb16">Подать жалобу</p>
            <FileComplaintForm
                reject={(values) => reject(values)}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchCreateComplaint: (data) => dispatch(fetchCreateComplaint(data))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(FileComplaint);