import { connect } from 'react-redux'
import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';

import pin from 'assets/images/pins/combine.svg'

function CurrentLocation({ default_address, current_location }) {

    return (
        <div className='locationBlock'>
            <div className='locationBlock_map'>
                <Map
                    defaultState={{
                        center: current_location ? [current_location.Navigation.Location.Latitude, current_location.Navigation.Location.Longitude] : (default_address ? [default_address.latitude, default_address.longitude] : [55.751574, 37.573856]),
                        zoom: 10,
                        type: 'yandex#hybrid'
                    }}
                    width='100%'
                    height='100%'
                >
                    <FullscreenControl />
                    <SearchControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    <TypeSelector options={{ float: 'right' }} />
                    {current_location
                        ?
                        <Placemark
                            geometry={[current_location.Navigation.Location.Latitude, current_location.Navigation.Location.Longitude]}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: pin,
                                iconImageSize: [70, 70],
                            }}
                        />
                        : (
                            default_address &&
                            <Placemark
                                geometry={[default_address.latitude, default_address.longitude]}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: pin,
                                    iconImageSize: [70, 70],
                                }}
                            />
                        )
                    }
                </Map>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        default_address: state.tehnicById.tehnics_by_id_data.default_address,
        current_location: state.tehnicById.current_location
    }
}

export default connect(mapStateToProps, null)(CurrentLocation);