import { useNavigate } from 'react-router-dom';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import './style.css';
import './media.css';

const SignUp = () => {
  const navigate = useNavigate()
  return (
    <div className='signUpBlock' style={{marginBottom: 60}}>
      <div>
        <p className='p24_600 whiteText'>Зарегистрируйтесь прямо сейчас!</p>
        <p className='p16_400 whiteText'>И пользуйтесь всеми преимуществами нашего сервиса!</p>
      </div>
      <SimpleButton 
        title={'Зарегистрироваться'}
        className="whiteButton2"
        onClick={() => navigate(`/signup/first-stage`)}
      />
    </div>
  )
}

export default SignUp