import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchAllSquares, fetchDeleteSquare } from 'store/actions/admin/squares';
import { allFieldsTable } from 'data/adminTableHeader';
import { allFieldsTableWidth } from 'data/adminTableWidth';
import { allSquareFilter } from 'data/adminLists';
import { verificationStatusesStyle } from 'data/lists';
import { warningNotification } from 'hooks/notifications';
import { createQuery } from 'hooks/createQuery';
import Table from 'components/ui/table/Table';
import Filter from 'components/shared/filter/Filter';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Pagination from 'components/shared/pagination/Pagination';

import deleteIcon from 'assets/images/icons/delete.svg';
import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';

function AllFieldsAdmin({ fetchAllSquares, square_types, crop_types, sowing_types, fetchDeleteSquare, allSquares, pages }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllSquares(query.page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return allSquares.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/fields/${el.id}`)}>{el.farmer || '-'}</p>
                },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
                // {
                //     type: 'text',
                //     content: 'В РАБОТЕ'
                // },
                {
                    type: 'text',
                    content: el.square_type.name
                },
                {
                    type: 'text',
                    content: el.size
                },
                {
                    type: 'text',
                    content: el.number
                },
                {
                    type: 'text',
                    content: el.sowing_type ? el.sowing_type.name : '-'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img src={edit_green_pen} alt="edit_green_pen" onClick={() => navigate(`/admin/fields/${el.id}`, { state: { edit: true } })} />
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchDeleteSquare(el.id)
                                await fetchAllSquares(Object.fromEntries([...searchParams]).page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
                                api.destroy()
                            }, 'удаление техники.')} />
                    </div>
                },
            ]
        ))
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                    <TextHeading text={'Поля'} />
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                    <Filter
                        className={'filter_marginRight'}
                        filterList={allSquareFilter(square_types, crop_types, sowing_types)}
                    />
                </div>
            </div>
            {contextHolder}
            <Table
                header={allFieldsTable}
                width={allFieldsTableWidth}
                data={createTableData()}
            />
            {allSquares.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        allSquares: state.allSquares.squares,
        pages: state.allSquares.pages,
        square_types: state.squareTypes.square_types,
        crop_types: state.cropTypes.crop_types,
        sowing_types: state.sowingTypes.sowing_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchAllSquares: (page, size, params) => dispatch(fetchAllSquares(page, size, params)),
        fetchDeleteSquare: (id) => dispatch(fetchDeleteSquare(id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllFieldsAdmin);