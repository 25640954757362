import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { fetchSignUp } from 'store/actions/signup';
import { checkPassword } from 'hooks/checkPassword';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function PhoneSignup({ fetchSignup }) {
    const [errorStatusFirst, setErrorStatusFirst] = useState({})
    const [errorStatusSecond, setErrorStatusSecond] = useState({})
    const [fields, setFields] = useState([
        {
            name: 'phone',
            value: '',
        },
    ]);
    const navigate = useNavigate()

    const onFinish = ({ password, replay_password, phone }) => {
        if (checkPassword(password)) {
            if (replay_password === password) {
                fetchSignup({
                    phone: '7' + phone,
                    password
                }).then(res => {
                    if (res) {
                        navigate('/signup/second-stage')
                    }
                })
            } else {
                setErrorStatusFirst({
                    validateStatus: "error"
                })
                setErrorStatusSecond({
                    validateStatus: "error",
                    help: "Пароли не совпадают"
                })
            }
        } else {
            setErrorStatusFirst({
                validateStatus: "error",
                help: "Некорректный пароль. Пароль должен состоять минимум из 8 символов и содержать буквы верхнего и нижнего регистра, цифры и специальные символы."
            })
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
                setErrorStatusFirst({})
                setErrorStatusSecond({})
            }}
        >
            <Form.Item
                label={'Номер телефона'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" />
            </Form.Item>
            <Form.Item
                label={'Пароль'}
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusFirst}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item
                label={'Повторите пароль'}
                name="replay_password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusSecond}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Зарегистрироваться'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchSignup: (data) => dispatch(fetchSignUp(data))
    }
}

export default connect(null, mapDispatvhToProps)(PhoneSignup);