import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchAllUser } from 'store/actions/admin/user';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { allUsersTable } from 'data/adminTableHeader';
import { allUsersTableWidth } from 'data/adminTableWidth';
import { allUsersFilter, allUsersSwitch } from 'data/adminLists';
import { verificationStatusesStyle } from 'data/lists';
import { createQuery, getQueryStringFromObject } from 'hooks/createQuery';
import { createFio } from 'hooks/createFio';
import { warningNotification } from 'hooks/notifications';
import Search from 'components/shared/search/Search';
import Filter from 'components/shared/filter/Filter';
import Pagination from 'components/shared/pagination/Pagination';
import Switch from 'components/shared/switch/Switch';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';

import './style.css';
import './media.css';

function AllUsers({ fetchAllUser, allUsers, pages, setVisible }) {
    const [api, contextHolder] = notification.useNotification();
    const [activeSwitch, setActiveSwitch] = useState('')
    const [search, setSearch] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllUser(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                if(obj.role){
                    setActiveSwitch('admins')
                } else {
                    setActiveSwitch('allUsers')
                }
                setSearch(obj.full_search)
            } else {
                setSearch('')
            }
        } else {
            query.page = 1
            setSearchParams(query)
            setSearch('')
            setActiveSwitch('allUsers')
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return allUsers.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/admin/users/${el.id}`)}>{createFio(el)}</p>
                },
                {
                    type: 'text',
                    content: el.status ? <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div> : '-'
                },
                {
                    type: 'text',
                    content: el.company ? el.company.name : '-'
                },
                {
                    type: 'text',
                    content: el.email
                },
                {
                    type: 'text',
                    content: el.phone
                },
                {
                    type: 'text',
                    content: el.company ? el.company.inn : '-'
                },
                {
                    type: 'text',
                    content: el.jur_type === "PHYSICAL" ? 'Физическое' : 'Юридическое'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, () => {
                                setVisible({user_id: el.id})
                                api.destroy()
                            }, 'удаление пользователя.')}
                        />
                    </div>
                },
            ]
        ))
    }

    const onSearch = (e) => {
        if (!/[,.*^&?%$#!=+-]/.test(e)) {
            const query = Object.fromEntries([...searchParams]);
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.full_search = e
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "full_search": e }))
            }
            setSearchParams(query)
            setSearch(e)
        }
    }

    const onSwitch = (role) => {
        const query = Object.fromEntries([...searchParams]);
        if (role === 'admins') {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.role = ['admin', 'manager']
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "role": ['admin', 'manager'] }))
            }
        } else {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                delete obj.role
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            }
        }
        setSearchParams(query)
        setActiveSwitch(role)
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                    <Switch
                        items={allUsersSwitch}
                        activeItem={activeSwitch}
                        onSwitch={onSwitch}
                        className="switch miniSwitch"
                    />
                    <Search
                        className={'search_marginLeft'}
                        value={search}
                        setValue={(e) => onSearch(e)}
                    />
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                    <Filter
                        className={'filter_marginRight'}
                        filterList={allUsersFilter}
                        blockFilterList={['full_search', 'role']}
                    />
                    <SimpleButton
                        title={'Создать пользователя'}
                        className="greenButton"
                        onClick={() => navigate(`/admin/users/create`)}
                    />
                </div>
            </div>
            {contextHolder}
            <Table
                header={allUsersTable}
                width={allUsersTableWidth}
                data={createTableData()}
            />
            {allUsers.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        allUsers: state.allUsers.users,
        pages: state.allUsers.pages
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'deleteProfileOnUser', data }),
        fetchAllUser: (page, size, params) => dispatch(fetchAllUser(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllUsers);