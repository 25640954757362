export const createBusyPeriods = (data_busy_periods) => {
    const range = []
    const obj = {}
    const busy_periods = data_busy_periods.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.date === value.date
        ))
    ).sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    })

    busy_periods.forEach((el, index) => {
        if (index === 0) {
            obj.startDate = new Date(el.date)
            obj.endDate = new Date(el.date)
        } else if (index === busy_periods.length - 1) {
            obj.endDate = new Date(el.date)
            obj.key = range.length + 1
            range.push(obj)
        } else {
            if (Date.parse(el.date) - Date.parse(busy_periods[index - 1].date) !== 86400000) {
                obj.endDate = new Date(busy_periods[index - 1].date)
                obj.key = range.length + 1
                range.push({ ...obj })
                obj.startDate = new Date(el.date)
            }
        }
    })

    return range
}