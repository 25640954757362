import { api_squares } from 'api/admin/squares';
import {
    FETCH_ALL_SQUARES_START,
    FETCH_ALL_SQUARES_SUCCESS,
    ADMIN_FETCH_SQUARE_BY_ID_DATA_START,
    ADMIN_FETCH_SQUARE_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'
import { fetchReloadCropType } from './seasons';

export function fetchAllSquares(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_SQUARES_START))
        try {
            const squares = await api_squares.GetAllSquares(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_SQUARES_SUCCESS, {
                data: squares.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchSquareByID(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_SQUARE_BY_ID_DATA_START))
        try {
            const seasons = []
            let squareSeasonCopy
            const square_data = await api_squares.GetSquareById(id)
            squareSeasonCopy = JSON.parse(JSON.stringify(square_data.data.data.seasons))
            square_data.data.data.seasons.forEach(season => {
                season.plots.forEach(plot => {
                    seasons.push({
                        season_id: season.id,
                        plot_id: plot.id
                    })
                })
            })
            await dispatch(fetchReloadCropType(seasons))
                .then(res => {
                    res.forEach(el => {
                        squareSeasonCopy.find(season => season.id === el.season_id).plots.find(plot => plot.id === el.plot_id).crop_type_id = el.crop_type
                    })
                })
            dispatch(fetchSuccess(ADMIN_FETCH_SQUARE_BY_ID_DATA_SUCCESS, {
                square_by_id_data: square_data.data.data,
                seasons: squareSeasonCopy
            }))
        } catch (e) {
        }
    }
}

export function fetchUpdateSquare(id, data) {
    return async dispatch => {
        try {
            await api_squares.UpdateSquare(data, id)
            await dispatch(fetchSquareByID(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteSquare(id) {
    return async dispatch => {
        try {
            await api_squares.DeleteSquareById(id)
        } catch (e) {
        }
    }
}

export function fetchUploadSquareImage(id, data) {
    return async dispatch => {
        try {
            await api_squares.UploadSquareImageById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteSquareImage(id, img_id) {
    return async dispatch => {
        try {
            await api_squares.DeleteSquareImageById(id, img_id)
        } catch (e) {
        }
    }
}

export function fetchUploadSquareDocs(id, data) {
    return async dispatch => {
        try {
            await api_squares.UploadSquareDocsById(id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteSquareDoc(id, doc_id) {
    return async dispatch => {
        try {
            await api_squares.DeleteSquareDocumentById(id, doc_id)
            dispatch(fetchSquareByID(id))
        } catch (e) {
        }
    }
}

export function fetchConfirmSquareVerificstion(square_id, verification_id) {
    return async dispatch => {
        try {
            await api_squares.ConfirmSquareVerificstion(square_id, verification_id)
            dispatch(fetchSquareByID(square_id))
        } catch (e) {
        }
    }
}

export function fetchRejectSquareVerificstion(square_id, verification_id) {
    return async dispatch => {
        try {
            await api_squares.RejectSquareVerificstion(square_id, verification_id)
            dispatch(fetchSquareByID(square_id))
        } catch (e) {
        }
    }
}