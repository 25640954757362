import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchMyLikes } from 'store/actions/likes';
import { fetchLikeTechnics } from 'store/actions/techics';
import { allCarsTable } from 'data/tableHeader';
import { allCarsTableWidth } from 'data/tableWidth';
import { carStatuses } from 'data/lists';
import { warningNotification } from 'hooks/notifications';
import { createQuery } from 'hooks/createQuery';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';

import deleteIcon from 'assets/images/icons/delete.svg';

function Favorites({ likes, pages, fetchMyLikes, fetchLikeTechnics }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyLikes(query.page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return likes.map(el => (
            [
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/market/technics/${el.id}`)}>{el.id}</p>
                },
                {
                    type: 'text',
                    content: el.technic_type.name
                },
                {
                    type: 'text',
                    content: el.model
                },
                {
                    type: 'text',
                    content: el.work_type.name
                },
                {
                    type: 'text',
                    content: el.power
                },
                {
                    type: 'text',
                    content: el.release_date
                },
                {
                    type: 'text',
                    content: <div
                        style={{
                            padding: '4px 8px',
                            display: 'inline-block',
                            backgroundColor: carStatuses.filter(st => st.status === el.is_busy)[0].bg,
                            borderRadius: 24,
                            marginLeft: 16
                        }}
                        className='whiteButtonText p12_400'>
                        {carStatuses.filter(st => st.status === el.is_busy)[0].title}
                    </div>
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                await fetchLikeTechnics(el.id)
                                fetchMyLikes(Object.fromEntries([...searchParams]).page, 50, createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order))
                                api.destroy()
                            }, 'удаление поля.')} />
                    </div>
                },
            ]
        ))
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                    <TextHeading text={'Избранное'} />
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                </div>
            </div>
            {contextHolder}
            <Table
                header={allCarsTable}
                width={allCarsTableWidth}
                data={createTableData()}
            />
            {likes.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        likes: state.likes.likes,
        pages: state.likes.pages,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyLikes: (page, size, params) => dispatch(fetchMyLikes(page, size, params)),
        fetchLikeTechnics: (id) => dispatch(fetchLikeTechnics(id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Favorites);