import React from 'react'
import { advantagesData } from 'data/mainPage';

import './style.css';
import './media.css';

const Advantages = () => {
  return (
    <div className='advantagesBlock'>
      <p className='p36_700 p_mb24' style={{ textAlign: 'center' }}>Чем полезен наш сервис</p>
      {advantagesData.map((adv, i) => (
        <div
          className='advantagesBlock_item'
          key={`advantagesBlock_item__${i}`}
          style={{
            flexDirection: adv.flexDirection
          }}
        >
          <img src={adv.image} alt="icon" className='advantagesBlock_item__img' style={{marginRight: adv.flexDirection === 'row' ? 40: 0}} />
          <div className='advantagesBlock_item__content'>
            <p className='p24_600 p_mb20'>{adv.title}</p>
            {adv.list.map((item, key) => (
              <div
                className='advantagesBlock_item__listItem'
                key={`advantagesBlock_item__listItem__${key * (i + 1)}`}
              >
                <div className='advantagesBlock_item__listItem__dot' />
                <p className='p_ml8 p16_400 subtext'>{item}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Advantages