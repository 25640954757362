import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchDeleteMe, fetchDeleteUser } from 'store/actions/user';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function DeleteProfileForm({ setVisible, user_data_id, role, data, fetchDeleteUser, fetchDeleteMe, ...props }) {
    const navigate = useNavigate()
    
    const onFinish = ({ reason_deletion }) => {
        if(role === 'user'){
            fetchDeleteMe(reason_deletion)
        } else {
            fetchDeleteUser(data.user_id, reason_deletion)
            .then(() => navigate('/admin/users'))
        }
        setVisible()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            {...props}
        >
            <Form.Item
                label={'Причина удаления:'}
                name="reason_deletion"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите причина удаления' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500"
                        style={{
                            border: '1px solid #01963A',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: 'white',
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Сохранить'}
                        className="p14_500"
                        htmltype="submit"
                        style={{
                            color: 'white',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: '#D32F2F'
                        }}
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data_id: state.user.user_data.id,
        role: state.userEnv.role,
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL}),
        fetchDeleteUser: (user_id, comment) => dispatch(fetchDeleteUser(user_id, comment)),
        fetchDeleteMe: (comment) => dispatch(fetchDeleteMe(comment))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(DeleteProfileForm);