import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { contractReviewsTable } from 'data/adminTableHeader';
import { contractReviewsTableWidth } from 'data/adminTableWidth';
import { reviewsStatusStyles } from 'data/lists';
import { createFio } from 'hooks/createFio';
import Table from 'components/ui/table/Table';

import goldStar from 'assets/images/icons/gold-star.svg';
import greyStar from 'assets/images/icons/grey-star.svg';

function Reviews({ contract_by_id_data_review }) {
    const navigate = useNavigate()

    const createReviews = () => {
        const stars = []
        for (let i = 1; i < 6; i++) {
            let star = null
            if (i <= contract_by_id_data_review.grade) {
                star = goldStar
            } else {
                star = greyStar
            }
            stars.push(<img src={star} alt='star' style={{ cursor: 'pointer' }} />)
        }
        return stars
    }

    const createTableData = () => {
        if(contract_by_id_data_review){
            return [
                [
                    {
                        type: 'active_text',
                        content: <span onClick={() => navigate(`/admin/users/${contract_by_id_data_review.owner.id}`)}>{createFio(contract_by_id_data_review.owner)}</span>
                    },
                    {
                        type: 'text',
                        content: <div className='statusOnTable p12_400' style={{ backgroundColor: reviewsStatusStyles[contract_by_id_data_review.is_completed].bg }}>{reviewsStatusStyles[contract_by_id_data_review.is_completed].title}</div>
                    },
                    {
                        type: 'text',
                        content: contract_by_id_data_review.comment
                    },
                    {
                        type: 'actions',
                        content: createReviews()
                    }
                ]
            ]
        } else {
            return []
        }
    }

    return (
        <Table
            header={contractReviewsTable}
            width={contractReviewsTableWidth}
            data={createTableData()}
        />
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data_review: state.adminContractById.contract_by_id_data.review
    }
}

export default connect(mapStateToProps, null)(Reviews);