import { connect } from 'react-redux'
import ResponseCollaps from 'components/shared/responsCollapse/ResponseCollanse';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import noFound from 'assets/images/noFound.svg';

function Responses({ application_by_id_data }) {
    
    return (
        application_by_id_data.replies.length > 0
            ?
            <>
                {application_by_id_data.replies.map(el => (
                    <ResponseCollaps
                        key={`ResponseCollaps_${el.id}`}
                        item={el}
                        application_status={application_by_id_data.is_canceled}
                    />
                ))}
            </>
            : <InfoBlock style={{ textAlign: 'center' }}>
                <img src={noFound} alt="noFound" />
            </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        application_by_id_data: state.applicationById.application_by_id_data
    }
}

export default connect(mapStateToProps, null)(Responses);