import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { SAVE_CREATE_TECHNIC_DATA, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchGetUniqueByPsm } from 'store/actions/techics';
import { formatDate } from 'hooks/formatDate';
import { numberInputFormat } from 'hooks/numderInputFormat';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import CustomCalendar from 'components/shared/calendar/Calendar';

const exceptThisSymbols = [" "]

function TechnicDescription({ form, nextStep, create_technic, SAVE_CREATE_TECHNIC_DATA, fetchGetUniqueByPsm, setVisible }) {
    const [PSMDate, setPSMDate] = useState(create_technic.PSM_date ? new Date(create_technic.PSM_date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : new Date())
    const [fields, setFields] = useState([
        {
            name: 'power',
            value: create_technic.power,
        },
        {
            name: 'PSM_serial',
            value: create_technic.PSM_serial,
        },
        {
            name: 'PSM_number',
            value: create_technic.PSM_number,
        },
        {
            name: 'description',
            value: create_technic.description,
        },
    ]);

    const onFinish = ({ power, PSM_serial, PSM_number, description }) => {
        let obj = { ...create_technic }
        obj.power = power
        obj.PSM_serial = PSM_serial
        obj.PSM_number = PSM_number
        obj.PSM_date = formatDate(PSMDate)
        obj.description = description
        fetchGetUniqueByPsm(PSM_serial, PSM_number)
            .then(res => {
                if (res) {
                    setVisible()
                } else {
                    SAVE_CREATE_TECHNIC_DATA(obj)
                    nextStep()
                }
            })
    };

    return (
        <InfoBlock>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                className="modalForm"
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label={'Мощность (л.с)'}
                    name="power"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите мощность (л.с)' type="number" onKeyDown={numberInputFormat} />
                </Form.Item>
                <Form.Item
                    label={'Серия ПСМ'}
                    name="PSM_serial"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите серию ПСМ' onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </Form.Item>
                <Form.Item
                    label={'Номер ПСМ'}
                    name="PSM_number"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите номер ПСМ' onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                </Form.Item>
                <p>Дата выдачи ПСМ <span className='errorColor'>*</span></p>
                <div className='calendarBlock'>
                    <CustomCalendar date={PSMDate} onChange={(item) => { setPSMDate(item) }} />
                </div>
                <Form.Item
                    label={'Описание техники'}
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input.TextArea placeholder='Введите описание техники' />
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        create_technic: state.createTechic.create_technic,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_DATA: (data) => dispatch({ type: SAVE_CREATE_TECHNIC_DATA, create_technic: data }),
        fetchGetUniqueByPsm: (psm_serial, psm_number) => dispatch(fetchGetUniqueByPsm(psm_serial, psm_number)),
        setVisible: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'uniqueByPsm' }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(TechnicDescription);