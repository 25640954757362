import { miniAdvantagesData } from 'data/mainPage';

import './style.css';
import './media.css';

const AdvantagesMini = () => {
  return (
    <div className='advantagesMiniBlock' style={{ marginTop: 60, marginBottom: 60 }}>
      <p className='p36_700 p_mb24' style={{ textAlign: 'center' }}>Наши преимущества</p>
      <div className='advantagesMiniBlock_content'>
        {miniAdvantagesData.map((adv, key) => (
          <div
            className='advantagesMiniBlock_content__item'
            key={`miniAdvantage_${key}`}
          >
            <div className='advantagesMiniBlock_content__item__img'>
              <img src={adv.icon} alt="icon" />
            </div>
            <p className='mainText p18_500'>{adv.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdvantagesMini