export const profileDocsTable = [
    {
        title: 'Название',
    },
    {
        title: 'Файл',
    },
    {
        title: 'Дата загрузки',
    },
    // {
    //     title:'Пользователь',
    // },
    {
        title: '',
    },
]

export const profileVerificationTable = [
    {
        title: 'Дата подачи заявки',
    },
    {
        title: 'Статус',
    },
    {
        title: 'Комментарий',
    },
]

export const allCarsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Тип работ',
    },
    {
        title: 'Мощность (л.с.)',
    },
    {
        title: 'Год выпуска',
    },
    // {
    //     title:'Занятость',
    //     sort: true
    // },
    {
        title: 'Статус',
        sort: true,
        sort_param: 'status'
    },
    {
        title: '',
    },
]

export const carEmploymentTable = [
    'ID',
    'ФИО заказчика',
    'ФИО исполнителя',
    'Тип работы',
    'Поле',
    'Техника',
    'Сумма, ₽',
    'Период действия контракта',
    'Статус'
]

export const allFieldsTable = [
    {
        title: 'Номер поля',
    },
    // {
    //     title:'Адрес поля',
    // },
    {
        title: 'Тип поля',
    },
    {
        title: 'Размер поля (Га)',
    },
    {
        title: '',
    }
]

export const fieldDocsTable = [
    {
        title: 'Название',
    },
    {
        title: 'Файл',
    },
    {
        title: 'Дата загрузки',
    },
    // {
    //     title:'Пользователь',
    // },
    {
        title: '',
    },
]

export const createFieldDocsTable = [
    {
        title: 'Название',
    },
    {
        title: 'Файл',
    },
    {
        title: 'Дата загрузки',
    },
    {
        title: '',
    },
]

export const allApplicationsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Тип работы',
    },
    {
        title: 'Тип заявки',
    },
    {
        title: 'Сумма, ₽',
    },
    {
        title: 'Кол-во откликов',
    },
    {
        title: 'Статус',
        sort: true,
        sort_param: 'is_canceled'
    }
]

export const offersCarsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Гос. номер',
    },
    {
        title: 'Серия ПСМ',
    },
    {
        title: 'Номер ПСМ',
    },
    {
        title: ''
    }
]

export const offersFieldsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Номер поля',
    },
    // {
    //     title: 'Адрес поля',
    // },
    {
        title: 'Тип поля',
    },
    {
        title: 'Выращиваемая культура',
    },
    {
        title: 'Размер поля (Га)',
    },
    {
        title: ''
    }
]

export const allConflictsTable = [
    {
        title: 'Дата и время',
    },
    {
        title: 'ID контракта',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Причина',
    },
    {
        title: '',
    },
]

export const allContractsTable = [
    {
        title: 'ID',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Тип работы',
    },
    {
        title: 'Поле',
    },
    {
        title: 'Техника',
    },
    {
        title: 'Сумма, ₽',
    },
    {
        title: 'Период действия контракта',
    },
    {
        title: 'Статус',
    },
]

export const allContractsWithoutStatusTable = [
    {
        title: 'ID',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Тип работы',
    },
    {
        title: 'Поле',
    },
    {
        title: 'Техника',
    },
    {
        title: 'Сумма, ₽',
    },
    {
        title: 'Период действия контракта',
    },
]

export const createReplyByTechnicTable = [
    {
        title: 'Номер поля',
    },
    {
        title: 'Адрес поля',
    },
    {
        title: 'Тип поля',
    },
    {
        title:'Размер поля (Га)',
    },
    {
        title: '',
    },
]

export const createReplyBySquareTable = [
    {
        title: 'ID',
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title:'Гос. номер',
    },
    {
        title: '',
    },
]

export const createReplyBySquareBidsTable = [
    {
        title: 'Тип работ',
    },
    {
        title: 'Выращиваемая культура',
    },
    {
        title: 'Дата проведения',
    },
    {
        title: 'Описание',
    },
    {
        title: 'Стоимость заявки',
    },
    {
        title: 'Условия оплаты',
    },
    {
        title: 'Участки',
    },
    {
        title: '',
    },
]

export const createReplyByTchnicBidsTable = [
    {
        title: 'Дата проведения',
    },
    {
        title: 'Описание',
    },
    {
        title: 'Стоимость заявки',
    },
    {
        title: 'Условия оплаты',
    },
    {
        title: '',
    },
]

export const marketBidsTable = [
    {
        title: 'Тип работ',
    },
    {
        title: 'Выращиваемая культура',
    },
    {
        title: 'Дата проведения',
    },
    {
        title: 'Описание',
    },
    {
        title: 'Стоимость заявки',
    },
    {
        title: 'Условия оплаты',
    },
    {
        title: 'Участки',
    },
]

export const marketBidsTechnicTable = [
    {
        title: 'Дата проведения',
    },
    {
        title: 'Описание',
    },
    {
        title: 'Стоимость заявки',
    },
    {
        title: 'Условия оплаты',
    },
]

export const responseTchnicsCarsTable = [
    {
        title: 'ID',
    },
    {
        title: 'Тип техники',
    },
    {
        title: 'Модель',
    },
    {
        title: 'Гос. номер',
    },
    {
        title: 'Серия ПСМ',
    },
    {
        title: 'Номер ПСМ',
    },
]

export const responseSquaresTable = [
    {
        title: 'Номер поля',
    },
    // {
    //     title: 'Адрес поля',
    // },
    {
        title: 'Выращиваемая культура',
    },
    {
        title: 'Размер поля (Га)',
    },
]