import { connect } from 'react-redux';
import { Dropdown } from 'antd';
import { EXIT } from 'store/actionTypes';

import HeaderLogo from 'assets/images/headerLogo.svg';
import angleDown from 'assets/images/icons/angle-down.svg';

import 'components/layout/style.css';
import 'components/layout/media.css';

function Header({ EXIT }) {

    const items = [
        {
            label: <p style={{ color: 'red' }} onClick={EXIT}>Выйти</p>,
            key: '0',
        }
    ];

    return (
        <div className="userHeader">
            <div className="userHeader_leftPart">
                <img src={HeaderLogo} alt="HeaderLogo" />
            </div>
            <div className="userHeader_rightPart">
                <Dropdown menu={{ items }} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()} className="userMenu">
                        <div className='userHeader_rightPart__avatar__text p14_500'>A</div>
                        <img src={angleDown} alt="angleDown" className="userHeader_rightPart__menu" />
                    </a>
                </Dropdown>
            </div>
        </div>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        EXIT: () => dispatch({ type: EXIT })
    }
}

export default connect(null, mapDispatvhToProps)(Header);
