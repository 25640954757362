import { api_contracts } from 'api/contracts'
import {
    FETCH_CONTRACT_BY_ID_DATA_START,
    FETCH_CONTRACT_BY_ID_DATA_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchContractById(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_CONTRACT_BY_ID_DATA_START))
        try {
            const contract_data = await api_contracts.GetContractById(id)
            dispatch(fetchSuccess(FETCH_CONTRACT_BY_ID_DATA_SUCCESS, {
                contract_by_id_data: contract_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchUploadContractDocs(id, data) {
    return async dispatch => {
        try {
            await api_contracts.UploadContractDocumentById(id, data)
            dispatch(fetchContractById(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteContractDocs(id, doc_id) {
    return async dispatch => {
        try {
            await api_contracts.DeleteContractDocumentById(id, doc_id)
            dispatch(fetchContractById(id))
        } catch (e) {
        }
    }
}

export function fetchCompleteContract(contract_id) {
    return async dispatch => {
        try {
            await api_contracts.PostCompleteContract(contract_id)
            dispatch(fetchContractById(contract_id))
        } catch (e) {
        }
    }
}

export function fetchTerminateContract(contract_id) {
    return async dispatch => {
        try {
            await api_contracts.PostTerminateContract(contract_id)
            dispatch(fetchContractById(contract_id))
            return true
        } catch (e) {
            return false
        }
    }
}

export function fetchRepeatContract(contract_id, data) {
    return async dispatch => {
        try {
            const contract_data = await api_contracts.PostRepeatContract(contract_id, data)
            return contract_data.data.data.id
        } catch (e) {
            return false
        }
    }
}