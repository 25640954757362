import { ApiUrl, instance } from '../apiEnv';

export const api_contracts = {

    async GetAllContracts(page, size, params) {
        return await instance.get(`${ApiUrl}contracts?page=${page}&size=${size}&${params}`)
    },

    async GetContractById(id) {
        return await instance.get(`${ApiUrl}contracts/${id}`)
    },

    async PostTerminateContract(contract_id) {
        return await instance.post(`${ApiUrl}contracts/${contract_id}/terminate`)
    },

    async UploadContractDocumentById(id, data) {
        return await instance.put(`${ApiUrl}contracts/${id}/document`, data)
    },

    async DeleteContractDocumentById(id, doc_id) {
        return await instance.delete(`${ApiUrl}contracts/${id}/document/${doc_id}`)
    },
}