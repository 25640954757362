import { connect } from 'react-redux'
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchConfirmSquareVerificstion } from 'store/actions/admin/squares';
import { userVerificationModalTable } from 'data/adminTableHeader';
import { userVerificationModalTableWidth } from 'data/adminTableWidth';
import { formatDate } from 'hooks/formatDate';
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import Table from 'components/ui/table/Table';

function MainVerificationSquare({ square_by_id_data_docs, data, setVisible, setReject, fetchConfirmSquareVerificstion }) {
    const new_data = JSON.parse(data.new_data)
    const createTableData = () => {
        const data = []
        square_by_id_data_docs.forEach(el => {
            data.push([
                {
                    type: 'text',
                    content: el.name
                },
                {
                    type: 'active_text',
                    content: <a href={el.media.link} download target="_blank" className='activeText'>{el.name}</a>
                },
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: 'В РАБОТЕ'
                },
            ])
        })
        return data
    }

    const confirm = () => {
        fetchConfirmSquareVerificstion(data.id, data.verification_id)
        setVisible()
    }

    return (
        <>
            <TextHeading
                text={'Верификация'}
                style={{
                    textAlign: 'center',
                    marginBottom: 20
                }}
            />
            <InfoBlock
                style={{
                    marginBottom: 20,
                    marginTop: 20
                }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Кадастровый номер</p>
                    <p className='p14_400 mainText'>{new_data.cadastral ? new_data.cadastral : '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Владеет полем</p>
                    <p className='p14_400 mainText'>{new_data.is_owner ? 'Да' : 'Нет'}</p>
                </div>
            </InfoBlock>
            <Table
                header={userVerificationModalTable}
                width={userVerificationModalTableWidth}
                data={createTableData()}
            />
            <div className="modalButtons">
                <SimpleButton
                    title={'Отклонен'}
                    style={{
                        color: '#FFF',
                        border: '1px solid #D32F2F',
                        borderRadius: '100px',
                        backgroundColor: '#D32F2F',
                        fontWeight: 500,
                        width: '100%'
                    }}
                    onClick={() => setReject(data)}
                />
                <SimpleButton
                    title={'Отменить'}
                    style={{
                        color: '#01963A',
                        backgroundColor: '#FFF',
                        fontWeight: 500,
                        width: '100%'
                    }}
                    onClick={() => setVisible()}
                />
                <SimpleButton
                    title={'Верифицирован'}
                    style={{
                        color: '#FFF',
                        border: '1px solid #01963A',
                        borderRadius: '100px',
                        backgroundColor: '#01963A',
                        fontWeight: 500,
                        width: '100%'
                    }}
                    onClick={confirm}
                />
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        square_by_id_data_docs: state.adminSquareById.square_by_id_data.documents,
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        setReject: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'rejectVerificationSquare', data }),
        fetchConfirmSquareVerificstion: (square_id, verification_id) => dispatch(fetchConfirmSquareVerificstion(square_id, verification_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainVerificationSquare);