
import { api_chats } from 'api/chats/chats'
import {
    FETCH_ALL_USER_CHATS_START,
    FETCH_CHAT_ROOM_BY_ID_DATA_START,
    FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS,
    FETCH_USER_CHATS_UNREADED_MESSAGES,
    FETCH_CHECK_CHAT_BY_USER_ID,
    FETCH_ALL_USER_CHATS_CONCAT,
    FETCH_ALL_USER_FOLDERS_START,
    FETCH_ALL_USER_FOLDERS_CONCAT,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchUserChats(page, size, params) {
    return async dispatch => {
        try {
            const user_chats = await api_chats.GetMyChats(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_USER_CHATS_START, {
                data: user_chats.data.data,
                unreaded_messages: user_chats.data.meta.unreaded_messages
            }))
        } catch (e) {
        }
    }
}

export function fetchUserUnreadedMessages(page, size, params) {
    return async dispatch => {
        try {
            const user_chats = await api_chats.GetMyChats(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_CHATS_UNREADED_MESSAGES, {
                unreaded_messages: user_chats.data.meta.unreaded_messages
            }))
        } catch (e) {
        }
    }
}

export function fetchUserChatsConcat(page, size, params) {
    return async dispatch => {
        try {
            const user_chats = await api_chats.GetMyChats(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_USER_CHATS_CONCAT, {
                data: user_chats.data.data,
                unreaded_messages: user_chats.data.meta.unreaded_messages
            }))
        } catch (e) {
        }
    }
}

export function fetchCheckUserChatById(page, size, params) {
    return async dispatch => {
        try {
            const user_chats = await api_chats.GetMyChats(page, size, params)
            dispatch(fetchSuccess(FETCH_CHECK_CHAT_BY_USER_ID, {
                data: user_chats.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchChatRoomById(room_id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_CHAT_ROOM_BY_ID_DATA_START))
        try {
            const chat_room_by_id = await api_chats.GetRoomById(room_id)
            dispatch(fetchSuccess(FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS, {
                chat_room_by_id: chat_room_by_id.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchSendMessage(room_id, data) {
    return async dispatch => {
        try {
            const message = await api_chats.PostSendMessageToRoom(room_id, data)
            return message.data.data
        } catch (e) {
            return false
        }
    }
}

export function fetchCreateRoom(reciever_id, data) {
    return async dispatch => {
        try {
            const room = await api_chats.PostCreateRoom(reciever_id, data)
            return room.data.data.id
        } catch (e) {
            return false
        }
    }
}

export function fetchUserFolders(page, size, params) {
    return async dispatch => {
        try {
            const user_folders = await api_chats.GetMyFolders(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_USER_FOLDERS_START, {
                data: user_folders.data.data,
                unreaded_messages: user_folders.data.meta.unreaded_messages
            }))
        } catch (e) {
        }
    }
}

export function fetchUserFoldersConcat(page, size, params) {
    return async dispatch => {
        try {
            const user_folders = await api_chats.GetMyFolders(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_USER_FOLDERS_CONCAT, {
                data: user_folders.data.data,
                unreaded_messages: user_folders.data.meta.unreaded_messages
            }))
        } catch (e) {
        }
    }
}