import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { notification } from "antd";
import { fetchMyConflicts } from 'store/actions/conflicts';
import { allDealsFilter } from 'data/adminLists';
import { allConflictsTableWidth } from 'data/tableWidth';
import { allConflictsTable } from 'data/tableHeader';
import { formatDateTime } from 'hooks/formatDate';
import { createQuery } from 'hooks/createQuery';
import { createFio } from 'hooks/createFio';
import Filter from 'components/shared/filter/Filter';
import Pagination from 'components/shared/pagination/Pagination';
import Table from 'components/ui/table/Table';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function AllConflicts({ conflicts, pages, fetchMyConflicts }) {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchMyConflicts(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return conflicts.map(el => (
            [
                {
                    type: 'text',
                    content: formatDateTime(el.created_at)
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/user/my-contracts/${el.contract_id}`)}>{el.contract_id}</p>
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/public/user/${el.owner.id}`)}>{createFio(el.owner)}</p>
                },
                {
                    type: 'active_text',
                    content: <p onClick={() => navigate(`/public/user/${el.executor.id}`)}>{createFio(el.executor)}</p>
                },
                {
                    type: 'text',
                    content: el.reason
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <SimpleButton
                            title={'Инфо'}
                            style={{
                                padding: '7px 16px',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                color: '#01963A',
                                fontWeight: 500,
                                fontSize: '14px',
                                backgroundColor: '#FFFFFF'
                            }}
                            onClick={() => navigate(`/user/my-conflicts/${el.id}`)}
                        />
                    </div>
                },
            ]
        ))
    }

    return (
        <div className='allUsersBlock'>
            <div className='allUsersBlock_header'>
                <div className='allUsersBlock_header__leftHalf'>
                    <TextHeading text={'Мои споры'} />
                </div>
                <div className='allUsersBlock_header__rightHalf'>
                    <Filter
                        className={'filter_marginRight'}
                        filterList={allDealsFilter()}
                    />
                </div>
            </div>
            {contextHolder}
            <Table
                header={allConflictsTable}
                width={allConflictsTableWidth}
                data={createTableData()}
            />
            {conflicts.length > 0 && <Pagination pages={pages} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        conflicts: state.userConflicts.conflicts,
        pages: state.userConflicts.pages
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchMyConflicts: (page, size, params) => dispatch(fetchMyConflicts(page, size, params))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AllConflicts);