import { connect } from 'react-redux'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { CLEAR_CONTRACT_BY_ID_DATA, FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS, FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS } from 'store/actionTypes';
import { fetchTechnicMovementById, fetchTechnicStatisticsById } from 'store/actions/technicsById';
import { closeContractByIdSwitch } from 'data/lists';
import { contractBreadcrumb } from 'data/brebcrumb';
import MainInfo from './mainInfo/MainInfo';
import Participants from './participants/Participants';
import Technics from './technics/Technics';
import Squares from './squares/Squares';
import Conflicts from './conflicts/Conflicts';
import Docs from './docs/Docs';
import Reports from './reports/Reports';
import Reviews from './reviews/Reviews';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';
import Breadcrumb from 'components/shared/breadcrumb/Breadcrumb';
import Switch from 'components/shared/switch/Switch';
import { fetchContractById } from 'store/actions/contractById';

function ContractById({
    contract_by_id_data,
    reportDateStart,
    reportDateEnd,
    fetchTechnicStatisticsById,
    fetchContractById,
    fetchTechnicMovementById,
    FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS,
    FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS,
    CLEAR_CONTRACT_BY_ID_DATA
}) {
    const { id } = useParams()
    const [activeSwitch, setActiveSwitch] = useState('mainInfo')
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        fetchContractById(id)
    }, [])

    useEffect(() => {
        if (contract_by_id_data) {
            FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS(contract_by_id_data.valid_from)
            FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS(contract_by_id_data.valid_to)
        }
    }, [contract_by_id_data])

    useEffect(() => {
        if (reportDateStart && reportDateEnd) {
            const data = {
                start_date: `${reportDateStart}T12:07:37.499Z`,
                end_date: `${reportDateEnd}T12:07:37.499Z`,
                navigation_settings: {
                    ScoutNavigationFilters: {
                        ExcludeValidPoints: false,
                        ExcludeInvalidPoints: true,
                        ExcludeNotValidatedPoints: false
                    },
                    ScoutTrackPeriodFilters: {
                        ExcludeRecoilPoints: true,
                        ExcludeNotMovePoints: true,
                        IncludeParkingPoints: false
                    }
                },
                statistic: "NavigationFiltration"
            }
            for (let i = 0; i < contract_by_id_data.technics.length; i++) {
                fetchTechnicMovementById(contract_by_id_data.id, contract_by_id_data.technics[i].id, reportDateStart, reportDateEnd)
                fetchTechnicStatisticsById(contract_by_id_data.technics[i].id, data)
            }
        }
    }, [reportDateStart, reportDateEnd])

    useEffect(() => {
        return () => {
            CLEAR_CONTRACT_BY_ID_DATA()
        }
    }, [])

    return (
        contract_by_id_data &&
        <div className='allUsersBlock'>
            {contextHolder}
            <Breadcrumb list={contractBreadcrumb} activeLink={'Контракт'} />
            <TextHeading text={'Контракт'} />
            <Switch
                items={closeContractByIdSwitch}
                activeItem={activeSwitch}
                onSwitch={setActiveSwitch}
                style={{
                    marginTop: 24,
                    marginBottom: 16
                }}
                className="switch miniSwitch"
            />
            {activeSwitch === 'mainInfo' && <MainInfo />}
            {activeSwitch === 'participants' && <Participants />}
            {activeSwitch === 'technics' && <Technics />}
            {activeSwitch === 'squares' && <Squares />}
            {activeSwitch === 'conflicts' && <Conflicts />}
            {activeSwitch === 'docs' && <Docs />}
            {activeSwitch === 'reports' && <Reports />}
            {activeSwitch === 'reviews' && <Reviews />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        contract_by_id_data: state.contractById.contract_by_id_data,
        reportDateStart: state.contractById.reportDateStart,
        reportDateEnd: state.contractById.reportDateEnd
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchContractById: (id) => dispatch(fetchContractById(id)),
        fetchTechnicMovementById: (contract_id, id, start_date, end_date) => dispatch(fetchTechnicMovementById(contract_id, id, start_date, end_date)),
        fetchTechnicStatisticsById: (technic_id, data) => dispatch(fetchTechnicStatisticsById(technic_id, data)),
        FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS: (reportDateStart) => dispatch({ type: FETCH_CONTRACT_BY_ID_REPORT_START_DATE_SUCCESS, reportDateStart }),
        FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS: (reportDateEnd) => dispatch({ type: FETCH_CONTRACT_BY_ID_REPORT_END_DATE_SUCCESS, reportDateEnd }),
        CLEAR_CONTRACT_BY_ID_DATA: () => dispatch({ type: CLEAR_CONTRACT_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ContractById);