import { api_complaints } from 'api/admin/complaints';
import {
    FETCH_ALL_COMPLAINTS_START,
    FETCH_ALL_COMPLAINTS_SUCCESS,
    ADMIN_FETCH_COMPLAINT_BY_ID_DATA_START,
    ADMIN_FETCH_COMPLAINT_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllComplaints(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_COMPLAINTS_START))
        try {
            const complaints_data = await api_complaints.GetAllComplaints(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_COMPLAINTS_SUCCESS, {
                data: complaints_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchComplaintById(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_COMPLAINT_BY_ID_DATA_START))
        try {
            const complaint_data = await api_complaints.GetComplaintById(id)
            dispatch(fetchSuccess(ADMIN_FETCH_COMPLAINT_BY_ID_DATA_SUCCESS, {
                complaint_by_id_data: complaint_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchUpdateComplaintByID(id, data) {
    return async dispatch => {
        try {
            await api_complaints.UpdateComplaintById(id, data)
            dispatch(fetchComplaintById(id))
        } catch (e) {
        }
    }
}

export function fetchDeleteComplaint(id) {
    return async dispatch => {
        try {
            await api_complaints.DeleteComplaintById(id)
        } catch (e) {
        }
    }
}