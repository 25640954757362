import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "antd";
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { fetchPlotByID } from "store/actions/admin/plotById";
import { fetchSquareByID } from "store/actions/admin/squares";
import { ADMIN_CLEAR_PLOTS_BY_ID_DATA } from "store/actionTypes";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import CreateUpdatePlot from "components/components/forms/admin/plot/CreateUpdatePlot";

import pin from 'assets/images/pins/field.svg';
import edit_green_pen from 'assets/images/icons/edit_green_pen.svg';

function PlotById({ mode, plotById, square_by_id_data, fetchPlotByID, fetchSquareByID, CLEAR_PLOTS_BY_ID_DATA }) {
    const [pinCoords, setCoords] = useState(null)
    const [edit, setEdit] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm();
    const { plot_id, square_id } = useParams()

    useEffect(() => {
        if (mode === 'byId') {
            fetchPlotByID(plot_id)
        }
        fetchSquareByID(square_id)
    }, [])

    useEffect(() => {
        return () => {
            CLEAR_PLOTS_BY_ID_DATA()
        }
    }, [])

    useEffect(() => {
        if (plotById) {
            setCoords([plotById.address.latitude, plotById.address.longitude])
        }
    }, [plotById])

    const defaultState = {
        center: square_by_id_data ? [square_by_id_data.address.latitude, square_by_id_data.address.longitude] : [55.751574, 37.573856],
        zoom: 10,
        type: 'yandex#hybrid'
    };

    return (
        <div className='allUsersBlock'>
            <div className='createCarBlock_header'>
                <TextHeading text={mode === 'byId' ? 'Участок' : 'Создание участка'} />
                {mode === 'byId'
                    ?
                    (edit
                        ?
                        <div className='userProfile_header__actions'>
                            <SimpleButton
                                title={'Отмена'}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500,
                                    marginRight: 16
                                }}
                                onClick={() => setEdit(false)}
                            />
                            <SimpleButton
                                title={'Сохранить'}
                                style={{
                                    color: '#FFF',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#01963A',
                                    fontWeight: 500
                                }}
                                onClick={() => form.submit()}
                            />
                        </div>
                        :
                        < div className='userProfile_header__actions'>
                            <div className='userProfile_header__actions__edit' onClick={() => setEdit(true)}>
                                <img src={edit_green_pen} alt="edit_green_pen" />
                                <p className='activeText p14_500'>Редактировать</p>
                            </div>
                            <SimpleButton
                                title={'Назад'}
                                style={{
                                    color: '#01963A',
                                    border: '1px solid #01963A',
                                    borderRadius: '100px',
                                    backgroundColor: '#FFF',
                                    fontWeight: 500,
                                    marginRight: 16
                                }}
                                onClick={() => navigate(`/admin/fields/${square_id}`, { state: { step: 'plotsAndSeasons' } })}
                            />
                        </div>
                    )
                    :
                    <div className='userProfile_header__actions'>
                        <SimpleButton
                            title={'Назад'}
                            style={{
                                color: '#01963A',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500,
                                marginRight: 16
                            }}
                            onClick={() => navigate(`/admin/fields/${square_id}`, { state: { step: 'plotsAndSeasons' } })}
                        />
                        <SimpleButton
                            title={'Сохранить'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={() => form.submit()}
                        />
                    </div>
                }
            </div>
            {
                mode === 'byId'
                    ? (plotById && (edit
                        ?
                        <InfoBlock
                            style={{ marginTop: 24 }}
                        >
                            <CreateUpdatePlot coords={pinCoords} form={form} step={'plotsAndSeasons'} page={'byId_edit'} />
                        </InfoBlock>
                        : <InfoBlock
                            style={{ marginTop: 24 }}
                            className="infoBlock twoRow"
                        >
                            <div style={{ width: '100%' }}>
                                <p className='p12_400 mainText p_mb8'>Номер</p>
                                <p className='p14_400 mainText p_mb16'>{plotById.number}</p>
                                <p className='p12_400 mainText p_mb8'>Фактический посев, Га</p>
                                <p className='p14_400 mainText'>{plotById.fact_sowing}</p>
                            </div>
                            <div style={{ width: '100%' }}>
                                <p className='p12_400 mainText p_mb8'>Порядковый номер</p>
                                <p className='p14_400 mainText p_mb16'>{plotById.serial}</p>
                                <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                                <p className='p14_400 mainText'>{plotById.crop_type.name}</p>
                            </div>
                        </InfoBlock>
                    ))
                    :
                    <InfoBlock
                        style={{ marginTop: 24 }}
                    >
                        <CreateUpdatePlot coords={pinCoords} form={form} step={'plotsAndSeasons'} page={'byId'} />
                    </InfoBlock>
            }
            <div className='locationBlock_map'>
                <Map
                    defaultState={defaultState}
                    width='100%'
                    height='100%'
                    onClick={(e) => { if (mode === 'create' || edit) setCoords(e.get('coords')) }}
                >
                    <FullscreenControl />
                    <SearchControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    <TypeSelector options={{ float: 'right' }} />
                    {pinCoords &&
                        <Placemark
                            geometry={pinCoords}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: pin,
                                iconImageSize: [70, 70],
                                // iconImageOffset: [-5, -38]
                            }}
                        />
                    }
                </Map>
            </div>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        plotById: state.adminPlotById.plots_by_id_data,
        square_by_id_data: state.adminSquareById.square_by_id_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchPlotByID: (id) => dispatch(fetchPlotByID(id)),
        fetchSquareByID: (id) => dispatch(fetchSquareByID(id)),
        CLEAR_PLOTS_BY_ID_DATA: () => dispatch({ type: ADMIN_CLEAR_PLOTS_BY_ID_DATA })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(PlotById);