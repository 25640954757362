import { connect } from 'react-redux'
import { profileVerificationTable } from 'data/tableHeader';
import { profileVerificationTableWidth } from 'data/tableWidth';
import { verificationStatusesStyle } from 'data/lists';
import { formatDate } from 'hooks/formatDate';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/ui/table/Table';

function Verification({ user_data_verifications }) {

    const createTableData = () => {
        return user_data_verifications.map(el => (
            [
                {
                    type: 'text',
                    content: formatDate(el.created_at)
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: verificationStatusesStyle[el.status].bg }}>{verificationStatusesStyle[el.status].title}</div>
                },
                {
                    type: 'text',
                    content: el.comment ? el.comment : '-'
                }
            ]
        ))
    }

    return (
        <>
            <Table
                header={profileVerificationTable}
                width={profileVerificationTableWidth}
                data={createTableData()}
            />
            <SimpleButton
                title={'Повторить верификацию'}
                style={{
                    marginTop: 20,
                    background: '#CCCCCC',
                    color: '#999999',
                    borderRadius: '100px',
                    padding: '11px 16px'
                }}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user_data_verifications: state.user.user_data.verifications
    }
}

export default connect(mapStateToProps, null)(Verification);