import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { notification, Select, Checkbox } from 'antd';
import { warningNotification } from 'hooks/notifications';
import { seasonCollapse } from 'data/lists';

import deleteIcon from 'assets/images/icons/delete.svg';

import './style.css';

const { Option } = Select;

function SeasonCollapseItem({ item, crop_types, seasonIndex, seasonPlotIndex, deletePlotOnSeason, editPlotOnSeason, mode, onSelectPlot, page }) {
    const [api, contextHolder] = notification.useNotification();
    const [plotData, setPlotData] = useState(item)
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setPlotData(item)
    }, [item])

    const onChangeSelect = (value, label) => {
        if (checked) {
            onSelectPlot(false, plotData)
            setChecked(false)
            if (mode !== 'addPlot') {
                editPlotOnSeason(seasonIndex, seasonPlotIndex, value, label.children)
            }
            let obj = { ...plotData }
            obj.crop_type_id = value
            obj.crop_type_name = label.children
            setPlotData(obj)
            onSelectPlot(true, obj)
            setChecked(true)
        } else {
            if (mode !== 'addPlot') {
                editPlotOnSeason(seasonIndex, seasonPlotIndex, value, label.children)
            }
            let obj = { ...plotData }
            obj.crop_type_id = value
            obj.crop_type_name = label.children
            setPlotData(obj)
        }
    }

    return (
        <>
            {contextHolder}
            <div
                className='seasonCollapseItem'
                style={{
                    padding: mode === 'addPlot' ? '8px 15px' : '8px 48px'
                }}
            >
                <div className='seasonCollapseItem_info'>
                    {mode === 'addPlot' &&
                        <Checkbox
                            style={{ marginRight: 24 }}
                            onChange={(e) => {
                                setChecked(e.target.checked)
                                onSelectPlot(e.target.checked, plotData)
                            }}
                            checked={checked}
                        />
                    }
                    {seasonCollapse.map((el, key) => (
                        <div key={`seasonCollapseItem_${key}`} style={{ width: el.width }}>
                            <p className='p12_400 p_mb4'>{el.title}</p>
                            {el.type === 'string' && <p className='p14_400'>{item[el.item]}</p>}
                            {el.type === 'select' &&
                                ((page !== 'market' && page !== 'check')
                                    ?
                                    <Select
                                        placeholder="Выберите тип культуры"
                                        style={{ display: 'block' }}
                                        value={plotData.crop_type_id}
                                        onChange={(value, label) => onChangeSelect(value, label)}
                                    >
                                        {crop_types.map(el => (
                                            <Option value={el.id} key={el.id}>{el.name}</Option>
                                        ))}
                                    </Select>
                                    : <p className='p14_400'>{crop_types.filter(el => el.id === plotData.crop_type_id)[0].name}</p>
                                )
                            }
                        </div>
                    ))}
                </div>
                {(mode !== 'addPlot' && page !== 'market' && page !== 'check') &&
                    <img
                        src={deleteIcon}
                        alt='deleteIcon'
                        onClick={() => {
                            warningNotification(api, () => {
                                deletePlotOnSeason(seasonIndex, seasonPlotIndex)
                                api.destroy()
                            }, 'удаление участка из сезона.')
                        }}
                    />
                }
                {page === 'check' && <Checkbox value={item.id} />}
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        crop_types: state.cropTypes.crop_types,
    }
}

export default connect(mapStateToProps, null)(SeasonCollapseItem);