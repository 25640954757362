import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { fetchUpdateUserData } from 'store/actions/admin/user';
import { numberInputFormat } from 'hooks/numderInputFormat';

function EditCompany({ form, onEdit, user_data_company, user_data_id, fetchUpdateUserData }) {
    const [fields, setFields] = useState([
        {
            name: 'name',
            value: user_data_company && user_data_company.name,
        },
        {
            name: 'address',
            value: user_data_company && user_data_company.address,
        },
        {
            name: 'inn',
            value: user_data_company && user_data_company.inn,
        },
        {
            name: 'ogrn',
            value: user_data_company && user_data_company.ogrn,
        },
        {
            name: 'email',
            value: user_data_company && user_data_company.email,
        },
        {
            name: 'phone',
            value: user_data_company && user_data_company.phone.substring(1),
        }
    ]);

    const onFinish = () => {
        const data = {
            company: {
                name: fields[0].value,
                address: fields[1].value,
                inn: Number(fields[2].value),
                ogrn: Number(fields[3].value),
                email: fields[4].value,
                phone: fields[5].value ? '7' + fields[5].value : fields[5].value,
            }
        }
        fetchUpdateUserData(data, user_data_id)
        onEdit()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                label={'Название компании'}
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите название компании' />
            </Form.Item>
            <Form.Item
                label={'Адрес компании'}
                name="address"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите адрес компании' />
            </Form.Item>
            <Form.Item
                label={'ИНН'}
                name="inn"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='ИНН' />
            </Form.Item>
            <Form.Item
                label={'ОГРН'}
                name="ogrn"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='ОГРН' />
            </Form.Item>
            <Form.Item
                label={'Коммерческая почта'}
                name="email"
                rules={[
                    {
                        required: true,
                        type: 'email',
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите коммерческую почту' />
            </Form.Item>
            <Form.Item
                label={'Телефон для связи'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='(000) 000-0000' prefix={'+7'} type="number" onKeyDown={numberInputFormat}/>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data_company: state.userById.user_by_id_data.company,
        user_data_id: state.userById.user_by_id_data.id
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateUserData: (data, user_id) => dispatch(fetchUpdateUserData(data, user_id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditCompany);