import { useEffect, useRef } from 'react';
import { createFio } from 'hooks/createFio';
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS } from 'store/actionTypes';
import AddMessage from 'components/components/forms/chats/AddMessage';

import bubbleArrowWhite from 'assets/images/chat/bubble-arrow-white.svg';
import bubbleArrowGreen from 'assets/images/chat/bubble-arrow-green.svg';
import downloading from 'assets/images/icons/downloading.svg';
import downloadingWhite from 'assets/images/icons/downloading-white.svg';
import backArrow from 'assets/images/icons/angle-left-grey.svg';

import './style.css'
import './media.css'

const WS_URL = process.env.REACT_APP_WSUrl

const ChatInfo = ({ mode, chat_room_by_id, user_data_id, public_user_by_id, FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const ws = useRef(null);

  useEffect(() => {
    var objDiv = document.getElementById("scrollDiv");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  })

  useEffect(() => {
    ws.current = new WebSocket(`${WS_URL}chats/ws/${Object.fromEntries([...searchParams]).room_id}`);
    // ws.current.onopen = () => console.log("ws opened");
    // ws.current.onclose = () => console.log("ws closed");

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  }, [Object.fromEntries([...searchParams]).room_id])

  if (ws.current) {
    ws.current.onmessage = function (event) {
      const json = JSON.parse(event.data);
      const chatRoomCopy = JSON.parse(JSON.stringify(chat_room_by_id))
      if (chatRoomCopy) {
        try {
          if ((json.event = "data")) {
            if (chatRoomCopy.messages[chatRoomCopy.messages.length - 1].id !== json.new_message.id) {
              chatRoomCopy.messages.push(json.new_message)
              FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS(chatRoomCopy)
            }
          }
        } catch (err) {
        }
      }
    };

    ws.current.onerror = (event) => {
    }
  }

  const goBack = () => {
    const query = Object.fromEntries([...searchParams]);
    delete query.room_id
    setSearchParams(query)
    ws.current.close()
  }

  const sendMessage = (data) => {
    ws.current.send(JSON.stringify(data))
  }

  return (
    (mode === 'reade' ? chat_room_by_id : public_user_by_id) &&
    <div className='chatInfo'>
      <div className='chatInfo_header'>
        <img src={backArrow} alt="backArrow " onClick={goBack} />
        {mode === 'reade'
          ? chat_room_by_id.members.map(el => {
            if (el.id !== user_data_id) {
              return <p className='mainText p16_500' style={{ cursor: 'pointer' }} onClick={() => navigate(`/public/user/${el.id}`)}>{createFio(el)}</p>
            }
          })
          : <p className='mainText p16_500' style={{ cursor: 'pointer' }} onClick={() => navigate(`/public/user/${public_user_by_id.id}`)}>{createFio(public_user_by_id)}</p>
        }
      </div>
      <div className='chatInfo_content'>
        <div className='chatInfo_content__messages' id='scrollDiv'>
          {mode === 'reade' && chat_room_by_id.messages.map(message => {
            if (message.owner_id === user_data_id) {
              return (
                <div className='messageBlock ownerMessage'>
                  <div className='ownerMessage_block'>
                    <p className='p14_400 messageBlock_text whiteButtonText'>{message.body}</p>
                    {message.documents.map(doc => (
                      <div className='messageBlock_docs'>
                        <div><a href={doc.media.link} download target="_blank"><img src={downloadingWhite} alt="downloadingWhite" /></a></div>
                        <div className='messageBlock_docs__title'>
                          <p className='p14_400 messageBlock_text whiteButtonText' style={{ maxWidth: '90%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{doc.name}</p>
                          <p className='p12_400 messageBlock_text whiteButtonText'>5.4 mb</p>
                        </div>
                      </div>
                    ))}
                    <img src={bubbleArrowGreen} alt="bubbleArrowGreen" className='ownerMessage_block__icon' />
                  </div>
                  <p className='p12_400 messageDate p_ml8'>{new Date(message.created_at).getHours() + ':' + new Date(message.created_at).getMinutes()}</p>
                </div>
              )
            } else {
              return (
                <div className='messageBlock readerMessage'>
                  <div className='readerMessage_block'>
                    <p className='p14_400 messageBlock_text'>{message.body}</p>
                    {message.documents.map(doc => (
                      <div className='messageBlock_docs'>
                        <div><a href={doc.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a></div>
                        <div className='messageBlock_docs__title'>
                          <p className='p14_400 messageBlock_text mainText' style={{ maxWidth: '90%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{doc.name}</p>
                          <p className='p12_400 messageBlock_text mainText'>5.4 mb</p>
                        </div>
                      </div>
                    ))}
                    <img src={bubbleArrowWhite} alt="bubbleArrowWhite" className='readerMessage_block__icon' />
                  </div>
                  <p className='p12_400 messageDate p_mr8' style={{ textAlign: 'end' }}>{new Date(message.created_at).getHours() + ':' + new Date(message.created_at).getMinutes()}</p>
                </div>
              )
            }
          })}
        </div>
        <AddMessage mode={mode} sendMessage={sendMessage} />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    chat_room_by_id: state.chatRoomById.chat_room_by_id,
    public_user_by_id: state.publicUser.public_user_by_id,
    user_data_id: state.user.user_data.id
  }
}

function mapDispatvhToProps(dispatch) {
  return {
    FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS: (chat_room_by_id) => dispatch({ type: FETCH_CHAT_ROOM_BY_ID_DATA_SUCCESS, data: { chat_room_by_id } })
  }
}

export default connect(mapStateToProps, mapDispatvhToProps)(ChatInfo)