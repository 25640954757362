import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import { fetchUpdateTechnicsByID } from 'store/actions/admin/technics';
import { carStatuses, carViews } from 'data/lists';
import Selector from 'components/shared/selector/Selector';
import EditTechnic from 'components/components/forms/admin/technics/editTechnic/EditTechnic';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import pin from 'assets/images/pins/combine.svg'

function MainInfo({ form, editMainIfo, tehnics_by_id_data, fetchUpdateTechnicsByID }) {
    const [coords, setCoords] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        if (tehnics_by_id_data.default_address) {
            setCoords([tehnics_by_id_data.default_address.latitude, tehnics_by_id_data.default_address.longitude])
        }
    }, [])

    const editHiddenOnMarket = (newStatus) => {
        const i = carViews.filter(el => el.title === newStatus)[0].status
        const obj = {
            is_hidden: i
        }
        fetchUpdateTechnicsByID(obj, id)
    }

    return (
        <>
            {
                !editMainIfo
                    ?
                    <>
                        <InfoBlock
                            style={{ marginTop: 24 }}
                            className="infoBlock twoRow"
                        >
                            <div style={{ width: '100%' }}>
                                <p className='p12_400 mainText p_mb8'>Собственник</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.is_owner ? 'Да' : 'Нет'}</p>
                                <p className='p12_400 mainText p_mb8'>Статус техники</p>
                                <p className='p14_400 mainText p_mb16'>В работе</p>
                                <p className='p12_400 mainText p_mb8'>Гос. номер</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.gos_num ? tehnics_by_id_data.gos_num : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Тип техники</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.technic_type.name}</p>
                                <p className='p12_400 mainText p_mb8'>Производитель</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.manufacturer ? tehnics_by_id_data.manufacturer : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Модель</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.model ? tehnics_by_id_data.model : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Год выпуска</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.release_date ? tehnics_by_id_data.release_date : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Тип работ</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.work_type.name}</p>
                                <p className='p12_400 mainText p_mb8'>Мощность (л.с)</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.power ? tehnics_by_id_data.power : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Дополнительное оборудование</p>
                                <p className='p14_400 mainText'>{tehnics_by_id_data.extra_devices ? tehnics_by_id_data.extra_devices : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Фотография</p>
                                <div className='imagesBlock'>
                                    {tehnics_by_id_data.Images.length === 0
                                        ? '-'
                                        : tehnics_by_id_data.Images.map((el, key) => (
                                            <img key={key} src={el.media.link} alt={'image'} />
                                        ))
                                    }
                                </div>
                            </div>
                            <div style={{ width: '100%' }}>
                                <p className='p12_400 mainText p_mb8'>Описание доп. оборудования</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.extra_devices_desc ? tehnics_by_id_data.extra_devices_desc : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Серия ПСМ</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.PSM_serial ? tehnics_by_id_data.PSM_serial : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Номер ПСМ</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.PSM_number ? tehnics_by_id_data.PSM_number : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Дата выдачи ПСМ</p>
                                <p className='p14_400 mainText p_mb16'>{tehnics_by_id_data.PSM_date ? tehnics_by_id_data.PSM_date : '-'}</p>
                                <p className='p12_400 mainText p_mb8'>Системы мониторинга местоположения</p>
                                <p className='p14_400 mainText p_mb16'>В РАБОТЕ</p>
                                <p className='p12_400 mainText p_mb8'>Код сервиса системы мониторинга</p>
                                <p className='p14_400 mainText p_mb16'>В РАБОТЕ</p>
                                <p className='p12_400 mainText p_mb8'>Описание техники</p>
                                <p className='p14_400 mainText'>{tehnics_by_id_data.description ? tehnics_by_id_data.description : '-'}</p>
                            </div>
                        </InfoBlock>
                        <InfoBlock
                            style={{ marginTop: 24 }}
                            className="infoBlock twoRow"
                        >
                            <div style={{ width: '100%' }}>
                                <p className='p12_400 mainText p_mb8'>Статус техники</p>
                                <div
                                    style={{
                                        padding: '4px 8px',
                                        display: 'inline-block',
                                        backgroundColor: carStatuses.filter(el => el.status === tehnics_by_id_data.is_busy)[0].bg,
                                        borderRadius: 24
                                    }}
                                    className='whiteButtonText p12_400'>
                                    {carStatuses.filter(el => el.status === tehnics_by_id_data.is_busy)[0].title}
                                </div>
                            </div>
                            <div style={{ width: '100%' }}>
                                <p className='p12_400 mainText p_mb8'>Скрыть технику с платформы</p>
                                <Selector
                                    list={carViews}
                                    activeItem={tehnics_by_id_data.is_hidden ? 'Скрыть' : 'Показывать'}
                                    setActiveSelect={editHiddenOnMarket}
                                    style={{
                                        backgroundColor: carViews.filter(el => el.title === (tehnics_by_id_data.is_hidden ? 'Скрыть' : 'Показывать'))[0].bg
                                    }}
                                />
                            </div>
                        </InfoBlock>
                    </>
                    : <InfoBlock><EditTechnic form={form} coords={coords} /></InfoBlock>
            }
            <div className='locationBlock'>
                <div className='locationBlock_map'>
                    <Map
                        defaultState={{
                            center: tehnics_by_id_data.default_address ? [tehnics_by_id_data.default_address.latitude, tehnics_by_id_data.default_address.longitude] : [55.751574, 37.573856],
                            zoom: 10,
                            type: 'yandex#hybrid'
                        }}
                        width='100%'
                        height='100%'
                        onClick={(e) => {
                            if (editMainIfo) {
                                setCoords(e.get('coords'))
                            }
                        }}
                    >
                        <FullscreenControl />
                        <SearchControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                        <TypeSelector options={{ float: 'right' }} />
                        {!editMainIfo
                            ?
                            (tehnics_by_id_data.default_address &&
                                <Placemark
                                    geometry={[tehnics_by_id_data.default_address.latitude, tehnics_by_id_data.default_address.longitude]}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: pin,
                                        iconImageSize: [70, 70],
                                        // iconImageOffset: [-5, -38]
                                    }}
                                />)
                            :
                            (coords &&
                                <Placemark
                                    geometry={coords}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: pin,
                                        iconImageSize: [70, 70],
                                        // iconImageOffset: [-5, -38]
                                    }}
                                />)
                        }
                    </Map>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        tehnics_by_id_data: state.adminTehnicById.technic_by_id_data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateTechnicsByID: (data, id) => dispatch(fetchUpdateTechnicsByID(data, id)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(MainInfo);