import { api_applications } from 'api/applications'
import {
    FETCH_USER_DATA_APPLICATIONS_SUCCESS
} from '../actionTypes'
import { fetchSuccess } from './fetchStatuses'

export function fetchMyApplications(page, size, params) {
    return async dispatch => {
        try {
            const applications_data = await api_applications.GetMyApplications(page, size, params)
            dispatch(fetchSuccess(FETCH_USER_DATA_APPLICATIONS_SUCCESS, {
                data: applications_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchCreateApplication(data) {
    return async dispatch => {
        try {
            const application = await api_applications.PostCreateApplications(data)
            return application.data.data.id
        } catch (e) {

            return false
        }
    }
}

export function fetchAddTechnicToApplication(bid_id, data) {
    return async dispatch => {
        try {
            await api_applications.PutAddTechnicsToApplications(bid_id, data)
        } catch (e) {

        }
    }
}

export function fetchAddPlotsToApplication(bid_id, data) {
    return async dispatch => {
        try {
            await api_applications.PutAddPlotsToApplications(bid_id, data)
        } catch (e) {

        }
    }
}
