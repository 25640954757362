import { api_address } from 'api/address'

export function fetchCreateAddress(data) {
    return async dispatch => {
        try {
            const address = await api_address.PostCreateAddress(data)
            return address.data.data.id
        } catch (e) {
        }
    }
}

export function fetchUpdateAddress(data, address_id) {
    return async dispatch => {
        try {
            await api_address.UpdateAddress(data, address_id)
        } catch (e) {
        }
    }
}