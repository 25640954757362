import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux'
import { Form, Input, Radio } from 'antd';
import { fetchCreateReview } from 'store/actions/reviews';
import { fetchCompleteContract } from 'store/actions/contractById';
import { successNotification } from 'hooks/notifications';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

import goldStar from 'assets/images/icons/gold-star.svg';
import greyStar from 'assets/images/icons/grey-star.svg';

function EvaluationContractForm({ fetchCreateReview, fetchCompleteContract }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const [value, setValue] = useState(true)
    const [reviews, setReviews] = useState(1)
    const [fields, setFields] = useState([
        {
            name: 'is_completed',
            value: true,
        },
    ]);

    const onFinish = async ({ is_completed, comment, reason }) => {
        const data = {
            is_completed,
            reason: reason || null,
            grade: reviews,
            comment,
            contract_id: id
        }
        fetchCreateReview(data)
            .then(res => {
                if (res) {
                    fetchCompleteContract(id)
                    successNotification('Отзыв создан!', 'Контракт успешно завершен')
                    navigate(`/user/my-contracts/${id}`)
                }
            })
    };

    const createReviews = () => {
        const stars = []
        for (let i = 1; i < 6; i++) {
            let star = null
            if (i <= reviews) {
                star = goldStar
            } else {
                star = greyStar
            }
            stars.push(<img src={star} alt='star' onClick={() => setReviews(i)} style={{ cursor: 'pointer' }} />)
        }
        return stars
    }

    return (
        <InfoBlock style={{ marginTop: 26 }}>
            <Form
                layout="vertical"
                requiredMark={false}
                autoComplete="off"
                className="modalForm"
                onFinish={onFinish}
                fields={fields}
                onFieldsChange={(_, allFields) => {
                    setFields(allFields)
                }}
            >
                <Form.Item
                    label={'Работа выполнена?'}
                    name="is_completed"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Radio.Group onChange={(e) => setValue(e.target.value)}>
                        <Radio value={true}>Да</Radio>
                        <Radio value={false}>Нет</Radio>
                    </Radio.Group>
                </Form.Item>
                {!value &&
                    <Form.Item
                        label={'Выберите причину'}
                        name="reason"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите причину' />
                    </Form.Item>}
                <p className='p14_400 p_mb4'>Оцените работу:</p>
                <div style={{ marginBottom: 12 }}>
                    {createReviews()}
                </div>
                <Form.Item
                    label={'Комментарий:'}
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите комментарий' />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <SimpleButton
                        title={'Отправить'}
                        className="p14_500"
                        htmltype="submit"
                        style={{
                            color: '#FFF',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#01963A',
                            fontWeight: 500,
                            padding: '11px 16px',
                        }}
                    />
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateReview: (data) => dispatch(fetchCreateReview(data)),
        fetchCompleteContract: (contract_id) => dispatch(fetchCompleteContract(contract_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EvaluationContractForm);