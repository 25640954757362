import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';

import noFound from 'assets/images/noFound.svg';
import squareAvatar from 'assets/images/testImages/squareAvatar.png';

function Squares({ public_user_by_id }) {
    const navigate = useNavigate()

    return (
        public_user_by_id.squares.length > 0
            ?
            <>
                {public_user_by_id.squares.map(el => (
                    <div
                        key={el.id}
                        className='technicCard'
                        onClick={() => navigate(`/market/fields/${el.id}`)}
                        style={{ width: '100%', alignItems: 'center' }}
                    >
                        <img src={el.Images.length > 0 ? el.Images[0].media.link : squareAvatar} alt="technicAvatar" />
                        <p className='p14_600 p_mb8'>{el.square_type.name} <span className='p14_400'>({el.number})</span></p>
                        {/* <div className='technicCard_description'>
                            <p className='p14_600'>{item.manufacturer} <span className='p14_400'>({item.model})</span></p>
                            <div className='technicCard_description__status' style={{ backgroundColor: item.is_busy ? '#D32F2F' : '#43A047' }}>{item.is_busy ? 'Занята' : 'Свободна'}</div>
                        </div> */}
                    </div>
                ))}
            </>
            : <InfoBlock style={{ textAlign: 'center' }}>
                <img src={noFound} alt="noFound" />
            </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        public_user_by_id: state.publicUser.public_user_by_id
    }
}

export default connect(mapStateToProps, null)(Squares);