import { useEffect } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import CreateReplyByTechnic from 'components/components/forms/market/createReplyByTechnic/CreateReplyByTechnic';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import TextHeading from 'components/ui/teaxtHeading/TextHeading';

import '../../style.css'

function TechnicByIdCreateReply({ access_token }) {
    const [form] = Form.useForm();
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!access_token) {
            navigate(`/market/technics/${id}`)
        }
    }, [])

    return (
        <div className='createReply'>
            <div className='createReply_header'>
                <TextHeading text={'Предложить сделку'} />
                <div className='createReply_header__buttons'>
                    <SimpleButton
                        title={'Отмена'}
                        className="whiteButton"
                        onClick={() => navigate(`/market/technics/${id}`)}
                    />
                    <SimpleButton
                        title={'Отправить'}
                        className="greenButton"
                        onClick={() => form.submit()}
                    />
                </div>
            </div>
            <InfoBlock >
                <CreateReplyByTechnic form={form} />
            </InfoBlock>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        access_token: state.userEnv.access_token,
    }
}

export default connect(mapStateToProps, null)(TechnicByIdCreateReply);