import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { headerList } from 'data/routings';
import { Badge, Dropdown } from 'antd';

import HeaderLogo from 'assets/images/headerLogo.svg';
import angleDown from 'assets/images/icons/angle-down.svg';

import 'components/layout/style.css';
import 'components/layout/media.css';
import { EXIT } from 'store/actionTypes';

function Header({ EXIT, user_data, unreaded_messages, notifications }) {
    const [messagesDot, setMessagesDot] = useState(false)
    const [notificationsDot, setNotificationsDot] = useState(false)

    useEffect(() => {
        let count = 0
        let notificationCount = 0
        unreaded_messages.forEach(el => {
            for (let i in el) {
                count += el[i]
            }
        })

        notifications.forEach(el => {
            if (!el.is_readed) notificationCount += 1
        })

        if (count === 0) {
            setMessagesDot(false)
        } else {
            setMessagesDot(true)
        }

        if (notificationCount === 0) {
            setNotificationsDot(false)
        } else {
            setNotificationsDot(true)
        }
    }, [unreaded_messages, notifications])

    const items = [
        {
            label: <NavLink
                to={'/user/favorites'}
                className={({ isActive }) => isActive ? 'active-header-link' : 'mainText'}
            >
                Избранное
            </NavLink>,
            key: '0',
        },
        {
            label: <p style={{ color: 'red' }} onClick={EXIT}>Выйти</p>,
            key: '1',
        }
    ];

    return (
        user_data &&
        <div className="userHeader">
            <div className="userHeader_leftPart">
                <img src={HeaderLogo} alt="HeaderLogo" />
                {headerList.map((item, key) => (
                    <NavLink to={item.to} key={`headerLink_${key}`} className={({ isActive }) => isActive ? 'header_link active-header-link' : 'header_link'}>{item.title}</NavLink>
                ))}
            </div>
            <div className="userHeader_rightPart">
                <Badge dot={messagesDot} style={{ marginTop: 4, marginRight: 20, backgroundColor: '#FFC107' }}>
                    <NavLink to={'/user/messages'} style={{ marginRight: 20 }} className={({ isActive }) => isActive ? 'header-active-link' : ''}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3917 14.025L15.7167 16.6583C15.8 17.35 15.0584 17.8333 14.4667 17.4749L10.975 15.3999C10.5917 15.3999 10.2167 15.375 9.85004 15.325C10.4667 14.6 10.8334 13.6833 10.8334 12.6916C10.8334 10.3249 8.78337 8.40831 6.25004 8.40831C5.28337 8.40831 4.39171 8.68329 3.65004 9.16663C3.62504 8.95829 3.6167 8.74995 3.6167 8.53328C3.6167 4.74162 6.90837 1.66663 10.975 1.66663C15.0417 1.66663 18.3334 4.74162 18.3334 8.53328C18.3334 10.7833 17.175 12.775 15.3917 14.025Z" stroke="#161616" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.8334 12.6916C10.8334 13.6833 10.4668 14.6 9.85009 15.325C9.02509 16.325 7.71675 16.9666 6.25008 16.9666L4.07508 18.2583C3.70841 18.4833 3.24175 18.175 3.29175 17.75L3.50008 16.1083C2.38341 15.3333 1.66675 14.0916 1.66675 12.6916C1.66675 11.225 2.45009 9.9333 3.65009 9.16664C4.39175 8.6833 5.28341 8.40833 6.25008 8.40833C8.78342 8.40833 10.8334 10.325 10.8334 12.6916Z" stroke="#161616" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </NavLink>
                </Badge>
                <Badge dot={notificationsDot} style={{ marginTop: 4, marginRight: 5, backgroundColor: '#FFC107' }}>
                    <NavLink to={'/user/notifications'} style={{ marginRight: 4, display: 'flex' }} className={({ isActive }) => isActive ? 'header-active-link' : ''}>
                        <div style={{ marginTop: 4 }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0167 2.42505C7.25841 2.42505 5.01674 4.66672 5.01674 7.42505V9.83338C5.01674 10.3417 4.80007 11.1167 4.54174 11.55L3.58341 13.1417C2.99174 14.125 3.40007 15.2167 4.48341 15.5834C8.07507 16.7834 11.9501 16.7834 15.5417 15.5834C16.5501 15.2501 16.9917 14.0584 16.4417 13.1417L15.4834 11.55C15.2334 11.1167 15.0167 10.3417 15.0167 9.83338V7.42505C15.0167 4.67505 12.7667 2.42505 10.0167 2.42505Z" stroke="#161616" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                                <path d="M11.5584 2.6667C11.3001 2.5917 11.0334 2.53337 10.7584 2.50003C9.95843 2.40003 9.19176 2.45837 8.4751 2.6667C8.71676 2.05003 9.31676 1.6167 10.0168 1.6167C10.7168 1.6167 11.3168 2.05003 11.5584 2.6667Z" stroke="#161616" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5166 15.8833C12.5166 17.2583 11.3916 18.3833 10.0166 18.3833C9.33327 18.3833 8.69993 18.1 8.24993 17.65C7.79993 17.2 7.5166 16.5666 7.5166 15.8833" stroke="#161616" stroke-width="1.5" stroke-miterlimit="10" />
                            </svg>
                        </div>
                    </NavLink>
                </Badge>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()} className="userMenu">
                        {user_data.image
                            ?
                            <div className='userHeader_rightPart__avatar' style={{ backgroundImage: `url(${user_data.image.media.link})` }} />
                            :
                            <div className='userHeader_rightPart__avatar__text p14_500'>{user_data.first_name[0].toUpperCase() + user_data.last_name[0].toUpperCase()}</div>
                        }
                        <img src={angleDown} alt="angleDown" className="userHeader_rightPart__menu" />
                    </a>
                </Dropdown>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
        unreaded_messages: state.headerInfo.unreaded_messages,
        notifications: state.headerInfo.notifications
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        EXIT: () => dispatch({ type: EXIT })
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(Header);
