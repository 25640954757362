import {
    FETCH_ROLES_SUCCESS
} from '../actionTypes'

const initialState = {
    roles: []
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.data.roles
            }
        default: return state
    }
}