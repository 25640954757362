import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "antd";
import { Map, FullscreenControl, SearchControl, GeolocationControl, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import CreatePlot from "components/components/forms/plot/createPlot/CreatePlot";

import pin from 'assets/images/pins/field.svg';

function Plot({ mode, plots, coords }) {
    const [pinCoords, setCoords] = useState(null)
    const [plot, setPlot] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm();
    const { id } = useParams()

    useEffect(() => {
        if (mode === 'byId') {
            setPlot(plots[id])
        }
    }, [])

    const defaultState = {
        center: coords || [55.751574, 37.573856],
        zoom: 10,
        type: 'yandex#hybrid'
    };

    return (
        <div className='allUsersBlock'>
            <div className='createCarBlock_header'>
                <TextHeading text={mode === 'byId' ? 'Участок' : 'Создание участка'} />
                {mode === 'byId'
                    ? <SimpleButton
                        title={'Назад'}
                        style={{
                            color: '#01963A',
                            border: '1px solid #01963A',
                            borderRadius: '100px',
                            backgroundColor: '#FFF',
                            fontWeight: 500,
                            marginRight: 16
                        }}
                        onClick={() => navigate(`/user/my-fields/full-create`, { state: { step: location.state ? location.state.step : 0 } })}
                    />
                    :
                    <div className='userProfile_header__actions'>
                        <SimpleButton
                            title={'Назад'}
                            style={{
                                color: '#01963A',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#FFF',
                                fontWeight: 500,
                                marginRight: 16
                            }}
                            onClick={() => navigate(`/user/my-fields/full-create`, { state: { step: location.state ? location.state.step : 0 } })}
                        />
                        <SimpleButton
                            title={'Сохранить'}
                            style={{
                                color: '#FFF',
                                border: '1px solid #01963A',
                                borderRadius: '100px',
                                backgroundColor: '#01963A',
                                fontWeight: 500
                            }}
                            onClick={() => form.submit()}
                        />
                    </div>
                }
            </div>
            {mode === 'byId'
                ? (plot
                    ?
                    <InfoBlock
                        style={{ marginTop: 24 }}
                        className="infoBlock twoRow"
                    >
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Номер</p>
                            <p className='p14_400 mainText p_mb16'>{plot.number}</p>
                            <p className='p12_400 mainText p_mb8'>Фактический посев, Га</p>
                            <p className='p14_400 mainText'>{plot.fact_sowing}</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className='p12_400 mainText p_mb8'>Порядковый номер</p>
                            <p className='p14_400 mainText p_mb16'>{plot.serial}</p>
                            <p className='p12_400 mainText p_mb8'>Выращиваемая культура</p>
                            <p className='p14_400 mainText'>{plot.crop_type_name}</p>
                        </div>
                    </InfoBlock>
                    : null)
                :
                <InfoBlock
                    style={{ marginTop: 24 }}
                >
                    <CreatePlot coords={pinCoords} form={form} step={location.state ? location.state.step : 0} />
                </InfoBlock>
            }
            <div className='locationBlock_map'>
                <Map
                    defaultState={defaultState}
                    width='100%'
                    height='100%'
                    onClick={(e) => { if (mode === 'create') setCoords(e.get('coords')) }}
                >
                    <FullscreenControl />
                    <SearchControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    <TypeSelector options={{ float: 'right' }} />
                    {pinCoords &&
                        <Placemark
                            geometry={pinCoords}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: pin,
                                iconImageSize: [70, 70],
                                // iconImageOffset: [-5, -38]
                            }}
                        />
                    }
                </Map>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        plots: state.createSquare.plots,
        coords: state.createSquare.coords,
    }
}

export default connect(mapStateToProps, null)(Plot);