import { connect } from 'react-redux'
import { useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { Select } from 'antd';
import * as locales from 'react-date-range/dist/locale';
import { SAVE_CREATE_APPLICATION_DATA_DATE, SAVE_CREATE_TECHNIC_BUSY_PERIOD } from 'store/actionTypes';
import { warningNotificationWithoutConfirmation } from 'hooks/notifications';

import './style.css';
import './media.css';

const { Option } = Select;

function CustomDatePicker({ monthe, ranges, mode, edit, SAVE_CREATE_TECHNIC_BUSY_PERIOD, SAVE_CREATE_APPLICATION_DATA_DATE, saveRange }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [selectRage, setSelectRage] = useState(null)
    const [select, setSelect] = useState(null)
    const [state, setState] = useState(ranges);

    useEffect(() => {
        setSelect(
            <Select
                onChange={(value) => {
                    setSelectRage(value)
                }}
                defaultValue={null}
            >
                {state.map((el, key) => (
                    <Option value={el.key} key={`busy_period_${key}`}>{key + 1}</Option>
                ))}
            </Select>
        )
        if (mode === 'userCreateTechnic') SAVE_CREATE_TECHNIC_BUSY_PERIOD(state)
        if(mode === 'userCreateApplication') SAVE_CREATE_APPLICATION_DATA_DATE(state)
        if (mode === 'adminCreateTechnic') saveRange(state)
        if (mode === 'userEditTechnic') saveRange(state)
        if (mode === 'userCreateReply') saveRange(state)
    }, [state])

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    const onAddRage = () => {
        setState([...state, {
            startDate: new Date(),
            endDate: new Date(),
            key: `selection${state.length + 1}`
        },])
    }

    const deleteRange = () => {
        if (selectRage) {
            setState(state.filter(item => item.key !== selectRage))
            setSelect(null)
            setSelectRage(null)
        } else {
            warningNotificationWithoutConfirmation('Вы не выбрали период')
        }
    }

    const onChangeDate = (item) => {
        let stateCopy = state.map(a => {return {...a}})
        stateCopy[state.findIndex(el => el.key === Object.keys(item)[0])] = item[Object.keys(item)[0]]
        setState(stateCopy);
    }

    return (
        <>
            {((edit || edit === undefined) && mode !== 'userCreateApplication') &&
                <>
                    <div
                        className='addRange p14_500 p_mb12'
                        onClick={onAddRage}
                    >
                        Добавить период занятости
                    </div>
                    {(state.length > 0 ) &&
                        <div className='deleteRage'>
                            <p onClick={() => deleteRange()}>Удалить период занятости</p>
                            {select}
                        </div>
                    }
                </>
            }
            <DateRange
                onChange={item => onChangeDate(item)}
                minDate={new Date()}
                months={monthe ? monthe : (width < 1120 ? 1 : 2)}
                locale={locales['ru']}
                direction="horizontal"
                ranges={state}
                rangeColors={['#01963A', '#01963A']}
                showPreview={((edit || edit === undefined) && state.length > 0) ? true : false}
                showDateDisplay={mode === 'marketDatePicker' ? false : true}
            />
        </>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        SAVE_CREATE_TECHNIC_BUSY_PERIOD: (busyPeriods) => dispatch({ type: SAVE_CREATE_TECHNIC_BUSY_PERIOD, busyPeriods }),
        SAVE_CREATE_APPLICATION_DATA_DATE: (date) => dispatch({type: SAVE_CREATE_APPLICATION_DATA_DATE, date})
    }
}

export default connect(null, mapDispatvhToProps)(CustomDatePicker);