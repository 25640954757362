import { api_busyPeriod } from "api/admin/busyPeriod"

export function fetchCreateBusyPeriod(data) {
    return async dispatch => {
        try {
            await api_busyPeriod.PostCreateBusyPeriod(data)
        } catch (e) {
        }
    }
}

export function fetchUpdateBusyPeriod(busyPeriod_id, data) {
    return async dispatch => {
        try {
            await api_busyPeriod.UpdateBusyPeriod(busyPeriod_id, data)
        } catch (e) {
        }
    }
}

export function fetchDeleteBusyPeriod(busyPeriod_id) {
    return async dispatch => {
        try {
            await api_busyPeriod.DeleteBusyPeriod(busyPeriod_id)
        } catch (e) {
        }
    }
}