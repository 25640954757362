import { connect } from 'react-redux'
import EditMainInfo from 'components/components/forms/profile/editMainInfo/EditMainInfo';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import EditPhoneAndEmail from 'components/components/forms/profile/editMainInfo/EditPhoneAndEmail';

function MainInfo({ edit, onEdit, user_data, form }) {
    return (
        !edit
            ? <div>
                <InfoBlock style={{ marginBottom: 16 }}>
                    <p className='p12_400 mainText p_mb8'>ФИО</p>
                    <p className='p14_400 mainText p_mb16'>{user_data.first_name + ' ' + user_data.last_name + ' ' + user_data.third_name}</p>
                    <p className='p12_400 mainText p_mb8'>Электронная почта</p>
                    <p className='p14_400 mainText p_mb16'>{user_data.email ? user_data.email : '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Номер телефона</p>
                    <p className='p14_400 mainText'>{user_data.phone ? '+' + user_data.phone : '-'}</p>
                </InfoBlock>
                <InfoBlock>
                    <p className='p12_400 mainText p_mb8'>Тип лица</p>
                    <p className='p14_400 mainText'>{user_data.jur_type === 'PHYSICAL' ? 'Физическое' : 'Юридическое'}</p>
                </InfoBlock>
            </div>
            :
            <div>
                <InfoBlock style={{ marginBottom: 16 }}>
                    <EditMainInfo onEdit={onEdit} form={form} />
                </InfoBlock>
                <InfoBlock>
                    <EditPhoneAndEmail />
                </InfoBlock>
            </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data
    }
}

export default connect(mapStateToProps, null)(MainInfo);