import { useState } from 'react';
import { connect } from 'react-redux'
import { CLOSE_MODAL } from 'store/actionTypes';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton'
import SeasonCollapseItem from 'components/shared/seasonCollapse/SeasonCollapseItem';

function AddPlotToTheSeason({ setVisible, data, visible }) {
    const [selectPlots, setSelectPlots] = useState([])

    const onSelectPlot = (checked, plot) => {
        const array = JSON.parse(JSON.stringify(selectPlots))
        if (checked) {
            array.push(plot)
            setSelectPlots(array)
        } else {
            array.splice(selectPlots.findIndex(el => el.index === plot.index), 1)
            setSelectPlots(array)
        }
    }

    const addData = () => {
        data.addPlotOnSeasons(data.seasonIndex, selectPlots)
        setSelectPlots([])
        setVisible()
    }

    return (
        <div>
            <p className='mainText p24_600 p_mb24' style={{ textAlign: 'center' }}>Добавить участок в сезон</p>
            <div className='seasonCollapseBlock'>
                <div
                    style={{ minWidth: '1000px' }}
                >
                    {visible && data.plots.filter(({ index: id1 }) => !data.plotsInSeason.some(({ index: id2 }) => id2 === id1)).map((el, key) => (
                        <SeasonCollapseItem
                            key={`seasonCollapsItem_${key}`}
                            item={el}
                            mode='addPlot'
                            onSelectPlot={onSelectPlot}
                        />
                    ))}
                    {data.plotsInSeason.length > 0 && visible && data.plots.filter(({ id: id1 }) => !data.plotsInSeason.some(({ id: id2 }) => id2 === id1)).map((el, key) => (
                        <SeasonCollapseItem
                            key={`seasonCollapsItem_${key}`}
                            item={el}
                            mode='addPlot'
                            onSelectPlot={onSelectPlot}
                        />
                    ))}
                </div>
            </div>
            <SimpleButton
                title={'Добавить в сезон'}
                style={{
                    marginTop: 20,
                    marginBottom: 11,
                    background: '#01963A',
                    borderRadius: '100px',
                    padding: '11px 16px',
                    width: '100%'
                }}
                onClick={addData}
            />
            <p
                className='activeText p14_500'
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => {
                    setVisible()
                    data.linkOnCreatPlot()
                }}
            >
                Создать новый участок
            </p>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
        visible: state.modal.visible
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(AddPlotToTheSeason);