import { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchPasswordReset } from 'store/actions/login';
import { checkPassword } from 'hooks/checkPassword';
import FormButton from 'components/shared/buttons/formButton/FormButton';

function EnterNewPassword({ fetchPasswordReset }) {
    const [errorStatusFirst, setErrorStatusFirst] = useState({})
    const [errorStatusSecond, setErrorStatusSecond] = useState({})
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const onFinish = ({ new_password, replay_password }) => {
        if (checkPassword(new_password)) {
            if (replay_password === new_password) {
                const data = {
                    token: searchParams.get('token'),
                    new_password
                }
                fetchPasswordReset(data)
                    .then(res => {
                        if (res === 'success') navigate('/')
                    })
            } else {
                setErrorStatusFirst({
                    validateStatus: "error"
                })
                setErrorStatusSecond({
                    validateStatus: "error",
                    help: "Пароли не совпадают"
                })
            }
        } else {
            setErrorStatusFirst({
                validateStatus: "error",
                help: "Некорректный новый пароль. Пароль должен состоять минимум из 8 символов и содержать буквы верхнего и нижнего регистра, цифры и специальные символы."
            })
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            onFieldsChange={() => {
                setErrorStatusFirst({})
                setErrorStatusSecond({})
            }}
        >
            <Form.Item
                label={'Новый пароль'}
                name="new_password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusFirst}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item
                label={'Повторите пароль'}
                name="replay_password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusSecond}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Восстановить'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                />
            </Form.Item>
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchPasswordReset: (data) => dispatch(fetchPasswordReset(data))
    }
}

export default connect(null, mapDispatvhToProps)(EnterNewPassword);