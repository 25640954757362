import { ApiUrl, instance } from './apiEnv';

export const api_address = {

    async PostCreateAddress(data) {
        return await instance.post(`${ApiUrl}addresses`, data)
    },

    async UpdateAddress(data, address_id) {
        return await instance.put(`${ApiUrl}addresses/${address_id}`, data)
    },

}