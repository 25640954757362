import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { CLOSE_MODAL } from 'store/actionTypes';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function FileComplaintForm({ reject, setVisible }) {
    
    const onFinish = (values) => {
        reject(values)
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Причина жалобы'}
                name="reason"
                rules={[
                    {
                        required: true,
                        message: 'Опишите кратко причину жалобы',
                    },
                ]}
            >
                <Input placeholder='Опишите кратко причину жалобы' />
            </Form.Item>
            <Form.Item
                label={'Комментарий'}
                name="comment"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Опишите подробную информацию для рассмотрения' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500"
                        style={{
                            border: '1px solid #01963A',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: 'white',
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Подать жалобу'}
                        className="p14_500"
                        htmltype="submit"
                        style={{
                            color: 'white',
                            padding: '11px 16px',
                            borderRadius: '100px',
                            backgroundColor: '#D32F2F'
                        }}
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(null, mapDispatvhToProps)(FileComplaintForm);