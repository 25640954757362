import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Input } from 'antd';
import { SET_VISIBLE_MODAL } from 'store/actionTypes';
import { fetchSensitiveCode } from 'store/actions/user';
import { numberInputFormat } from 'hooks/numderInputFormat';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function EditPhoneAndEmail({ user_data, setVisible, fetchSensitiveCode }) {
    const [emailForm, setEmail] = useState([{
        name: 'email',
        value: user_data.email,
    }])
    const [phoneForm, setPhone] = useState([{
        name: 'phone',
        value: user_data.phone.substring(1),
    }])

    const emailFinish = ({ email }) => {
        if (emailForm[0].value !== user_data.email) {
            fetchSensitiveCode(email, null)
                .then(res => {
                    if (res) {
                        setVisible({
                            type: 'email',
                            info: user_data.email,
                            email,
                            phone: null,
                        })
                    }
                })
        }
    }

    const phoneFinish = ({ phone }) => {
        if (phoneForm[0].value !== user_data.phone.substring(1)) {
            fetchSensitiveCode(null, '7' + phone)
                .then(res => {
                    if (res) {
                        setVisible({
                            type: 'phone',
                            info: user_data.phone,
                            email: null,
                            phone: '7' + phone
                        })
                    }
                })
        }
    }

    return (
        <div>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                className="modalForm"
                fields={emailForm}
                onFieldsChange={(_, allFields) => {
                    setEmail(allFields)
                }}
                style={{
                    display: 'flex',
                    alignItems: 'flex-end'
                }}
                onFinish={emailFinish}
            >
                <Form.Item
                    label={'Электронная почта'}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                    style={{
                        width: '100%'
                    }}
                >
                    <Input placeholder='Введите почту' />
                </Form.Item>
                <Form.Item>
                    <SimpleButton
                        title={'Сохранить'}
                        htmlType="submit"
                        style={emailForm[0].value === user_data.email
                            ? {
                                borderRadius: 1000,
                                marginLeft: 20,
                                backgroundColor: '#CCCCCC',
                                color: '#999999'
                            }
                            : {
                                borderRadius: 1000,
                                marginLeft: 20,
                                backgroundColor: '#01963A',
                                color: '#FFFFFF'
                            }}
                    />
                </Form.Item>
            </Form>
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                className="modalForm"
                fields={phoneForm}
                onFieldsChange={(_, allFields) => {
                    setPhone(allFields)
                }}
                style={{
                    display: 'flex',
                    alignItems: 'flex-end'
                }}
                onFinish={phoneFinish}
            >
                <Form.Item
                    label={'Номер телефона'}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                    style={{
                        width: '100%'
                    }}
                >
                    <Input
                        placeholder='(000) 000-0000'
                        prefix={'+7'}
                        type="number"
                        onKeyDown={numberInputFormat}
                    />
                </Form.Item>
                <Form.Item>
                    <SimpleButton
                        title={'Сохранить'}
                        htmlType="submit"
                        style={phoneForm[0].value === user_data.phone.substring(1)
                            ? {
                                borderRadius: 1000,
                                marginLeft: 20,
                                backgroundColor: '#CCCCCC',
                                color: '#999999'
                            }
                            : {
                                borderRadius: 1000,
                                marginLeft: 20,
                                backgroundColor: '#01963A',
                                color: '#FFFFFF'
                            }}
                    />
                </Form.Item>
            </Form>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'confirmProfileChanges', data }),
        fetchSensitiveCode: (email, phone) => dispatch(fetchSensitiveCode(email, phone))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditPhoneAndEmail);