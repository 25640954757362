import { problemsData } from 'data/mainPage'

import './style.css';
import './media.css';

const Problems = () => {
  return (
    <div className='problemsBlock'>
      <p className='p36_700 p_mb24' style={{ textAlign: 'center' }}>Проблемы которые мы решаем</p>
      <div className='problemsBlock_item'>
        {problemsData.map((problem, key) => (
          <div
            className='problemsBlock_item__column'
            key={`problemItem_${key}`}
          >
            <p className='p14_600 activeText'>{problem.title}</p>
            {problem.list.map((item, i) => (
              <div
                className='problemItem_listItem'
                key={`problemItem_listItem__${i}`}
              >
                <div className='problemItem_listItem__img'><img src={item.icon} alt="icon" /></div>
                <div>
                  <p className='p24_600 p_mb12'>{item.title}</p>
                  <p className='subtext p16_400'>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Problems