import messages from 'assets/images/headerIcons/messages.svg';
import notification from 'assets/images/headerIcons/notification.svg';

/*list of links in the header */
export const headerList = [
    {
        to: '/market/technics',
        title: 'Биржа техники'
    },
    {
        to: '/market/fields',
        title: 'Биржа полей'
    }
]

/*list of links in the user header */
export const userHeaderList = [
    {
        to: '/',
        icon: messages
    },
    {
        to: '/user/notifications',
        icon: notification
    }
]

/*list of links in the footer */
export const footerList = [
    {
        to: '/',
        title: 'Главная'
    },
    {
        to: 'rules/about-us',
        title: 'О нас'
    },
    {
        to: 'rules/privacy',
        title: 'Политика конфиденциальности'
    },
    {
        to: 'rules/terms-of-use',
        title: 'Условия использования'
    },
    {
        to: 'rules/contact-us',
        title: 'Связаться с нами'
    }
]

/*list of links in the login */
export const loginList = [
    {
        to: '/password-recovery',
        title: 'Забыли пароль?'
    },
    {
        to: '/signup/first-stage',
        title: 'Зарегистрироваться'
    },
]

/*list of links in the admin login */
export const adminLoginList = [
    {
        to: 'amdin/password-recovery',
        title: 'Забыли пароль?'
    }
]

/*list of links in the password recovery */
export const passwordRecoveryList = [
    {
        to: '/login',
        title: 'Войти'
    },
    {
        to: '/signup/first-stage',
        title: 'Зарегистрироваться'
    },
]

/*list of links in the password recovery */
export const signupList = [
    {
        to: '/login',
        title: 'Войти'
    },
    {
        to: '/rules/privacy',
        title: 'политикой конфиденциальности'
    },
    {
        to: '/rules/terms-of-use',
        title: 'условиями использования'
    },
]

/*list rules in pules page */
export const rulesPageList = [
    {
        title: 'FAQ',
        to: '/rules/faq'
    },
    {
        title: 'Политика конфиденциальности',
        to: '/rules/privacy'
    },
    {
        title: 'Условия использования',
        to: '/rules/terms-of-use'
    },
    {
        title: 'О нас',
        to: '/rules/about-us'
    },
    {
        title: 'Связаться с нами',
        to: '/rules/contact-us'
    },
]

export const menuListUser = [
    {
        title: 'Профиль',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10664 7.24671C6.03998 7.24004 5.95998 7.24004 5.88664 7.24671C4.29998 7.19337 3.03998 5.89337 3.03998 4.29337C3.03998 2.66004 4.35998 1.33337 5.99998 1.33337C7.63331 1.33337 8.95998 2.66004 8.95998 4.29337C8.95331 5.89337 7.69331 7.19337 6.10664 7.24671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.94 2.66663C12.2333 2.66663 13.2733 3.71329 13.2733 4.99996C13.2733 6.25996 12.2733 7.28663 11.0267 7.33329C10.9733 7.32663 10.9133 7.32663 10.8533 7.33329" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.77335 9.70663C1.16002 10.7866 1.16002 12.5466 2.77335 13.62C4.60669 14.8466 7.61335 14.8466 9.44669 13.62C11.06 12.54 11.06 10.78 9.44669 9.70663C7.62002 8.48663 4.61335 8.48663 2.77335 9.70663Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.2267 13.3334C12.7067 13.2334 13.16 13.04 13.5334 12.7534C14.5734 11.9734 14.5734 10.6867 13.5334 9.90671C13.1667 9.62671 12.72 9.44004 12.2467 9.33337" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-profile'
    },
    {
        title: 'Техника',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.34 1.88672H5.66002C4.00002 1.88672 3.63335 2.71339 3.42002 3.72672L2.66669 7.33339H13.3334L12.58 3.72672C12.3667 2.71339 12 1.88672 10.34 1.88672Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.66 13.2134C14.7333 13.9934 14.1067 14.6667 13.3067 14.6667H12.0533C11.3333 14.6667 11.2333 14.36 11.1067 13.98L10.9733 13.58C10.7867 13.0334 10.6667 12.6667 9.70667 12.6667H6.29334C5.33334 12.6667 5.19334 13.08 5.02667 13.58L4.89334 13.98C4.76667 14.36 4.66667 14.6667 3.94667 14.6667H2.69334C1.89334 14.6667 1.26667 13.9934 1.34001 13.2134L1.71334 9.15337C1.80667 8.15337 2.00001 7.33337 3.74667 7.33337H12.2533C14 7.33337 14.1933 8.15337 14.2867 9.15337L14.66 13.2134Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.66667 5.33337H2" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 5.33337H13.3333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 2V3.33333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 3.33337H9" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 10H6" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 10H12" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-cars'
    },
    {
        title: 'Поля',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99998 14.6667H9.99998C13.3333 14.6667 14.6666 13.3334 14.6666 10V6.00004C14.6666 2.66671 13.3333 1.33337 9.99998 1.33337H5.99998C2.66665 1.33337 1.33331 2.66671 1.33331 6.00004V10C1.33331 13.3334 2.66665 14.6667 5.99998 14.6667Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.33331 8H14.6666" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5 8V14.3333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 14.6667V8" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5 8.00004V1.33337" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 8.00003V1.69336" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-fields'
    },
    {
        title: 'Заявки',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66667 8C2 8 2 9.19333 2 10.6667V11.3333C2 13.1733 2 14.6667 5.33333 14.6667H10.6667C13.3333 14.6667 14 13.1733 14 11.3333V10.6667C14 9.19333 14 8 11.3333 8C10.6667 8 10.48 8.14 10.1333 8.4L9.45333 9.12C8.66667 9.96 7.33333 9.96 6.54 9.12L5.86667 8.4C5.52 8.14 5.33333 8 4.66667 8Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.6666 8.00004V4.00004C12.6666 2.52671 12.6666 1.33337 9.99992 1.33337H5.99992C3.33325 1.33337 3.33325 2.52671 3.33325 4.00004V8.00004" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.03345 6.15332H9.25345" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.47998 4.15332H9.81331" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-applications'
    },
    {
        title: 'Контракты',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 6.66671V10C14.6666 13.3334 13.3333 14.6667 9.99998 14.6667H5.99998C2.66665 14.6667 1.33331 13.3334 1.33331 10V6.00004C1.33331 2.66671 2.66665 1.33337 5.99998 1.33337H9.33331" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6666 6.66671H12C9.99998 6.66671 9.33331 6.00004 9.33331 4.00004V1.33337L14.6666 6.66671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 8.66663H8.66669" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 11.3334H7.33335" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-contracts'
    },
    {
        title: 'Предложения',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.44 6.95996L13.7866 9.74663C13.2266 12.1533 12.12 13.1266 10.04 12.9266C9.70664 12.9 9.34664 12.84 8.95998 12.7466L7.83998 12.48C5.05998 11.82 4.19998 10.4466 4.85331 7.65997L5.50664 4.86663C5.63998 4.29996 5.79998 3.80663 5.99998 3.39996C6.77998 1.78663 8.10664 1.3533 10.3333 1.87996L11.4466 2.13996C14.24 2.7933 15.0933 4.1733 14.44 6.95996Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.04 12.9267C9.62665 13.2067 9.10665 13.44 8.47332 13.6467L7.41998 13.9934C4.77332 14.8467 3.37998 14.1334 2.51998 11.4867L1.66665 8.85336C0.813317 6.20669 1.51998 4.80669 4.16665 3.95336L5.21998 3.60669C5.49332 3.52002 5.75332 3.44669 5.99998 3.40002C5.79998 3.80669 5.63998 4.30002 5.50665 4.86669L4.85332 7.66002C4.19998 10.4467 5.05998 11.82 7.83998 12.48L8.95998 12.7467C9.34665 12.84 9.70665 12.9 10.04 12.9267Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.4267 5.68665L11.66 6.50665" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.77332 8.26672L9.70665 8.76006" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-offers'
    },
    {
        title: 'Споры',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6V9.33333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99998 14.2734H3.95998C1.64664 14.2734 0.679976 12.6201 1.79998 10.6001L3.87998 6.85339L5.83998 3.33339C7.02664 1.19339 8.97331 1.19339 10.16 3.33339L12.12 6.86006L14.2 10.6067C15.32 12.6267 14.3466 14.2801 12.04 14.2801H7.99998V14.2734Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99634 11.3334H8.00233" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-conflicts'
    },
    {
        title: 'Чаты',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9867 7.19334V9.86001C11.9867 10.0333 11.98 10.2 11.96 10.36C11.8066 12.16 10.7466 13.0533 8.79331 13.0533H8.52665C8.35998 13.0533 8.19998 13.1333 8.09998 13.2667L7.29999 14.3333C6.94665 14.8067 6.37331 14.8067 6.01998 14.3333L5.21997 13.2667C5.1333 13.1533 4.93998 13.0533 4.79331 13.0533H4.52665C2.39999 13.0533 1.33331 12.5267 1.33331 9.86001V7.19334C1.33331 5.24001 2.23332 4.18001 4.02665 4.02667C4.18665 4.00667 4.35332 4 4.52665 4H8.79331C10.92 4 11.9867 5.06667 11.9867 7.19334Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6533 4.52671V7.19338C14.6533 9.15338 13.7533 10.2067 11.96 10.36C11.98 10.2 11.9867 10.0334 11.9867 9.86005V7.19338C11.9867 5.06671 10.92 4.00004 8.79333 4.00004H4.52667C4.35334 4.00004 4.18667 4.00671 4.02667 4.02671C4.18001 2.23338 5.24001 1.33337 7.19334 1.33337H11.46C13.5867 1.33337 14.6533 2.40005 14.6533 4.52671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99702 8.83333H9.00302" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66365 8.83333H6.66965" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.33033 8.83333H4.33633" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/messages'
    },
    // {
    //     title: 'Подключение GPS',
    //     icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M4.46667 6.17334L8 8.22001L11.5067 6.18667" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M8 11.8466V8.21332" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M7.17332 4.19334L5.03998 5.38001C4.55998 5.64667 4.15997 6.32 4.15997 6.87334V9.13334C4.15997 9.68667 4.55331 10.36 5.03998 10.6267L7.17332 11.8133C7.62665 12.0667 8.37331 12.0667 8.83331 11.8133L10.9667 10.6267C11.4467 10.36 11.8466 9.68667 11.8466 9.13334V6.86667C11.8466 6.31334 11.4533 5.64 10.9667 5.37334L8.83331 4.18667C8.37331 3.93334 7.62665 3.93334 7.17332 4.19334Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M14.6667 10C14.6667 12.58 12.58 14.6667 10 14.6667L10.7 13.5" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M1.33331 5.99998C1.33331 3.41998 3.41998 1.33331 5.99998 1.33331L5.29999 2.49998" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //     </svg>,
    //     path: 'gps'
    // },
]

export const menuListAdmin = [
    {
        title: 'Пользователи',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10664 7.24671C6.03998 7.24004 5.95998 7.24004 5.88664 7.24671C4.29998 7.19337 3.03998 5.89337 3.03998 4.29337C3.03998 2.66004 4.35998 1.33337 5.99998 1.33337C7.63331 1.33337 8.95998 2.66004 8.95998 4.29337C8.95331 5.89337 7.69331 7.19337 6.10664 7.24671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.94 2.66663C12.2333 2.66663 13.2733 3.71329 13.2733 4.99996C13.2733 6.25996 12.2733 7.28663 11.0267 7.33329C10.9733 7.32663 10.9133 7.32663 10.8533 7.33329" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.77335 9.70663C1.16002 10.7866 1.16002 12.5466 2.77335 13.62C4.60669 14.8466 7.61335 14.8466 9.44669 13.62C11.06 12.54 11.06 10.78 9.44669 9.70663C7.62002 8.48663 4.61335 8.48663 2.77335 9.70663Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.2267 13.3334C12.7067 13.2334 13.16 13.04 13.5334 12.7534C14.5734 11.9734 14.5734 10.6867 13.5334 9.90671C13.1667 9.62671 12.72 9.44004 12.2467 9.33337" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'users'
    },
    {
        title: 'Техника',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.34 1.88672H5.66002C4.00002 1.88672 3.63335 2.71339 3.42002 3.72672L2.66669 7.33339H13.3334L12.58 3.72672C12.3667 2.71339 12 1.88672 10.34 1.88672Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.66 13.2134C14.7333 13.9934 14.1067 14.6667 13.3067 14.6667H12.0533C11.3333 14.6667 11.2333 14.36 11.1067 13.98L10.9733 13.58C10.7867 13.0334 10.6667 12.6667 9.70667 12.6667H6.29334C5.33334 12.6667 5.19334 13.08 5.02667 13.58L4.89334 13.98C4.76667 14.36 4.66667 14.6667 3.94667 14.6667H2.69334C1.89334 14.6667 1.26667 13.9934 1.34001 13.2134L1.71334 9.15337C1.80667 8.15337 2.00001 7.33337 3.74667 7.33337H12.2533C14 7.33337 14.1933 8.15337 14.2867 9.15337L14.66 13.2134Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.66667 5.33337H2" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 5.33337H13.3333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 2V3.33333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 3.33337H9" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 10H6" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 10H12" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'cars'
    },
    {
        title: 'Поля',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99998 14.6667H9.99998C13.3333 14.6667 14.6666 13.3334 14.6666 10V6.00004C14.6666 2.66671 13.3333 1.33337 9.99998 1.33337H5.99998C2.66665 1.33337 1.33331 2.66671 1.33331 6.00004V10C1.33331 13.3334 2.66665 14.6667 5.99998 14.6667Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.33331 8H14.6666" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5 8V14.3333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 14.6667V8" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5 8.00004V1.33337" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 8.00003V1.69336" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'fields'
    },
    {
        title: 'Контракты',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 6.66671V10C14.6666 13.3334 13.3333 14.6667 9.99998 14.6667H5.99998C2.66665 14.6667 1.33331 13.3334 1.33331 10V6.00004C1.33331 2.66671 2.66665 1.33337 5.99998 1.33337H9.33331" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6666 6.66671H12C9.99998 6.66671 9.33331 6.00004 9.33331 4.00004V1.33337L14.6666 6.66671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 8.66663H8.66669" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 11.3334H7.33335" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'contracts'
    },
    {
        title: 'Споры',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6V9.33333" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99998 14.2734H3.95998C1.64664 14.2734 0.679976 12.6201 1.79998 10.6001L3.87998 6.85339L5.83998 3.33339C7.02664 1.19339 8.97331 1.19339 10.16 3.33339L12.12 6.86006L14.2 10.6067C15.32 12.6267 14.3466 14.2801 12.04 14.2801H7.99998V14.2734Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99634 11.3334H8.00233" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'conflicts'
    },
    {
        title: 'Жалобы',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 8.13332V9.2667C13.3333 11.3667 12.1333 12.2667 10.3333 12.2667H4.33325C2.53325 12.2667 1.33325 11.3667 1.33325 9.2667V5.66669C1.33325 3.56669 2.53325 2.66669 4.33325 2.66669H6.13325C6.04659 2.92002 5.99992 3.20002 5.99992 3.50002V6.10004C5.99992 6.7467 6.21325 7.29336 6.59325 7.67336C6.97325 8.05336 7.51992 8.2667 8.16659 8.2667V9.19338C8.16659 9.53338 8.55325 9.73999 8.83992 9.55332L10.7666 8.2667H12.4999C12.7999 8.2667 13.0799 8.21999 13.3333 8.13332Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6667 3.49998V6.1C14.6667 7.09333 14.16 7.83995 13.3333 8.13328C13.08 8.21995 12.8 8.26666 12.5 8.26666H10.7667L8.84 9.55328C8.55333 9.73995 8.16667 9.53334 8.16667 9.19334V8.26666C7.52 8.26666 6.97333 8.05332 6.59333 7.67332C6.21333 7.29332 6 6.74666 6 6.1V3.49998C6 3.19998 6.04667 2.91998 6.13333 2.66665C6.42667 1.83998 7.17333 1.33331 8.16667 1.33331H12.5C13.8 1.33331 14.6667 2.19998 14.6667 3.49998Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.93335 14.6667H9.73335" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.33325 12.2667V14.6666" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.3303 4.83333H12.3363" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4639 4.83333H10.4699" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.59693 4.83333H8.60293" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'complaints'
    },
    // {
    //     title: 'Чаты',
    //     icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M11.9867 7.19334V9.86001C11.9867 10.0333 11.98 10.2 11.96 10.36C11.8066 12.16 10.7466 13.0533 8.79331 13.0533H8.52665C8.35998 13.0533 8.19998 13.1333 8.09998 13.2667L7.29999 14.3333C6.94665 14.8067 6.37331 14.8067 6.01998 14.3333L5.21997 13.2667C5.1333 13.1533 4.93998 13.0533 4.79331 13.0533H4.52665C2.39999 13.0533 1.33331 12.5267 1.33331 9.86001V7.19334C1.33331 5.24001 2.23332 4.18001 4.02665 4.02667C4.18665 4.00667 4.35332 4 4.52665 4H8.79331C10.92 4 11.9867 5.06667 11.9867 7.19334Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M14.6533 4.52671V7.19338C14.6533 9.15338 13.7533 10.2067 11.96 10.36C11.98 10.2 11.9867 10.0334 11.9867 9.86005V7.19338C11.9867 5.06671 10.92 4.00004 8.79333 4.00004H4.52667C4.35334 4.00004 4.18667 4.00671 4.02667 4.02671C4.18001 2.23338 5.24001 1.33337 7.19334 1.33337H11.46C13.5867 1.33337 14.6533 2.40005 14.6533 4.52671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M8.99702 8.83333H9.00302" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M6.66365 8.83333H6.66965" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    //         <path d="M4.33033 8.83333H4.33633" stroke="#A8A8A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    //     </svg>,
    //     path: 'messages'
    // },
    {
        title: 'Запросы на удаления',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.66675 3.31331L5.81341 2.43998C5.92008 1.80665 6.00008 1.33331 7.12675 1.33331H8.87341C10.0001 1.33331 10.0867 1.83331 10.1867 2.44665L10.3334 3.31331" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5667 6.09332L12.1334 12.8067C12.06 13.8533 12 14.6667 10.14 14.6667H5.86002C4.00002 14.6667 3.94002 13.8533 3.86668 12.8067L3.43335 6.09332" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.88672 11H9.10672" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.33325 8.33331H9.66659" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'deletion-requests'
    },
]

export const menuSubList = [
    {
        to: '/rules/about-us',
        title: 'О проекте'
    },
    {
        to: '/rules/privacy',
        title: 'Техническая поддержка'
    },
]