export function fetchStart(type) {
    return {
        type
    }
}

export function fetchSuccess(type, data) {
    return {
        type,
        data,
    }
}

export function fetchError(type, e) {
    return {
        type,
        error: e,
    }
}