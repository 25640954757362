import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { CLOSE_MODAL } from 'store/actionTypes';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';

function NoAuth({ data, setVisible }) {
    const navigate = useNavigate()

    return (
        <>
            <p className="mainText p24_600 p_mb16" style={{ textAlign: 'center' }}>Авторизуйтесь</p>
            <p className='mainText p14_400 p_mb24'>{data.worning_text}</p>
            <SimpleButton
                title={'Войти/Зарегистрироваться'}
                style={{
                    color: '#FFF',
                    border: '1px solid #01963A',
                    borderRadius: '100px',
                    backgroundColor: '#01963A',
                    fontWeight: 500,
                    width: '100%',
                    marginBottom: 24
                }}
                onClick={() => {navigate(`/`); setVisible()}}
            />
            <p className='activeText p14_500' style={{textAlign: 'center', cursor: 'pointer'}} onClick={setVisible}>Отменить</p>
        </>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(NoAuth);