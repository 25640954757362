import { useState } from 'react';
import { connect } from 'react-redux'
import { Form, Upload, Radio, Input, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { fetchDeleteSquareDoc, fetchDeleteSquareImage, fetchUpdateSquare, fetchUploadSquareDocs, fetchUploadSquareImage } from 'store/actions/admin/squares';
import { fetchUpdateAddress } from 'store/actions/admin/address';
import { checkFioLength } from 'hooks/checkFioLength';
import { numberInputFormat } from 'hooks/numderInputFormat';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';

import uploadIcon from 'assets/images/icons/upload.svg';

const { Option } = Select;

const propsUpload = {
    action: '',
    accept: ".png, .jpg, .doc, .docx, .pdf, .word",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function EditSquare({
    form,
    setEditMainInfo,
    coords,
    square_by_id_data,
    square_types,
    crop_types,
    sowing_types,
    fetchUpdateSquare,
    fetchUploadSquareImage,
    fetchUploadSquareDocs,
    fetchDeleteSquareDoc,
    fetchUpdateAddress,
    fetchDeleteSquareImage
}) {
    const { id } = useParams()
    const [value, setValue] = useState(square_by_id_data.is_owner)
    const [filePhotoList, setFilePhotoList] = useState(JSON.parse(JSON.stringify(square_by_id_data.Images)))
    const [fileOwnerList, setFileOwnerList] = useState(JSON.parse(JSON.stringify(square_by_id_data.documents)))
    const [errorStatus, setErrorStatus] = useState({})
    const [fields, setFields] = useState([
        {
            name: 'number',
            value: square_by_id_data.number,
        },
        {
            name: 'farmer',
            value: square_by_id_data.farmer
        },
        {
            name: 'is_owner',
            value: square_by_id_data.is_owner
        },
        {
            name: 'cadastral',
            value: square_by_id_data.cadastral
        },
        {
            name: 'description',
            value: square_by_id_data.description
        },
        {
            name: 'size',
            value: square_by_id_data.size
        },
        {
            name: 'square_type_id',
            value: square_by_id_data.square_type_id
        },
        {
            name: 'crop_type_id',
            value: square_by_id_data.crop_type_id
        },
        {
            name: 'sowing_type_id',
            value: square_by_id_data.sowing_type_id
        },
        {
            name: 'filePhotoList',
            value: JSON.parse(JSON.stringify(square_by_id_data.Images))
        },
        {
            name: 'fileOwnerList',
            value: JSON.parse(JSON.stringify(square_by_id_data.documents))
        }
    ]);

    const onFinish = async (values) => {
        if (checkFioLength(values.farmer).length === 3) {
            let newDocs = []
            let deleteDocs = []
            if (values.fileOwnerList) {
                newDocs = values.fileOwnerList.filter(el => !el.media)
                deleteDocs = values.fileOwnerList.filter(el => el.media)
                deleteDocs = square_by_id_data.documents.filter(({ id: id1 }) => !deleteDocs.some(({ id: id2 }) => id2 === id1))
                delete values.fileOwnerList
            }
            const newImages = values.filePhotoList.filter(el => el.name)
            let deleteImages = values.filePhotoList.filter(el => el.media)
            deleteImages = square_by_id_data.Images.filter(({ media: { id: id1 } }) => !deleteImages.some(({ media: { id: id2 } }) => id2 === id1))
            const address = {
                latitude: coords[0],
                longitude: coords[1]
            }

            await fetchUpdateAddress(address, square_by_id_data.address_id)
            for (let i = 0; i < newImages.length; i++) {
                let data = new FormData();
                let filedata = newImages[i].originFileObj
                delete filedata.uid
                data.append('image_file', filedata);
                data.append('title', newImages[i].name);
                await fetchUploadSquareImage(id, data)
            }
            for (let i = 0; i < newDocs.length; i++) {
                let data = new FormData();
                let filedata = newDocs[i].originFileObj
                delete filedata.uid
                data.append('file', filedata);
                data.append('name', newDocs[i].name);
                await fetchUploadSquareDocs(id, data)
            }
            for (let i = 0; i < deleteDocs.length; i++) {
                await fetchDeleteSquareDoc(id, deleteDocs[i].id)
            }
            for (let i = 0; i < deleteImages.length; i++) {
                await fetchDeleteSquareImage(id, deleteImages[i].id)
            }
            await fetchUpdateSquare(id, values)
            setEditMainInfo()
        } else {
            setErrorStatus({
                validateStatus: "error",
                help: "Некорректные данные"
            })
        }
    };

    const normFile = (e, type) => {
        if (type === 'fileOwnerList') {
            setFileOwnerList(e.fileList)
        } else {
            setFilePhotoList(e.fileList)
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const fieldsArray = [...fields]
        if (typeof fieldsArray[1].name === 'object') {
            fieldsArray.filter(el => el.name[0] === type)[0].value.splice(index, 1)
        } else {
            fieldsArray.filter(el => el.name === type)[0].value.splice(index, 1)
        }
        if (type === 'fileOwnerList') {
            const files = [...fileOwnerList];
            files.splice(index, 1);
            setFileOwnerList(files);
        } else {
            const files = [...filePhotoList];
            files.splice(index, 1);
            setFilePhotoList(files);
        }
        setFields(fieldsArray)
    }

    return (
        <Form
            layout="vertical"
            requiredMark={true}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
                setErrorStatus({})
            }}
        >
            <div className="twoColumnForm">
                <div className='twoColumnForm_column'>
                    <Form.Item
                        label={'Номер поля'}
                        name="number"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите номер поля' type="number" onKeyDown={numberInputFormat} />
                    </Form.Item>
                    <Form.Item
                        label={'Кадастровый номер'}
                        name="cadastral"
                        rules={[
                            {
                                required: false,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input placeholder='Введите кадастровый номер' />
                    </Form.Item>
                    <Form.Item
                        label={'ФИО фермера'}
                        name="farmer"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                        {...errorStatus}
                    >
                        <Input placeholder='Введите ФИО фермера' />
                    </Form.Item>
                    <Form.Item
                        label={'Владеет полем'}
                        name="is_owner"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Radio.Group onChange={(e) => setValue(e.target.value)}>
                            <Radio value={true}>Да</Radio>
                            <Radio value={false}>Нет</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className='twoColumnForm_column'>
                    <Form.Item
                        label={'Тип поля'}
                        name="square_type_id"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Select placeholder="Выберите тип поля">
                            {square_types.map(el => (
                                <Option value={el.id} key={el.id}>{el.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={'Культура'}
                        name="crop_type_id"
                        rules={[
                            {
                                required: false,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Select placeholder="Выберите тип культуры">
                            {crop_types.map(el => (
                                <Option value={el.id} key={el.id}>{el.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={'Фактический посев'}
                        name="sowing_type_id"
                        rules={[
                            {
                                required: false,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Select placeholder="Выберите фактический посев">
                            {sowing_types.map(el => (
                                <Option value={el.id} key={el.id}>{el.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <Form.Item
                label={'Описание'}
                name="description"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input.TextArea placeholder='Введите описание' />
            </Form.Item>
            <Form.Item
                label={'Размер поля (Га)'}
                name="size"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите размер поля' type="number" onKeyDown={numberInputFormat} />
            </Form.Item>
            {!value &&
                <div style={{ marginTop: 24 }}>
                    <p className='p14_500 p_mb12'>Загрузите документ, подтверждающий право пользования полем (необязательно)</p>
                    <div className={`${fileOwnerList.length === 0 ? '' : 'squareUploadBlock'}`}>
                        <div className='squareUploadBlock'>
                            {fileOwnerList.map((el, index) => (
                                <UploadCard key={`UploadCard_${index}`} title={el.name} onClick={() => deleteFile(index, 'fileOwnerList')} />
                            ))}
                        </div>
                        <Form.Item
                            name="fileOwnerList"
                            valuePropName="fileOwnerList"
                            getValueFromEvent={(e) => normFile(e, 'fileOwnerList')}
                            className={`${fileOwnerList.length === 0 ? '' : 'squareUpload'}`}
                        >
                            {fileOwnerList.length === 0
                                ?
                                <Upload.Dragger
                                    name="fileOwnerList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUpload}
                                >
                                    <div style={{ width: 190, margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить документы подтверждающие право пользования</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="fileOwnerList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsUpload}
                                    fileList={fileOwnerList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            }
            <div style={{ marginTop: 24 }}>
                <p className='p14_500 p_mb12'>Загрузите фото (необязательно)</p>
                <div className={`${filePhotoList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {filePhotoList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'filePhotoList')} />
                        ))}
                    </div>
                    <Form.Item
                        name="filePhotoList"
                        valuePropName="filePhotoList"
                        getValueFromEvent={(e) => normFile(e, 'filePhotoList')}
                        className={`${filePhotoList.length === 0 ? '' : 'squareUpload'}`}
                    >
                        {filePhotoList.length === 0
                            ?
                            <Upload.Dragger
                                name="filePhotoList"
                                action="/upload.do"
                                itemRender={null}
                                customRequest={dummyRequest}
                                {...propsUpload}
                            >
                                <div style={{ width: 190, margin: '100px auto' }}>
                                    <p className="subtext upload-text">Перетащите сюда, чтобы загрузить фото</p>
                                    <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                </div>
                            </Upload.Dragger>
                            :
                            <Upload.Dragger
                                name="filePhotoList"
                                action="/upload.do"
                                itemRender={null}
                                customRequest={dummyRequest}
                                {...propsUpload}
                                fileList={filePhotoList}
                            >
                                <img src={uploadIcon} alt="uploadIcon" />
                            </Upload.Dragger>
                        }
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        square_by_id_data: state.adminSquareById.square_by_id_data,
        square_types: state.squareTypes.square_types,
        crop_types: state.cropTypes.crop_types,
        sowing_types: state.sowingTypes.sowing_types,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchUpdateSquare: (id, data) => dispatch(fetchUpdateSquare(id, data)),
        fetchUploadSquareImage: (id, data) => dispatch(fetchUploadSquareImage(id, data)),
        fetchUploadSquareDocs: (id, data) => dispatch(fetchUploadSquareDocs(id, data)),
        fetchDeleteSquareDoc: (id, doc_id) => dispatch(fetchDeleteSquareDoc(id, doc_id)),
        fetchUpdateAddress: (data, address_id) => dispatch(fetchUpdateAddress(data, address_id)),
        fetchDeleteSquareImage: (id, img_id) => dispatch(fetchDeleteSquareImage(id, img_id))
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditSquare);