import { api_services } from 'api/services'
import {
    FETCH_DADATA_START,
    FETCH_DADATA_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchSearchCompany(query, limit) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_DADATA_START))
        try {
            const search_company = await api_services.GetCompanies(query, limit)
            dispatch(fetchSuccess(FETCH_DADATA_SUCCESS, {
                search_company: search_company.data
            }))
        } catch (e) {
        }
    }
}