import { api_technics } from 'api/technics'
import {
    FETCH_TEHNICS_BY_ID_DATA_START,
    FETCH_TEHNICS_BY_ID_DATA_SUCCESS,
    FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS,
    FETCH_CONTRACT_BY_ID_TECHNIC_MOVEMENT_DATA_SUCCESS,
    FETCH_CONTRACT_BY_ID_TECHNIC_STATISCICS_DATA_SUCCESS
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchTechnicsByID(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_TEHNICS_BY_ID_DATA_START))
        try {
            const tehnic_data = await api_technics.GetTechnicsById(id)
            dispatch(fetchSuccess(FETCH_TEHNICS_BY_ID_DATA_SUCCESS, {
                tehnics_by_id_data: tehnic_data.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchUpdateTechnicsByID(data, id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_TEHNICS_BY_ID_DATA_START))
        try {
            const tehnic_data = await api_technics.UpdateTechic(data, id)
            await dispatch(fetchTechnicsByID(id))
        } catch (e) {
        }
    }
}

export function fetchCurrentLocationTechnicsByID(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_TEHNICS_BY_ID_DATA_START))
        try {
            const current_location = await api_technics.PostCurrentLocationTechic(id)
            dispatch(fetchSuccess(FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS, {
                current_location: current_location.data.data
            }))
        } catch (e) {
            dispatch(fetchSuccess(FETCH_CURRENT_LOCATION_TEHNICS_BY_ID_DATA_SUCCESS, {
                current_location: null
            }))
        }
    }
}

export function fetchTechnicMovementById(contract_id, id, start_date, end_date) {
    return async dispatch => {
        try {
            const movement_location = await api_technics.GetTechicMovement(contract_id, id, start_date, end_date)
            dispatch(fetchSuccess(FETCH_CONTRACT_BY_ID_TECHNIC_MOVEMENT_DATA_SUCCESS, {
                movement: {
                    statistics: movement_location.data.data,
                    technic_id: id
                }
            }))
        } catch (e) {
        }
    }
}

export function fetchTechnicStatisticsById(technic_id, data) {
    return async dispatch => {
        try {
            const statistics_location = await api_technics.PostTechicStatistics(technic_id, data)
            dispatch(fetchSuccess(FETCH_CONTRACT_BY_ID_TECHNIC_STATISCICS_DATA_SUCCESS, {
                statistics: {
                    statistics: statistics_location.data.data,
                    technic_id
                }
            }))
        } catch (e) {
        }
    }
}